import { IModulo } from '@ps-erp-angular/shared';
import { BehaviorSubject, Observable } from 'rxjs';

export class ModuloState {
  /**
   * NOTE: Toda instância de BehaviorSubject<T>(firstValue as T) deve
   * conter três métodos de manipulação e que atendam as seguintes
   * especificações:
   * 1°: Deve ter seu acesso controlado por métodos getters and setters,
   *      não sendo possível o acesso direto.
   * 2°: Deve retorne um observable da instância (get)
   * 3°: Deve emitir um valor para o obeservable descrito na 2° especificação (set)
   * 4°: Deve retorne o último valor settado (get)
   */
  private static _modulos$ = new BehaviorSubject<IModulo[]>([]);

  public static addModulos(permissao: IModulo[]): void {
    const currentModulos = this.modulos;
    this.modulos = [...currentModulos, ...permissao];
  }

  public static get modulos$(): Observable<IModulo[]> {
    return this._modulos$.asObservable();
  }

  public static get modulos(): IModulo[] {
    return this._modulos$.getValue();
  }

  public static set modulos(permissoes: IModulo[]) {
    this._modulos$.next(permissoes);
  }
}
