import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService, ModalTermoAceiteService } from '@ps-erp-angular/shared';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'ps-digitalizacao-modal-termo-aceite',
  templateUrl: './modal-termo-aceite.component.html',
  styleUrls: ['./modal-termo-aceite.component.scss'],
})
export class ModalTermoAceiteComponent {
  formB: FormGroup = new FormGroup({});
  isCheckedAceiteTermos = false;
  certificados = [];

  isSpinning = false;
  loading = false;

  constructor(
    public formBuilder: FormBuilder,
    protected authService: AuthService,
    public service: ModalTermoAceiteService
  ) {
    this.formB = this.formBuilder.group({
      certificadoDigitalB64: [null, Validators.required],
      senhaCert: [null, Validators.required],
      isCheckedAceiteTermos: [null, Validators.required],
      cidade: [null],
      estado: [null],
      text: [null],
    });
  }

  upload = (file): boolean => {
    this.isSpinning = true;
    if (file.name.lastIndexOf('.pfx') === -1) {
      setTimeout(() => {
        this.certificados = [];
        this.isSpinning = false;
        this.service.notification.error('Arquivo', 'Tipo de arquivo inválido!');
      }, 1000);
      return false;
    }
    this.isSpinning = false;
    this.certificados = [file];
    this.formB.controls.certificadoDigitalB64.setValue(file);
    return false;
  };

  async assinarTermo(ugLogged?, querys?) {
    if (this.formB.invalid) {
      return this.service.notification.error(
        'Formulário invalido',
        'Preencha corretamente todos os campos.'
      );
    }
    const input = document.getElementById('txt').innerHTML;
    this.formB.controls.cidade.setValue(ugLogged.nomCidade);
    this.formB.controls.estado.setValue(ugLogged.estadoCidade);
    this.formB.controls.text.setValue(input);
    return await this.service
      .cadastrar(this.formB.value, 'assinante', querys)
      .then(async (response) => {
        await FileSaver.saveAs(response.data.urlTermoSigned);
        window.location.reload();
      })
      .catch((err) => {
        throw err;
      });
  }
}
