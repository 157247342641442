<nz-spin [nzSpinning]="loading" [nzSize]="'large'">
  <ps-complex-form
    [title]="'Pessoa Jurídica'"
    [tabIndex]="tabIndex"
    [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)"
  >
    <div table>
      <div class="row">
        <div nz-row nz-col nzSpan="12" nzOffset="12" nzJustify="end">
          <div class="m-b-10 m-r-20">
            <nz-input-group [nzPrefix]="prefixTemplate">
              <input
                type="text"
                nz-input
                placeholder="Buscar pessoa jurídica"
                nz-tooltip
                nzTooltipTitle="Digite um valor ou clique em consultar para buscar todos os registros"
                (click)="this.innerWidth <= 748 ? searchPessoaJuridica() : null"
                [nzTooltipVisible]="tooltipPessoaJuridica"
                nzTooltipPlacement="bottom"
                [(ngModel)]="searchInput"
                (ngModelChange)="getPessoasJuridicas($event)"
              />
            </nz-input-group>
            <ng-template #prefixTemplate>
              <i nz-icon nzType="search" class="opacity-05"></i>
            </ng-template>
          </div>
        </div>
      </div>

      <nz-table
        class="m-b-20"
        nzSize="small"
        #documentListTable
        [nzShowPagination]="false"
        [nzScroll]="{ y: '380px' }"
        style="overflow-y: overlay"
        [nzData]="displayData"
      >
        <thead>
          <tr>
            <th nzWidth="20px" [nzLeft]="getWidthContent() > 1024"></th>
            <ng-container *ngFor="let column of getTableColumns()">
              <th
                nzWidth="150px"
                *ngIf="checkList[column.value]"
                [nzSortFn]="column.compare"
              >
                {{ column.text }}
              </th>
            </ng-container>
            <th style="background: #edf1fd" nzWidth="40px">
              <button
                nz-button
                nzType="text"
                nzSize="small"
                nz-dropdown
                nzTrigger="click"
                [nzDropdownMenu]="colunas"
              >
                <i nz-icon nzType="setting"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="documentListTable.data">
            <tr
              (click)="selectItem(item)"
              (dblclick)="dbClickItem(item)"
              style="cursor: pointer"
              nzTooltipPlacement="left"
            >
              <td [nzLeft]="getWidthContent() > 1024">
                <label
                  nz-checkbox
                  [(ngModel)]="item.checked"
                  (ngModelChange)="selectItem(item)"
                ></label>
              </td>
              <ng-container *ngFor="let column of getTableColumns()">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
              <td></td>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <div *ngIf="displayData" nz-row class="row mt-10 content">
        <nz-pagination
          [nzPageIndex]="1"
          [nzTotal]="resultLength"
          (nzPageIndexChange)="pageChanged($event)"
        >
        </nz-pagination>
      </div>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>

    <div body>
      <form nz-form [formGroup]="formB" nzLayout="vertical">
        <h4 class="m-t-15">Informações básicas</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row>
          <div class="p-r-15" nz-col nzSpan="5" nzSm="5" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>CNPJ</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="cnpjTpl">
                <input
                  nz-input
                  formControlName="cnpj"
                  placeholder="Digite um cnpj válido"
                  [mask]="'00.000.000/0000-00'"
                  [dropSpecialCharacters]="true"
                  maxlength="18"
                  (ngModelChange)="searchPessoaByCnpj($event)"
                />
                <ng-container *ngIf="formB.controls.cnpj.hasError('mask')">
                  Valor inválido para cnpj!
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="p-r-15" nz-col nzSpan="19" nzSm="19" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Razão social</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="razaoSocialTpl">
                <input
                  nz-input
                  formControlName="razaoSocial"
                  placeholder="Digite uma razão social"
                  maxlength="150"
                />
                <ng-template #razaoSocialTpl let-control>
                  <ng-container *ngIf="control.hasError('razaoSocial')">
                    Valor inválido para razão social!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    Por favor digite uma razão social!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <h4 class="m-t-15">Representante(s) Legal(s)</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row class="align-items-center">
          <div nz-col nzSpan="5" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
            <nz-form-item>
              <nz-form-label nzRequired>CPF</nz-form-label>
              <nz-form-control [nzErrorTip]="cpfTpl">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonTpDoc">
                  <input
                    type="text"
                    nz-input
                    placeholder="Digite o CPF"
                    formControlName="cpf"
                    [mask]="'000.000.000-00'"
                    [dropSpecialCharacters]="true"
                    (ngModelChange)="consultarResponsavel($event)"
                  />
                </nz-input-group>
                <ng-template #suffixIconButtonTpDoc>
                  <button
                    *ngIf="formB.value.entidadePessoaFisica"
                    (click)="limparPF()"
                    nz-button
                    type="button"
                    nzType="text"
                    nzSearch
                  >
                    <i nz-icon nzType="close-circle"></i>
                  </button>

                  <button
                    (click)="showModalPF()"
                    nz-button
                    type="button"
                    nzType="primary"
                    nzSearch
                  >
                    <i nz-icon nzType="search"></i>
                  </button>
                </ng-template>

                <ng-container *ngIf="formB.controls.cpf.hasError('mask')">
                  Valor inválido para cpf!
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="p-r-15" nz-col nzSpan="7" nzSm="7" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Nome</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="nomeTpl">
                <input
                  nz-input
                  formControlName="nome"
                  placeholder="Digite um nome"
                  maxlength="150"
                />
                <ng-template #nomeTpl let-control>
                  <ng-container *ngIf="control.hasError('nome')">
                    Valor inválido para nome da pessoa!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    Por favor digite um nome de pessoa!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="8" nzSm="8" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>E-mail</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="descTpl">
                <input
                  nz-input
                  formControlName="email"
                  placeholder="Digite um e-mail válido"
                  maxlength="150"
                />
                <ng-template #descTpl let-control>
                  <ng-container *ngIf="control.hasError('email')">
                    Valor inválido para email!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    Por favor digite um email!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="2" nzSm="2" nzXs="12" class="text-right d-block">
            <button
              nz-button
              [disabled]="!verificaPFVazia()"
              (click)="limparPF()"
              nzType="primary"
              nzDanger
            >
              <i nz-icon nzType="clear"></i>
            </button>
          </div>

          <div nz-col nzSpan="2" nzSm="2" nzXs="12" class="p-l-10">
            <button
              nz-button
              [disabled]="!formB.value.entidadePessoaFisica"
              (click)="addPF(formB.value)"
              nzType="primary"
            >
              <i nz-icon nzType="plus"></i>
            </button>
          </div>

          <div>
            <nz-table
              class="m-b-20"
              nzSize="small"
              #responsaveisListTable
              [nzScroll]="{ y: '210px' }"
              style="overflow-y: overlay"
              [nzData]="formB.value.pessoaResponsaveis"
            >
              <thead>
                <tr>
                  <th>Responsáveis</th>
                  <th
                    nzWidth="30%"
                    style="background: #edf1fd; text-align: center"
                    [nzRight]="getWidthContent() > 1024"
                  >
                    Ações
                  </th>
                  <th style="background: #edf1fd" nzWidth="5%">
                    <button
                      nz-button
                      nzType="text"
                      nzSize="small"
                      nz-dropdown
                      nzTrigger="click"
                      [nzDropdownMenu]="colunas"
                    >
                      <i nz-icon nzType="setting"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  ngFor
                  let-item
                  [ngForOf]="responsaveisListTable.data"
                >
                  <tr>
                    <td>
                      <div nz-row>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ item.cpf }}</p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0" style="color: black; font-size: 16">
                            {{ item.nome }}
                          </p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ item.email }}</p>
                        </div>
                      </div>
                    </td>

                    <td
                      *ngIf="checkListPF.acoes"
                      nzRight
                      style="text-align-last: center"
                    >
                      <button
                        *ngIf="
                          item.cpf &&
                          item.nome &&
                          responsaveisListTable.data.length > 1
                        "
                        nz-button
                        nz
                        nzSize="small"
                        nzShape="circle"
                        (click)="removePF(item.cpf)"
                      >
                        <i nz-icon nzType="delete"></i>
                      </button>
                    </td>
                    <td></td>
                  </tr>
                </ng-template>
              </tbody>
            </nz-table>
          </div>
        </div>

        <h4 class="m-t-15">Endereço</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row>
          <div nz-col nzSpan="5" nzSm="5" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label nzRequired>CEP</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="cepTpl">
                <input
                  nz-input
                  class="text-disabled"
                  formControlName="cep"
                  [mask]="'00000-000'"
                  [dropSpecialCharacters]="true"
                  placeholder="Digite um cep"
                  maxlength="9"
                  (blur)="consultaCep($event)"
                />
                <ng-container *ngIf="formB.controls.cep.hasError('mask')">
                  Valor inválido para cep!
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="3" nzSm="3" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label>Número</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="numeroTpl">
                <input
                  nz-input
                  class="text-disabled"
                  formControlName="numero"
                  placeholder="Digite um numero"
                  maxlength="100"
                />
                <ng-template #numeroTpl let-control>
                  <ng-container *ngIf="control.hasError('numero')">
                    Valor inválido para número!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="13" nzSm="13" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label>Complemento</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="complementoTpl">
                <input
                  nz-input
                  class="text-disabled"
                  formControlName="complemento"
                  placeholder="Digite um complemento"
                  maxlength="100"
                />
                <ng-template #complementoTpl let-control>
                  <ng-container *ngIf="control.hasError('complemento')">
                    Valor inválido para complemento!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="7" nzSm="7" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label>Logradouro</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="logradouroTpl">
                <input
                  nz-input
                  class="text-disabled"
                  formControlName="nomLogradouro"
                  placeholder="Digite um logradouro"
                  maxlength="100"
                />
                <ng-template #logradouroTpl let-control>
                  <ng-container *ngIf="control.hasError('nomLogradouro')">
                    Valor inválido para logradouro!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="6" nzSm="6" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label>Bairro</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="bairroTpl">
                <input
                  nz-input
                  class="text-disabled"
                  formControlName="nomBairro"
                  placeholder="Digite um bairro"
                  maxlength="100"
                />
                <ng-template #bairroTpl let-control>
                  <ng-container *ngIf="control.hasError('nomBairro')">
                    Valor inválido para bairro!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="6" nzSm="6" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label>Cidade</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="cidadeTpl">
                <input
                  nz-input
                  class="text-disabled"
                  formControlName="nomCidade"
                  placeholder="Digite um cidade"
                  maxlength="100"
                />
                <ng-template #cidadeTpl let-control>
                  <ng-container *ngIf="control.hasError('nomCidade')">
                    Valor inválido para cidade!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="5" nzSm="5" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label>UF</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="ufTpl">
                <input
                  nz-input
                  class="text-disabled"
                  formControlName="nomUf"
                  placeholder="Digite uma uf"
                  maxlength="100"
                />
                <ng-template #ufTpl let-control>
                  <ng-container *ngIf="control.hasError('nomUf')">
                    Valor inválido para UF!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>

  <nz-dropdown-menu #colunas="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let column of getTableColumns()">
        <label nz-checkbox [(ngModel)]="checkList[column.value]">{{
          column.text
        }}</label>
      </li>
      <li nz-menu-item class="text-center">
        <button nz-button nzType="text" nzSize="small" (click)="resetar()">
          Resetar
        </button>
      </li>
    </ul>
  </nz-dropdown-menu>
</nz-spin>
