<nz-spin [nzSpinning]="formLoading" [nzSize]="'large'">
  <div style="text-align: end">
    <p style="text-align: end">Atualizado em: {{ getDateNow }}</p>
  </div>
  <div class="row is-not-mobile">
    <div class="col-md-24 col-xl-12 p-b-15">
      <nz-collapse nzGhost>
        <nz-collapse-panel
          *ngFor="let panel of panels"
          [nzHeader]="panel.name"
          [nzActive]="panel.active"
        >
          <div nz-row class="d-flex">
            <div nz-col nzSpan="24" nzXs="24">
              <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                <div class="p-r-10">
                  <!-- <div *ngIf="
                    adm
                  " class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="d-flex">
                      <div class="p-r-10">
                        <nz-select style="width: 250px" nzShowSearch nzAllowClear [(ngModel)]="assinanteSelected"
                          nzPlaceHolder="Selecione um signatário" (ngModelChange)="addFiltroPorAssinante()">
                          <nz-option *ngFor="let assinante of listSignatarios" [nzValue]="assinante"
                            [nzLabel]="assinante.text">
                          </nz-option>
                        </nz-select>
                      </div>

                      <div class="align-self-center">
                        <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'"
                          (nzOnClose)="removeFiltroPorAssinante(assinanteTag.cpf)" class="filters"
                          *ngFor="let assinanteTag of assinantesSelected">
                          {{ assinanteTag.text }}
                        </nz-tag>
                      </div>
                    </div>
                  </div> -->

                  <div class="p-l-10" nz-col nzSpan="24">
                    <!-- <nz-select style="width: 250px" [(ngModel)]="intervalTimeSelected"
                      (ngModelChange)="addFilter(this.showDocsAvulsos, intervalTimeSelected)">
                      <nz-option *ngFor="let interval of intervalsTime" [nzValue]="interval[0]" [nzLabel]="interval[1]">
                      </nz-option>
                    </nz-select> -->
                    <nz-select
                      *ngIf="adm"
                      style="width: 350px"
                      nzShowSearch
                      nzAllowClear
                      [(ngModel)]="assinanteSelected"
                      nzPlaceHolder="Selecione um signatário"
                      (ngModelChange)="addFiltroPorAssinante()"
                    >
                      <nz-option
                        *ngFor="let assinante of listSignatarios"
                        [nzValue]="assinante"
                        [nzLabel]="assinante.text"
                      >
                      </nz-option>
                    </nz-select>
                    <!-- <div class="align-self-center">
                      <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'"
                        (nzOnClose)="removeFiltroPorAssinante(assinanteTag.cpf)" class="filters"
                        *ngFor="let assinanteTag of assinantesSelected">
                        {{ assinanteTag.text }}
                      </nz-tag>
                    </div> -->

                    <nz-avatar
                      (click)="setFilterType(2)"
                      [ngStyle]="{
                        opacity: filterType.includes(2) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="field-time"
                      class="m-l-15 cursor-pointer"
                      [ngClass]="
                        filterType.includes(2)
                          ? 'ant-avatar-gold'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por pendentes"
                      nzTooltipPlacement="topRight"
                    >
                    </nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(1)"
                      [ngStyle]="{
                        opacity: filterType.includes(1) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="form"
                      class="m-l-15 cursor-pointer"
                      [ngClass]="
                        filterType.includes(1)
                          ? 'ant-avatar-blue'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por assinados"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(4)"
                      [ngStyle]="{
                        opacity: filterType.includes(4) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="check-circle"
                      class="m-l-15 cursor-pointer"
                      [ngClass]="
                        filterType.includes(4)
                          ? 'ant-avatar-green'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por concluídos"
                      nzTooltipPlacement="topRight"
                    >
                    </nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(3)"
                      [ngStyle]="{
                        opacity: filterType.includes(3) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="close-circle"
                      class="m-l-10 cursor-pointer"
                      [ngClass]="
                        filterType.includes(3)
                          ? 'ant-avatar-red'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por desacordos"
                      nzTooltipPlacement="topRight"
                    >
                    </nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(5)"
                      [ngStyle]="{
                        opacity: filterType.includes(5) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="history"
                      class="m-l-10 cursor-pointer"
                      [ngClass]="
                        filterType.includes(5)
                          ? 'ant-avatar-orange'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por expirados"
                      nzTooltipPlacement="topRight"
                    >
                    </nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(6)"
                      [ngStyle]="{
                        opacity: filterType.includes(6) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="stop"
                      class="ant-avatar-dark m-l-10 cursor-pointer"
                      nz-tooltip
                      nzTooltipTitle="Filtrar por cancelados"
                      nzTooltipPlacement="topRight"
                    >
                    </nz-avatar>

                    <nz-avatar
                      (click)="changeViewDocsAvulsos()"
                      [ngStyle]="{
                        opacity: this.showDocsAvulsos ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="file-add"
                      class="ant-avatar-dark m-l-10 cursor-pointer"
                      nz-tooltip
                      nzTooltipTitle="Filtrar por documentos avulsos"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>

                    <nz-tag
                      *ngIf="
                        documentService.resultDashBoardDocuments?.length > 0
                      "
                      [nzColor]="'blue'"
                      [nzMode]="'closeable'"
                      (nzOnClose)="clearFilterByDashboard()"
                      class="filters"
                    >
                      Filtro por dashboard -
                      {{ documentService.getNamesFilters(filterType) }}s
                    </nz-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div nz-row class="m-l-20">
            <nz-select
              style="width: 350px"
              nzPlaceHolder="Selecione o campo"
              [(ngModel)]="fieldSelected"
              (ngModelChange)="getOptionsSelect(fieldSelected)"
            >
              <nz-option
                *ngFor="let interval of arrColumnsSelect"
                [nzValue]="interval"
                [nzLabel]="interval?.text"
              >
              </nz-option>
            </nz-select>

            <!-- <div class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"> -->
            <nz-input-group
              *ngIf="fieldSelected && fieldSelected.type === 'input'"
              class="m-b-10 m-l-20"
              nz-col
              nzSpan="8"
              nzXl="8"
              nzLg="12"
              nzXs="24"
              [nzPrefix]="prefixTemplate"
            >
              <input
                type="text"
                class="text-disabled"
                nz-input
                placeholder="Informe um valor"
                [(ngModel)]="searchInput"
              />
            </nz-input-group>

            <nz-select
              *ngIf="fieldSelected && fieldSelected.type === 'select'"
              class="m-b-10 m-l-20"
              nz-col
              nzSpan="8"
              nzXl="8"
              nzLg="12"
              nzXs="24"
              nzPlaceHolder="Selecione o campo"
              [(ngModel)]="searchInput"
            >
              <nz-option
                *ngFor="let result of selectedValues"
                [nzValue]="result"
                [nzLabel]="result?.text"
              >
              </nz-option>
            </nz-select>

            <div
              *ngIf="fieldSelected && fieldSelected.type === 'range-picker'"
              nzSpan="8"
              nzXl="8"
              nzLg="12"
              nzXs="24"
              class="m-b-10 m-l-20"
            >
              <nz-range-picker
                nzFormat="dd/MM/yyyy"
                [(ngModel)]="searchInput"
              ></nz-range-picker>
            </div>

            <div
              *ngIf="fieldSelected && fieldSelected.type === 'date-picker'"
              nzSpan="8"
              nzXl="12"
              nzLg="12"
              nzXs="24"
              class="m-b-10 m-l-20"
            >
              <nz-date-picker
                nzShowTime
                nzFormat="dd/MM/yyyy"
                [(ngModel)]="searchInput"
              ></nz-date-picker>
            </div>

            <div
              nz-row
              *ngIf="fieldSelected && fieldSelected.type === 'boolean'"
              class="m-b-10 m-l-20"
            >
              <div nz-col nzSpan="24">
                <nz-radio-group
                  class="style-radio"
                  [(ngModel)]="searchInput"
                  nzName="radiogroup"
                >
                  <label nz-radio [nzValue]="'Sim'">Sim</label>
                  <label nz-radio [nzValue]="'Não'">Não</label>
                </nz-radio-group>
              </div>
            </div>

            <ng-template #prefixTemplate>
              <i nz-icon nzType="search" class="opacity-05"></i>
            </ng-template>
            <!-- </div> -->

            <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
              <button
                nz-button
                [disabled]="!searchInput"
                (click)="addFiltroPorCampo()"
                nzType="primary"
              >
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>

          <div nz-row class="m-t-10 m-l-20">
            <div
              *ngIf="fieldsSelected.length != 0"
              nz-col
              nzSpan="12"
              nzXl="12"
              nzLg="12"
              nzXs="24"
            >
              <nz-input-group [nzPrefix]="prefixTemplate">
                <div class="align-self-center">
                  <nz-tag
                    style="margin-bottom: 0"
                    [nzColor]="'blue'"
                    [nzMode]="'closeable'"
                    (nzOnClose)="removeFiltroPorField(i)"
                    class="filters"
                    *ngFor="
                      let fieldsValuesTag of fieldsSelected;
                      let i = index
                    "
                  >
                    {{ fieldsValuesTag.text }}
                  </nz-tag>
                </div>
              </nz-input-group>
            </div>
            <div
              nz-col
              [class]="'m-t-10 p-l-20'"
              [nzOffset]="fieldsSelected.length != 0 ? 8 : 20"
              nzSpan="3"
              nzXl="3"
              nzLg="3"
              nzXs="24"
            >
              <button
                nzSize="small"
                nz-button
                nzType="primary"
                (click)="filterDocuments()"
              >
                <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
              </button>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>

  <!-- visão até tablet (x <= 768px) -->
  <div class="row is-mobile">
    <div class="col-md-24 col-xl-12 p-b-15">
      <nz-collapse nzGhost>
        <nz-collapse-panel
          *ngFor="let panel of panels"
          [nzHeader]="panel.name"
          [nzActive]="panel.active"
        >
          <div nz-row class="d-flex">
            <div nz-col nzSpan="24" nzXs="24">
              <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                <div class="p-r-10">
                  <div
                    *ngIf="
                      documentService.resultDashBoardDocuments?.length === 0 &&
                      adm
                    "
                    class="p-l-10 p-b-15"
                    nz-col
                    nzSpan="24"
                  >
                    <div class="d-flex">
                      <div class="p-r-10">
                        <nz-select
                          style="width: 250px"
                          nzShowSearch
                          nzAllowClear
                          [(ngModel)]="assinanteSelected"
                          nzPlaceHolder="Selecione um signatário"
                          (ngModelChange)="addFiltroPorAssinante()"
                        >
                          <nz-option
                            *ngFor="let assinante of allAssinantes"
                            [nzValue]="assinante"
                            [nzLabel]="assinante?.text"
                          >
                          </nz-option>
                        </nz-select>
                      </div>

                      <div class="align-self-center">
                        <nz-tag
                          style="margin-bottom: 0"
                          [nzColor]="'blue'"
                          [nzMode]="'closeable'"
                          (nzOnClose)="
                            removeFiltroPorAssinante(assinanteTag.cpf)
                          "
                          class="filters"
                          *ngFor="let assinanteTag of assinantesSelected"
                        >
                          {{ assinanteTag.text }}
                        </nz-tag>
                      </div>
                    </div>
                  </div>

                  <div class="p-l-10" nz-col nzSpan="24">
                    <nz-select
                      style="width: 250px"
                      [(ngModel)]="intervalTimeSelected"
                      (ngModelChange)="getDocumentsPerPeriod()"
                      *ngIf="
                        documentService.resultDashBoardDocuments?.length === 0
                      "
                    >
                      <nz-option
                        *ngFor="let interval of intervalsTime"
                        [nzValue]="interval[0]"
                        [nzLabel]="'Período de ' + interval[1]"
                      ></nz-option>
                      <nz-option
                        [nzValue]="null"
                        nzLabel="Período Total"
                      ></nz-option>
                    </nz-select>

                    <nz-avatar
                      (click)="setFilterType(2)"
                      [ngStyle]="{
                        opacity: filterType.includes(2) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="field-time"
                      class="m-l-15 cursor-pointer"
                      [ngClass]="
                        filterType.includes(2)
                          ? 'ant-avatar-gold'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por pendentes"
                      [nzTooltipVisible]="tooltipVisible.filter2"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(1)"
                      [ngStyle]="{
                        opacity: filterType.includes(1) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="form"
                      class="m-l-15 cursor-pointer"
                      [ngClass]="
                        filterType.includes(1)
                          ? 'ant-avatar-blue'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por assinados"
                      [nzTooltipVisible]="tooltipVisible.filter1"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(4)"
                      [ngStyle]="{
                        opacity: filterType.includes(4) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="check-circle"
                      class="m-l-15 cursor-pointer"
                      [nzTooltipVisible]="tooltipVisible.filter4"
                      [ngClass]="
                        filterType.includes(4)
                          ? 'ant-avatar-green'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por concluídos"
                      nzTooltipPlacement="topRight"
                    >
                    </nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(3)"
                      [nzTooltipVisible]="tooltipVisible.filter3"
                      [ngStyle]="{
                        opacity: filterType.includes(3) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="close-circle"
                      class="m-l-10 cursor-pointer"
                      [ngClass]="
                        filterType.includes(3)
                          ? 'ant-avatar-red'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por desacordos"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>

                    <nz-avatar
                      (click)="setFilterType(5)"
                      [nzTooltipVisible]="tooltipVisible.filter5"
                      [ngStyle]="{
                        opacity: filterType.includes(5) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="history"
                      class="m-l-10 cursor-pointer"
                      [ngClass]="
                        filterType.includes(5)
                          ? 'ant-avatar-orange'
                          : 'ant-avatar-dark'
                      "
                      nz-tooltip
                      nzTooltipTitle="Filtrar por expirados"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>
                    <nz-avatar
                      (click)="setFilterType(6)"
                      [nzTooltipVisible]="tooltipVisible.filter6"
                      [ngStyle]="{
                        opacity: filterType.includes(6) ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="stop"
                      class="ant-avatar-dark m-l-10 cursor-pointer"
                      nz-tooltip
                      nzTooltipTitle="Filtrar por cancelados"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>
                    <nz-avatar
                      *ngIf="
                        documentService.resultDashBoardDocuments?.length === 0
                      "
                      (click)="changeViewDocsAvulsos()"
                      [ngStyle]="{
                        opacity: this.showDocsAvulsos ? '100%' : '40%'
                      }"
                      [nzSize]="40"
                      nzIcon="file-add"
                      class="ant-avatar-dark m-l-10 cursor-pointer"
                      nz-tooltip
                      nzTooltipTitle="Filtrar por documentos avulsos"
                      nzTooltipPlacement="topRight"
                    ></nz-avatar>

                    <nz-tag
                      *ngIf="
                        documentService.resultDashBoardDocuments?.length > 0
                      "
                      [nzColor]="'blue'"
                      [nzMode]="'closeable'"
                      (nzOnClose)="clearFilterByDashboard()"
                      class="filters"
                    >
                      Filtro por dashboard -
                      {{ documentService.getNamesFilters(filterType) }}s
                    </nz-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div nz-row class="m-l-20">
            <nz-select
              style="width: 350px"
              nzPlaceHolder="Selecione o campo"
              [(ngModel)]="fieldSelected"
              (ngModelChange)="getOptionsSelect(fieldSelected)"
            >
              <nz-option
                *ngFor="let interval of arrColumnsSelect"
                [nzValue]="interval"
                [nzLabel]="interval?.text"
              >
              </nz-option>
            </nz-select>

            <!-- <div class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"> -->
            <nz-input-group
              *ngIf="fieldSelected && fieldSelected.type === 'input'"
              class="m-b-10 m-l-20"
              nz-col
              nzSpan="8"
              nzXl="8"
              nzLg="12"
              nzXs="24"
              [nzPrefix]="prefixTemplate"
            >
              <input
                type="text"
                class="text-disabled"
                nz-input
                placeholder="Informe um valor"
                [(ngModel)]="searchInput"
              />
            </nz-input-group>

            <nz-select
              *ngIf="fieldSelected && fieldSelected.type === 'select'"
              class="m-b-10 m-l-20"
              nz-col
              nzSpan="8"
              nzXl="8"
              nzLg="12"
              nzXs="24"
              nzPlaceHolder="Selecione o campo"
              [(ngModel)]="searchInput"
            >
              <nz-option
                *ngFor="let result of selectedValues"
                [nzValue]="result"
                [nzLabel]="result?.text"
              >
              </nz-option>
            </nz-select>

            <div
              *ngIf="fieldSelected && fieldSelected.type === 'date-picker'"
              nzSpan="8"
              nzXl="8"
              nzLg="12"
              nzXs="24"
              class="m-b-10 m-l-20"
            >
              <nz-date-picker
                nzShowTime
                nzFormat="dd/MM/yyyy HH:mm:ss"
                [(ngModel)]="searchInput"
              ></nz-date-picker>
            </div>

            <div
              nz-row
              *ngIf="fieldSelected && fieldSelected.type === 'boolean'"
              class="m-b-10 m-l-20"
            >
              <div nz-col nzSpan="24">
                <nz-radio-group
                  class="style-radio"
                  [(ngModel)]="searchInput"
                  nzName="radiogroup"
                >
                  <label nz-radio [nzValue]="'Sim'">Sim</label>
                  <label nz-radio [nzValue]="'Não'">Não</label>
                </nz-radio-group>
              </div>
            </div>

            <ng-template #prefixTemplate>
              <i nz-icon nzType="search" class="opacity-05"></i>
            </ng-template>
            <!-- </div> -->

            <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
              <button
                nz-button
                [disabled]="!searchInput"
                (click)="addFiltroPorCampo()"
                nzType="primary"
              >
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>

          <div
            *ngIf="showComboTags"
            class="m-t-10 m-l-20"
            nz-col
            nzSpan="12"
            nzXl="14"
            nzLg="12"
            nzXs="24"
          >
            <nz-input-group [nzPrefix]="prefixTemplate">
              <div class="align-self-center">
                <nz-tag
                  style="margin-bottom: 0"
                  [nzColor]="'blue'"
                  [nzMode]="'closeable'"
                  (nzOnClose)="removeFiltroPorField(fieldsValuesTag.field)"
                  class="filters"
                  *ngFor="let fieldsValuesTag of fieldsSelected"
                >
                  {{ fieldsValuesTag.text }}
                </nz-tag>
              </div>
            </nz-input-group>
          </div>
          <button
            nzSize="small"
            class="m-t-10 m-l-20"
            nz-button
            nzType="primary"
            (click)="filterDocuments()"
            nz-tooltip
          >
            <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
          </button>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>
  <div>
    <nz-table
      class="m-b-20"
      nzSize="small"
      [nzLoading]="loading"
      #documentListTable
      [nzShowPagination]="false"
      [nzScroll]="{ y: '420px' }"
      style="overflow-y: overlay"
      [nzData]="displayData"
    >
      <thead>
        <tr>
          <th
            style="background: #edf1fd"
            [nzLeft]="documentService.getWidthContent() > 1024"
            nzWidth="70px"
          ></th>
          <th
            style="background: #edf1fd"
            nzWidth="70px"
            [nzLeft]="documentService.getWidthContent() > 1024"
          >
            <label
              nz-checkbox
              [nzIndeterminate]="checkAllIndeterminate"
              (ngModelChange)="updateAllChecked()"
              [(ngModel)]="checkedAll"
            ></label>
          </th>
          <ng-container *ngIf="documentService.getColumnsStatus(tableColumns)">
            <th
              nzWidth="150px"
              *ngIf="checkList.status"
              [nzSortFn]="
                documentService.getColumnsStatus(tableColumns).compare
              "
            >
              {{ documentService.getColumnsStatus(tableColumns).text }}
            </th>
          </ng-container>
          <ng-container
            *ngIf="documentService.getColumnsRatificado(tableColumns)"
          >
            <th
              nzWidth="150px"
              *ngIf="checkList.ratificado"
              [nzSortFn]="
                documentService.getColumnsRatificado(tableColumns).compare
              "
            >
              {{ documentService.getColumnsRatificado(tableColumns).text }}
            </th>
          </ng-container>
          <ng-container
            *ngFor="let column of documentService.getTableColumns(tableColumns)"
          >
            <th
              nzWidth="150px"
              *ngIf="checkList[column.value]"
              [nzSortFn]="column.compare"
            >
              {{ column.text }}
            </th>
          </ng-container>

          <ng-container
            *ngIf="documentService.getColumnsChancela(tableColumns)"
          >
            <th
              nzWidth="150px"
              *ngIf="checkList.chancela"
              [nzSortFn]="
                documentService.getColumnsChancela(tableColumns).compare
              "
            >
              {{ documentService.getColumnsChancela(tableColumns).text }}
            </th>
          </ng-container>

          <th
            *ngIf="checkList.acoes"
            style="background: #edf1fd"
            nzWidth="110px"
            [nzRight]="documentService.getWidthContent() > 1024"
          >
            Ações
          </th>
          <th
            style="background: #edf1fd"
            nzWidth="40px"
            [nzRight]="documentService.getWidthContent() > 1024"
          >
            <button
              nz-button
              nzType="text"
              nzSize="small"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="colunas"
            >
              <i nz-icon nzType="setting"></i>
            </button>
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-template
          ngFor
          let-item
          [ngForOf]="documentListTable.data"
          let-indexArr="index"
        >
          <!---colocar o atributo de tooltipeVisible do tipo booleano dentro de documentListTable.data e passar esse
             atributo no propertybiding nzTooltipVisible da tr-->
          <tr
            style="cursor: pointer"
            (dblclick)="navigateToDocumentDetail(item, indexArr)"
            (click)="toggleTooltipVisible(item)"
            [nzTooltipVisible]="item.tooltipeVisibleTableRow"
            nz-tooltip
            [nzTooltipTitle]="
              innerWidth < 768
                ? null
                : 'Clique duas vezes para visualizar mais detalhes'
            "
            nzTooltipPlacement="topRight"
          >
            <td
              [nzLeft]="getWidthContent() > 1024"
              [(nzExpand)]="item.expand"
              (click)="modifyDocument(item, item.expand, indexArr)"
            ></td>
            <td
              [nzLeft]="documentService.getWidthContent() > 1024"
              style="padding: 6px"
            >
              <label
                nz-checkbox
                [(ngModel)]="item.checked"
                (ngModelChange)="check(item, item.checked)"
              ></label>
              <a
                nz-dropdown
                nzTrigger="click"
                nz-tooltip
                [nzTooltipVisible]="item.tooltipeVisibleEye"
                (click)="toggleTooltipVisibleEye(item)"
                [nzTooltipTitle]="
                  innerWidth < 768
                    ? null
                    : 'Clique para visualizar a descrição do documento'
                "
                nzTooltipPlacement="top"
                [nzDropdownMenu]="menu"
              >
                <i nz-icon nzType="eye" theme="outline"></i>
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item>
                    <span class="menu-item-concent"
                      >Descrição do documento:
                      {{ documentService.visualizeDocDescription(item) }}</span
                    >
                  </li>
                </ul>
              </nz-dropdown-menu>
            </td>
            <ng-container
              *ngIf="documentService.getColumnsStatus(tableColumns)"
            >
              <td *ngIf="checkList.status">
                <nz-tag class="m-b-0" [nzColor]="getCorDocumento(item)">
                  {{ getNomeStatusDocumento(item) }}
                  {{ getQuantidadeDeAssinantesProgressBar(item) }}</nz-tag
                >
                <div>
                  <nz-progress
                    *ngIf="mostarProgressBarAndClock(item)"
                    [nzPercent]="documentService.progressBar(item)"
                    [nzStrokeColor]="{ '0%': '#108ee9', '100%': '#87d068' }"
                    [nzFormat]="documentService.formatProgress"
                    nzSize="small"
                  ></nz-progress>
                </div>
              </td>
            </ng-container>
            <ng-container
              *ngIf="documentService.getColumnsRatificado(tableColumns)"
            >
              <td *ngIf="checkList.ratificado">
                <nz-avatar
                  style="background-color: #ffffff; opacity: 100%"
                  [nzSize]="40"
                  [nzIcon]="item.ratificado === true ? 'trademark' : null"
                  class="m-l-10 cursor-pointer"
                  [ngClass]="
                    showColorRatificado(item.signatarios) === true
                      ? 'ant-avatar-gold'
                      : 'ant-avatar-green'
                  "
                ></nz-avatar>
              </td>
            </ng-container>
            <ng-container
              *ngFor="
                let column of documentService.getTableColumns(tableColumns)
              "
            >
              <td
                (click)="previewDocVinculado(item)"
                *ngIf="
                  checkList[column.value] &&
                  column.value === 'numeroDocOriginal'
                "
                nz-tooltip
                [nzTooltipTitle]="
                  item.numeroDocOriginal || item.hashDocOriginal
                    ? 'Clique para visualizar o documento vinculado'
                    : null
                "
                nzTooltipPlacement="bottom"
              >
                <a>{{ documentService.prepareColumnValue(item, column) }}</a>
              </td>
              <td
                *ngIf="
                  checkList[column.value] &&
                  column.value === 'dataLimiteAssinatura'
                "
              >
                <i
                  *ngIf="mostarProgressBarAndClock(item)"
                  nz-icon
                  nzType="clock-circle"
                  class="ant-scroll-number-custom-component p-l-10"
                  [style]="
                    documentService.getColorByDataLimiteAssinatura(
                      item.dataLimiteAssinatura
                    )
                  "
                ></i>
                {{
                  documentService.getDateFormatted(item.dataLimiteAssinatura)
                }}
              </td>
              <td
                *ngIf="
                  checkList[column.value] &&
                  column.value !== 'dataLimiteAssinatura' &&
                  column.value !== 'numeroDocOriginal'
                "
              >
                {{ documentService.prepareColumnValue(item, column) }}
              </td>
            </ng-container>
            <ng-container
              *ngIf="documentService.getColumnsChancela(tableColumns)"
            >
              <td *ngIf="checkList.chancela">
                <nz-avatar
                  style="background-color: #ffffff; opacity: 100%"
                  [nzSize]="40"
                  [nzIcon]="
                    item.chancela === true ? 'check-circle' : 'close-circle'
                  "
                  class="m-l-10 cursor-pointer"
                  [ngClass]="
                    item.chancela === true
                      ? 'ant-avatar-green'
                      : 'ant-avatar-red'
                  "
                ></nz-avatar>
              </td>
            </ng-container>

            <td
              [colspan]="2"
              *ngIf="checkList.acoes"
              [nzRight]="documentService.getWidthContent() > 1024"
            >
              <div class="d-flex m-r-20">
                <div
                  (click)="viewDoc(item)"
                  class="font-size-25 p-l-20 p-r-20"
                  nzTooltipPlacement="left"
                >
                  <i
                    nz-icon
                    [nzType]="'file-pdf'"
                    theme="twotone"
                    [twoToneColor]="documentService.themeColors.red"
                  ></i>
                </div>
                <div>
                  <a
                    class="text-dark font-size-20"
                    nz-dropdown
                    [nzTrigger]="'click'"
                    [nzPlacement]="'bottomRight'"
                    [nzDropdownMenu]="latestUploadDropDown"
                  >
                    <i nz-icon nzType="ellipsis" theme="outline"></i>
                  </a>
                  <nz-dropdown-menu #latestUploadDropDown="nzDropdownMenu">
                    <ul nz-menu>
                      <li
                        nz-menu-item
                        (click)="downloadDoc(item, 'DownloadOriginal')"
                      >
                        <i nz-icon nzType="download" theme="outline"></i>
                        <span class="m-l-5">Baixar documento original</span>
                      </li>
                      <li
                        *ngIf="item.numProcesso"
                        nz-menu-item
                        (click)="documentService.visualizeProcess(item)"
                      >
                        <i nz-icon nzType="eye" nzTheme="outline"></i>
                        <span class="m-l-5">Visualizar processo</span>
                      </li>
                      <li
                        *ngIf="item.statusDocumento === statusDoc.Confirmado"
                        nz-menu-item
                        (click)="downloadDoc(item, 'DownloadAssinado')"
                      >
                        <i nz-icon nzType="download" theme="outline"></i>
                        <span class="m-l-5">Baixar documento assinado</span>
                      </li>
                      <li
                        *ngIf="
                          (adm || item.usCriacao === authService.getIdUser()) &&
                          isSigned(item) === false &&
                          item.statusDocumento === statusDoc.Pendente &&
                          item.avulso === true
                        "
                        nz-menu-item
                        (click)="editDoc(item)"
                      >
                        <i nz-icon nzType="edit" theme="outline"></i>
                        <span class="m-l-5">Editar</span>
                      </li>
                    </ul>
                  </nz-dropdown-menu>
                </div>
              </div>
            </td>
          </tr>

          <tr [nzExpand]="item.expand">
            <nz-spin
              *ngIf="item.expand"
              [nzSpinning]="loadingSignatarios"
              [nzSize]="'default'"
            >
              <nz-table
                #innerTable
                [nzData]="item.signatarios"
                nzSize="middle"
                [nzShowPagination]="false"
              >
                <thead>
                  <tr>
                    <th class="p-l-15">Data de Assinatura</th>
                    <th *ngIf="item.ratificado">Data da Ratificação</th>
                    <th>Nome</th>
                    <th *ngIf="documentService.getColumnsStatus(tableColumns)">
                      Status
                    </th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of innerTable.data">
                    <td>
                      {{ data?.dataAssinatura | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </td>
                    <td *ngIf="item.ratificado">
                      {{ data?.dataRatificacao | date: 'dd/MM/yyyy HH:mm:ss' }}
                    </td>
                    <td>{{ data?.nome || data?.nomeRepresentante }}</td>
                    <td *ngIf="documentService.getColumnsStatus(tableColumns)">
                      <nz-tag
                        class="m-b-0"
                        [nzColor]="
                          this.documentService.themeColors[
                            colorStatusDoc[data?.status]
                          ]
                        "
                      >
                        {{ statusDocLabel[data?.status] }}</nz-tag
                      >
                    </td>
                    <td>
                      <button
                        *ngIf="
                          data?.status === statusDoc.Pendente ||
                          data?.status === statusDoc.PendenteRatificacao
                        "
                        nzSize="small"
                        nz-button
                        nzType="primary"
                        (click)="
                          notifySigner(data?.idPessoaFisica, item.idPrivado)
                        "
                        nz-tooltip
                        [nzTooltipTitle]="getEmail(data?.idPessoaFisica, data)"
                        nzTooltipPlacement="topRight"
                      >
                        <i nz-icon nzType="mail" nzTheme="outline"></i>Notificar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </nz-table>
            </nz-spin>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
    <nz-pagination
      style="text-align: end"
      [nzSize]="'small'"
      [nzPageIndex]="1"
      [nzTotal]="pageTotal"
      (nzPageIndexChange)="pageChange($event)"
    ></nz-pagination>

    <div
      class="shadow-default p-b-1"
      nz-row
      style="background: rgba(63, 135, 245, 0.1); align-items: center"
    >
      <div
        nz-col
        nzSpan="12"
        nzMd="12"
        nzXs="24"
        class="text-left text-xs-center p-l-10 p-b-5"
      >
        Documentos selecionados:
        <b> {{ checkedAll ? pageTotal : dataDocumentsCheckeds().length }} </b>
      </div>

      <div
        nz-col
        nzSpan="12"
        nzMd="12"
        nzXs="24"
        class="text-right text-xs-center p-r-10 p-b-5"
      >
        Documentos listados: <b> {{ pageTotal || 0 }} </b>
      </div>

      <div
        nz-col
        nzSpan="6"
        nzLg="6"
        nzMd="12"
        nzXs="24"
        class="text-left text-xs-center p-l-10 p-b-5"
      >
        Valor selecionado:
        <b>
          {{ documentService.getValorSelecionado(dataDocumentsCheckeds()) }}
        </b>
      </div>

      <div
        nz-col
        nzSpan="6"
        nzLg="6"
        nzMd="12"
        nzXs="24"
        class="text-right text-xs-center p-b-5 pr-sm-10"
      >
        Valor total a assinar:
        <b>
          {{
            documentService.getValorTotalNaoAssinado(dataDocumentsCheckeds())
          }}
        </b>
      </div>

      <div
        nz-col
        nzSpan="7"
        nzLg="7"
        nzMd="13"
        nzXs="24"
        class="text-left text-xs-center p-l-30 p-b-5 pl-sm-10"
      >
        Valor total já assinado:
        <b>
          {{ documentService.getValorTotalAssinado(dataDocumentsCheckeds()) }}
        </b>
      </div>

      <div
        nz-col
        nzSpan="5"
        nzLg="5"
        nzMd="11"
        nzXs="24"
        class="text-right text-xs-center p-r-10 p-b-5"
      >
        Valor total:
        <b> {{ documentService.getValorTotal(dataDocumentsCheckeds()) }} </b>
      </div>
    </div>

    <nz-dropdown-menu #colunas="nzDropdownMenu">
      <ul nz-menu>
        <li
          nz-menu-item
          *ngFor="let column of documentService.getTableColumns(tableColumns)"
        >
          <label nz-checkbox [(ngModel)]="checkList[column.value]">{{
            column.text
          }}</label>
        </li>
        <li nz-menu-item *ngIf="documentService.getColumnsStatus(tableColumns)">
          <label nz-checkbox [(ngModel)]="checkList.status">Situação</label>
        </li>
        <li nz-menu-item>
          <label nz-checkbox [(ngModel)]="checkList.acoes">Ações</label>
        </li>
        <li nz-menu-item class="text-center">
          <button nz-button nzType="text" nzSize="small" (click)="resetar()">
            Resetar
          </button>
        </li>
      </ul>
    </nz-dropdown-menu>

    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
</nz-spin>
