<ps-complex-form
  [title]="'Modelos de Mensagens'"
  [tabIndex]="tabIndex"
  [isAntd]="true"
  (changeTabIndex)="changeTabIndex($event)"
>
  <div table>
    <div class="row is-not-mobile">
      <div class="col-md-24 col-xl-12 p-b-15">
        <nz-collapse nzGhost>
          <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
            <div nz-row class="d-flex">
              <div nz-col nzSpan="24" nzXs="24">
                <div class="p-l-10" nz-col nzSpan="24">
                  <div class="p-r-10">
                    <div class="p-l-10" nz-col nzSpan="24">
                      <div nz-row>
                        <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                          <nz-select
                            style="width: 350px"
                            nzPlaceHolder="Selecione o campo"
                            [(ngModel)]="fieldMensagensSelected"
                            (ngModelChange)="
                              getFilterMensagemOptionsSelect(
                                fieldMensagensSelected
                              )
                            "
                          >
                            <nz-option
                              *ngFor="let interval of arrColumnsMensagensSelect"
                              [nzValue]="interval"
                              [nzLabel]="interval?.text"
                            >
                            </nz-option>
                          </nz-select>
                        </div>
                        <nz-input-group
                          *ngIf="
                            fieldMensagensSelected &&
                            fieldMensagensSelected.type === 'input'
                          "
                          class="m-l-10"
                          nz-col
                          nzSpan="8"
                          nzXl="8"
                          nzLg="12"
                          nzXs="24"
                          [nzPrefix]="prefixTemplate"
                        >
                          <input
                            type="text"
                            class="text-disabled"
                            nz-input
                            placeholder="Informe um valor"
                            [(ngModel)]="searchInput"
                          />
                        </nz-input-group>

                        <div
                          *ngIf="
                            fieldMensagensSelected &&
                            tabIndex == 1 &&
                            fieldMensagensSelected.type === 'range-picker'
                          "
                          nzSpan="8"
                          nzXl="8"
                          nzLg="8"
                          nzXs="24"
                          class="m-l-10"
                        >
                          <nz-range-picker
                            nzFormat="dd/MM/yyyy"
                            [(ngModel)]="searchInput"
                          ></nz-range-picker>
                        </div>

                        <div
                          nz-col
                          *ngIf="fieldMensagensSelected"
                          nzSpan="2"
                          nzXl="2"
                          nzXs="12"
                          class="p-l-10"
                        >
                          <button
                            nz-button
                            [disabled]="!searchInput"
                            (click)="addFiltroValuesPorCampo()"
                            nzType="primary"
                          >
                            <i nz-icon nzType="plus"></i>
                          </button>
                        </div>
                      </div>
                      <div nz-row class="m-t-10">
                        <div
                          *ngIf="showComboTagsMensagens"
                          class="p-0"
                          nz-col
                          nzSpan="12"
                          nzXl="12"
                          nzLg="12"
                          nzXs="24"
                        >
                          <nz-input-group [nzPrefix]="' '">
                            <div class="align-self-center">
                              <nz-tag
                                style="margin-bottom: 0"
                                [nzColor]="'blue'"
                                [nzMode]="'closeable'"
                                (nzOnClose)="
                                  removeFiltroMensagemPorField(fieldsValuesTag)
                                "
                                class="filters"
                                *ngFor="
                                  let fieldsValuesTag of fieldsMensagensSelected;
                                  let i = index
                                "
                              >
                                {{ fieldsValuesTag.text }}
                              </nz-tag>
                            </div>
                          </nz-input-group>
                        </div>
                        <div
                          nz-col
                          [class]="'m-t-10 p-l-20'"
                          [nzOffset]="showComboTagsMensagens ? 8 : 20"
                          nzSpan="3"
                          nzXl="3"
                          nzLg="3"
                          nzXs="24"
                        >
                          <button
                            class="m-l-10"
                            nzSize="small"
                            nz-button
                            nzType="primary"
                            (click)="filterMensagens()"
                          >
                            <i nz-icon nzType="filter" nzTheme="outline"></i
                            >Aplicar Filtro
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nz-collapse-panel>
        </nz-collapse>
      </div>
    </div>

    <nz-table
      nzSize="small"
      [nzLoading]="loading"
      [nzShowPagination]="true"
      #MensagemListTable
      [nzScroll]="{ y: '420px' }"
      style="overflow-y: overlay"
      [nzData]="displayData"
    >
      <thead>
        <tr>
          <th
            class="p-t-15 p-b-15"
            style="background: #edf1fd"
            [nzLeft]="true"
            nzWidth="15px"
          ></th>
          <th
            class="p-t-15 p-b-15"
            style="background: #edf1fd"
            nzWidth="60px"
            [nzLeft]="true"
          >
            <label
              nz-checkbox
              [nzIndeterminate]="checkAllIndeterminate"
              (ngModelChange)="updateAllChecked()"
              [(ngModel)]="checkedAll"
            ></label>
          </th>

          <ng-container *ngFor="let column of tableMensagemColumns">
            <th
              class="p-t-15 p-b-15"
              nzWidth="150px"
              *ngIf="checkList[column.value]"
              nzWidth="{{ column.width }}"
              [nzSortFn]="column.compare"
            >
              {{ column.text }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-item [ngForOf]="MensagemListTable.data">
          <tr
            (click)="selectItem(item)"
            (dblclick)="dbClickItem(item)"
            style="cursor: pointer"
            nzTooltipPlacement="left"
          >
            <td></td>
            <td [nzLeft]="getWidthContent() > 1024">
              <label
                nz-checkbox
                [(ngModel)]="item.checked"
                (ngModelChange)="selectItem(item)"
              ></label>
            </td>
            <ng-container *ngFor="let column of tableMensagemColumns">
              <td *ngIf="checkList[column.value]">
                {{ prepareColumnValue(item, column) }}
              </td>
            </ng-container>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
  <div body>
    <nz-spin [nzSpinning]="manutencaoLoading">
      <form nz-form [formGroup]="formB" nzLayout="vertical">
        <h4 class="m-t-15">Informações básicas</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

        <div nz-row>
          <div class="p-r-20" nz-col nzSpan="4" nzSm="4" nzXs="24">
            <nz-form-item>
              <nz-form-label>Código</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="id" placeholder="código" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div class="p-r-20" nz-col nzSpan="11" nzSm="11" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Título da mensagem</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  formControlName="titulo"
                  placeholder="Título da mensagem"
                />
              </nz-form-control>
            </nz-form-item>
          </div>

          <!-- <div *ngIf="false" nz-col nzSpan="9" nzSm="9" nzXs="24">
          <nz-form-item>
            <nz-form-label>Chaves disponíveis:</nz-form-label>
            <nz-form-control>
              <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo" [(ngModel)]="chave"
                (ngModelChange)="getOptionsSelect(chave)" (ngModelSelect)="getOptionsSelect(chave)"
                [ngModelOptions]="{standalone: true}">
                <nz-option *ngFor="let suggestion of suggestions" [nzValue]="suggestion" [nzLabel]="suggestion">
                </nz-option>
              </nz-select>

            </nz-form-control>
          </nz-form-item>
        </div> -->
        </div>
        <!-- <div nz-row> -->
        <div class="p-r-15">
          <nz-form-item>
            <nz-form-label nzRequired
              >Texto da mensagem&nbsp;<span
                nz-icon
                nzType="info-circle"
                nzTheme="outline"
                nzTooltipTitle="Para utilizar as chaves de substituição de texto disponíveis adicione um '@' e as opções aparecerão"
                nzTooltipPlacement="top"
                nz-button
                nz-tooltip
              ></span
            ></nz-form-label>
            <nz-form-control [nzErrorTip]="'preencha o campo corretamente'">
              <nz-mention
                [nzSuggestions]="suggestions"
                (nzOnSearchChange)="onSearchChange($event)"
                [nzPrefix]="['@']"
                (nzOnSelect)="onSelect($event)"
              >
                <textarea
                  nz-input
                  formControlName="texto"
                  [nzAutosize]="{ minRows: 3, maxRows: 999 }"
                  placeholder="Texto da mensagem"
                  nzAutosize
                  nzMentionTrigger
                  (ngModelChange)="onChange($event)"
                ></textarea>
              </nz-mention>
            </nz-form-control>
          </nz-form-item>
        </div>
        <!-- </div> -->
      </form>
    </nz-spin>
    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
</ps-complex-form>

<!-- <ps-fab *ngIf="this.tabIndex === 1" [actions]=" fabButtons1"></ps-fab> -->
