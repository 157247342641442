import * as moment from 'moment';
import { AtestadorService } from './../../../../../libs/shared/src/lib/services/atestador.service';

import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  Helper,
  IModulo,
  ModuloService,
  TipoDocumentoService,
} from '@ps-erp-angular/shared';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subject, Subscription } from 'rxjs';

import { GridModalAtestadoresComponent } from '../grid-modal-atestadores/grid-modal-atestadores.component';
import { PessoaFisicaComponent } from '../pessoa-fisica/pessoa-fisica.component';
import { ModuloState } from '../states/modulos.state';
import { TipoDocumentoComponent } from '../tipo-documento/tipo-documento.component';

@Component({
  selector: 'ps-modulo',
  templateUrl: './modulo.component.html',
  styleUrls: ['./modulo.component.scss'],
})
export class ModuloComponent implements OnInit {
  public tabIndex: 0 | 1 = 0;
  @Input() openModal = false;
  public fabButtons: IMenuButton[];
  public formB: FormGroup = new FormGroup({});
  public loading: boolean = false;
  public displayData: any[] = [];
  public buscarModuloInput: string;
  public tooltipModulo: boolean = false;
  public innerWidth: any;
  public timeoutgetResult;
  checkAllIndeterminate: boolean;
  checkedAll: boolean;
  public checkList: { [typekey: string]: boolean } = {
    idPublico: true,
    idPublicoModuloPai: true,
    descricaoModuloPai: true,
    descricao: true,
  };
  protected dateSelected: Date = new Date();
  public externalDate: Date;
  public checkListTableTipoDoc: { [typekey: string]: boolean } = {
    idPublico: true,
    descricao: true,
  };

  public dateSelectedSubject: Subject<string> = new Subject<string>();
  loadingTipoDoc = false;
  intervaloDigitando: any;
  public dataInicioAtesto: string;
  public getTableTipoDoc = [
    {
      label: 'Código',
      columnName: 'idPublico',
      width: '4rem',
      compare: (a: any, b: any) => {
        return a['idPublico'] - b['idPublico'];
      },
    },
    {
      label: 'Descrição',
      columnName: 'descricao',
      width: '20rem',
      compare: (a: any, b: any) => {
        return a['descricao'].localeCompare(b['descricao']);
      },
    },
  ];
  public getTableAtestadores = [
    {
      label: 'CPF',
      columnName: 'cpfAtestador',
      mascara: 'cpf',
      width: '10em',
      compare: (a: any, b: any) => {
        return a['cpfAtestador'].localeCompare(b['cpfAtestador']);
      },
    },
    {
      label: 'Nome Atestador',
      columnName: 'nomeAtestador',
      width: '20rem',
      compare: (a: any, b: any) => {
        return a['nomeAtestador'].localeCompare(b['nomeAtestador']);
      },
    },
    {
      label: 'Data Inicio Atesto',
      columnName: 'dataInicioAtesto',
      mascara: 'date',
      width: '20rem',
      compare: (a: any, b: any) => {
        return a['dataInicioAtesto'] - b['dataInicioAtesto'];
      },
    },
  ];

  checkListTableAtestadores = {
    cpfAtestador: true,
    columnName: true,
    dataInicioAtesto: true,
    nomeAtestador: true,
  };

  public getTableColumns = [
    {
      label: 'Id',
      columnName: 'idPublico',
      width: '5rem',
      compare: (a: any, b: any) => {
        return a['idPublico'] - b['idPublico'];
      },
    },
    {
      label: 'Descrição',
      columnName: 'descricao',
      width: '8rem',
      compare: (a: any, b: any) => {
        return a['descricao'].localeCompare(b['descricao']);
      },
    },
    {
      label: 'Id Módulo Pai',
      columnName: 'idPublicoModuloPai',
      width: '5rem',
      compare: (a: any, b: any) => {
        return a['idPublicoModuloPai'] - b['idPublicoModuloPai'];
      },
    },
    {
      label: 'Descrição Módulo Pai',
      columnName: 'descricaoModuloPai',
      width: '8rem',
      compare: (a: any, b: any) => {
        return a['descricaoModuloPai'].localeCompare(b['descricaoModuloPai']);
      },
    },
  ];

  private title: string = 'Modulo';

  public tiposDocumentosAtestadoresVinculados: Array<any> = [];
  public tiposDocumentosAtestadoresVinculadosDeleted: Array<any> = [];

  public entidadeAtestadorArr: Array<unknown> = new Array<any>();
  public loadingTableTipoDoc: boolean = false;
  constructor(
    @Optional()
    private modalRef: NzModalRef<ModuloComponent>,
    private formBuilder: FormBuilder,
    private readonly _notification: NzNotificationService,
    private readonly _moduloService: ModuloService,
    private readonly _atestador: AtestadorService,
    private readonly _tipoDocService: TipoDocumentoService,
    private readonly _modalService: NzModalService
  ) {
    // this.dataInicioAtesto =

    this.dataInicioAtesto = this.formatDate23hrs(this.getDateSelected());
    this.onValueChange();
    this.resetFabButtons();
    this.resetForm();
    // this.formB.controls.dataInicioAtesto.setValue();

    this._moduloService.authService.getUgSelected().then((ug: any) => {
      this.formB.controls.idUg.setValue(ug.idUg);
      this.formB.controls.usCriacao.setValue(ug.usCriacao);
      this.formB.controls.idMunicipio.setValue(ug.idMunicipio);
    });
  }

  onValueChange(value: Date = new Date()) {
    this.setDateSelected(value);
  }

  setDateSelected(date) {
    this.dateSelected = date;
    this.externalDate = date;
    this.dateSelectedSubject.next(this.getDateSelected());
  }
  private buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  getDateSelected() {
    const dateSelected = moment(new Date()).format();
    return dateSelected;
  }

  private resetFabButtons(): void {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'selecionar',
        condition: this.openModal,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },

      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: false,
        // condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'save',
        tooltip: this.formB.value.idPrivado ? 'Editar' : 'Salvar',
        color: 'green',
        condition:
          this.tabIndex === 0 &&
          (!this.formB.value.idPrivado || this.formB.value.idPrivado),
        onClick: this.salvar,
      },
      // {
      //   icon: 'save',
      //   tooltip: 'Salvar',
      //   color: 'green',
      //   condition: this.tabIndex === 0,
      //   onClick: this.salvar,
      // },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletear',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal,
        onClick: this.selecionar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: true,
        onClick: this.consultar,
      },

      // {
      //   icon: 'reload',
      //   tooltip: 'Atualizar',
      //   // color: 'blue',
      //   condition: true,
      //   onClick: this.atualizar,
      // },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  editarVinculacao(item) {
    this.tiposDocumentosAtestadoresVinculados = this.tiposDocumentosAtestadoresVinculados.filter(
      (e: any) => e.idPrivado !== item.idPrivado
    );

    const { atestadores, ...tipoDoc } = item;
    this.setDataTipoDoc(item);

    (atestadores as Array<any>).forEach((atestador, i) => {
      this.setDataAtestador(
        {
          idPessoa: atestador.idPessoa ?? atestador.idPessoaAtestador,
          nome: atestador.nomeAtestador,
          dataInicioAtesto: atestador.dataInicioAtesto,
          cpf: atestador.cpfAtestador,
          idPrivado: atestador.idPrivado,
          idPessoaAtestador: atestador.idPessoa ?? atestador.idPessoaAtestador,
          editado: atestador.editado,
          vinculacaoModuloTipoDoc:
            atestador.vinculacaoModuloTipoDoc ??
            tipoDoc.vinculacaoModuloTipoDoc,
        },
        i
      );

      // Impede que duplique os campos do form pra novos atestadores ao tipo documento
      if (i < atestadores.length - 1) {
        this.addAtestador();
      }
    });
  }

  consultar: () => void = () => {
    this.consultarModulos();

    this.changeTabIndex(1);
  };
  selecionar = () => {
    this.selectRegistro();
  };

  selectRegistro(registro: IModulo = this.getFirstRegistro()) {
    this.modalRef.destroy(registro);
  }

  dblClick(registro: IModulo) {
    this.openModal ? this.selectRegistro(registro) : this.dbClickEdit(registro);
  }

  novoCadastro: () => void = () => {
    this.resetForm();
    this.tiposDocumentosAtestadoresVinculados = [];
  };

  deletar: () => void = () => {
    // this.moduloService
    //   .deleteModulo(this.getFirstRegistro().idPrivado)
    //   .then(() => this.consultarModulos());
    this.loading = true;

    const modulos = this.pegarModulosSelecionados();
    if (modulos.length !== 1) {
      this._moduloService.notification.info(
        'Módulos',
        'Não foi possível deletar o módulo, pois exitem muitos selecionados ou nenhum.'
      );
      this.uncheckedAll();
      return;
    }
    const modulo = modulos[0];
    this._modalService.confirm({
      nzTitle: '<i>Deseja excluir módulo?</i>',
      nzOnOk: () => {
        this._moduloService.deleteModulo(modulo.idPrivado).then(() => {
          this.consultarModulos();
          const sub: Subscription = this._moduloService
            .getManyModuloNav$()
            .subscribe({
              next: (resp: { msg: string; data: IModulo[] }) => {
                ModuloState.modulos = resp.data;
              },
              error: (err) => {
                this._notification.error('Módulos', err);
              },
              complete: () => {
                sub.unsubscribe();
              },
            });
        });
        this.loading = false;

        this.displayData = this.displayData.filter(
          (msg) => !(msg.idPrivado === modulo.idPrivado)
        );
      },
    });
  };

  async searchTipoDoc(value) {
    if (
      !value ||
      this.formB.value.entidadeTipoDoc ||
      this.formB.value.idPrivado
    ) {
      return;
    }
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(() => {
      if (this.formB.value.idTipoDocPublico || value) {
        return;
      }
      this.loadingTipoDoc = true;
      this._tipoDocService.getTipoDocsByIdPublico(value).then((result) => {
        this.setDataTipoDoc(result);
      });
      this.loadingTipoDoc = false;
    }, 2000);
  }

  setDataTipoDoc(data: any) {
    this.formB.patchValue({
      entidadeTipoDoc: data,
      idTipoDoc: data.idPrivado,
      idTipoDocPublico: data.idPublico,
      descricaoTipoDocumento: data.descricao,
    });
    if (data.atestadores.length > 0) {
      const atestadoresForm: FormArray = this.formB.get(
        'atestadores'
      ) as FormArray;

      while (atestadoresForm.length !== 0) {
        atestadoresForm.removeAt(0);
      }

      data.atestadores.forEach((atesto, index) => {
        const atestadorFormGroup = this.formBuilder.group({
          idPrivado: [atesto.idPrivado],
          idPessoaAtestador: [atesto.idPessoaAtestador],
          idPessoa: [atesto.idPessoaAtestador],
          cpfAtestador: [atesto.cpfAtestador],
          nomeAtestador: [atesto.nomeAtestador],
          dataInicioAtesto: [atesto.dataInicioAtesto],
          vinculacaoModuloTipoDoc: [atesto.vinculacaoModuloTipoDoc],
        });
        atestadoresForm.push(atestadorFormGroup);
      });
    }
  }

  getAtestadorFormBuild(i) {
    return (
      this.formB.controls.atestadores.value.length > 1 &&
      !this.formB.get(`atestadores.${i}`).value.idPrivado
    );
  }

  setDataAtestador(data: any, i: number) {
    this.formB.get(`atestadores.${i}`).patchValue({
      idPessoaAtestador: data.idPessoa ?? data.idPessoaAtestador,
      entidadeAtestador: data,
      cpfAtestador: data.cpf,
      idAtestador: data.idPrivado,
      idPrivado: data.idPrivado,
      nomeAtestador: data.nome,
      idPessoa: data.idPessoa ?? data.idPessoaAtestador,
      vinculacaoModuloTipoDoc: data.vinculacaoModuloTipoDoc,
    });

    if (data.dataInicioAtesto) {
      this.formB
        .get(`atestadores.${i}`)
        .patchValue({ dataInicioAtesto: data.dataInicioAtesto });
    }

    // this.formB.controls.atestadores[i].nomeAtestador.setValue(data.nome);
    this.entidadeAtestadorArr[i] = data;
  }

  addAtestadorValidate(): boolean {
    const atestadores: Array<any> = this.formB.get('atestadores').value;
    const todosElementosAtestadoresPreenchidos: boolean =
      atestadores.length === 0
        ? false
        : atestadores.every((atestador) => atestador.idPessoaAtestador);

    const ultimoAtestadorPreenchido: boolean =
      atestadores.length === 0
        ? false
        : atestadores[atestadores.length - 1].idPessoaAtestador;

    return ultimoAtestadorPreenchido && todosElementosAtestadoresPreenchidos;
  }

  limparEntidadeTipoDoc() {
    this.formB.get('idTipoDocPublico').reset();
    this.formB.get('descricaoTipoDocumento').reset();
    this.formB.get('entidadeTipoDoc').reset();
    this.resetTipoDocumentoAtestadores();
  }

  resetTipoDocumentoAtestadores() {
    // Acesse o FormArray
    const atestadoresArray = this.formB.get('atestadores') as FormArray;

    // Limpa todos os controles do FormArray
    atestadoresArray.clear();
    this.addAtestador();
  }

  limparEntidadeAtestardor(i) {
    this.formB.get(`atestadores.${i}`).patchValue({
      entidadeAtestador: null,
      cpfAtestador: null,
      idAtestador: null,
      nomeAtestador: null,
      idPessoa: null,
      idPessoaAtestador: null,
    });
    this.entidadeAtestadorArr[i] = null;
  }

  limparTipoDocAtestador() {
    this.limparEntidadeTipoDoc();
  }

  showModalTipoDoc() {
    const setorModal = this._modalService.create({
      nzTitle: 'Selecione um Tipo de documento',
      nzContent: TipoDocumentoComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    const modalSubscription: Subscription = setorModal.afterClose.subscribe({
      next: (result: any) => {
        const tipoDocumentoJaVinculado: boolean = this.tiposDocumentosAtestadoresVinculados.some(
          (tipoDocumentoVinculado: any) =>
            tipoDocumentoVinculado.idPrivado === result.idPrivado
        );

        if (tipoDocumentoJaVinculado) {
          this._notification.warning(
            'Tipo de Documento',
            'Este tipo de documento já está vinculado ao módulo!'
          );
          return;
        }
        this.setDataTipoDoc(result);
      },
      error: (err) => {},
      complete: () => {
        modalSubscription.unsubscribe();
      },
    });
  }

  validarDocumentoJaVinculado() {
    const tipoDocumentoJaVinculado: boolean = this.tiposDocumentosAtestadoresVinculados.some(
      (tipoDocumentoVinculado: any) =>
        tipoDocumentoVinculado.idPrivado ===
        this.formB.value.entidadeTipoDoc.idPrivado
    );

    if (tipoDocumentoJaVinculado) {
      this._notification.warning(
        'Tipo de Documento',
        'Este tipo de documento já está vinculado ao módulo!'
      );
      return;
    }
  }

  addTipoDoc() {
    const userInfo = this._moduloService.authService.getUserInfo();
    this.validarDocumentoJaVinculado();
    this.tiposDocumentosAtestadoresVinculados = [
      {
        expand: false,
        descricao: this.formB.value.entidadeTipoDoc.descricao,
        idPublico: this.formB.value.entidadeTipoDoc.idPublico,
        idPrivado: this.formB.value.entidadeTipoDoc.idPrivado,
        usCriacao: userInfo.usCriacao,
        atestadores: this.formB
          .get('atestadores')
          .value.filter(
            (e) => e.idPessoaAtestador && e.nomeAtestador && e.cpfAtestador
          ),
      },
      ...this.tiposDocumentosAtestadoresVinculados,
    ];
    this.limparEntidadeTipoDoc();

    //TODO: limpar Array Atestadores e entidade documento
  }

  addAtestador() {
    const formularioForm: FormArray = this.formB.get(
      'atestadores'
    ) as FormArray;
    formularioForm.push(this.formAtestador());
  }

  limparAtestadorForm(i) {
    const atestadoresArray = this.formB.get('atestadores') as FormArray;
    atestadoresArray.removeAt(i);
  }

  async removerTipoDocModulo(item) {
    const validacaoRemoverAtestador = [];
    for (const atestador of item?.atestadores) {
      validacaoRemoverAtestador.push(
        this._atestador.validarRemoverAtestador({
          idModulo: Number(this.formB.controls.idPrivado.value),
          idTipoDocumento: item.idPrivado,
          cpf: atestador.cpfAtestador,
        })
      );
    }

    this._modalService.confirm({
      nzTitle: '<i>Deseja excluir esse tipo de documento?</i>',
      // nzContent:
      //   'A vinculação só será excluída depois que as alterações forem salvas.',
      nzOnOk: async () => {
        await Promise.all(validacaoRemoverAtestador)
          .then(async () => {
            for (const atestador of item?.atestadores) {
              this.removerAtestador(item, atestador);
            }
            this.tiposDocumentosAtestadoresVinculados = this.tiposDocumentosAtestadoresVinculados.filter(
              (tiposDoc) => tiposDoc.idPublico !== item.idPublico
            );
            if (item?.vinculacaoModuloTipoDoc?.idPrivado)
              await this._moduloService.deletarVinculacaoTipoDocModulo({
                idPrivado: item.vinculacaoModuloTipoDoc.idPrivado,
              });
          })
          .catch((e) =>
            this._notification.warning(
              'Remover Tipo Documento',
              'Não é possível remover o tipo de documento, pois há um ou mais atestadores que já possuem documentos associados para atestar.',
              { nzAnimate: true, nzDuration: 8000 }
            )
          );
      },
    });
  }

  //TODO: remover atestador do banco, remover atestador da memória
  removerAtestador(tipoDoc, atestador) {
    //

    this._modalService.confirm({
      nzTitle: '<i>Deseja excluir esse atestador?</i>',
      nzOnOk: async () => {
        await this._atestador
          .validarRemoverAtestador({
            idModulo: Number(this.formB.controls.idPrivado.value),
            idTipoDocumento: tipoDoc.idPrivado,
            cpf: atestador.cpfAtestador,
          })
          .then((data) => {
            this.tiposDocumentosAtestadoresVinculados = this.tiposDocumentosAtestadoresVinculados.map(
              (data) => {
                if (data.idPublico === tipoDoc.idPublico) {
                  return {
                    ...data,
                    atestadores: data.atestadores.map((ates) => {
                      if (ates.idPessoa === atestador.idPessoa) {
                        return {
                          ...ates,
                          deleted: true,
                        };
                      }
                      return ates;
                    }),
                  };
                }
                return data;
              }
            );
          })
          .catch((e) => {
            this._notification.warning('Remover Atestador', e);
          });
      },
    });
  }

  private salvar = async () => {
    if (this.formB.invalid) {
      return this._notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente'
      );
    }
    this._modalService.confirm({
      nzTitle: '<i>Deseja salvar as alterações feitas para esse módulo?</i>',
      nzOnOk: async () => {
        this.loading = true;
        const idPrivadoModulo = this.formB.get('idPrivado').value;

        if (idPrivadoModulo) {
          //Atualiza modulos

          await this._moduloService
            .updateModulo({
              idPrivado: idPrivadoModulo,
              idPublico: this.formB.get('idPublico').value,
              descricao: this.formB.get('descricao').value,
              idModuloPai: this.formB.get('idPrivadoModuloPai').value,
              tiposDocumento: this.tiposDocumentosAtestadoresVinculados,
            })
            .then((resp: any) => {})
            .catch((error) => {
              this._notification.error(
                this.title,
                'Modulo não atualizado!' + ' ' + error
              );
            });
          this.tiposDocumentosAtestadoresVinculados = [];
          this.formB.reset();

          // this.resetForm();
          this.consultarModulos();
          return;
        }

        await this._moduloService.createModulo({
          idPrivado: this.formB.get('idPrivado').value,
          idPublico: this.formB.get('idPublico').value,
          descricao: this.formB.get('descricao').value,
          idModuloPai: this.formB.get('idPrivadoModuloPai').value,
          tiposDocumento: this.tiposDocumentosAtestadoresVinculados,
        });
        this.tiposDocumentosAtestadoresVinculados = [];
        this.formB.reset();
        await this.consultarModulos();
        this.resetForm();
        this.loading = false;

        return;
      },
    });
  };

  async removeTipoDoc(idTipoDocumento) {
    this._modalService.confirm({
      nzTitle: '<i>Deseja excluir esse tipo de documento?</i>',
      nzContent:
        'A vinculação só será excluída depois que as alterações forem salvas.',
      nzOnOk: () => {
        const tipoDocFormb: FormArray = this.formB.get(
          'tiposDocumento'
        ) as FormArray;

        const index = this.formB.value.tiposDocumento.indexOf(
          this.formB.value.tiposDocumento.find(
            (key) => key.idPublico === idTipoDocumento
          )
        );

        const attachToRemove = this.formB.value.tiposDocumento[index];
        attachToRemove.idPrivado;
        tipoDocFormb.removeAt(index);
      },
    });
  }

  public dbClickEdit(data) {
    if (this.openModal) {
      this.modalRef.destroy(data);
      return;
    }
    this.tiposDocumentosAtestadoresVinculados = [];
    for (const tipoDoc of data.tiposDocumento) {
      this.loadingTableTipoDoc = true;
      // if (e.data.length > 0) {
      this._atestador
        .consultarAtestadorByTipoDocModulo(data.idPrivado, tipoDoc.idPrivado)
        .then((e) => {
          const atestadores = [
            ...e.data.map((atestador: any) => {
              return {
                cpfAtestador: atestador.cpfAtestador,
                dataInicioAtesto:
                  atestador.tipoDocumentoAtestador.dataInicioAtesto,
                nomeAtestador: atestador.nomeAtestador,
                idPrivado: atestador.idPrivado,
                idPessoa: atestador.pessoa.id,
                vinculacaoModuloTipoDoc:
                  tipoDoc.idVinculacaoTipoDocumentoModulo,
              };
            }),
          ];

          const tipoDocAtestadores = {
            ...tipoDoc,
            atestadores,
          };

          if (atestadores.length > 0) tipoDocAtestadores['expand'] = false;

          this.tiposDocumentosAtestadoresVinculados = [
            ...this.tiposDocumentosAtestadoresVinculados,
            tipoDocAtestadores,
          ];
          // }
          this.loadingTableTipoDoc = false;
        })
        .catch((e) => {
          this.loadingTableTipoDoc = false;
          this._notification.error(
            'Atestadores',
            `Erro ao consultar os atestadores desse modulo para esse tipo documento. error: ${e}`
          );
        });
    }

    this.resetForm();
    this.changeTabIndex(0);

    this.formB.patchValue({
      idPrivado: data.idPrivado,
      idPublico: data.idPublico,
      descricao: data.descricao,
      idPrivadoModuloPai: data.moduloPai?.idPrivado,
      idPublicoModuloPai: data.moduloPai?.idPublico,
      descricaoModuloPai: data.moduloPai?.descricao,
    });

    this.formB.controls.idPublico.disable();

    if (data.tiposDocumento) {
      const tipoDocForm: FormArray = this.formB.get(
        'tiposDocumento'
      ) as FormArray;
      for (const tipoDoc of data.tiposDocumento) {
        tipoDocForm.push(
          this.formBuilder.group({
            descricao: [tipoDoc.descricao],
            idPublico: [tipoDoc.idPublico],
            idPrivado: [tipoDoc.idPrivado],
            // idSubmodulo: [data.idPrivado],
          })
        );
      }
    }

    this.uncheckedAll();
  }

  private uncheckedAll() {
    this.displayData.map((data: any) => (data.checked = false));
  }

  private pegarModulosSelecionados(): IModulo[] {
    return this.displayData.filter((modulos: IModulo) => modulos.checked);
  }

  private getFirstRegistro(): IModulo {
    return this.pegarModulosSelecionados().find(
      (modulo: IModulo) => modulo.checked
    );
  }

  public showModalAtestador(i) {
    const atestadorModal = this._modalService.create({
      nzTitle: 'Selecione uma pessoa para ser atestador',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    atestadorModal.afterClose.subscribe((pessoaAtestador) => {
      for (const atestador of this.formB.value.atestadores) {
        if (atestador.idPessoaAtestador === pessoaAtestador.idPessoa) {
          this._notification.warning(
            'Atestador Selecionado',
            'Essa pessoa já foi selecionada para esse tipo de documento'
          );
          return;
        }
      }
      for (const tipoDocAtestVinc of this
        .tiposDocumentosAtestadoresVinculados as Array<any>) {
        for (const atestador of tipoDocAtestVinc.atestadores) {
          if (
            atestador.idPessoaAtestador === pessoaAtestador.idPessoa &&
            this.formB.value.entidadeTipoDoc.idPrivado ===
              tipoDocAtestVinc.idPrivado
          ) {
            this._notification.warning(
              'Atestador Selecionado',
              'Essa pessoa já está vinculada a esse tipo documento como atestador'
            );
            return;
          }
        }
      }
      this.setDataAtestador(pessoaAtestador, i);
    });
  }

  private editar: () => void = () => {
    if (this.pegarModulosSelecionados().length !== 1) {
      this._notification.warning(
        'Modulo',
        'Muitos ou nenhum registro selecionado!'
      );
      return;
    }
    const firstRegistro = this.getFirstRegistro();

    this.tiposDocumentosAtestadoresVinculados = [];

    for (const tipoDoc of firstRegistro.tiposDocumento) {
      this.loadingTableTipoDoc = true;
      this._atestador
        .consultarAtestadorByTipoDocModulo(
          firstRegistro.idPrivado,
          tipoDoc.idPrivado
        )
        .then((e) => {
          const atestadores = [
            ...e.data.map((atestador: any) => {
              return {
                cpfAtestador: atestador.cpfAtestador,
                dataInicioAtesto:
                  atestador.tipoDocumentoAtestador.dataInicioAtesto,
                nomeAtestador: atestador.nomeAtestador,
                idPrivado: atestador.idPrivado,
                idPessoa: atestador.pessoa.id,
                vinculacaoModuloTipoDoc:
                  tipoDoc.idVinculacaoTipoDocumentoModulo,
              };
            }),
          ];

          const tipoDocAtestadores = {
            ...tipoDoc,
            atestadores,
          };

          if (atestadores.length > 0) tipoDocAtestadores['expand'] = false;

          this.tiposDocumentosAtestadoresVinculados = [
            ...this.tiposDocumentosAtestadoresVinculados,
            tipoDocAtestadores,
          ];
          // }
          this.loadingTableTipoDoc = false;
        })
        .catch((e) => {
          this.loadingTableTipoDoc = false;
          this._notification.error(
            'Atestadores',
            `Erro ao consultar os atestadores desse modulo para esse tipo documento. error: ${e}`
          );
        });
    }

    this.resetForm();
    this.changeTabIndex(0);

    this.formB.patchValue({
      descricao: firstRegistro.descricao,
      idPrivado: firstRegistro.idPrivado,
      idPublico: firstRegistro.idPublico,
      idPrivadoModuloPai: firstRegistro.moduloPai?.idPrivado,
      idPublicoModuloPai: firstRegistro.moduloPai?.idPublico,
    });

    if (firstRegistro.tiposDocumento) {
      const tipoDocForm: FormArray = this.formB.get(
        'tiposDocumento'
      ) as FormArray;
      // const userInfo = this.authService.getUserInfo();
      for (const tipoDoc of firstRegistro.tiposDocumento) {
        tipoDocForm.push(
          this.formBuilder.group({
            descricao: [tipoDoc.descricao],
            idPublico: [tipoDoc.idPublico],
            idPrivado: [tipoDoc.idPrivado],
            // idSubmodulo: [this.getFirstRegistro().idPrivado],
          })
        );
      }
    }

    this.uncheckedAll();
  };

  // selecionar = () => {
  //   const registro = this.getFirstRegistro();
  //   this.modalRef.destroy(registro);
  // };

  public async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    /*usado para carregar os destinatarios da parte de consulta
    apenas quando o usuário clicar em consulta*/

    // if (value === 1 && !this.listSignatariosMensagemEnvio) {
    //   await this.populateSignatariosMensagemEnvio();
    // }
    // this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      this.consultarModulos();
      return;
    }
  }

  async showModalModuloPai() {
    const moduloModal = this._modalService.create({
      nzTitle: 'Selecione um módulo pai',
      nzContent: ModuloComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    await moduloModal.afterClose.subscribe((result: IModulo) => {
      this.formB.patchValue({
        idPublicoModuloPai: result.idPublico,
        idPrivadoModuloPai: result.idPrivado,
        descricaoModuloPai: result.descricao,
        tiposDocumento: result.tiposDocumento || [],
      });

      this.formB.get('idPrivadoModuloPai').setValue(result.idPrivado);
      this.formB.get('idPublicoModuloPai').setValue(result.idPublico);
      this.formB.get('descricaoModuloPai').setValue(result.descricao);
      this.formB.get('tiposDocumento').setValue(result.tiposDocumento);
    });
  }

  public limparModuloPai() {
    this.formB.get(`idPublicoModuloPai`).reset();
    this.formB.get(`idPrivadoModuloPai`).reset();
    this.formB.get(`descricaoModuloPai`).reset();
    this.formB.get(`tiposDocumento`).reset();
  }

  private resetForm() {
    this.formB = this.formBuilder.group({
      idPrivado: [{ value: null, disabled: true }],
      idPublico: [{ value: null, disabled: true }],
      descricao: [{ value: null, disabled: false }, [Validators.required]],
      idPublicoModuloPai: [{ value: null, disabled: false }],
      idPrivadoModuloPai: [{ value: null, disabled: false }],
      descricaoModuloPai: [{ value: null, disabled: true }],
      idUg: [null],
      usCriacao: [null],
      idMunicipio: [null],
      descricaoTipoDocumento: [{ value: null, disabled: true }, ,],
      entidadeTipoDoc: [null],

      idTipoDocPublico: [{ value: null, disabled: false }, ,],
      atestadores: this.formBuilder.array([this.formAtestador()]),
      tiposDocumento: this.formBuilder.array([]),

      // entidadeAtestador: [null],
      // cpfAtestador: [{ value: null, disabled: false }, [Validators.required]],
      // idAtestador: [{ value: null, disabled: false }, [Validators.required]],
      // nomeAtestador: [{ value: null, disabled: true }, [Validators.required]],
      idPessoa: [],
      dataInicioAtesto: [
        {
          value: this.dataInicioAtesto,
          disabled: false,
        },
        ,
      ],
    });
  }

  async showModalAdicinarNovoAtestadorTipoDoc(item) {
    const moduloModal = this._modalService.create({
      nzTitle: 'Adicionar novo atestador',
      nzContent: GridModalAtestadoresComponent,
      nzComponentParams: {
        idPrivadoTipoDocumento: item?.idPrivado,
        atestadoresAdicionados: item?.atestadores,
      },
      nzFooter: null,
      nzWidth: 1000,
    });

    await moduloModal.afterClose.subscribe((result) => {
      if (!result) return;

      this.tiposDocumentosAtestadoresVinculados = [
        ...this.tiposDocumentosAtestadoresVinculados.map((tdav) => {
          if (tdav.idPrivado === item.idPrivado) {
            const atestador = tdav.atestadores.find(
              (at) => at.cpfAtestador === result.cpfAtestador
            );
            if (atestador && atestador?.deleted) {
              const atestadores = tdav.atestadores.map((ates) => {
                if (ates.cpfAtestador === result.cpfAtestador) {
                  delete ates.deleted;
                  return ates;
                }
                return ates;
              });
              return {
                ...tdav,
                atestadores: atestadores,
              };
            }
            return {
              ...tdav,
              atestadores: [...tdav.atestadores, result],
            };
          }
          return tdav;
        }),
      ];
    });
  }

  marcarComoEditado(i) {
    if (this.formB.get(`atestadores.${i}`).value.idPrivado)
      this.formB.get(`atestadores.${i}`).patchValue({ editado: true });
  }

  formAtestador() {
    return this.formBuilder.group({
      idPrivado: [{ value: null }],
      editado: [false],
      idPessoaAtestador: [{ value: null, disabled: false }, []],
      cpfAtestador: [{ value: null, disabled: false }, []],
      nomeAtestador: [{ value: null, disabled: false }, []],
      dataInicioAtesto: [
        {
          value: this.dataInicioAtesto,
          disabled: false,
        },
        [],
      ],
      vinculacaoModuloTipoDoc: [null],
    });
  }

  checkDataExpiracao(data, i) {
    this.marcarComoEditado(i);
    if (data) {
      const today = new Date(this.getDateSelected()).getTime();
      const selectedDate = new Date(data).getTime();

      // if (selectedDate < today) {
      //   return this._notification.warning(
      //     'Formulário',
      //     'Data de expiração não pode ser anterior a data de hoje'
      //   );
      // }

      return;
    }
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.changeTabIndex(1);
    }

    this.resetForm();
  }

  public getWidthContent() {
    return window.innerWidth;
  }

  formatDate23hrs(date: string) {
    const systemDate = new Date(date);
    return new Date(
      systemDate.getFullYear(),
      systemDate.getMonth(),
      1,
      0,
      0,
      0
    ).toISOString();
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  public async getResult(event) {
    this.loading = true;
    clearTimeout(this.timeoutgetResult);
    this.timeoutgetResult = setTimeout(async () => {
      this.consultarModulos();
      this.loading = false;
    }, 800);
  }

  private async consultarModulos() {
    this.loading = true;
    this.displayData = ((await this._moduloService.getManyModuloGrid({
      descricao: this.buscarModuloInput as string,
    })) as { msg: string; data: IModulo[] }).data;
    const sub: Subscription = this._moduloService
      .getManyModuloNav$()
      .subscribe({
        next: (resp: { msg: string; data: IModulo[] }) => {
          ModuloState.modulos = resp.data;
        },
        error: (err) => {
          this._notification.error('Módulos', err);
        },
        complete: () => {
          sub.unsubscribe();
        },
      });
    this.loading = false;
  }

  public searchModulo() {
    this.tooltipModulo = true;
    setTimeout(() => {
      this.tooltipModulo = false;
    }, 2000);
  }
  prepareColumnValue(item, column) {
    if (!item) return;
    if (item.deleted) return;
    if (
      this.displayData.length > 0 ||
      this.tiposDocumentosAtestadoresVinculados.length > 0
    ) {
      return column?.mascara
        ? Helper.addMask(
            column?.mascara === 'date'
              ? item[column.columnName].toString()
              : item[column.columnName],
            column.mascara
          )
        : item[column.columnName];
    }
  }

  resetar() {
    this.checkList = {
      idPublico: true,
      idModuloPai: true,
      descricao: true,
      descricaoModuloPai: true,
    };
  }
}
