<ps-complex-form
  title="Meus Certificados"
  [isAntd]="true"
  [tabIndex]="tabIndex"
  (changeTabIndex)="changeTabIndex($event)"
>
  <div table>
    <div class="m-b-10 m-r-20">
      <nz-input-group [nzPrefix]="prefixTemplate">
        <input
          type="text"
          nz-input
          placeholder="Buscar documento"
          [(ngModel)]="searchInput"
          (ngModelChange)="getResult($event)"
        />
      </nz-input-group>
      <ng-template #prefixTemplate>
        <i nz-icon nzType="search" class="opacity-05"></i>
      </ng-template>
    </div>
    <nz-table
      #table
      [nzData]="rowData"
      [nzPageSize]="10"
      [nzLoading]="loading"
      nzSize="small"
      [nzScroll]="{ x: '1000px', y: '380px' }"
      style="overflow-y: overlay"
    >
      <thead>
        <tr>
          <th
            style="background: #edf1fd"
            [nzChecked]="allChecked"
            (nzCheckedChange)="onAllChecked($event)"
          ></th>
          <ng-container *ngFor="let h of headers">
            <th
              style="background: #edf1fd; width: 5px"
              *ngIf="checkCol[h.key]"
              [nzSortFn]="h.compare"
              [nzSortPriority]="h.priority"
            >
              {{ h.title }}
            </th>
          </ng-container>
          <th style="background: #edf1fd; width: 5px">
            <button
              nz-button
              nzType="text"
              nzSize="small"
              nz-dropdown
              nzTrigger="click"
              [nzDropdownMenu]="colunas"
            >
              <i nz-icon nzType="setting"></i>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of table.data">
          <td
            [nzChecked]="setChecked.has(data.uuid)"
            (nzCheckedChange)="selectedRow($event, data)"
          ></td>
          <td *ngIf="checkCol.cpfCnpjCert">
            {{ getCpfMasked(data.cpfCnpjCert) }}
          </td>
          <td *ngIf="checkCol.descricaoCert">
            {{ data.descricaoCert }}
          </td>
          <td *ngIf="checkCol.empresaCertificadora">
            {{ data.empresaCertificadora }}
          </td>
          <td *ngIf="checkCol.tipoCertificado">{{ data.tipoCertificado }}</td>
          <td *ngIf="checkCol.dataFimCert">
            {{ data.dtFinalCert | date: 'dd/MM/yyyy' }}
          </td>
          <td *ngIf="checkCol.status">
            <nz-tag
              [nzColor]="
                checkVencimentoCertificado(data.dtFinalCert) ? 'red' : 'green'
              "
            >
              {{
                checkVencimentoCertificado(data.dtFinalCert)
                  ? 'Vencido'
                  : 'Ativo'
              }}
            </nz-tag>
          </td>
          <td></td>
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div body>
    <div class="form">
      <form nz-form [formGroup]="formCert" nzLayout="vertical">
        <div nz-row [nzGutter]="4">
          <div nz-col nzXl="5" nzLg="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Tipo do certificado:</nz-form-label>
              <nz-form-control>
                <nz-radio-group formControlName="tipoCertificado">
                  <label nz-radio [nzValue]="1">e-CPF</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>

          <!-- <div *ngIf="formCert.get('tipoCertificado').value === 'cnpj'" nz-col nzXl="6" nzLg="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Empresas:</nz-form-label>
              <nz-form-control>
                <nz-select class="w-select" nzShowSearch nzAllowClear nzPlaceHolder="Selecione uma empresa"
                  [(ngModel)]="empresaSelecionada" formControlName="empresa">
                  <nz-option *ngFor="let empresa of empresasAssinante" [nzLabel]="empresa.razaoSocial"
                    [nzValue]="empresa.cnpj"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div> -->

          <div nz-col nzXl="8" nzLg="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Upload de Certificado:</nz-form-label>
              <nz-form-control>
                <div *ngIf="!hasCertificates">
                  <button
                    nz-button
                    nzBlock
                    class="d-flex"
                    hidden="hasCertificates"
                    (click)="createModalTermo()"
                  >
                    <div class="mr-2"><i nz-icon nzType="upload"></i></div>
                    <span class="text-select">{{ btnUploadTxt }}</span>
                  </button>
                </div>
                <div *ngIf="hasCertificates">
                  <nz-upload
                    [nzShowUploadList]="false"
                    [nzBeforeUpload]="beforeUpload"
                    [nzRemove]="removeUpload"
                  >
                    <button nz-button nzBlock class="d-flex">
                      <div class="mr-2"><i nz-icon nzType="upload"></i></div>
                      <span class="text-select">{{ btnUploadTxt }}</span>
                    </button>
                  </nz-upload>
                </div>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzXl="5" nzLg="12" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Senha Certificado:</nz-form-label>
              <nz-form-control>
                <nz-input-group [nzSuffix]="icon">
                  <input
                    type="password"
                    nz-input
                    placeholder="Senha do certificado"
                    formControlName="senhaCert"
                  />
                </nz-input-group>
                <ng-template #icon><i nz-icon nzType="lock"></i></ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div>

    <!-- <div class="form2">
      <form nz-form [formGroup]="formCert" nzLayout="vertical">
        <div nz-row nzGutter="16">
          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired>Tipo do certificado:</nz-form-label>
              <nz-form-control>
                <nz-radio-group formControlName="tipoCertificado">
                  <label nz-radio nzValue="cpf">e-CPF</label>
                  <label nz-radio nzValue="cnpj">e-CNPJ</label>
                </nz-radio-group>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div *ngIf="formCert.get('tipoCertificado').value === 'cnpj'" nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired>Empresas:</nz-form-label>
              <nz-form-control>
                <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Selecione uma empresa" [(ngModel)]="empresaSelecionada" formControlName="empresa">
                  <nz-option *ngFor="let empresa of empresasAssinante" [nzLabel]="empresa.razaoSocial" [nzValue]="empresa.cnpj"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired>Upload Certificado:</nz-form-label>
              <nz-form-control>
                <nz-upload [nzShowUploadList]="false" [nzBeforeUpload]="beforeUpload" [nzRemove]="removeUpload">
                  <button nz-button nzBlock class="d-flex">
                    <div class="mr-2"><i nz-icon nzType="upload"></i></div>
                    <span class="text-select">{{ btnUploadTxt }}</span>
                  </button>
                </nz-upload>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="8">
            <nz-form-item>
              <nz-form-label nzRequired>Senha Certificado:</nz-form-label>
              <nz-form-control>
                <nz-input-group [nzSuffix]="icon">
                  <input type="password" nz-input placeholder="Senha do certificado" formControlName="senhaCert" />
                </nz-input-group>
                <ng-template #icon><i nz-icon nzType="lock"></i></ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
    </div> -->
  </div>
</ps-complex-form>

<nz-dropdown-menu #colunas="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkCol.cpfCnpjCert">CPF/CNPJ</label>
    </li>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkCol.razaoSocial">Emissor</label>
    </li>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkCol.empresaCertificadora">
        Empresa
      </label>
    </li>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkCol.tipoCertificado">Tipo</label>
    </li>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkCol.dataFimCert">Validade</label>
    </li>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkCol.status">Situação</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetar()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>

<ps-fab [actions]="fabButtons"></ps-fab>
