import { Component, Inject, OnInit } from '@angular/core';
import { IModulo, Module, ModuloService } from '@ps-erp-angular/shared';

import { AuthService } from '@ps-erp-angular/shared';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import { ModuloState } from '../states/modulos.state';

@Component({
  selector: 'ps-digitalizacao-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  public modulo = 'Digitalização';
  public icone!: string;
  public urlEventSource: string;

  constructor(
    @Inject('urlEventSource') private readonly _urlEventSource: string,
    private _moduloService: ModuloService,
    private _authService: AuthService,
    private readonly _notification: NzNotificationService
  ) {
    this.urlEventSource = this._urlEventSource;
    const moduloInfo: Partial<Module> = this._authService.getmoduloNomeIcone();
    this.modulo = moduloInfo.nomModulo;
    this.icone = moduloInfo.icone;
  }

  ngOnInit(): void {
    setTimeout(() => {
      const sub: Subscription = this._moduloService
        .getManyModuloNav$()
        .subscribe({
          next: (resp: { msg: string; data: IModulo[] }) => {
            ModuloState.modulos = resp.data;
          },
          error: (err) => {
            this._notification.error('Modulos', err);
          },
          complete: () => {
            sub.unsubscribe();
          },
        });
    }, 200);
  }
}
