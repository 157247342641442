import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  ModuloService,
  SubmoduloService,
  TipoDocumentoService,
} from '@ps-erp-angular/shared';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Subscription } from 'rxjs';
import {
  IModulo,
  ISubmodulo,
} from '../../../../../libs/shared/src/lib/interfaces/document-interfaces';
import { ModuloComponent } from '../modulo/modulo.component';
import { TipoDocumentoComponent } from '../tipo-documento/tipo-documento.component';

@Component({
  selector: 'ps-submodulo',
  templateUrl: './submodulo.component.html',
  styleUrls: ['./submodulo.component.scss'],
})
export class SubmoduloComponent implements OnInit {
  public tabIndex: 0 | 1 = 0;
  @Input() openModal = false;
  public fabButtons: IMenuButton[];
  public formB: FormGroup = new FormGroup({});
  public loading: boolean = false;
  public displayData: any[] = [];
  public buscarSubmoduloInput: string;
  public tooltipSubmodulo: boolean = false;
  public innerWidth: any;
  public timeoutgetResult;
  checkAllIndeterminate: boolean;
  checkedAll: boolean;
  public checkList: { [typekey: string]: boolean } = {
    idPublico: true,
    descricao: true,
    descricaoModulo: true,
  };
  intervaloDigitandoSubmodulo: any;
  intervaloDigitando: any;
  loadingTipoDoc = false;

  public getTableColumns = [
    {
      label: 'Id',
      columnName: 'idPublico',
      width: '5rem',
      compare: (a: any, b: any) => {
        return a['idPublico'] - b['idPublico'];
      },
    },
    {
      label: 'Descrição',
      columnName: 'descricao',
      width: '25rem',
      compare: (a: any, b: any) => {
        return a['descricao'].localeCompare(b['descricao']);
      },
    },
    {
      label: 'Módulo',
      columnName: 'descricaoModulo',
      width: '25rem',
      compare: (a: any, b: any) => {
        return a['descricaoModulo'].localeCompare(b['descricaoModulo']);
      },
    },
  ];

  constructor(
    @Optional()
    private modalRef: NzModalRef<ModuloComponent>,
    private formBuilder: FormBuilder,
    public notification: NzNotificationService,
    private readonly submoduloService: SubmoduloService,
    private moduloService: ModuloService,
    private tipoDocService: TipoDocumentoService,
    private modalService: NzModalService
  ) {
    this.resetFabButtons();
    this.resetForm();
  }

  private buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  private resetFabButtons(): void {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'selecionar',
        condition: this.openModal,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: false,
        // condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletear',
        color: 'red',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: true,
        onClick: this.consultar,
      },
      // {
      //   icon: 'reload',
      //   tooltip: 'Atualizar',
      //   // color: 'blue',
      //   condition: true,
      //   onClick: this.atualizar,
      // },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  consultar: () => void = () => {
    this.consultarSubmodulos();

    this.changeTabIndex(1);
  };

  selecionar: () => void = () => {
    const registro = this.getFirstRegistro();
    this.modalRef.destroy(registro);
  };

  novoCadastro: () => void = () => {
    this.resetForm();
  };

  limparEntidadeTipoDoc() {
    this.formB.get('idTipoDocPublico').reset();
    this.formB.get('tipoDocumentoSubmodulo').reset();
    this.formB.get('entidadeTipoDoc').reset();
  }

  deletar: () => void = () => {
    // this.moduloService
    //   .deleteModulo(this.getFirstRegistro().idPrivado)
    //   .then(() => this.consultarModulos());
    this.loading = true;

    const submodulos = this.pegarSubmodulosSelecionados();
    if (submodulos.length !== 1) {
      this.submoduloService.notification.info(
        'Módulos',
        'Não foi possível deletar o submódulo, pois exitem muitos selecionados ou nenhum.'
      );
      this.uncheckedAll();
      return;
    }
    const submodulo = submodulos[0];
    this.modalService.confirm({
      nzTitle: '<i>Deseja excluir Submódulo?</i>',
      nzOnOk: () => {
        this.submoduloService
          .deleteSubmodulo(submodulo.idPrivado)
          .then(() => this.consultarSubmodulos());
        this.loading = false;

        this.displayData = this.displayData.filter(
          (msg) => !(msg.idPrivado === submodulo.idPrivado)
        );
      },
    });
  };

  private salvar: () => void = async () => {
    this.loading = true;

    if (this.formB.get('idPrivado').value) {
      this.submoduloService.updateModulo(this.formB.value);
      this.resetForm();
      this.consultarSubmodulos();
      return;
    }

    await this.submoduloService.createSubmodulo(this.formB.value);
    this.consultarSubmodulos();
    this.resetForm();
    this.loading = false;

    return;
  };

  public dbClickEdit(data) {
    this.resetForm();
    this.formB.controls.idPublico.enable();

    if (this.openModal) {
      this.modalRef.destroy(data);
      return;
    }
    this.changeTabIndex(0);
    this.formB.patchValue({
      idPrivado: data.idPrivado,
      idPublico: data.idPublico,
      descricao: data.descricao,
      descricaoModulo: data.modulo.descricao,
      idModulo: data.modulo.idPrivado,
      idModuloPublico: data.modulo.idPublico,
    });

    this.formB.controls.idPublico.disable();

    if (data.tiposDocumento) {
      const tipoDocForm: FormArray = this.formB.get(
        'tiposDocumento'
      ) as FormArray;
      // const userInfo = this.authService.getUserInfo();
      for (const tipoDoc of data.tiposDocumento) {
        tipoDocForm.push(
          this.formBuilder.group({
            descricao: [tipoDoc.descricao],
            // usCriacao: [userInfo.idUsuario],
            idPublico: [tipoDoc.idPublico],
            idPrivado: [tipoDoc.idPrivado],
            idSubmodulo: [data.idPrivado],
          })
        );
      }
    }

    this.uncheckedAll();
  }

  private uncheckedAll() {
    this.displayData.map((data: any) => (data.checked = false));
  }

  private pegarSubmodulosSelecionados(): ISubmodulo[] {
    return this.displayData.filter((setor: ISubmodulo) => setor.checked);
  }

  private getFirstRegistro(): ISubmodulo {
    return this.pegarSubmodulosSelecionados().find(
      (setor: ISubmodulo) => setor.checked
    );
  }

  private editar: () => void = () => {
    this.resetForm();
    this.formB.controls.idPublico.enable();

    if (this.pegarSubmodulosSelecionados().length !== 1) {
      this.notification.warning(
        'Setor',
        'Muitos ou nenhum registro selecionado!'
      );
      return;
    }
    this.formB.patchValue({
      descricao: this.getFirstRegistro().descricao,
      idPrivado: this.getFirstRegistro().idPrivado,
      idPublico: this.getFirstRegistro().idPublico,
      descricaoModulo: this.getFirstRegistro().modulo.descricao,
      idModulo: this.getFirstRegistro().modulo.idPrivado,
      idModuloPublico: this.getFirstRegistro().modulo.idPublico,
    });
    this.formB.controls.idPublico.disable();

    if (this.getFirstRegistro().tiposDocumento) {
      const tipoDocForm: FormArray = this.formB.get(
        'tiposDocumento'
      ) as FormArray;
      // const userInfo = this.authService.getUserInfo();
      for (const tipoDoc of this.getFirstRegistro().tiposDocumento) {
        tipoDocForm.push(
          this.formBuilder.group({
            descricao: [tipoDoc.descricao],
            // usCriacao: [userInfo.idUsuario],
            idPublico: [tipoDoc.idPublico],
            idPrivado: [tipoDoc.idPrivado],
            idSubmodulo: [this.getFirstRegistro().idPrivado],
          })
        );
      }
    }

    this.changeTabIndex(0);
    this.uncheckedAll();
  };

  // selecionar = () => {
  //   const registro = this.getFirstRegistro();
  //   this.modalRef.destroy(registro);
  // };

  public async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    /*usado para carregar os destinatarios da parte de consulta
    apenas quando o usuário clicar em consulta*/

    // if (value === 1 && !this.listSignatariosMensagemEnvio) {
    //   await this.populateSignatariosMensagemEnvio();
    // }
    // this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  private resetForm() {
    this.formB = this.formBuilder.group({
      idPrivado: [{ value: null, disabled: false }],
      idPublico: [{ value: null, disabled: true }],
      descricao: [{ value: null, disabled: false }, [Validators.required]],
      idModuloPublico: [
        { value: null, disabled: false },
        [Validators.required],
      ],
      idTipoDocPublico: [
        { value: null, disabled: false },
        [Validators.required],
      ],
      tipoDocumentoSubmodulo: [
        { value: null, disabled: true },
        [Validators.required],
      ],
      entidadeTipoDoc: [null],
      tiposDocumento: this.formBuilder.array([]),
      idModulo: [{ value: null, disabled: false }, [Validators.required]],
      descricaoModulo: [{ value: null, disabled: true }, [Validators.required]],
    });
  }

  limparTipoDoc() {
    this.formB.controls.tipoDocumentoSubmodulo.reset();
    this.formB.controls.idTipoDocPublico.reset();
    this.formB.controls.entidadeTipoDoc.reset();
  }

  addTipoDoc() {
    const tipoDocForm: FormArray = this.formB.get(
      'tiposDocumento'
    ) as FormArray;
    // const userInfo = this.authService.getUserInfo();
    tipoDocForm.push(
      this.formBuilder.group({
        descricao: [this.formB.value.entidadeTipoDoc.descricao],
        // usCriacao: [userInfo.idUsuario],
        idPublico: [this.formB.value.entidadeTipoDoc.idPublico],
        idPrivado: [this.formB.value.entidadeTipoDoc.idPrivado],
      })
    );
    this.limparTipoDoc();
  }

  async removeTipoDoc(idTipoDocumento) {
    const tipoDocFormb: FormArray = this.formB.get(
      'tiposDocumento'
    ) as FormArray;
    const index = this.formB.value.tiposDocumento.indexOf(
      this.formB.value.tiposDocumento.find(
        (key) => key.idTipoDocPublico === idTipoDocumento
      )
    );
    const attachToRemove = this.formB.value.anexos[index];
    attachToRemove.idPrivado;
    tipoDocFormb.removeAt(index);
    //Remover tipo doc do banco
    // this.delete(
    //   attachToRemove.idPrivado,
    //   tipoDocFormb,
    //   index,
    //   'deleteAnexo',
    //   'anexo'
    // )
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.changeTabIndex(1);
    }

    this.consultarSubmodulos();
    this.resetForm();
  }

  public getWidthContent() {
    return window.innerWidth;
  }

  private async consultarModulo(params?: Partial<IModulo>): Promise<IModulo> {
    this.loadingTipoDoc = true;

    return await this.moduloService
      .getOneModulo(params)
      .then((result: { msg: string; data: IModulo }) => {
        this.loadingTipoDoc = false;
        return result.data;
      });
  }

  showModalModulo() {
    const setorModal = this.modalService.create({
      nzTitle: 'Selecione um Módulo',
      nzContent: ModuloComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    const modalSubscription: Subscription = setorModal.afterClose.subscribe({
      next: (result: any) => {
        this.setDataModulo(result);
      },
      error: (err) => {},
      complete: () => {
        modalSubscription.unsubscribe();
      },
    });
  }

  showModalTipoDoc() {
    const setorModal = this.modalService.create({
      nzTitle: 'Selecione um Tipo de documento',
      nzContent: TipoDocumentoComponent,
      nzComponentParams: {
        openModal: true,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    const modalSubscription: Subscription = setorModal.afterClose.subscribe({
      next: (result: any) => {
        this.setDataTipoDoc(result);
      },
      error: (err) => {},
      complete: () => {
        modalSubscription.unsubscribe();
      },
    });
  }

  async searchTipoDoc(value) {
    if (
      !value ||
      this.formB.value.entidadeTipoDoc ||
      this.formB.value.idPrivado
    ) {
      return;
    }
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(() => {
      if (this.formB.value.idTipoDocPublico || value) {
        return;
      }
      this.loadingTipoDoc = true;
      this.tipoDocService.getTipoDocsByIdPublico(value).then((result) => {
        this.setDataTipoDoc(result);
      });
      this.loadingTipoDoc = false;
    }, 2000);
  }

  async searchModulo(value) {
    if (
      !value ||
      this.formB.value.entidadeTipoDoc ||
      this.formB.value.idPrivado
    ) {
      return;
    }
    clearTimeout(this.intervaloDigitandoSubmodulo);
    this.intervaloDigitandoSubmodulo = await setTimeout(async () => {
      if (this.formB.value.idModuloPublico || value) {
        return;
      }
      this.loadingTipoDoc = true;

      const modulo: unknown = await this.consultarModulo({
        idPrivado: value,
      });

      this.setDataModulo(modulo);

      this.loadingTipoDoc = false;
    }, 2000);
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  setDataModulo(data: any) {
    this.formB.patchValue({
      entidadeModulo: data,
      idModulo: data.idPrivado,
      idModuloPublico: data.idPublico,
      descricaoModulo: data.descricao,
    });
    this.formB.controls.descricaoModulo.disable();
    // this.formB.controls.idPrivadoModulo.disable();
  }

  setDataTipoDoc(data: any) {
    this.formB.patchValue({
      entidadeTipoDoc: data,
      idTipoDoc: data.idPrivado,
      idTipoDocPublico: data.idPublico,
      tipoDocumentoSubmodulo: data.descricao,
    });
  }

  public async getResult(event) {
    this.loading = true;
    clearTimeout(this.timeoutgetResult);
    this.timeoutgetResult = setTimeout(async () => {
      this.consultarSubmodulos();
      this.loading = false;
    }, 800);
  }

  private async consultarSubmodulos() {
    this.loading = true;
    const teste = ((await this.submoduloService.listarSubmodule({
      descricao: this.buscarSubmoduloInput as string,
    })) as { msg: string; data: ISubmodulo[] }).data;

    this.displayData = teste.map((itm) => {
      return {
        ...itm,
        descricaoModulo: itm.modulo.descricao,
      };
    });
    this.loading = false;
  }

  public searchSubmodulo() {
    this.tooltipSubmodulo = true;
    setTimeout(() => {
      this.tooltipSubmodulo = false;
    }, 2000);
  }

  prepareColumnValue(item, column) {
    if (this.displayData.length > 0) {
      return item[column.columnName];
    }
  }

  resetar() {
    this.checkList = {
      idPublico: true,
      descricao: true,
      descricaoModulo: true,
    };
  }
}
