<div>
  <p id="txt">
    Considerando que, com o objetivo de simplificar as operações contratuais e
    reduzir os custos para todos os envolvidos nas negociações, o signatário
    adere a inovação tecnologica de assinatura por meio eletrônico, por meio de
    sistema ("Plataforma") disponibilizado no ambiente Publicsoft. Importante
    ressaltar que a legislação brasileira reconhece a validade da assinatura
    eletrônica por meio de seu ordenamento jurídico. A plataforma de assinatura
    digital da PublicSoft está em conformidade com as leis brasileiras, de modo
    que, os contratos são validos e passíveis de serem admitidos como meio de
    prova no Poder Judiciário brasileiro. A qualquer momento, você poderá
    solicitar à PublicSoft, cópia eletrônicas dos Contratos assinados por você
    via plataforma. As informações, documentos e dados fornecidos por você
    referente aos poderes de assinatura, serão de sua inteira responsabilidade,
    isentando a PublicSoft por qualquer responsabilidade por atos praticados que
    gerem danos, prejuízos e perdas oriundas de acessos, movimentação e
    informações erroneamente informadas por você, sobretudo aquelas que decorrem
    da má observância, má-fé e mau uso da Plataforma. Pelo presente termo,
    declaro ter lido, compreendido e concordado com as condições acima
    descritas.
  </p>
</div>

<div class="clearfix">
  <nz-spin [nzSpinning]="isSpinning">
    <nz-upload [(nzFileList)]="certificados" [nzBeforeUpload]="upload">
      <button nz-button>
        <i nz-icon nzType="upload"></i>Selecionar certificado digital
      </button>
    </nz-upload>
    <form [formGroup]="formB" (ngSubmit)="assinarTermo()">
      <div style="margin-top: 20px" nz-col nzSpan="5" class="p-r-15">
        <nz-form-item>
          <nz-form-label nzRequired>Senha</nz-form-label>
          <nz-form-control [nzErrorTip]="senha">
            <input
              nz-input
              type="password"
              class="text-disabled"
              formControlName="senhaCert"
              placeholder="Digite a senha do certifica digital"
              maxlength="150"
            />
          </nz-form-control>
        </nz-form-item>
        <ng-template #senha let-control>
          <ng-container *ngIf="control.hasError('senhaCert')">
            Valor inválido para Senha!
          </ng-container>
          <ng-container *ngIf="control.hasError('required')">
            Por favor digite a senha do certificado digital!
          </ng-container>
        </ng-template>
      </div>
      <p style="margin-top: 20px">
        <label formControlName="isCheckedAceiteTermos" nz-checkbox
          >Li e declaro que aceito os termos de uso</label
        >
      </p>
    </form>
  </nz-spin>
</div>
<br /><br />
