import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  PsComponentsModule,
  PsDirectiveModule,
  PsDynamicFormModule,
  PsGridModule,
  PsUiModule,
} from '@ps-erp-angular/ps-ui';
import { AuthService } from '@ps-erp-angular/shared';
import { NgChartjsModule } from 'ng-chartjs';
import { NgxMaskModule } from 'ngx-mask';
import { DashboardComponent } from './dashboard/dashboard.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProcessoService } from '@ps-erp-angular/shared';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMentionModule } from 'ng-zorro-antd/mention';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { environment } from '../environments/environment';
import { AnexosComponent } from './anexos/anexos.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DocumentSignComponent } from './document-sign/document-sign.component';
import { DocumentsDetailComponent } from './documents-detail/documents-detail.component';
import { DocumentsUploadWrapComponent } from './documents-upload-wrap/documents-upload-wrap.component';
import { DocumentsUploadWrapDirective } from './documents-upload-wrap/documents-upload-wrap.directive';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { StateDocumentService } from './documents-upload/state-document-detail.service';
import { DocumentsComponent } from './documents/documents.component';
import { ExportacaoArquivosComponent } from './exportacao-arquivos/exportacao-arquivos.component';
import { GridModalAtestadoresComponent } from './grid-modal-atestadores/grid-modal-atestadores.component';
import { IframeFileExportComponent } from './iframe-file-export/iframe-file-export.component';
import { LayoutComponent } from './layout/layout.component';
import { MensagensEnvioComponent } from './mensagens-envio/mensagens-envio.component';
import { MensagensComponent } from './mensagens/mensagens.component';
import { MeusDocumentosComponent } from './meus-documentos/meus-documentos.component';
import { ModalDialogComponent } from './modal-dialog/modal-dialog.component';
import { ModalTermoAceiteNouserComponent } from './modal-termo-aceite-nouser/modal-termo-aceite-nouser.component';
import { ModalTermoAceiteComponent } from './modal-termo-aceite/modal-termo-aceite.component';
import { ModuloComponent } from './modulo/modulo.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PerfilComponent } from './perfil/perfil.component';
import { PessoaFisicaComponent } from './pessoa-fisica/pessoa-fisica.component';
import { PessoaJuridicaComponent } from './pessoa-juridica/pessoa-juridica.component';
import { SetorComponent } from './setor/setor.component';
import { SubmoduloComponent } from './submodulo/submodulo.component';
import { TipoDocumentoComponent } from './tipo-documento/tipo-documento.component';
import { VerifyDocumentComponent } from './verify-document/verify-document.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    DashboardComponent,
    VerifyDocumentComponent,
    DocumentSignComponent,
    ModalTermoAceiteNouserComponent,
    ModalTermoAceiteComponent,
    ModalDialogComponent,
    NotFoundComponent,
    DocumentsComponent,
    DocumentsDetailComponent,
    AnexosComponent,
    DocumentsUploadComponent,
    PessoaFisicaComponent,
    PessoaJuridicaComponent,
    SetorComponent,
    TipoDocumentoComponent,
    PerfilComponent,
    MeusDocumentosComponent,
    ExportacaoArquivosComponent,
    MensagensComponent,
    MensagensEnvioComponent,
    IframeFileExportComponent,
    ModuloComponent,
    DocumentsUploadWrapComponent,
    DocumentsUploadWrapDirective,
    SubmoduloComponent,
    GridModalAtestadoresComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgChartjsModule,
    AppRoutingModule,
    PsDynamicFormModule.forRoot({
      validationMessages: [{ name: 'pattern', message: 'Formato invalido' }],
    }),
    PsGridModule.forRoot({ gridType: 'antd' }),
    PsUiModule.forRoot({ appName: 'Digitalização', appVersion: '1.0.0' }),
    NgxMaskModule.forRoot(),
    PsComponentsModule,
    PsDirectiveModule,
    NzCollapseModule,
    NzTableModule,
    NzSpinModule,
    NzPaginationModule,
    NzDropDownModule,
    NzButtonModule,
    NzGridModule,
    NzFormModule,
    NzSelectModule,
    NzCascaderModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzDatePickerModule,
    NzSkeletonModule,
    NzIconModule,
    NzMenuModule,
    NzInputModule,
    NzTagModule,
    NzCardModule,
    NzBadgeModule,
    BrowserAnimationsModule,
    NzCarouselModule,
    NzAvatarModule,
    NzToolTipModule,
    NgChartjsModule,
    NzSwitchModule,
    NzUploadModule,
    NzRadioModule,
    NzProgressModule,
    NzTimelineModule,
    NzDividerModule,
    NzTreeModule,
    NzMentionModule,
    NzBreadCrumbModule,
  ],
  providers: [
    ProcessoService,
    AuthService,
    StateDocumentService,
    {
      provide: 'urlEventSource',
      useValue: `${environment.apiUrl(
        'digitalizacao-worker'
      )}/api/jobs/events-queue`,
      // useValue: 'http://localhost:3335/api/jobs/events-queue',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
