import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
  AfterContentInit,
  Component,
  Input,
  OnInit,
  Optional,
} from '@angular/core';

import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  AuthService,
  Helper,
  IColumnsFieldsProps,
  ISearchFieldsProps,
  ISubmodulo,
  ITipoDoc,
  ModuloService,
  TipoDocumentoService,
} from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModuloComponent } from '../modulo/modulo.component';
import { PessoaFisicaComponent } from '../pessoa-fisica/pessoa-fisica.component';

@Component({
  selector: 'ps-digitalizacao-tipo-documento',
  templateUrl: './tipo-documento.component.html',
  styleUrls: ['./tipo-documento.component.scss'],
})
export class TipoDocumentoComponent implements OnInit, AfterContentInit {
  @Input() openModal = false;
  @Input() entidadeTipoDoc: ITipoDoc = null;

  loading = false;
  loadingTipoDoc = false;

  searchInput: any;
  displayData = [];
  tipoDocumentList: ITipoDoc[] = [];

  searchColumns: ISearchFieldsProps[] = [];
  tableColumns: IColumnsFieldsProps[] = [];

  tabIndex = 0;

  checkAllIndeterminate;
  checkedAll;
  formB: FormGroup = new FormGroup({});
  public entidadeAtestadorArr: Array<unknown> = new Array<any>();

  intervaloDigitando: any;

  checkList = {
    idPublico: true,
    tipo: true,
    descricao: true,
    tag: true,
    tituloManifesto: true,
    textoComplementar: true,
  };

  fabButtons: IMenuButton[];

  public innerWidth: any;
  public tooltipTiposDocumentos = false;
  public hideButtons = false;
  public dataInicioAtesto: string;

  constructor(
    @Optional()
    private modalRef: NzModalRef<ModuloComponent>,

    private modalService: NzModalService,
    public service: TipoDocumentoService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private readonly moduloService: ModuloService
  ) {
    this.dataInicioAtesto = this.formatDate23hrs(this.getDateSelected());

    this.reset();
  }

  async ngAfterContentInit() {
    await this.setConfigTable();
    this.reset();
  }

  formatDate23hrs(date: string) {
    const systemDate = new Date(date);
    return new Date(
      systemDate.getFullYear(),
      systemDate.getMonth(),
      1,
      0,
      0,
      0
    ).toISOString();
  }

  getDateSelected() {
    const dateSelected = moment(new Date()).format();
    return dateSelected;
  }

  reset() {
    this.formB = this.formBuilder.group({
      idPrivado: [null],
      idPublico: [{ value: null, disabled: true }],
      tipo: [null, Validators.required],
      tag: [null],
      tituloManifesto: [null],
      textoComplementar: [null],
      descricao: [null, Validators.required],
      idUnidadeGestora: [null],
      dtCriacao: [this.authService.getDateSelected()],
      usuarioCriacao: [this.authService.getIdUser()],
      atestadores: this.formBuilder.array([this.formAtestador()]),
    });
  }

  resetFabButton() {
    const buttons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'Novo Cadastro',
        condition: !this.openModal || this.openModal,
        onClick: this.implantar,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.gravar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'orange',
        condition: (!this.openModal || this.openModal) && this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: (!this.openModal || this.openModal) && this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: true,
        onClick: this.consultar,
      },
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
    ];
    const menuFiltered = buttons.filter((button) => button.condition);
    this.buildFabButtons(menuFiltered);
  }

  resetState() {
    this.formB.reset();
    this.reset();
  }

  ngOnInit() {
    if (this.openModal) {
      return this.changeTabIndex(1);
    }
    this.innerWidth = window.innerWidth;
    this.resetFabButton();
  }

  formAtestador() {
    return this.formBuilder.group({
      idPrivado: [null],
      editado: [false],
      idPessoaAtestador: [{ value: null, disabled: false }, []],
      deletado: [false],
      cpfAtestador: [{ value: null, disabled: false }, []],
      nomeAtestador: [{ value: null, disabled: true }, []],
      dataInicioAtesto: [
        {
          value: this.dataInicioAtesto,
          disabled: false,
        },
        [],
      ],
      vinculacaoModuloTipoDoc: [null],
    });
  }

  addAtestadorValidate(): boolean {
    const atestadores: Array<any> = this.formB.get('atestadores').value;
    const todosElementosAtestadoresPreenchidos: boolean =
      atestadores.length === 0
        ? false
        : atestadores.every((atestador) => atestador.idPessoaAtestador);

    const ultimoAtestadorPreenchido: boolean =
      atestadores.length === 0
        ? false
        : atestadores[atestadores.length - 1].idPessoaAtestador;

    return ultimoAtestadorPreenchido && todosElementosAtestadoresPreenchidos;
  }

  addAtestador() {
    const formularioForm: FormArray = this.formB.get(
      'atestadores'
    ) as FormArray;
    formularioForm.push(this.formAtestador());
  }

  async deletarAtestador(i) {
    const formAtestadores: FormArray = this.formB.get(
      'atestadores'
    ) as FormArray;

    await this.modalService.confirm({
      nzTitle: '<i>Deseja remover esse atestador?</i>',
      nzContent: 'O atestador só será removido após salvar as alterações',
      nzOnOk: async () => {
        //TODO: verificar se esse atestador que está vinculado com o tipo_documento já foi vinculado a algum modulo
        await this.service
          .verificarDeletarAtestadorTipoDoc({
            idPrivadoAtestador: formAtestadores.at(i).value.idPrivado,
            idPrivadoTipoDocumento: this.formB.value.idPrivado,
          })
          .then(() => formAtestadores.at(i).patchValue({ deletado: true }))
          .catch((e) =>
            this.service.notification.warning('Remover Atestador', e)
          );
      },
    });
  }

  checkDataExpiracao($event, i) {
    const formAtestadores: FormArray = this.formB.get(
      'atestadores'
    ) as FormArray;
    if (formAtestadores.at(i).value.idPrivado)
      formAtestadores.at(i).patchValue({ editado: true });
  }

  public showModalAtestador(i) {
    const atestadorModal = this.modalService.create({
      nzTitle: 'Selecione uma pessoa para ser atestador',
      nzContent: PessoaFisicaComponent,
      nzComponentParams: {
        openModal: true,
        tabIndex: 1,
      },
      nzFooter: null,
      nzWidth: 1000,
    });
    atestadorModal.afterClose.subscribe((pessoaAtestador) => {
      for (const atestador of this.formB.value.atestadores) {
        if (atestador.idPessoaAtestador === pessoaAtestador.idPessoa) {
          this.service.notification.warning(
            'Atestador Duplicado',
            'Não é possível adicionar duas vezes a mesma pessoa como atestador!'
          );
          return;
        }
      }
      this.setDataAtestador(pessoaAtestador, i);
    });
  }

  setDataAtestador(data: any, i: number) {
    this.formB.get(`atestadores.${i}`).patchValue({
      idPessoaAtestador: data.idPessoa ?? data.idPessoaAtestador,
      entidadeAtestador: data,
      cpfAtestador: data.cpf,
      idAtestador: data.idPrivado,
      idPrivado: data.idPrivado,
      nomeAtestador: data.nome,
      idPessoa: data.idPessoa ?? data.idPessoaAtestador,
      vinculacaoModuloTipoDoc: data.vinculacaoModuloTipoDoc,
    });

    if (data.dataInicioAtesto) {
      this.formB
        .get(`atestadores.${i}`)
        .patchValue({ dataInicioAtesto: data.dataInicioAtesto });
    }

    // this.formB.controls.atestadores[i].nomeAtestador.setValue(data.nome);
    this.entidadeAtestadorArr[i] = data;
  }

  limparAtestadorForm(i) {
    const atestadoresArray = this.formB.get('atestadores') as FormArray;
    atestadoresArray.removeAt(i);
  }

  limparEntidadeAtestardor(i) {
    this.formB.get(`atestadores.${i}`).patchValue({
      entidadeAtestador: null,
      cpfAtestador: null,
      idAtestador: null,
      nomeAtestador: null,
      idPessoa: null,
      idPessoaAtestador: null,
    });
    this.entidadeAtestadorArr[i] = null;
  }

  getAtestadorFormBuild(i) {
    return (
      this.formB.controls.atestadores.value.length > 1 &&
      !this.formB.get(`atestadores.${i}`).value.idPrivado
    );
  }

  searchTiposDocumentos() {
    this.tooltipTiposDocumentos = true;
    setTimeout(() => {
      this.tooltipTiposDocumentos = false;
    }, 2000);
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async setTipoDocValido() {
    this.loading = true;
    const codigoValido = await this.service.getTipoDocs(null, true);
    this.moduloService.getManyModuloNav();
    const values = codigoValido.map((val) => val.idPublico);
    const max = Math.max.apply(null, values);
    const getCodigo = codigoValido.find((key) => key.idPublico === max);

    if (!getCodigo) {
      this.formB.controls.idPublico.setValue(1);
    }

    if (!this.formB.value.idPublico) {
      this.formB.controls.idPublico.setValue(getCodigo.idPublico + 1);
    }
    this.loading = false;
  }

  resetForm() {
    this.resetState();
    this.setTipoDocsData([]);
    this.changeTabIndex(0);
    this.loading = false;
  }

  async setConfigTable() {
    this.loading = true;
    await this.service.getDocumentTableProps().then((result) => {
      Object.assign(this, result);
      this.setCompareToTableColumns();
    });
    this.loading = false;
  }

  setCompareToTableColumns() {
    this.tableColumns.map((column) => {
      column[`compare`] =
        this.displayData.length > 0 &&
        typeof this.displayData[0][column.value] === 'string'
          ? (a: ITipoDoc, b: ITipoDoc) =>
              a[column.value].localeCompare(b[column.value])
          : (a: ITipoDoc, b: ITipoDoc) => a[column.value] - b[column.value];
    });
  }

  async getTiposDoc(value = '') {
    clearTimeout(this.intervaloDigitando);
    this.intervaloDigitando = await setTimeout(async () => {
      this.loading = true;
      await this.service
        .getTipoDocs(value)
        .then((tiposDoc: ITipoDoc[]) => {
          this.moduloService.getManyModuloNav();
          this.formatResultGetTiposDoc(tiposDoc);
        })
        .catch(() => this.formatResultGetTiposDoc([]));
      this.loading = false;
    }, 1000);
  }

  formatResultGetTiposDoc(tiposDoc: ITipoDoc[]) {
    tiposDoc.map((tipo: ITipoDoc) => (tipo.checked = false));
    this.setTipoDocsData(tiposDoc);
  }

  setTipoDocsData(tipos: ITipoDoc[]) {
    this.tipoDocumentList = tipos;
    this.displayData = tipos;
  }

  getResult(): void {
    this.displayData = this.service.documentService.search(
      this.tipoDocumentList,
      this.searchInput
    );
  }

  getColumnsStatus() {
    return this.tableColumns.find(
      (column) => column.value === 'statusDocumento'
    );
  }

  getTableColumns() {
    return this.tableColumns.filter(
      (column) => column.value !== 'statusDocumento'
    );
  }

  // limparEntidadeSetor() {
  //   this.formB.get('idSubmodulo').reset();
  //   this.formB.get('descricaoSubmodulo').reset();
  //   this.formB.get('entidadeSubmodulo').reset();
  // }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    if (value && column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  changeTabIndex(value) {
    this.tabIndex = value;
    this.resetFabButton();
    if (this.tabIndex === 1) {
      this.loading = true;
      this.service
        .getTipoDocs(null, true)
        .then((tiposDoc: ITipoDoc[]) => {
          this.loading = false;
          this.moduloService.getManyModuloNav();
          this.formatResultGetTiposDoc(tiposDoc);
        })
        .catch(() => {
          this.loading = false;
          this.formatResultGetTiposDoc([]);
        });
    }
    // if (this.tabIndex === 0 && this.openModal) {
    //   this.setTipoDocValido();
    // }
    // this.buildFabButtons([
    //   {
    //     icon: 'plus',
    //     tooltip: 'Novo Cadastro',
    //     condition: !this.openModal || this.openModal,
    //     onClick: this.implantar,
    //   },
    //   {
    //     icon: 'save',
    //     tooltip: 'Salvar',
    //     condition: this.tabIndex === 0,
    //     onClick: this.gravar,
    //   },
    //   {
    //     icon: 'edit',
    //     tooltip: 'Editar',
    //     condition: (!this.openModal || this.openModal) && this.tabIndex === 1,
    //     onClick: this.editar,
    //   },
    //   {
    //     icon: 'search',
    //     tooltip: 'Consultar',
    //     condition: true,
    //     onClick: this.consultar,
    //   },
    //   {
    //     icon: 'select',
    //     tooltip: 'Selecionar',
    //     condition: this.openModal && this.tabIndex === 1,
    //     onClick: this.selecionar,
    //   },
    // ]);
  }

  // selectItem(entidadeTipoDoc) {
  //   this.checkItemPesquisa(entidadeTipoDoc);

  // this.entidadeTipoDoc = this.tipoDocumentList.every((tipo) => !tipo.checked)
  //   ? null
  //   : entidadeTipoDoc;

  // this.entidadeTipoDoc
  //   ? this.setDataFormTipoDoc(entidadeTipoDoc)
  //   : this.resetState();
  // }

  checkItemPesquisa(entidadeTipoDoc) {
    this.tipoDocumentList.map((tipo) => {
      tipo.checked =
        tipo.idPublico !== entidadeTipoDoc?.idPublico ? false : true;
    });
    this.entidadeTipoDoc = this.tipoDocumentList.every((tipo) => !tipo.checked)
      ? null
      : entidadeTipoDoc;

    // this.setDataFormTipoDoc(entidadeTipoDoc);
  }

  implantar = () => {
    this.resetForm();
    // this.setTipoDocValido();
  };

  selecionar = () => {
    if (
      !this.entidadeTipoDoc ||
      this.tipoDocumentList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!'
      );
    }

    this.modalRef.destroy(this.entidadeTipoDoc);
  };

  editar = () => {
    if (
      !this.entidadeTipoDoc ||
      this.tipoDocumentList.every((tipo) => !tipo.checked)
    ) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!'
      );
    }
    this.setDataFormTipoDoc(this.entidadeTipoDoc);
    this.changeTabIndex(0);
  };

  deletar = () => {
    const tiposDocumento = this.pegarTipoDocumentoSelecionados();
    if (tiposDocumento.length !== 1) {
      this.service.notification.info(
        'Tipo Documento',
        'Não foi possível deletar o tipo de documento, pois exitem muitos selecionados ou nenhum.'
      );
      this.uncheckedAll();
      return;
    }
    const tipoDoc = tiposDocumento[0];
    this.modalService.confirm({
      nzTitle: '<i>Deseja excluir este tipo de documento?</i>',
      nzOnOk: () => {
        this.service
          .deleteTipoDocumento(tipoDoc.idPrivado)
          .then(() => this.consultar());
        this.displayData = this.displayData.filter(
          (msg) => !(msg.idPrivado === tipoDoc.idPrivado)
        );
      },
    });
  };

  setDataFormTipoDoc(item) {
    this.formB.patchValue({
      idPrivado: item.idPrivado,
      idPublico: item.idPublico,
      tipo: item.tipo,
      descricao: item.descricao,
      tag: item.tag,
      tituloManifesto: item.tituloManifesto,
      textoComplementar: item.textoComplementar,
    });

    if (item.atestadores.length > 0) {
      const atestadoresForm: FormArray = this.formB.get(
        'atestadores'
      ) as FormArray;

      while (atestadoresForm.length !== 0) {
        atestadoresForm.removeAt(0);
      }

      item.atestadores.forEach((atesto, index) => {
        const atestadorFormGroup = this.formBuilder.group({
          idPrivado: [atesto.idPrivado],
          editado: [false],
          deletado: [false],
          idPessoaAtestador: [atesto.idPessoaAtestador],
          cpfAtestador: [atesto.cpfAtestador],
          nomeAtestador: [atesto.nomeAtestador],
          dataInicioAtesto: [atesto.dataInicioAtesto],
        });
        atestadoresForm.push(atestadorFormGroup);
      });
      atestadoresForm.controls.forEach((control) => {
        control.get('nomeAtestador').disable();
        control.get('cpfAtestador').disable();
      });
    }
  }

  private uncheckedAll() {
    this.displayData.map((data: any) => (data.checked = false));
  }

  private pegarTipoDocumentoSelecionados(): ISubmodulo[] {
    return this.displayData.filter((setor: ISubmodulo) => setor.checked);
  }

  private getFirstRegistro(): ISubmodulo {
    return this.pegarTipoDocumentoSelecionados().find(
      (setor: ISubmodulo) => setor.checked
    );
  }

  gravar = async () => {
    try {
      if (this.formB.invalid) {
        return this.service.notification.warning(
          'Formulário',
          'Por favor, preencha todos os campos corretamente'
        );
      }

      await this.modalService.confirm({
        nzTitle: `<i>Salvar ${
          this.formB.get('idPrivado').value ? 'alterações' : 'tipo de documento'
        }?</i>`,
        // nzContent: ``,
        nzOnOk: async () => {
          const ugSelected = await this.authService.getUgSelected();

          this.formB.controls.idUnidadeGestora.setValue(ugSelected.id);
          this.formB.controls.idPublico.enable();
          await this.setTipoDocValido();

          const { entidadeModulo, ...tipoDocumento } = this.formB.value;

          this.loading = true;
          const form = {
            ...tipoDocumento,
            atestadores: tipoDocumento.atestadores.filter(
              (e) => e.idPessoaAtestador
            ),
          };

          this.formB.get('idPrivado').value
            ? await this.service
                .alterar(form, this.formB.controls.idPrivado.value)
                .then(() => {
                  this.moduloService.getManyModuloNav();
                  this.resetForm();
                  this.loading = false;
                })
                .catch(() => (this.loading = false))
            : await this.service
                .inserir(form)
                .then(() => {
                  this.moduloService.getManyModuloNav();
                  this.resetForm();
                  this.loading = false;
                })
                .catch(() => (this.loading = false));
        },
      });

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  };

  consultar = async () => {
    this.loading = true;
    this.changeTabIndex(1);
    this.searchInput = null;
    await this.service
      .getTipoDocs(null, true)
      .then((tiposDoc: ITipoDoc[]) => {
        this.moduloService.getManyModuloNav();
        this.formatResultGetTiposDoc(tiposDoc);
      })
      .catch(() => this.formatResultGetTiposDoc([]));
    this.loading = false;
  };

  dbClickItem(item: ITipoDoc) {
    if (!item.checked) {
      this.checkItemPesquisa(item);
      this.entidadeTipoDoc = item;
    }

    this.openModal
      ? this.modalRef.destroy(this.entidadeTipoDoc)
      : this.editar();
  }

  getWidthContent() {
    return window.innerWidth;
  }

  resetar() {
    this.checkList = {
      idPublico: true,
      tag: true,
      tipo: true,
      descricao: true,
      tituloManifesto: true,
      textoComplementar: true,
    };
  }
}
