import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export const componentStateMap: Map<string, any> = new Map<
  string,
  { [key: string]: any }
>();

export const docUploadChangeState$ = new EventEmitter();

@Injectable({
  providedIn: 'root',
})
export class StateDocumentService {
  private componentStateMap: Map<string, any> = new Map<
    string,
    { [key: string]: any }
  >();
  private _componentStateSubject$ = new BehaviorSubject<{ [key: string]: any }>(
    {}
  );

  constructor(private router: Router) {
    // this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => {
    //     const currentState = this.componentStateMap.get(
    //       event.urlAfterRedirects
    //     );
    //     this.componentStateSubject = currentState;
    //   });
  }

  set componentState({
    url,
    state,
  }: {
    url: string;
    state: { [key: string]: any };
  }) {
    this.componentStateMap.set(url, state);
    const currentState = this.componentStateMap.get(url);
    this.componentStateSubject = currentState;
  }

  get componentStateSubject$() {
    return this._componentStateSubject$.asObservable();
  }
  get componentStateSubject() {
    return this._componentStateSubject$.getValue();
  }
  set componentStateSubject(component) {
    this._componentStateSubject$.next(component);
  }
}
