import { Location } from '@angular/common';
import {
  Component,
  ComponentFactoryResolver,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DocumentsUploadComponent } from '../documents-upload/documents-upload.component';
import { DocumentsUploadWrapDirective } from './documents-upload-wrap.directive';

@Component({
  template: `
    <div>
      <ng-template documents-upload-wrapper></ng-template>
    </div>
  `,
  styleUrls: ['./documents-upload-wrap.component.scss'],
})
export class DocumentsUploadWrapComponent implements OnInit {
  @ViewChild(DocumentsUploadWrapDirective, { static: true })
  directive: DocumentsUploadWrapDirective;
  private _routerChangeSubscription: { [key: string]: Subscription } = {};
  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _resolver: ComponentFactoryResolver,
    private readonly _location: Location
  ) {}

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      this.loadComponent();
    });
  }

  loadComponent() {
    const factoryResolver = this._resolver.resolveComponentFactory(
      DocumentsUploadComponent
    );

    this.directive.viewContainerRef.clear();

    this.directive.viewContainerRef.createComponent<DocumentsUploadComponent>(
      factoryResolver
    );
  }
}
