<div class="header">
  <div class="header-child">
    <div class="nav-wrap d-flex flex-sm-row flex-column">
      <div class="nav-left">
        <nz-avatar [nzSize]="32" [nzSrc]="'../favicon.ico'"> </nz-avatar>
        <h5 class="m-l-15 mt-2 m-r-15">Publicsoft</h5>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid p-v-20 h-100">
  <div class="d-flex flex-column justify-content-between h-100">
    <div class="container">
      <div class="row align-items-center items">
        <div class="col-md-8 m-h-auto">
          <div class="text-center m-t-100">
            <img
              class="img-fluid w-90"
              src="assets/images/others/error-2.png"
              alt=""
            />
            <h2 class="font-weight-light font-size-30 m-t-60 m-b-20">
              Desculpe, você não tem acesso ao documento...
            </h2>
            <p class="w-70 lh-1-8 m-h-auto font-size-17">
              Para acessá-lo é necessário retornar a página anterior, ler e
              aceitar os termos de uso e privacidade.
            </p>
            <button
              [nzType]="'primary'"
              class="m-l-10 btn-actions"
              nz-button
              type="button"
              (click)="goBack()"
            >
              Voltar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ps-footer
  class="position-fixed"
  style="bottom: 0; width: 100%"
  [config]="footerConfig"
>
</ps-footer>
