import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from '@ps-erp-angular/shared';
import { AnexosComponent } from './anexos/anexos.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentSignComponent } from './document-sign/document-sign.component';
import { DocumentsDetailComponent } from './documents-detail/documents-detail.component';
import { DocumentsUploadWrapComponent } from './documents-upload-wrap/documents-upload-wrap.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { DocumentsComponent } from './documents/documents.component';
import { ExportacaoArquivosComponent } from './exportacao-arquivos/exportacao-arquivos.component';
import { IframeFileExportComponent } from './iframe-file-export/iframe-file-export.component';
import { LayoutComponent } from './layout/layout.component';
import { MeusDocumentosComponent } from './meus-documentos/meus-documentos.component';
import { ModuloComponent } from './modulo/modulo.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PessoaFisicaComponent } from './pessoa-fisica/pessoa-fisica.component';
import { PessoaJuridicaComponent } from './pessoa-juridica/pessoa-juridica.component';
import { SetorComponent } from './setor/setor.component';
import { TipoDocumentoComponent } from './tipo-documento/tipo-documento.component';
import { VerifyDocumentComponent } from './verify-document/verify-document.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'session',
    pathMatch: 'full',
    canActivate: [AuthService],
  },
  { path: 'validar-arquivo', component: VerifyDocumentComponent },
  { path: 'assinar-documento', component: DocumentSignComponent },
  {
    path: 'arquivos-exportados/:uuidUpload',
    component: IframeFileExportComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: 'session',
    component: LayoutComponent,
    canActivate: [AuthService],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'modulo', component: ModuloComponent },
      {
        path: `documentos/:modulo/:tipo-documento`,
        component: DocumentsUploadWrapComponent,
      },
      {
        path: `documentos/:modulo/:modulo/:tipo-documento`,
        component: DocumentsUploadWrapComponent,
      },
      // { path: 'analitcs', component: AnalitcsComponent },
      { path: 'documentos', component: DocumentsComponent },
      { path: 'anexos', component: AnexosComponent },
      { path: 'documento-detalhes', component: DocumentsDetailComponent },
      { path: 'documentos-avulso', component: DocumentsUploadComponent },
      { path: 'tipo-documento', component: TipoDocumentoComponent },
      { path: 'pessoa-fisica', component: PessoaFisicaComponent },
      { path: 'pessoa-juridica', component: PessoaJuridicaComponent },
      // { path: 'perfil', component: PerfilComponent },
      { path: 'meus-certificados', component: MeusDocumentosComponent },
      { path: 'exportacao-arquivos', component: ExportacaoArquivosComponent },
      // { path: 'mensagens', component: MensagensComponent },
      // { path: 'envio-mensagens', component: MensagensEnvioComponent },
      { path: 'setor', component: SetorComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
