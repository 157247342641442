import { Component, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { ISetor, SetorService } from '@ps-erp-angular/shared';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DocumentsUploadComponent } from '../documents-upload/documents-upload.component';

@Component({
  selector: 'ps-digitalizacao-setor',
  templateUrl: './setor.component.html',
  styleUrls: ['./setor.component.scss'],
})
export class SetorComponent implements OnInit {
  public tabIndex: 0 | 1 = 0;
  public fabButtons: IMenuButton[];
  public formB: FormGroup = new FormGroup({});
  public buscarSetorInput: string;
  public innerWidth: any;
  public tooltipSetor: boolean = false;
  public displayData: Array<ISetor> = [];
  public timeoutgetResult;
  public loading: boolean = false;
  public checkAllIndeterminate: boolean = true;
  public checkedAll = false;
  public checkList: { [typekey: string]: boolean } = {
    idPublico: true,
    descricao: true,
  };
  @Input()
  public openModal: boolean;

  public pageTotal: number;
  public getTableColumns = [
    {
      label: 'Id',
      columnName: 'idPublico',
      width: '5rem',
      compare: (a: any, b: any) => {
        return a['idPublico'] - b['idPublico'];
      },
    },
    {
      label: 'Descrição',
      columnName: 'descricao',
      width: '50rem',
      compare: (a: any, b: any) => {
        return a['descricao'].localeCompare(b['descricao']);
      },
    },
  ];

  constructor(
    @Optional()
    private modalRef: NzModalRef<DocumentsUploadComponent>,
    private readonly setorService: SetorService,
    private formBuilder: FormBuilder,
    public notification: NzNotificationService
  ) {
    this.resetFabButtons();
  }

  public async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    /*usado para carregar os destinatarios da parte de consulta
    apenas quando o usuário clicar em consulta*/

    // if (value === 1 && !this.listSignatariosMensagemEnvio) {
    //   await this.populateSignatariosMensagemEnvio();
    // }
    // this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }

  updateAllChecked() {
    this.checkedAll = !this.checkedAll;
    this.checkAllIndeterminate = !this.checkedAll;
    this.displayData = this.checkedAll
      ? (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: true };
        }))
      : (this.displayData = this.displayData.map((data: any) => {
          return { ...data, checked: false };
        }));
  }

  public getWidthContent() {
    return window.innerWidth;
  }

  public searchSetor() {
    this.tooltipSetor = true;
    setTimeout(() => {
      this.tooltipSetor = false;
    }, 2000);
  }

  private resetFabButtons(): void {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'selecionar',
        condition: this.openModal,
        onClick: this.selecionar,
      },
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },

      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: false,
        // condition: this.tabIndex === 1,
        onClick: this.deletar,
      },

      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: true,
        onClick: this.consultar,
      },

      // {
      //   icon: 'reload',
      //   tooltip: 'Atualizar',
      //   // color: 'blue',
      //   condition: true,
      //   onClick: this.atualizar,
      // },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }
  selecionar = () => {
    const registro = this.getFirstRegistro();
    this.modalRef.destroy(registro);
  };

  public novoCadastro: () => void = () => {
    this.formB.reset();
    this.changeTabIndex(0);
  };

  public dbClickEdit(data) {
    if (this.openModal) {
      this.modalRef.destroy(data);
      return;
    }
    this.changeTabIndex(0);
    this.formB.patchValue({
      idPrivado: data.idPrivado,
      idPublico: data.idPublico,
      descricao: data.descricao,
    });

    this.uncheckedAll();
  }

  private uncheckedAll() {
    this.displayData.map((data: any) => (data.checked = false));
  }

  private salvar: () => void = async () => {
    if (this.formB.get('idPrivado').value) {
      this.setorService.updateSetor(
        this.formB.get('idPrivado').value,
        this.formB.value
      );
      this.formB.reset();
      this.consultarSetores();
      return;
    }

    await this.setorService.createSetor(this.formB.value);
    this.consultarSetores();
    this.formB.reset();
    return;
  };

  private editar: () => void = () => {
    if (this.pegarSetoresSelecionados().length !== 1) {
      this.notification.warning(
        'Setor',
        'Muitos ou nenhum registro selecionado!'
      );
      return;
    }
    this.formB.patchValue({
      descricao: this.getFirstRegistro().descricao,
      idPrivado: this.getFirstRegistro().idPrivado,
      idPublico: this.getFirstRegistro().idPublico,
    });

    this.changeTabIndex(0);
    this.uncheckedAll();
  };

  private deletar: () => void = () => {
    if (this.pegarSetoresSelecionados().length !== 1) {
      this.notification.warning(
        'Setor',
        'Muitos ou nenhum registro selecionado!'
      );
    }
  };
  private consultar: () => void = () => {
    this.consultarSetores();
    this.changeTabIndex(1);
  };

  private atualizar: () => void = () => {
    this.consultarSetores();
  };

  private pegarSetoresSelecionados(): ISetor[] {
    return this.displayData.filter((setor: ISetor) => setor.checked);
  }

  private getFirstRegistro(): ISetor {
    return this.pegarSetoresSelecionados().find(
      (setor: ISetor) => setor.checked
    );
  }

  private buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  ngOnInit(): void {
    if (this.openModal) {
      this.changeTabIndex(1);
    }
    this.consultarSetores();
    this.resetForm();
  }

  public async getResult(event) {
    this.loading = true;
    clearTimeout(this.timeoutgetResult);
    this.timeoutgetResult = setTimeout(async () => {
      this.consultarSetores();
      this.loading = false;
    }, 800);
  }

  private async consultarSetores() {
    this.loading = true;
    this.displayData = (await this.setorService.listarSetor({
      descricao: this.buscarSetorInput,
    })) as ISetor[];
    this.loading = false;
  }

  private resetForm() {
    this.formB = this.formBuilder.group({
      idPrivado: [{ value: null, disabled: true }],
      idPublico: [{ value: null, disabled: true }],
      descricao: [{ value: null, disabled: false }, [Validators.required]],
    });
  }

  prepareColumnValue(item, column) {
    if (this.displayData.length > 0) {
      return item[column.columnName];
    }
  }

  resetar() {
    this.checkList = {
      idPublico: true,
      descricao: true,
    };
  }
}
