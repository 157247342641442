<nz-spin [nzSpinning]="formLoading" [nzSize]="'large'">
  <ps-complex-form [title]="'Exportação de arquivos'" [tabIndex]="tabIndex" [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)">
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div nz-row>
                          <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldFilesSelected" (ngModelChange)="
                                getFilterFilesOptionsSelect(fieldFilesSelected)
                              ">
                              <nz-option *ngFor="let interval of arrColumnsfilesSelect" [nzValue]="interval"
                                [nzLabel]="interval?.text">
                              </nz-option>
                            </nz-select>
                          </div>
                          <nz-input-group *ngIf="
                              fieldFilesSelected &&
                              fieldFilesSelected.type === 'input'
                            " class="m-l-10" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"
                            [nzPrefix]="prefixTemplate">
                            <input type="text" class="text-disabled" nz-input placeholder="Informe um valor"
                              [(ngModel)]="searchInput" />
                          </nz-input-group>

                          <div *ngIf="
                              fieldFilesSelected &&
                              tabIndex == 1 &&
                              fieldFilesSelected.type === 'select'
                            " nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-select style="width: 350px" nzPlaceHolder="Selecione um valor"
                              [(ngModel)]="searchInput">
                              <nz-option *ngFor="let usuarioCriacao of usuarioCriacaoArr" [nzValue]="usuarioCriacao"
                                [nzLabel]="
                                  usuarioCriacao?.cpf +
                                  ' - ' +
                                  usuarioCriacao?.nome
                                ">
                              </nz-option>
                            </nz-select>
                          </div>

                          <div *ngIf="
                              fieldFilesSelected &&
                              tabIndex == 1 &&
                              fieldFilesSelected.type === 'range-picker'
                            " nzSpan="8" nzXl="8" nzLg="8" nzXs="24" class="m-l-10">
                            <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                          </div>

                          <div nz-col *ngIf="fieldFilesSelected" nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                            <button nz-button [disabled]="!searchInput" (click)="addFiltroValuesPorCampo()"
                              nzType="primary">
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>
                        </div>
                        <!-- <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="field-time"
                          class="m-l-15 cursor-pointer" [ngClass]="
                            filterType.includes(2)
                              ? 'ant-avatar-gold'
                              : 'ant-avatar-dark'
                        ">
                        </nz-avatar>

                        <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="form"
                          class="m-l-15 cursor-pointer" [ngClass]="
                          filterType.includes(1)
                            ? 'ant-avatar-blue' 
                            : 'ant-avatar-dark'
                          "></nz-avatar>

                        <nz-avatar [ngStyle]="{
                            opacity: filterType.includes(4) ? '100%' : '40%'
                          }" [nzSize]="40" nzIcon="check-circle" class="m-l-15 cursor-pointer" [ngClass]="
                            filterType.includes(4)
                              ? 'ant-avatar-green'
                              : 'ant-avatar-dark'
                          " nz-tooltip nzTooltipTitle="Filtrar por concluídos" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="close-circle"
                          class="m-l-10 cursor-pointer" [ngClass]="
                            filterType.includes(3)
                              ? 'ant-avatar-red'
                              : 'ant-avatar-dark'
                          ">
                        </nz-avatar>

                        <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="history"
                          class="m-l-10 cursor-pointer" [ngClass]="
                            filterType.includes(5)
                              ? 'ant-avatar-orange'
                              : 'ant-avatar-dark'
                          ">
                        </nz-avatar>

                        <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="stop"
                          class="ant-avatar-dark m-l-10 cursor-pointer">
                        </nz-avatar>

                        <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="file-add"
                          class="ant-avatar-dark m-l-10 cursor-pointer">
                        </nz-avatar> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div nz-row class="m-l-20">
                  <!-- <div class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"> -->
                  <!-- <nz-input-group *ngIf="fieldSelected && fieldSelected.type === 'input'" class="m-b-10 m-l-20" nz-col
                    nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="prefixTemplate">
                    <input type="text" class="text-disabled" nz-input placeholder="Informe um valor"
                      [(ngModel)]="searchInput" [mask]="getMaskReturned" />
                  </nz-input-group> -->

                  <!-- <nz-select *ngIf="fieldSelected && fieldSelected.type === 'select'" class="m-b-10 m-l-20" nz-col
                    nzSpan="8" nzXl="8" nzLg="12" nzXs="24" nzPlaceHolder="Selecione o campo" [(ngModel)]="searchInput">
                    <nz-option *ngFor="let result of selectedValues" [nzValue]="result" [nzLabel]="result?.text">
                    </nz-option>
                  </nz-select> -->

                  <!-- <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10"> -->

                  <!-- </div> -->

                  <div *ngIf="
                      fieldSelected &&
                      tabIndex == 0 &&
                      fieldSelected.type === 'range-picker'
                    " nzSpan="8" nzXl="8" nzLg="12" nzXs="24" class="m-b-10 m-l-20">
                    <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                  </div>

                  <div *ngIf="false" nzSpan="8" nzXl="12" nzLg="12" nzXs="24" class="m-b-10">
                    <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-date-picker>
                  </div>

                  <ng-template #prefixTemplate>
                    <i nz-icon nzType="search" class="opacity-05"></i>
                  </ng-template>
                </div>
              </div>
              <div nz-row class="m-l-20">
                <div *ngIf="showComboTagsFiles" nz-col nzSpan="12" nzXl="12" nzLg="12" nzXs="24">
                  <nz-input-group [nzPrefix]="prefixTemplate">
                    <div class="align-self-center">
                      <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                          removeFiltroExportedPorField(fieldsValuesTag)
                        " class="filters" *ngFor="
                          let fieldsValuesTag of fieldsFilesSelected;
                          let i = index
                        ">
                        {{ fieldsValuesTag.text }}
                      </nz-tag>
                    </div>
                  </nz-input-group>
                </div>
                <div nz-col class="m-t-10 p-l-20" [nzOffset]="showComboTagsFiles ? 8 : 20" nzSpan="3" nzXl="3" nzLg="3"
                  nzXs="24">
                  <button nzSize="small" nz-button nzType="primary" (click)="filterFilesExporteds()">
                    <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar
                    Filtro
                  </button>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <nz-table nzSize="small" [nzLoading]="loading" [nzShowPagination]="true" #fileExportsListTable
        [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayDataFileExport">
        <thead>
          <!-- class="p-t-20 p-b-20" -->
          <tr>
            <th style="background: #edf1fd" [nzLeft]="true" nzWidth="15px"></th>
            <th style="background: #edf1fd" nzWidth="60px" [nzLeft]="true">
              <label nz-checkbox [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngFor="let column of tableExportColumns">
              <th nzWidth="150px" *ngIf="
                  checkList[column.value] &&
                  fileExportsListTable.data.length !== 0
                " nzWidth="{{ column.width }}" [nzSortFn]="column.compare">
                {{ column.text }}
              </th>
              <th nzWidth="150px" *ngIf="
                  checkList[column.value] &&
                  fileExportsListTable.data.length == 0
                " nzWidth="{{ column.width }}">
                {{ column.text }}
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let fileExport of fileExportsListTable.data" style="cursor: pointer"
            (dblclick)="navigateTofileExport(fileExport)">
            <td [nzLeft]="true"></td>
            <td [nzLeft]="true">
              <label nz-checkbox [(ngModel)]="fileExport.checked" (ngModelChange)="check(fileExport)"></label>
            </td>
            <td>{{ fileExport.idPublico }}</td>
            <td>{{ fileExport.usCriacao }}</td>
            <td>{{ fileExport.descricao }}</td>
            <td>{{ service.getDateFormatted(fileExport.dtCriacao) }}</td>
            <td>{{ service.getDateFormatted(fileExport.dtAlteracao) }}</td>
            <td>{{ service.getDateFormatted(fileExport.dtExpiracao) }}</td>
            <td>{{ fileExport.pastas ? 'Sim' : 'Não' }}</td>
            <td>{{ fileExport.uuidUpload }}</td>
          </tr>
        </tbody>
      </nz-table>
      <div nz-row class="shadow-default p-b-1" nzJustify="space-between"
        style="background: rgba(63, 135, 245, 0.1); align-items: center">
        <div nz-col nzSpan="6" nzMd="8" nzXs="24" class="text-left text-xs-center p-l-10 p-b-5">
          Exportações selecionados:
          <b> {{ countCheked || 0 }} </b>
        </div>

        <!-- <div nz-col nzSpan="6" nzMd="8" nzXs="24" class="text-center text-xs-center p-b-5">
          Anexos selecionados:
          <b> {{anexsSelecteds||0}} </b>
        </div> -->

        <div nz-col nzSpan="6" nzMd="8" nzXs="24" class="text-right text-xs-center p-r-10 p-b-5">
          Exportações listados: <b> {{ displayDataFileExport.length || 0 }} </b>
        </div>
      </div>

      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>

    <div body>
      <form nz-form [formGroup]="formB" nzLayout="vertical">
        <h4 class="m-t-15">Informações básicas</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

        <div nz-row>
          <div class="p-r-20" nz-col nzSpan="4" nzSm="4" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Id</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input nz-input formControlName="id" placeholder="id" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="p-r-20" nz-col nzSpan="8" nzSm="8" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Hash</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input nz-input formControlName="hash" placeholder="hash" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="p-r-20" nz-col nzSpan="7" nzSm="7" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Usuario Criador</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input nz-input formControlName="usuarioCriacao" placeholder="usuario Criador Registrado" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="5" nzSm="5" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired> Data da Expiração </nz-form-label>
              <nz-form-control>
                <nz-date-picker nzShowTime formControlName="dataExpiracao" (ngModelChange)="checkDataExpiracao($event)"
                  nzFormat="dd/MM/yyyy HH:mm:ss"></nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div>
          <div class="p-r-15">
            <nz-form-item>
              <nz-form-label nzRequired>Descrição</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="nomeTpl">
                <textarea nz-input formControlName="descricao" placeholder="Descrição"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div>
          <div class="">
            <nz-form-item>
              <nz-form-control>
                <label nz-checkbox formControlName="pastas">Exportar arquivos com estrutura de pastas</label>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
      <h4 class="m-t-15">Seleção</h4>
      <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
      <nz-spin [nzSpinning]="treeLoading" [nzSize]="'large'">
        <div class="row is-not-mobile m-t-10">
          <div class="col-md-24 col-xl-12 p-b-15">
            <nz-collapse nzGhost>
              <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
                <div nz-row class="d-flex">
                  <div nz-col nzSpan="24" nzXs="24">
                    <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                      <div class="p-r-10">
                        <div class="p-l-10" nz-col nzSpan="24">
                          <nz-select *ngIf="adm" style="width: 350px" nzShowSearch nzAllowClear
                            [(ngModel)]="assinanteSelected" nzPlaceHolder="Selecione um signatário"
                            (ngModelChange)="addFiltroPorAssinante()">
                            <nz-option *ngFor="let assinante of listSignatarios" [nzValue]="assinante"
                              [nzLabel]="assinante.text">
                            </nz-option>
                          </nz-select>

                          <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="field-time"
                            class="m-l-15 cursor-pointer" [ngClass]="
                              filterType.includes(2)
                                ? 'ant-avatar-gold'
                                : 'ant-avatar-dark'
                            ">
                          </nz-avatar>

                          <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="form"
                            class="m-l-15 cursor-pointer" [ngClass]="
                              filterType.includes(1)
                                ? 'ant-avatar-blue'
                                : 'ant-avatar-dark'
                            "></nz-avatar>

                          <nz-avatar [ngStyle]="{
                              opacity: filterType.includes(4) ? '100%' : '40%'
                            }" [nzSize]="40" nzIcon="check-circle" class="m-l-15 cursor-pointer" [ngClass]="
                              filterType.includes(4)
                                ? 'ant-avatar-green'
                                : 'ant-avatar-dark'
                            " nz-tooltip nzTooltipTitle="Filtrar por concluídos" nzTooltipPlacement="topRight">
                          </nz-avatar>

                          <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="close-circle"
                            class="m-l-10 cursor-pointer" [ngClass]="
                              filterType.includes(3)
                                ? 'ant-avatar-red'
                                : 'ant-avatar-dark'
                            ">
                          </nz-avatar>

                          <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="history"
                            class="m-l-10 cursor-pointer" [ngClass]="
                              filterType.includes(5)
                                ? 'ant-avatar-orange'
                                : 'ant-avatar-dark'
                            ">
                          </nz-avatar>

                          <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="stop"
                            class="ant-avatar-dark m-l-10 cursor-pointer">
                          </nz-avatar>

                          <nz-avatar [ngStyle]="{ opacity: '40%' }" [nzSize]="40" nzIcon="file-add"
                            class="ant-avatar-dark m-l-10 cursor-pointer">
                          </nz-avatar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div nz-row class="m-l-20">
                  <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo" [(ngModel)]="fieldSelected"
                    (ngModelChange)="getOptionsSelect(fieldSelected)">
                    <nz-option *ngFor="let interval of arrColumnsSelect" [nzValue]="interval"
                      [nzLabel]="interval?.text">
                    </nz-option>
                  </nz-select>

                  <!-- <div class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"> -->
                  <nz-input-group *ngIf="fieldSelected && fieldSelected.type === 'input'" class="m-b-10 m-l-20" nz-col
                    nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="prefixTemplate">
                    <input type="text" class="text-disabled" nz-input placeholder="Informe um valor"
                      [(ngModel)]="searchInput" />
                  </nz-input-group>

                  <div *ngIf="
                      fieldSelected && fieldSelected.type === 'range-picker'
                    " nzSpan="8" nzXl="8" nzLg="12" nzXs="24" class="m-b-10 m-l-20">
                    <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                  </div>

                  <nz-select *ngIf="fieldSelected && fieldSelected.type === 'select'" class="m-b-10 m-l-20" nz-col
                    nzSpan="8" nzXl="8" nzLg="12" nzXs="24" nzPlaceHolder="Selecione o campo" [(ngModel)]="searchInput">
                    <nz-option *ngFor="let result of selectedValues" [nzValue]="result" [nzLabel]="result?.text">
                    </nz-option>
                  </nz-select>

                  <div *ngIf="
                      fieldSelected && fieldSelected.type === 'date-picker'
                    " nzSpan="8" nzXl="12" nzLg="12" nzXs="24" class="m-b-10 m-l-20">
                    <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-date-picker>
                  </div>

                  <ng-template #prefixTemplate>
                    <i nz-icon nzType="search" class="opacity-05"></i>
                  </ng-template>
                  <!-- </div> -->

                  <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                    <button nz-button [disabled]="!searchInput" (click)="addFiltroPorCampo()" nzType="primary">
                      <i nz-icon nzType="plus"></i>
                    </button>
                  </div>
                </div>

                <div nz-row class="m-t-10 m-l-20">
                  <div *ngIf="showComboTags" nz-col nzSpan="12" nzXl="12" nzLg="12" nzXs="24">
                    <nz-input-group [nzPrefix]="prefixTemplate">
                      <div class="align-self-center">
                        <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'"
                          (nzOnClose)="removeFiltroPorField(i)" class="filters" *ngFor="
                            let fieldsValuesTag of fieldsSelected;
                            let i = index
                          ">
                          {{ fieldsValuesTag.text }}
                        </nz-tag>
                      </div>
                    </nz-input-group>
                  </div>
                  <div [class]="'m-t-10 p-l-15'" nz-col [nzOffset]="fieldsSelected.length != 0 ? 8 : 20" nzOffset="3"
                    nzSpan="3" nzXl="3" nzLg="3" nzXs="24">
                    <button class="m-l-10" nzSize="small" nz-button nzType="primary" (click)="filterDocuments()">
                      <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar
                      Filtro
                    </button>
                  </div>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>

        <div class="row m-t-10 m-b-10" [style]="docNodes ? null : 'background-color:red'">
          <div class="col-md-24 col-xl-12 p-b-15 p-l-10 p-r-10">
            <nz-tree nzBlockNode #nzTreeComponent class="bg-wgrey p-t-10 p-b-10 tree-view" [nzData]="docNodes"
              (nzClick)="activeNode($event)" (nzDblClick)="openFolder($event)" [nzTreeTemplate]="nzTreeTemplate"
              (nzCheckBoxChange)="nzEvent($event)" #nzTreeComponent nzCheckable></nz-tree>
            <ng-template #nzTreeTemplate let-node let-origin="origin" class="bg-grey">
              <span class="custom-node">
                <span *ngIf="!node.isLeaf" (contextmenu)="contextMenu($event, menu)">
                  <i nz-icon [nzType]="node.isExpanded ? 'folder-open' : 'folder'"></i>
                  <span class="folder-name">{{ node.title }}</span>
                  <!-- <span class="folder-desc">created by {{ origin.author | lowercase }}</span> -->
                </span>
                <span *ngIf="node.isLeaf" (contextmenu)="contextMenu($event, menu)" (click)="openFile(node)">
                  <i nz-icon nzType="file-pdf" twoToneColor="#ff0000" theme="twotone"></i>
                  <span class="file-name">{{ node.title }}</span>
                  <!-- <span class="file-desc">modified by {{ origin.author | lowercase }}</span> -->
                </span>
              </span>
            </ng-template>
            <nz-dropdown-menu #menu="nzDropdownMenu">
              <!-- <ul nz-menu>
              <li nz-menu-item (click)="selectDropdown()">Action 1</li>
              <li nz-menu-item (click)="selectDropdown()">Action 2</li>
            </ul> -->
            </nz-dropdown-menu>
          </div>
        </div>
        <div class="shadow-default p-b-1 m-t-10" style="background: rgba(63, 135, 245, 0.1); align-items: center">
          <!-- <div nz-row nzJustify="space-between">
          <div nz-col nzSpan="12" nzMd="12" nzXs="24" class="text-left text-xs-center p-l-10 p-b-5">
            Documentos selecionados:
            <b> {{docsSelecteds||0}} </b>
          </div>

          <div nz-col nzSpan="12" nzMd="12" nzXs="24" class="text-right text-xs-center p-r-10 p-b-5">
            Documentos listados: <b> {{pageTotal||0}} </b>
          </div>
        </div> -->
          <div nz-row nzJustify="space-between">
            <div nz-col nzSpan="6" nzMd="8" nzXs="24" class="text-left text-xs-center p-l-10 p-b-5">
              Documentos selecionados:
              <b> {{ docsSelecteds || 0 }} </b>
            </div>

            <div nz-col nzSpan="6" nzMd="8" nzXs="24" class="text-center text-xs-center p-b-5">
              Anexos selecionados:
              <b> {{ anexsSelecteds || 0 }} </b>
            </div>

            <div nz-col nzSpan="6" nzMd="8" nzXs="24" class="text-right text-xs-center p-r-10 p-b-5">
              Documentos listados: <b> {{ pageTotal || 0 }} </b>
            </div>
          </div>
          <!---->
        </div>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>