<nz-card nzTitle="Perfil">
  <div nz-row [nzGutter]="24">
    <div nz-col nzXl="7" nzXs="24">
      <nz-card class="card-height">
        <div class="text-center mt-2 mb-3">
          <a>
            <nz-badge [nzCount]="iconBadge">
              <nz-avatar
                [nzSize]="80"
                nzIcon="user"
                [nzSrc]="perfilAvatar"
              ></nz-avatar>
            </nz-badge>
            <ng-template #iconBadge>
              <nz-upload
                [nzBeforeUpload]="beforeUploadAvatar"
                [nzShowUploadList]="false"
              >
                <i
                  nz-icon
                  nzType="edit"
                  class="ant-scroll-number-custom-component back-badge"
                  nzTheme="outline"
                ></i>
              </nz-upload>
            </ng-template> </a
          ><br />
          <span class="text-gray">{{ authService.getNomUser() }}</span
          ><br />
          <div class="row" style="justify-content: center">
            <span class="text-gray" *ngIf="cpfVisible">{{ getCpf() }}</span>
            <span class="text-gray" *ngIf="!cpfVisible">
              {{ getCpfMasked() }}</span
            >
            <i
              style="margin: 5px"
              nz-icon
              [nzType]="!cpfVisible ? 'eye-invisible' : 'eye'"
              (click)="cpfVisible = !cpfVisible"
            ></i>
          </div>
          <span class="text-green"
            ><i
              class="text-green mr-1"
              nz-icon
              nzType="safety-certificate"
              nzTheme="outline"
            ></i
            >Certificado Ativo</span
          >
        </div>
      </nz-card>
    </div>
    <div nz-col nzXl="17" nzXs="24">
      <nz-card class="card-height">
        <form nz-form [formGroup]="formEdit" nzLayout="vertical">
          <div nz-row>
            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="email">Email</nz-form-label>
                <nz-form-control>
                  <div class="d-flex">
                    <input
                      type="email"
                      nz-input
                      name="email"
                      formControlName="emailAtual"
                      placeholder="Email Atual"
                    />
                    <button
                      class="ml-2"
                      nz-button
                      [nzType]="isEditEmail ? 'primary' : 'default'"
                      (click)="toggleEmail()"
                    >
                      <i nz-icon nzType="edit"></i>
                    </button>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" [ngClass]="isEditEmail ? 'open' : 'close'">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="novo_email"
                  >Novo Email</nz-form-label
                >
                <nz-form-control>
                  <input
                    type="email"
                    nz-input
                    name="novo_email"
                    formControlName="novoEmail"
                    placeholder="Novo Email"
                  />
                </nz-form-control>
                <span
                  *ngIf="!isValidEmail(formEdit.value.novoEmail)"
                  style="text-align: right; color: red; opacity: 0.7"
                  >Valor inválido para Email!</span
                >
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" [ngClass]="isEditEmail ? 'open' : 'close'">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="confirmar_email"
                  >Confirmar Email</nz-form-label
                >
                <nz-form-control>
                  <input
                    type="email"
                    nz-input
                    name="confirmar_email"
                    formControlName="confirmarEmail"
                    placeholder="Confirmar Email"
                  />
                </nz-form-control>
                <span
                  *ngIf="
                    formEdit.value.confirmarEmail &&
                    formEdit.value.confirmarEmail !== formEdit.value.novoEmail
                  "
                  style="text-align: right; color: red; opacity: 0.7"
                  >E-mails não coincidem!</span
                >
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="senha">Senha</nz-form-label>
                <nz-form-control>
                  <div class="d-flex">
                    <input
                      type="password"
                      nz-input
                      name="senha"
                      formControlName="senhaAtual"
                      placeholder="Senha Atual"
                    />
                    <button
                      class="ml-2"
                      nz-button
                      [nzType]="isEditPass ? 'primary' : 'default'"
                      (click)="togglePass()"
                    >
                      <i nz-icon nzType="edit"></i>
                    </button>
                  </div>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" [ngClass]="isEditPass ? 'open' : 'close'">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="nova_senha"
                  >Nova Senha</nz-form-label
                >
                <nz-form-control>
                  <input
                    type="password"
                    nz-input
                    name="nova_senha"
                    formControlName="senha"
                    placeholder="Senha"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" [ngClass]="isEditPass ? 'open' : 'close'">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="confirmar_senha"
                  >Confirmar Senha</nz-form-label
                >
                <nz-form-control>
                  <input
                    type="password"
                    nz-input
                    name="confirmar_senha"
                    formControlName="confirmarSenha"
                    placeholder="Confirmar Senha"
                  />
                </nz-form-control>
                <span
                  *ngIf="
                    formEdit.value.confirmarSenha &&
                    formEdit.value.senha !== formEdit.value.confirmarSenha
                  "
                  style="text-align: right; color: red; opacity: 0.7"
                  >Senhas não coincidem!</span
                >
              </nz-form-item>
            </div>
          </div>
        </form>
      </nz-card>
    </div>
  </div>
</nz-card>

<ps-fab [actions]="fabButtons"></ps-fab>
