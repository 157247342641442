<nz-spin [nzSpinning]="pageLoading" [nzSize]="'large'">
  <div class="align-items-center p-10 shadow-default is-not-banner-mobile" nz-row style="background: #edf1fd">
    <div nz-col nzSpan="8">
      <div class="media align-items-center">
        <div *ngIf="document?.valorDoc" class="p-l-15">
          <h5 class="m-b-0">Valor documento</h5>
          <h4 class="m-b-0">
            <b>{{ getValueDoc() }}</b>
          </h4>
        </div>
      </div>
    </div>

    <div class="p-r-15" nz-col nzSpan="14" nzOffset="2">
      <div nz-row>
        <div class="d-flex p-t-5" nz-col nzSpan="8" style="justify-content: center">
          <div>
            <nz-avatar class="cursor-pointer" [nzSize]="40" [nzIcon]="'file-search'" [class]="'ant-avatar-blue'"
              (click)="previewDoc()" nz-tooltip nzTooltipTitle="Clique para visualizar o documento original"
              nzTooltipPlacement="top"></nz-avatar>
          </div>
          <div *ngIf="document?.numProcesso" class="p-l-10">
            <nz-avatar class="cursor-pointer" [nzSize]="40" [nzIcon]="'eye'" [class]="'ant-avatar-blue'"
              (click)="documentService.visualizeProcess(this.document)" nz-tooltip
              nzTooltipTitle="Clique para visualizar o processo do documento" nzTooltipPlacement="top"></nz-avatar>
          </div>
          <div *ngIf="document?.numeroDocOriginal || document?.hashDocOriginal" class="p-l-10">
            <nz-avatar class="cursor-pointer" [nzSize]="40" [nzIcon]="'file'" [class]="'ant-avatar-blue'"
              (click)="previewDocVinculado()" nz-tooltip nzTooltipTitle="Clique para visualizar o documento vinculado"
              nzTooltipPlacement="top"></nz-avatar>
          </div>
          <div class="p-l-10">
            <nz-avatar *ngIf="document?.statusDocumento === statusDoc['Confirmado']" class="cursor-pointer"
              [nzSize]="40" [nzIcon]="'file'" [class]="'ant-avatar-blue'" (click)="viewDoc(document)" nz-tooltip
              nzTooltipTitle="Clique para visualizar o documento concluído" nzTooltipPlacement="top">
            </nz-avatar>
          </div>
          <div class="p-l-10">
            <nz-avatar *ngIf="
                document?.statusDocumento === statusDoc['Confirmado'] ||
                document?.statusDocumento === statusDoc['Desacordo'] ||
                document?.statusDocumento === statusDoc['Cancelado']
              " class="cursor-pointer" [nzSize]="40" [nzIcon]="'download'" [class]="'ant-avatar-blue'"
              (click)="downloadDoc()" nz-tooltip nzTooltipTitle="Clique para fazer o download do documento"
              nzTooltipPlacement="top">
            </nz-avatar>
          </div>
        </div>

        <div nz-col nzSpan="16" class="p-l-15 align-items-center form-group" style="text-align: end">
          <h5 class="m-b-0">Status documento</h5>
          <h5 class="m-b-0 p-l-5 p-r-10">
            <b>{{ getNomeStatusDocumento(document) }}</b>
          </h5>
        </div>
      </div>
    </div>
  </div>

  <div class="align-items-center p-10 shadow-default is-banner-mobile" nz-row style="background: #edf1fd">
    <div class="d-flex justify-content-between">
      <div *ngIf="document?.valorDoc">
        <h5 class="m-b-0">Valor documento</h5>
        <h4 class="m-b-0">
          <b>{{ getValueDoc() }}</b>
        </h4>
      </div>
      <div style="text-align: end">
        <h5 class="m-b-0">Status documento</h5>
        <h5 class="m-b-0 p-l-5 p-r-10">
          <b>{{ statusDocLabel[document?.statusDocumento] }}</b>
        </h5>
      </div>
    </div>

    <div class="p-t-10" nz-col nzSpan="24">
      <div class="d-flex p-t-5" style="justify-content: center">
        <div>
          <nz-avatar class="cursor-pointer" [nzSize]="40" [nzIcon]="'file-search'" [class]="'ant-avatar-blue'"
            (click)="previewDoc()" [nzTooltipVisible]="tooltibVisibleNzAvatar.previewDoc" nz-tooltip
            [nzTooltipTitle]="null" nzTooltipPlacement="top">
          </nz-avatar>
        </div>

        <div class="p-l-10">
          <nz-avatar *ngIf="
              document?.statusDocumento === statusDoc['Confirmado'] ||
              document?.statusDocumento === statusDoc['Desacordo'] ||
              document?.statusDocumento === statusDoc['Cancelado']
            " class="cursor-pointer" [nzSize]="40" [nzIcon]="'download'" [class]="'ant-avatar-blue'"
            (click)="downloadDoc()" [nzTooltipVisible]="tooltibVisibleNzAvatar.downloadDoc" nz-tooltip
            [nzTooltipTitle]="null" nzTooltipPlacement="top">
          </nz-avatar>
        </div>
      </div>
    </div>
  </div>

  <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
    <div class="p-l-15 p-t-10 m-b-10 align-items-center" nz-col nzSpan="24">
      <h5 class="m-b-0">Dados do documento</h5>
      <h6 class="text-color-value">{{ getDataDocFormated() }}</h6>
    </div>

    <div nz-col nzSpan="12" nzSm="12" nzXs="24" class="p-l-15 p-t-10 align-items-center d-flex">
      <div class="form-group">
        <div class="d-flex">
          <h6 class="m-b-0 p-r-5">Tipo do documento:</h6>
          <h6 class="m-b-0 text-color-value">{{ document.tipoDoc }}</h6>
        </div>

        <!-- <div *ngIf="document?.statusDocumento === statusDoc.Desacordo && verifyAllDisagreements(document)" class="d-flex">
          <h6 class="m-b-0 p-r-5">Motivo do desacordo:</h6>
          <h6 class="m-b-0 text-color-value">{{ document.signatarios.motivoDesacordo }}</h6>
        </div> -->

        <div *ngIf="document?.numeroDoc" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número do documento:</h6>
          <h6 class="m-b-0 text-color-value">{{ document?.numeroDoc }}</h6>
        </div>

        <div *ngIf="document?.dataLimiteAssinatura" class="d-flex">
          <h6 class="m-b-0 p-r-5">Data limite de assinatura:</h6>
          <h6 class="m-b-0 text-color-value">{{ getLimitDateFormated() }}</h6>
        </div>

        <div *ngIf="document?.numeroLicitacao" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número da Licitação:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.numeroLicitacao }}
          </h6>
        </div>

        <div *ngIf="document?.modalidadeLicitacao" class="d-flex">
          <h6 class="m-b-0 p-r-5">Modalidade da Licitação:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.modalidadeLicitacao }}
          </h6>
        </div>

        <div *ngIf="document?.numeroObra" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número da Obra:</h6>
          <h6 class="m-b-0 text-color-value">{{ document?.numeroObra }}</h6>
        </div>

        <div *ngIf="document?.numeroContrato" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número do Contrato:</h6>
          <h6 class="m-b-0 text-color-value">{{ document?.numeroContrato }}</h6>
        </div>

        <div *ngIf="document?.numeroEvento" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número do Evento:</h6>
          <h6 class="m-b-0 text-color-value">{{ document?.numeroEvento }}</h6>
        </div>

        <div *ngIf="document?.numeroConvenio" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número do Convênio:</h6>
          <h6 class="m-b-0 text-color-value">{{ document?.numeroConvenio }}</h6>
        </div>

        <div *ngIf="document?.numeroProgramaInstitucional" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número do Programa Institucional:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.numeroProgramaInstitucional }}
          </h6>
        </div>
      </div>
    </div>

    <div nz-col nzSpan="12" nzSm="12" nzXs="24" class="p-l-15 p-t-10 align-items-center d-flex">
      <div class="form-group">
        <div class="d-flex">
          <h6 class="m-b-0 p-r-5">Unidade Gestora:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ dadosUnidadeGestora?.razaoSocialPessoaJuridica }}
          </h6>
        </div>

        <div class="d-flex">
          <h6 class="m-b-0 p-r-5">Chancela:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.chancela === true ? 'Sim' : 'Não' }}
          </h6>
        </div>

        <div *ngIf="document?.nomeUnidadeOrc" class="d-flex">
          <h6 class="m-b-0 p-r-5">Unidade Orçamentária:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.nomeUnidadeOrc }}
          </h6>
        </div>

        <div *ngIf="document?.numProcesso" class="d-flex">
          <h6 class="m-b-0 p-r-5">Número do processo:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.numProcesso }}
          </h6>
        </div>

        <div *ngIf="document?.historicoDoc" class="d-flex">
          <h6 class="m-b-0 p-r-5">Descrição do documento:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.historicoDoc }}
          </h6>
        </div>
        <div *ngIf="document?.informacoesAdicionais" class="d-flex">
          <h6 class="m-b-0 p-r-5">Informações Adicionais:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.informacoesAdicionais }}
          </h6>
        </div>

        <div *ngIf="document?.nomeFav" class="d-flex">
          <h6 class="m-b-0 p-r-5">Favorecido:</h6>
          <h6 class="m-b-0 text-color-value">
            {{ document?.nomeFav }}
          </h6>
        </div>
      </div>
    </div>
  </div>

  <nz-spin *ngIf="anexos.length > 0 && infoAnexo === true" [nzSpinning]="loadingAnexos" [nzSize]="'large'">
    <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
      <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
        <h5>Documentos anexados</h5>
      </div>

      <div class="col-md-24 col-xl-12 p-b-15">
        <nz-collapse nzGhost>
          <nz-collapse-panel *ngFor="let panel of newDoc" [nzHeader]="panel.vinculacao" [nzActive]="false">
            <div nz-row class="d-flex">
              <div nz-col nzSpan="24" nzXs="24">
                <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                  <div class="p-r-10">
                    <div>
                      <div class="p-r-10">
                        <div class="m-b-10">
                          <nz-input-group [nzPrefix]="prefixTemplate">
                            <input type="text" nz-input placeholder="Buscar documento" [(ngModel)]="searchInput"
                              (ngModelChange)="getResult($event)" />
                          </nz-input-group>
                          <ng-template #prefixTemplate>
                            <i nz-icon nzType="search" class="opacity-05"></i>
                          </ng-template>
                        </div>
                        <nz-table class="m-b-20" nzSize="small" [nzLoading]="loading" #documentListTableAttach
                          [nzShowPagination]="true" [nzScroll]="{ y: '380px' }" style="overflow-y: overlay"
                          [nzData]="getDataAnexos(panel.vinculacao)">
                          <thead>
                            <tr>
                              <th style="background: #edf1fd" [nzLeft]="
                                  documentService.getWidthContent() > 1024
                                " nzWidth="35px"></th>
                              <th style="background: #edf1fd" nzWidth="70px" [nzLeft]="
                                  documentService.getWidthContent() > 1024
                                ">
                                <label nz-checkbox [nzIndeterminate]="indeterminate"
                                  (ngModelChange)="updateAllChecked()" [(ngModel)]="allChecked">
                                </label>
                              </th>

                              <ng-container>
                                <th nzWidth="150px" [nzSortFn]="
                                    documentService.getColumnsAnexoStatus(
                                      tableColumns
                                    ).compare
                                  ">
                                  {{
                                  documentService.getColumnsAnexoStatus(
                                  tableColumns
                                  ).text
                                  }}
                                </th>
                              </ng-container>

                              <ng-container *ngIf="
                                  documentService.getColumnsRatificadoAnexo(
                                    tableColumns
                                  )
                                ">
                                <th nzWidth="150px" *ngIf="checkListConsulta.ratificado" [nzSortFn]="
                                    documentService.getColumnsRatificadoAnexo(
                                      tableColumns
                                    ).compare
                                  ">
                                  {{
                                  documentService.getColumnsRatificadoAnexo(
                                  tableColumns
                                  ).text
                                  }}
                                </th>
                              </ng-container>
                              <ng-container *ngFor="
                                  let column of documentService.getTableColumnsAnexos(
                                    tableColumns
                                  );
                                  let i = index
                                ">
                                <th nzWidth="150px" *ngIf="checkListConsulta[column.value]" [nzSortFn]="column.compare">
                                  {{ column.text }}
                                </th>
                              </ng-container>

                              <th style="background: #edf1fd" nzWidth="110px" [nzRight]="
                                  documentService.getWidthContent() > 1024
                                ">
                                Visualizar
                              </th>
                              <th style="background: #edf1fd" nzWidth="110px" [nzRight]="
                                  documentService.getWidthContent() > 1024
                                ">
                                Baixar
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-template ngFor let-item [ngForOf]="documentListTableAttach.data" let-indexArr="index">
                              <tr>
                                <td [nzLeft]="
                                    documentService.getWidthContent() > 1024
                                  " [(nzExpand)]="item.expand" (click)="
                                    modifyDocument(item, item.expand, indexArr)
                                  "></td>
                                <td [nzLeft]="
                                    documentService.getWidthContent() > 1024
                                  " style="padding: 6px">
                                  <label nz-checkbox [(ngModel)]="item.checked"
                                    (ngModelChange)="check(item.checked)"></label>
                                </td>
                                <ng-container *ngIf="
                                    documentService.getColumnsAnexoStatus(
                                      tableColumns
                                    )
                                  ">
                                  <td *ngIf="checkListConsulta.status">
                                    <nz-tag class="m-b-0" [nzColor]="getCorDocumento(item)">
                                      {{ getNomeStatusAnexo(item) }}</nz-tag>
                                  </td>
                                </ng-container>
                                <ng-container *ngIf="
                                    documentService.getColumnsRatificadoAnexo(
                                      tableColumns
                                    )
                                  ">
                                  <td *ngIf="checkListConsulta.ratificado">
                                    <nz-avatar style="
                                        background-color: #ffffff;
                                        opacity: 100%;
                                      " [nzSize]="40" [nzIcon]="
                                        item.ratificado === true
                                          ? 'trademark'
                                          : null
                                      " class="m-l-10 cursor-pointer" [ngClass]="
                                        showColorRatificado(
                                          item.anexoSignatarios
                                        ) === true
                                          ? 'ant-avatar-gold'
                                          : 'ant-avatar-green'
                                      "></nz-avatar>
                                  </td>
                                </ng-container>
                                <ng-container *ngFor="
                                    let column of documentService.getTableColumnsAnexos(
                                      tableColumns
                                    )
                                  ">
                                  <td *ngIf="checkListConsulta[column.value]">
                                    {{
                                    documentService.prepareColumnValue(
                                    item,
                                    column
                                    )
                                    }}
                                  </td>
                                </ng-container>
                                <td class="cursor-pointer" [nzRight]="
                                    documentService.getWidthContent() > 1024
                                  ">
                                  <div class="d-flex">
                                    <div (click)="getURLAnexo(item)" class="font-size-25 p-l-20 p-r-20" nz-tooltip
                                      nzTooltipTitle="Clique para visualizar o documento" nzTooltipPlacement="left">
                                      <i nz-icon [nzType]="'file-pdf'" theme="twotone"></i>
                                    </div>
                                  </div>
                                </td>
                                <td class="cursor-pointer" [nzRight]="
                                    documentService.getWidthContent() > 1024
                                  ">
                                  <div class="d-flex">
                                    <div class="cursor-pointer" (click)="getURLAnexo(item, true)"
                                      class="font-size-25 p-l-20 p-r-20" nz-tooltip
                                      nzTooltipTitle="Clique para baixar o documento" nzTooltipPlacement="left">
                                      <i nz-icon [nzType]="'download'"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                              <tr [nzExpand]="item.expand">
                                <nz-spin *ngIf="item.expand" [nzSpinning]="loadingAnexosSig" [nzSize]="'default'">
                                  <nz-table #innerTable [nzData]="item.anexoSignatarios" nzSize="middle"
                                    [nzShowPagination]="false">
                                    <thead>
                                      <tr>
                                        <th class="p-l-15">
                                          Data de Assinatura
                                        </th>
                                        <th *ngIf="item.ratificado">
                                          Data da Ratificação
                                        </th>
                                        <th>Nome</th>
                                        <th *ngIf="
                                            documentService.getColumnsAnexoStatus(
                                              tableColumns
                                            )
                                          ">
                                          Status
                                        </th>
                                        <th>Ações</th>
                                      </tr>
                                    </thead>
                          <tbody>
                            <tr *ngFor="let data of innerTable.data">
                              <td>
                                {{
                                data?.dataAssinatura
                                | date: 'dd/MM/yyyy HH:mm:ss'
                                }}
                              </td>
                              <td *ngIf="item.ratificado">
                                {{
                                data?.dataRatificacao
                                | date: 'dd/MM/yyyy HH:mm:ss'
                                }}
                              </td>
                              <td>
                                {{
                                data?.nome ||
                                data?.nomeRepresentante
                                }}
                              </td>
                              <td *ngIf="
                                            documentService.getColumnsAnexoStatus(
                                              tableColumns
                                            )
                                          ">
                                <nz-tag class="m-b-0" [nzColor]="getCorDocumento(data)">
                                  {{
                                  statusDocLabel[data?.status]
                                  }}</nz-tag>
                              </td>
                              <td>
                                <button *ngIf="
                                              data?.status ===
                                              statusDoc.Pendente
                                            " nzSize="small" nz-button nzType="primary" nz-tooltip [nzTooltipTitle]="
                                              getEmail(
                                                data?.idPessoaFisica,
                                                indexArr
                                              )
                                            " nzTooltipPlacement="topRight">
                                  <i nz-icon nzType="mail" nzTheme="outline"></i>Notificar
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </nz-table>
  </nz-spin>
  </tr>
  </ng-template>
  </tbody>
  </nz-table>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>
  </nz-collapse-panel>
  </nz-collapse>
  </div>
  </div>
</nz-spin>

<div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
  <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
    <h5>Assinaturas</h5>
  </div>

  <div *ngFor="let assinante of document.signatarios" nz-col nzSpan="8"
    class="p-l-20 p-t-10 p-b-10 align-items-center d-flex">
    <div class="d-flex align-items-center">
      <nz-avatar class="m-r-10" [nzSize]="50" [nzIcon]="getIcoStatusSigner(assinante.status)"
        [class]="'ant-avatar-' + getColorStatusSigner(assinante.status)" nz-tooltip
        [nzTooltipTitle]="statusDocLabel[assinante.status]" nzTooltipPlacement="left">
      </nz-avatar>
      <div>
        <h5 class="m-b-0 p-r-5">
          {{ assinante.nome || assinante.razaoSocial }}
        </h5>
        <div *ngIf="assinante.razaoSocial">
          <p class="m-b-0 p-r-5 text-color-value">
            Representante: {{ assinante.nomeRepresentante }}
          </p>
        </div>
        <div>
          <p class="m-b-0 p-r-5 text-color-value">
            {{ getLabelByStatus(assinante) }}:
          </p>
          <p class="m-b-0 text-color-value">
            {{ getDateSigned(assinante) }}
          </p>
        </div>
      </div>

      <div class="is-mobile">
        <h5 class="m-b-0 p-r-5 text-truncate" style="max-width: 400px">
          {{ assinante.nome || assinante.razaoSocial }}
        </h5>

        <div class="">
          <p class="fs-12 m-b-0 p-r-5 text-color-value">
            {{ getLabelByStatus(assinante) }}:
          </p>
          <p class="fs-12 m-b-0 text-color-value">
            {{ getDateSigned(assinante) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<nz-spin [nzSpinning]="loadingEvents" [nzSize]="'large'">
  <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
    <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
      <h5>Linha do tempo</h5>
    </div>

    <div class="p-r-15 p-l-15 p-t-15" nz-col nzSpan="24">
      <nz-timeline nzMode="alternate">
        <nz-timeline-item [nzDot]="template" [nzColor]="getEventColor(event?.acao)" *ngFor="let event of sortEvents()">
          <p>{{ event?.data | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
          {{ getDescEventDoc(event?.usuario, event?.acao, event?.empresa) }}
          <ng-template #template>
            <i nz-icon [nzType]="getEventIco(event?.acao)" style="font-size: 22px"></i>
          </ng-template>
        </nz-timeline-item>
      </nz-timeline>
    </div>
  </div>
</nz-spin>
</nz-spin>
<ps-fab *ngIf="document?.documentoAnexo?.length" [actions]="fabButtons"></ps-fab>