import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import {
  AuthService,
  DocumentsUploadService,
  Helper,
  IModulo,
  ITipoDocumento,
  MenuItem,
  MenuService,
} from '@ps-erp-angular/shared';
import { ModuloState } from './states/modulos.state';

export const routerChange$ = new EventEmitter<unknown>();

@Component({
  selector: 'ps-digitalizacao-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  formLoading = false;
  labelTip: string;
  formulario = [];

  menu: MenuItem[] = [
    {
      path: '/session/dashboard',
      title: 'Dashboard - Documentos',
      entidade: 'dashboard',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'area-chart',
      submenu: [],
      initialPage: true,
      level: 1,
    },
    {
      path: 'documentos',
      title: 'Documentos',
      entidade: '',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'file-protect',
      submenu: [
        {
          path: '/session/documentos',
          title: `Todos documentos - Assinar,\n Discordar...`,
          entidade: 'document',
          iconType: 'nzIcon',
          icon: 'field-time',
          iconTheme: 'outline',
          submenu: [],
        },
        {
          path: '/session/anexos',
          title: `Todos anexos - Assinar,\n Discordar...`,
          entidade: 'anexos',
          iconType: 'nzIcon',
          icon: 'paper-clip',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
    {
      path: 'tabelas',
      title: 'Cadastrar',
      entidade: '',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'form',
      level: 1,
      submenu: [
        {
          path: '/session/tipo-documento',
          title: 'Tipo de Documento',
          entidade: 'tipo-de-documento',
          iconType: 'nzIcon',
          icon: 'file-text',
          iconTheme: 'outline',
          submenu: [],
          level: 2,
        },
        {
          path: '/session/modulo',
          title: 'Módulo',
          entidade: 'modulo',
          iconType: 'nzIcon',
          iconTheme: 'outline',
          icon: 'apartment',
          submenu: [],
          level: 2,
        },
        {
          path: '/session/setor',
          title: 'Setor',
          entidade: 'setor',
          iconType: 'nzIcon',
          icon: 'cluster',
          iconTheme: 'outline',
          submenu: [],
          level: 2,
        },

        {
          path: '/session/pessoa-fisica',
          title: 'Pessoa Física',
          entidade: 'pessoa-fisica',
          iconType: 'nzIcon',
          icon: 'user',
          iconTheme: 'outline',
          submenu: [],
          level: 2,
        },
        {
          path: '/session/pessoa-juridica',
          title: 'Pessoa Jurídica',
          entidade: 'pessoa-juridica',
          iconType: 'nzIcon',
          icon: 'team',
          iconTheme: 'outline',
          submenu: [],
          level: 2,
        },
      ],
    },
    {
      path: 'ferramentas',
      title: 'Ferramentas',
      entidade: '',
      iconType: 'nzIcon',
      iconTheme: 'outline',
      icon: 'tool',
      submenu: [
        {
          path: '/session/exportacao-arquivos',
          title: `Exportação de arquivos`,
          entidade: 'exportacao-de-arquivos',
          iconType: 'nzIcon',
          icon: 'export',
          iconTheme: 'outline',
          submenu: [],
        },
      ],
    },
  ];

  constructor(
    private _menuService: MenuService,
    private _authService: AuthService,
    private _service: DocumentsUploadService,
    private readonly _router: Router,
    private readonly _location: Location
  ) {}

  ngOnInit(): void {
    this.formulario = this._authService.getFormularios();

    this._service.formLoading.subscribe(async (data) => {
      this.formLoading = data;
    });

    this._service.labelTip.subscribe(async (msg) => {
      this.labelTip = msg;
    });
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this._location.path().split('/')[2] === 'documentos') {
          routerChange$.emit({ url: this._location.path().split('?')[0] });
        }

        //TODO: salvar o state se a rota for documents;
      }
    });
    this.getModulos();
  }
  async getModulos() {
    ModuloState.modulos$.subscribe({
      next: (modulos: IModulo[]) => {
        const menuWithModules = [
          ...this.menu,
          ...modulos.map(
            (modulo): MenuItem => {
              return {
                path: 'digitalizacao',
                title: `${modulo.descricao.substring(0, 25)}`,
                entidade: 'digitalizacao',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'container',
                level: 1,
                submenu: [...this.recursiveModuloFilho(modulo, 1)],
              };
            }
          ),
        ];
        this._menuService.menuItems.next(menuWithModules);
      },
    });
  }

  recursiveModuloFilho(
    modulo: IModulo,
    levelPai: number,
    pathPai?: string,
    tabTitlePai?: string,
    breadCrumpPai?: string
  ) {
    let subMenus = [];

    if (modulo.modulosFilho && modulo.modulosFilho.length > 0) {
      subMenus = [
        ...subMenus,
        ...modulo.modulosFilho.map((filho) => {
          const path = pathPai
            ? `${pathPai}/${Helper.ajustarParaURI(filho.descricao)}`
            : `/session/documentos/${Helper.ajustarParaURI(
                modulo.descricao
              )}/${Helper.ajustarParaURI(filho.descricao)}`;

          const tabTitle = tabTitlePai
            ? `${tabTitlePai} > ${Helper.getSigla(filho.descricao)}`
            : `${Helper.getSigla(modulo.descricao)} > ${Helper.getSigla(
                filho.descricao
              )}`;

          const breadCrump = breadCrumpPai
            ? `${breadCrumpPai} > ${filho.descricao}`
            : `${modulo.descricao} > ${filho.descricao}`;

          return {
            path,
            level: levelPai + 1,
            title: `${filho.descricao.substring(0, 25)}`,
            tabTitle,
            entidade: 'digitalizacao',
            iconType: 'nzIcon',
            icon: 'container',
            iconTheme: 'outline',
            submenu: [
              ...this.recursiveModuloFilho(
                filho,
                levelPai + 1,
                path,
                tabTitle,
                breadCrump
              ),
            ],
          };
        }),
      ];
    }

    if (modulo.tiposDocumento && modulo.tiposDocumento.length > 0) {
      subMenus = [
        ...subMenus,
        ...modulo.tiposDocumento.map(
          (tipoDocumento): MenuItem => {
            const atributoTipoDocumento: keyof ITipoDocumento = 'tipo';
            const tips = (breadCrumpPai ?? modulo.descricao)
              .split('>')
              .map((bc) => Helper.ajustarParaURI(bc.trim()));
            const descricaoTipoDocumento = Helper.ajustarParaURI(
              tipoDocumento[atributoTipoDocumento]
            );
            return {
              level: levelPai + 1,
              path: `/session/documentos/${tips[0]}/${
                tips[1] || descricaoTipoDocumento
              }/${descricaoTipoDocumento || ''}`,
              tabTitle: `${(breadCrumpPai ?? modulo.descricao)
                .split('>')
                .map((bc) => Helper.getSigla(bc.trim()))
                .join(' > ')} > ${tipoDocumento[
                atributoTipoDocumento
              ].substring(0, 32)}`,
              queryParams: {
                content: Helper.encryptData(
                  {
                    nomeModulo: modulo.descricao,
                    idPrivadoModulo: modulo.idPrivado,
                    idPublicoModulo: modulo.idPublico,
                    descricaoTipoDocumento: tipoDocumento[
                      atributoTipoDocumento
                    ].substring(0, 64),
                    breadCrump: (breadCrumpPai ?? modulo.descricao)
                      .split('>')
                      .map((bc) => bc.trim()),
                    idPublicoTipoDocumento: tipoDocumento.idPublico,
                    idPrivadoTipoDocumento: tipoDocumento.idPrivado,
                  },
                  this._authService.getToken()
                ),
              },
              title: `${tipoDocumento[atributoTipoDocumento].substring(0, 32)}`,
              submenu: [],
              entidade: 'digitalizacao',
              iconType: 'nzIcon',
              icon: 'file-text',
              iconTheme: 'outline',
            };
          }
        ),
      ];
    }

    return subMenus;
  }

  obterHierarquiaModulosIterativo(modulo) {
    const hierarquia = [];
    const stack = [{ modulo, indice: 0 }];

    while (stack.length > 0) {
      const { modulo, indice } = stack.pop();

      if (indice === 0) {
        // Adicionar a descrição do módulo atual à hierarquia
        hierarquia.push(modulo.descricao);

        // Adicionar tipos de documentos se existirem
        if (modulo.tiposDocumento && modulo.tiposDocumento.length > 0) {
          hierarquia.push(modulo.tiposDocumento[0].descricao);
        }
      }

      // Adicionar os módulos filhos ao stack em ordem reversa para processamento correto
      if (modulo.modulosFilho && modulo.modulosFilho.length > 0) {
        for (let i = modulo.modulosFilho.length - 1; i >= 0; i--) {
          stack.push({ modulo: modulo.modulosFilho[i], indice: i });
        }
      }
    }

    return hierarquia;
  }

  mountMenu() {
    let data;

    this.formulario.forEach((form) => {
      data = this.menu.map((menu) => {
        menu.submenu.map((submenu) => {
          if (submenu.entidade === form.entidade) {
            return submenu;
          }
        });

        return menu;
      });
    });

    this._menuService.menuItems.next(data);
  }
}
