import { Component, OnInit } from '@angular/core';
import { FooterConfig } from '@ps-erp-angular/ps-ui';

@Component({
  selector: 'ps-digitalizacao-not-found',
  styleUrls: ['./not-found.component.scss'],
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent implements OnInit {
  footerConfig: FooterConfig;

  constructor() {}

  goBack() {
    window.history.back();
  }

  ngOnInit(): void {
    this.footerConfig = {
      hasIcons: true,
      company: {
        name: 'Publicsoft',
        url: 'https://www.publicsoft.com.br/',
      },
      icons: [
        {
          type: 'instagram',
          url: 'https://www.instagram.com/publicsoft/?hl=pt-br',
        },
        {
          type: 'facebook',
          url: 'https://www.facebook.com/PublicSoftOficial/',
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/publicsoft',
        },
      ],
    };
  }
}
