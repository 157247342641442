import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FooterConfig } from '@ps-erp-angular/ps-ui';
import { AuthService } from '@ps-erp-angular/shared';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '../../environments/environment';

@Component({
  selector: 'ps-digitalizacao-verify-document',
  templateUrl: './verify-document.component.html',
  styleUrls: ['./verify-document.component.scss'],
})
export class VerifyDocumentComponent implements OnInit {
  loading = false;
  footerConfig: FooterConfig;

  psHash;
  urlDoc;
  @ViewChild('cardValidDoc', { static: true }) cardValidation: ElementRef;

  constructor(
    private activateRouter: ActivatedRoute,
    private authService: AuthService,
    public http: HttpClient,
    public notification: NzNotificationService
  ) {}

  public headerAuthenticateOptions() {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.authService.getToken()}`,
        'request-date': this.authService.getDateSelected(),
      }),
    };
  }

  generateUrlByEnvironment(port: string, path?: string, server = ''): string {
    // if (server === 'assinatura-documentos' || server === 'pessoa') {
    //   return `http://localhost:5018/${path}`;
    // }
    return `${environment.apiUrl(server)}/${path}`;
  }

  ngOnInit(): void {
    this.footerConfig = {
      hasIcons: true,
      company: {
        name: 'Publicsoft',
        url: 'https://www.publicsoft.com.br/',
      },
      icons: [
        {
          type: 'instagram',
          url: 'https://www.instagram.com/publicsoft/?hl=pt-br',
        },
        {
          type: 'facebook',
          url: 'https://www.facebook.com/PublicSoftOficial/',
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/publicsoft',
        },
      ],
    };

    this.activateRouter.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get('psHash')) {
        this.psHash = queryParams.get('psHash');
        this.executeValidation();
      }
    });
  }

  async executeValidation() {
    this.urlDoc = '';
    this.cardValidation.nativeElement.classList.remove('valid-doc-show');
    this.loading = true;
    await this.http
      // .get(
      //   `${this.generateUrlByEnvironment(
      //     'document',
      //     'assinatura-documentos',
      //   )}/verificar-documento/${this.psHash}`,
      //   this.headerAuthenticateOptions(),
      // )
      .get(
        this.generateUrlByEnvironment(
          '5017',
          `document/verificar-documento/${this.psHash}`,
          'assinatura-documentos'
        ),
        this.headerAuthenticateOptions()
      )
      .toPromise()
      .then((result: any) => {
        this.cardValidation.nativeElement.classList.add('valid-doc-show');
        this.urlDoc = result.data;
        this.loading = false;
      })
      .catch((err) => {
        this.cardValidation.nativeElement.classList.add('valid-doc-show');
        this.loading = false;
      });
  }

  showDoc() {
    window.open(this.urlDoc, '_blank');
  }
}
