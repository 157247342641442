import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  AssinantesService,
  AuthService,
  Helper,
  PessoaFisicaService,
  StatusCertificado,
} from '@ps-erp-angular/shared';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { EmpresaAssinante } from '../perfil/perfil.component';
import { ModalTermoAceiteComponent } from '../modal-termo-aceite/modal-termo-aceite.component';
@Component({
  selector: 'ps-digitalizacao-meus-documentos',
  templateUrl: './meus-documentos.component.html',
  styleUrls: ['./meus-documentos.component.scss'],
})
export class MeusDocumentosComponent implements OnInit {
  tabIndex = 1;

  formCert: FormGroup = new FormGroup({});

  empresaSelecionada: EmpresaAssinante;
  empresasAssinante: EmpresaAssinante[];

  rowData: any[] = [];
  rowSelect: any;
  allSelect: any;

  checkCol = {
    cpfCnpjCert: true,
    descricaoCert: true,
    empresaCertificadora: true,
    tipoCertificado: true,
    dataFimCert: true,
    status: true,
    razaoSocial: true,
  };
  allChecked = false;
  setChecked = new Set<any>();

  loading = true;
  fileList: NzUploadFile[] = [];
  btnUploadTxt = 'Selecionar certificado';

  hasCertificates = false;
  headers = [
    {
      key: 'cpfCnpjCert',
      title: 'CPF/CNPJ',
      compare: (a: any, b: any) => (a.cpfCnpjCert > b.cpfCnpjCert ? 1 : -1),
      priority: 6,
      width: '150px',
    },
    {
      key: 'descricaoCert',
      title: 'Descrição',
      compare: (a: any, b: any) => (a.descricaoCert > b.descricaoCert ? 1 : -1),
      priority: 5,
      width: '200px',
    },
    {
      key: 'empresaCertificadora',
      title: 'Emissor',
      compare: (a: any, b: any) =>
        a.empresaCertificadora > b.empresaCertificadora ? 1 : -1,
      priority: 4,
      width: '200px',
    },
    {
      key: 'tipoCertificado',
      title: 'Tipo',
      compare: (a: any, b: any) =>
        a.tipoCertificado > b.tipoCertificado ? 1 : -1,
      priority: 3,
      width: '100px',
    },
    {
      key: 'dataFimCert',
      title: 'Validade',
      compare: (a: any, b: any) => (a.validade > b.dataFimCert ? 1 : -1),
      priority: 2,
      width: '100px',
    },
    {
      key: 'status',
      title: 'Situação',
      compare: (a: any, b: any) => (a.status > b.status ? 1 : -1),
      priority: 1,
      width: '100px',
    },
  ];

  fabButtons: IMenuButton[];
  searchInput: any;

  constructor(
    protected authService: AuthService,
    private assinanteService: AssinantesService,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private pessoaFisicaService: PessoaFisicaService,
    private formBuilder: FormBuilder
  ) {
    this.formCert = this.formBuilder.group({
      tipoCertificado: [1],
      certificadoDigitalB64: [null, Validators.required],
      senhaCert: [null, Validators.required],
    });

    this.buildFabButton([
      {
        icon: 'delete',
        tooltip: 'Deletar',
        condition: true,
        onClick: this.excluir,
      },
      {
        icon: 'plus',
        tooltip: 'Novo Cadastro',
        condition: true,
        onClick: this.addCertificado,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        condition: true,
        onClick: this.saveCertificado,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: true,
        onClick: this.listCertificadosAssinanteLogado,
      },
      // {
      //   icon: 'edit',
      //   tooltip: 'Editar',
      //   condition: true,
      //   onClick: this.editar,
      // },
    ]);
  }

  addCertificado = () => {
    this.changeTabIndex(0);
    if (!this.hasCertificates) {
      this.createModalTermoAceite();
    }
  };

  ngOnInit(): void {
    this.listCertificadosAssinanteLogado();
    // this.listEmpresasAssinanteLogado();
  }

  buildFabButton(fabButtons: IMenuButton[]): void {
    this.fabButtons = fabButtons;
  }

  changeTabIndex(value) {
    this.tabIndex = value;
  }

  listCertificadosAssinanteLogado = () => {
    const certificados = [];
    this.assinanteService
      .getCertificadosAssinanteLogado()
      .then((res: any) => {
        if (!res.length) {
          this.notification.info(
            'Certificado',
            `Carregue um certificado digital válido!`
          );
          this.hasCertificates = false;
          this.loading = false;
          return;
        }
        this.hasCertificates = false;
        res
          .filter((cert) => cert.status === StatusCertificado.Valido)
          .map((certificate) => {
            certificados.push({ ...certificate, empresa: res.empresas });
          });
        if (certificados.length >= 1) {
          this.hasCertificates = true;
        }
        this.rowData = certificados;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
        this.notification.warning('Certificados', err.message);
      });
  };

  checkVencimentoCertificado(dataFimCert) {
    const dataValidade = new Date(dataFimCert);
    const dataHoje = new Date(this.authService.getDateSelected());

    return dataHoje.getTime() > dataValidade.getTime();
  }

  selectedRow(checked, row) {
    if (!checked) {
      this.setChecked.delete(row.uuid);
      this.rowSelect = null;
      return;
    }

    this.setChecked.add(row.uuid);
    this.rowSelect = row;
  }

  onAllChecked(checked) {
    this.rowData.map((row) => {
      if (!checked) {
        this.setChecked.delete(row.uuid);
        this.allSelect = null;
        return;
      }

      this.setChecked.add(row.uuid);
    });

    const data = this.rowData.filter((d) => this.setChecked.has(d.uuid));
    this.allSelect = data;
  }

  resetar() {
    this.checkCol = {
      cpfCnpjCert: true,
      empresaCertificadora: true,
      descricaoCert: true,
      tipoCertificado: true,
      dataFimCert: true,
      status: true,
      razaoSocial: true,
    };
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    if (file.name.lastIndexOf('.pfx') === -1) {
      setTimeout(() => {
        this.notification.error('Arquivo', 'Tipo de arquivo inválido');
      }, 1000);
      return;
    }

    this.btnUploadTxt = file.name;
    this.formCert.controls.certificadoDigitalB64.setValue(file);
    return false;
  };

  removeUpload = () => {
    this.fileList.pop();
    this.formCert.controls.certificadoDigitalB64.reset();
    return false;
  };

  resetarFormCert = () => {
    this.formCert.reset();
    this.btnUploadTxt = 'Selecionar certificado';
  };

  saveCertificado = () => {
    this.loading = true;
    const dataFimCert = this.rowData.map((item) => {
      return item.dtFinalCert;
    });

    if (!this.checkVencimentoCertificado(dataFimCert)) {
      this.loading = false;
      return this.notification.warning(
        'Atenção',
        'Não foi possível salvar um novo certificado digital, pois já existe um vinculado ao seu usuário na situação ativo!'
      );
    }

    if (this.formCert.invalid) {
      this.loading = false;
      return this.notification.warning(
        'Atenção',
        'Formulário de certificado inválido'
      );
    }

    this.modal.create({
      nzTitle: 'Salvar Certificado',
      nzContent: 'Deseja salvar esse certificado?',
      nzOkText: 'Sim',
      nzCancelText: 'Não',
      nzOnOk: () => {
        const form = {
          senhaCert: this.formCert.value.senhaCert,
          certificadoDigitalProps: this.formCert.value.certificadoDigitalB64,
          idAssinante: this.rowData[0].idAssinante,
        };

        this.assinanteService
          .cadastrar(form)
          .then((response) => {
            this.loading = false;
            this.notification.success('Sucesso', response.message);
            this.listCertificadosAssinanteLogado();
            this.modal.closeAll();
            window.location.reload();
          })
          .catch((err) => {
            const erro = err.message ? err.message : err;
            this.loading = false;
            this.notification.error('Erro', erro);
            this.listCertificadosAssinanteLogado();
          });

        this.btnUploadTxt = 'Selecionar certificado';
        this.fileList = [];
        this.formCert.reset();
      },
    });
  };

  excluir = () => {
    if (!this.rowSelect) {
      this.notification.info(
        'Atenção',
        'Por favor selecione um registro na tabela'
      );
      return;
    }

    this.modal.confirm({
      nzTitle: 'Remover registro',
      nzContent: 'Deseja realmente remover esse registro?',
      nzOkText: 'Sim',
      nzCancelText: 'Não',
      nzOnOk: () => {
        this.loading = true;
        this.assinanteService
          .deletarCertificado(this.rowSelect.idPrivado)
          .then((response: any) => {
            this.loading = false;
            this.notification.success('Sucesso', response.message);
            this.listCertificadosAssinanteLogado();
          })
          .catch((err: any) => {
            this.loading = false;
            this.notification.error('Erro', err.message);
            this.listCertificadosAssinanteLogado();
          });
      },
    });
  };

  listEmpresasAssinanteLogado() {
    this.pessoaFisicaService
      .getEmpresasAssinanteLogado()
      .then((empresas: EmpresaAssinante[]) => {
        this.loading = false;
        this.empresasAssinante = empresas;
      })
      .catch(() => {
        this.notification.warning(
          'Empresas',
          'Falha ao consultar empresas do assinante.'
        );
      });
  }

  getResult(pesquisa) {
    this.loading = true;
    const resultado = [];

    if (!pesquisa) {
      this.listCertificadosAssinanteLogado();
      this.loading = false;
      return;
    }

    this.rowData.map((row) => {
      if (row.cpfCnpjCert.includes(pesquisa)) {
        resultado.push(row);
        return;
      }

      if (row.descricaoCert.includes(pesquisa)) {
        resultado.push(row);
        return;
      }

      if (row.empresaCertificadora.includes(pesquisa)) {
        resultado.push(row);
        return;
      }

      if (row.tipoCertificado.includes(pesquisa)) {
        resultado.push(row);
        return;
      }

      if (row.dtFinalCert.includes(pesquisa)) {
        resultado.push(row);
        return;
      }
    });

    setTimeout(() => {
      if (resultado.length === 0) {
        this.notification.info('Pesquisa', 'Nenhum registro encontrado');
        this.rowData = resultado;
        this.loading = false;
        return;
      }

      this.notification.success('Pesquisa', 'Consulta realizada');
      this.rowData = resultado;
      this.loading = false;
    }, 3000);
  }

  createModalTermo() {
    const cpfUser = this.authService.getCpfUser();
    this.createModalTermoAceite(`cpf=${cpfUser}`);
  }

  createModalTermoAceite(querys?) {
    let loadingButton = false;
    //TODO: Verificar uso
    return this.modal.create({
      nzTitle: 'Termos de adesão',
      nzWidth: 1050,
      nzContent: ModalTermoAceiteComponent,
      nzOnOk: () => new Promise((resolve) => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: 'recusar',
          onClick: () =>
            (window.location.href = `${this.authService.getUrlAppAcesso()}/select-module`),
        },
        {
          loading: loadingButton,
          label: 'Salvar',
          disabled: (componentInstance) =>
            this.authService.disableButton(componentInstance),
          type: 'primary',
          onClick: (componentInstance) => {
            loadingButton = true;
            return componentInstance
              .assinarTermo(this.authService.getUgSelected(), querys)
              .then(() => (loadingButton = false))
              .catch(() => (loadingButton = false));
          },
        },
      ],
    });
  }

  getCpfMasked(cpf) {
    return Helper.cpfCnpjMascarado(cpf);
  }
}
