<ps-complex-form [title]="'Atestadores'" [tabIndex]="tabIndex" [isAntd]="true" disabledBody="true"
  (changeTabIndex)="changeTabIndex($event)">
  <div table>

    <nz-table nzSize="small" [nzLoading]="loading" [nzShowPagination]="true" #documentListTable
      [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
      <thead>
        <tr>
          <th class="p-t-15 p-b-15" style="background: #edf1fd" [nzLeft]="false" nzWidth="15px"></th>
          <th class="p-t-15 p-b-15" style="background: #edf1fd" nzWidth="60px" [nzLeft]="true">
            <label nz-checkbox [nzIndeterminate]="checkAllIndeterminate" [(ngModel)]="checkedAll">
            </label>
          </th>
          <ng-container *ngFor="let column of getTableAtestadores">
            <th nzWidth="150px" *ngIf="checkListTableAtestadores[column.columnName]" [nzSortFn]="column.compare">
              {{ column.label }}
            </th>
          </ng-container>
          <th style="background: #edf1fd" nzWidth="40px">
            <!-- <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click" [nzDropdownMenu]="colunas">
              <i nz-icon nzType="setting"></i>
            </button> -->
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-template ngFor let-item [ngForOf]="documentListTable.data">
          <tr (dblclick)="select(item)" style="cursor: pointer" nzTooltipPlacement="left">
            <td></td>

            <td>
              <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="checkItemPesquisa(item)"></label>
            </td>
            <ng-container *ngFor="let column of getTableAtestadores">
              <td *ngIf="checkListTableAtestadores[column.columnName]">
                {{ prepareColumnValue(item, column) }}
              </td>
            </ng-container>
            <td></td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
    <ps-fab [actions]="fabButtons"></ps-fab>

  </div>
</ps-complex-form>