<nz-spin [nzSpinning]="loading" [nzSize]="'large'">
  <ps-complex-form [title]="'Pessoa Física'" [tabIndex]="tabIndex" [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)">
    <div table>
      <div class="row">
        <div class="is-not-mobile" nz-row nz-col nzSpan="12" nzOffset="12" nzJustify="end">
          <div class="m-b-10 m-r-20">
            <nz-input-group [nzPrefix]="prefixTemplate">
              <input type="text" nz-input placeholder="Buscar pessoa física" nz-tooltip
                nzTooltipTitle="Digite um valor ou clique em consultar para buscar todos os registros"
                nzTooltipPlacement="bottom" [(ngModel)]="searchInput" (ngModelChange)="getPessoasFisicas($event)" />
            </nz-input-group>
            <ng-template #prefixTemplate>
              <i nz-icon nzType="search" class="opacity-05"></i>
            </ng-template>
          </div>
        </div>

        <div class="is-mobile" nz-row nz-col nzSpan="24">
          <div class="m-b-10 m-r-20 m-l-20">
            <nz-input-group [nzPrefix]="prefixTemplate">
              <input type="text" nz-input placeholder="Buscar pessoa física" nz-tooltip
                nzTooltipTitle="Digite um valor ou clique em consultar para buscar todos os registros"
                (click)="this.innerWidth <= 748? searchPessoaFisica():null" nzTooltipPlacement="bottom"
                [(ngModel)]="searchInput" (ngModelChange)="getPessoasFisicas($event)"
                [nzTooltipVisible]="tooltipPessoaFisica" />
            </nz-input-group>
            <ng-template #prefixTemplate>
              <i nz-icon nzType="search" class="opacity-05"></i>
            </ng-template>
          </div>
        </div>
      </div>

      <nz-table class="m-b-20" nzSize="small" [nzShowPagination]="false" #documentListTable [nzScroll]="{ y: '380px' }"
        style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th nzWidth="20px" [nzLeft]="getWidthContent() > 1024"></th>
            <ng-container *ngFor="let column of getTableColumns()">
              <th nzWidth="150px" *ngIf="checkList[column.value]" [nzSortFn]="column.compare">
                {{ column.text }}
              </th>
            </ng-container>
            <th style="background: #edf1fd" nzWidth="40px">
              <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click" [nzDropdownMenu]="colunas">
                <i nz-icon nzType="setting"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="documentListTable.data">
            <tr (click)="selectItem(item)" (dblclick)="dbClickItem(item)" style="cursor: pointer"
              nzTooltipPlacement="left">
              <td [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="selectItem(item)"></label>
              </td>
              <ng-container *ngFor="let column of getTableColumns()">
                <td *ngIf="checkList[column.value]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
              <td></td>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <div *ngIf="displayData" nz-row class="row mt-10 content">
        <nz-pagination [nzPageIndex]="1" [nzTotal]="resultLength" (nzPageIndexChange)="pageChanged($event)">
        </nz-pagination>
      </div>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>

    <div body>
      <form nz-form [formGroup]="formB" nzLayout="vertical">

        <h4 class="m-t-15">Informações básicas</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row>
          <div class="p-r-15" nz-col nzSpan="5" nzSm="5" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>CPF</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="cpfTpl">
                <input nz-input formControlName="cpf" placeholder="Digite um cpf válido" [mask]="'000.000.000-00'"
                  [dropSpecialCharacters]="true" maxlength="14" (ngModelChange)="searchPessoaByCpf($event)" />
                <ng-container *ngIf="formB.controls.cpf.hasError('mask')">
                  Valor inválido para cpf!
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="13" nzSm="13" nzXs="24" class="p-r-15">
            <nz-form-item>
              <nz-form-label nzRequired>Nome</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="nomeTpl">
                <input nz-input formControlName="nome" placeholder="Digite um nome" maxlength="150" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <!-- <div nz-col nzSpan="9" nzSm="9" nzXs="24">
          <nz-form-item>
            <nz-form-label nzRequired>E-mail</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="descTpl">
              <input nz-input formControlName="email" placeholder="Digite um e-mail válido" maxlength="150" />
              <ng-template #descTpl let-control>
                <ng-container *ngIf="control.hasError('email')">
                  Valor inválido para email!
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">
                  Por favor digite um email!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div> -->
          <!-- 
        <div class="p-r-15" nz-col nzSpan="6" nzSm="6" nzSpan="24">
          <nz-form-item>
            <nz-form-label>Cargo</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="cargoTpl">
              <input nz-input formControlName="cargo" placeholder="Digite um cargo válido" maxlength="150" />
              <ng-template #cargoTpl let-control>
                <ng-container *ngIf="control.hasError('cargo')">
                  Valor inválido para cargo!
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">
                  Por favor digite um cargo!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div> -->

          <!-- <div nz-col nzSpan="6" nzSm="6" nzSpan="24">
          <nz-form-item>
            <nz-form-label>Matrícula</nz-form-label>
            <nz-form-control nzHasFeedback [nzErrorTip]="matriculaTpl">
              <input nz-input formControlName="matricula" placeholder="Digite uma matrícula válida" maxlength="150" />
              <ng-template #matriculaTpl let-control>
                <ng-container *ngIf="control.hasError('matricula')">
                  Valor inválido para matrícula!
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">
                  Por favor digite um matrícula!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div> -->
        </div>

        <h4 class="m-t-15">Contato</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row>
          <div nz-col nzSpan="5" nzSm="5" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label nzRequired>E-mail</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="emailTpl">
                <input nz-input class="text-disabled" formControlName="email" placeholder="Digite um e-mail"
                  maxlength="100" />
                <ng-container *ngIf="formB.controls.email.hasError('email')">
                  Valor inválido para e-mail!
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="13" nzSm="13" nzXs="24" class="p-r-15">
            <nz-form-item class="m-b-20">
              <nz-form-label nzRequired>Telefone</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="telefoneTpl">
                <input nz-input class="text-disabled" formControlName="telefone" placeholder="Digite um telefone"
                  [mask]="'(00) 00000-0000'" maxlength="16" />
                <ng-container *ngIf="formB.controls.telefone.hasError('mask')">
                  Valor inválido para telefone!
                </ng-container>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>

  <nz-dropdown-menu #colunas="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item *ngFor="let column of getTableColumns()">
        <label nz-checkbox [(ngModel)]="checkList[column.value]">{{ column.text }}</label>
      </li>
      <li nz-menu-item class="text-center">
        <button nz-button nzType="text" nzSize="small" (click)="resetar()">Resetar</button>
      </li>
    </ul>
  </nz-dropdown-menu>


</nz-spin>