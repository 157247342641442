import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FooterConfig, IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  AuthService,
  DocumentService,
  ExportacaoArquivosService,
  IDocumento,
  ITreeview,
} from '@ps-erp-angular/shared';
import { EventSourcePolyfill } from 'event-source-polyfill';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTreeNode, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ps-digitalizacao-iframe-file-export',
  templateUrl: './iframe-file-export.component.html',
  styleUrls: ['./iframe-file-export.component.scss'],
})
export class IframeFileExportComponent implements OnInit {
  header: boolean;
  footer: boolean;
  docNodes: NzTreeNodeOptions[];
  isValid: boolean;
  filesExporteds: ITreeview;
  fabButtons: IMenuButton[];
  footerConfig: FooterConfig;
  innerWidth;
  filesTreeViewExport;
  ugName;
  usCriacaoName;
  cnpjUg;
  exportacaoIsValid;
  loading;
  documentList;
  displayData;
  listSignatarios;
  flagQueryParams;
  tableColumns;
  treeLoading: boolean;

  arquivosProntos: boolean = false;
  constructor(
    private router: ActivatedRoute,
    public service: ExportacaoArquivosService,
    private documentService: DocumentService,
    public notification: NzNotificationService,
    private authService: AuthService
  ) {
    this.router.queryParams.subscribe((params) => {
      this.ugName = params.ugName;
      this.usCriacaoName = params.usName;
      this.cnpjUg = params.cnpjUg;
    });
    this.router.params.subscribe(async (params) => {
      this.loading = true;
      const result = await this.service.getFileExportByUuidUpload(
        params.uuidUpload,
        true
      );
      this.filesExporteds = result.data;
      this.exportacaoIsValid = this.validateDate(result.data.dtExpiracao);

      this.buildTreeView();

      const subscriptionConsumer = new EventSourcePolyfill(
        `${this.service.generateUrlByEnvironment(
          '3030',
          `events`,
          'exportador-digitalizacao'
        )}/exporter?uuidUpload=${result.data.uuidUpload}`,
        {
          headers: {
            Authorization: 'Bearer ' + this.authService.getToken(),
          },
        }
      );

      const isAvaliable = await this.service.isZipFileAvaliable(
        result.data.uuidUpload
      );
      this.loading = false;
      if (isAvaliable.data.disponivel) {
        this.notification.success(
          'Arquivos Exportados',
          'A exportação já está pronta para download!'
        );
        this.arquivosProntos = (
          await this.service.isZipFileAvaliable(result.data.uuidUpload)
        ).data?.disponivel;
        this.resetFabButtons();
        return;
      }

      subscriptionConsumer.onmessage = async (message) => {
        if (message.data == 'Arquivo pronto!') {
          this.notification.success(
            'Arquivos Exportados',
            'A exportação já está pronta para download!'
          );
          this.arquivosProntos = (
            await this.service.isZipFileAvaliable(result.data.uuidUpload)
          ).data?.disponivel;
          return this.resetFabButtons();
        }
        this.resetFabButtons(Number(message.data));
      };
    });
  }
  validateDate(date: string | Date): boolean {
    return !this.documentService.docIsExpirado(date);
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async buildTreeView() {
    // this.formLoading = true;
    // const docs = [];
    this.treeLoading = true;
    // for (const file of this.filesExporteds.files.files) {
    //   if (file.numeroDoc) {
    //     const document = await this.documentService.getDocumentById(
    //       file.idPrivado,
    //     );
    //     docs.push(document);
    //   }
    // }

    const documentos: IDocumento[] = this.filesExporteds.documentos.map(
      (doc) => {
        return {
          ...doc,
          documentoAnexo: this.filesExporteds.anexos.filter(
            (item) => item.idDocumento === doc.idPrivado
          ),
        };
      }
    );

    // const docsExportados = this.filesExporteds.files.files.filter(
    //   (file) => file.numeroDoc,
    // );
    // const files = docsExportados.map((file) => file.idPrivado);
    let arrOrders: string[] = [
      `nomeUnidadeGestora`,
      'tipoDoc',
      'dataDocumentoFormated',
      'numeroDoc',
    ];
    // await this.formatResultGetDocuments(docs);
    await this.formatResultGetDocuments(documentos);
    await this.docNodesTreeView(this.documentList, arrOrders);
    this.treeLoading = false;
  }

  async docNodesTreeView(docs: IDocumento[], ordenator: string[]) {
    const result = [];
    const levels = { result };
    docs.forEach(async (doc: IDocumento, index, docs) => {
      ordenator.reduce((r, e) => {
        const title = doc[e];
        if (!r[title]) {
          let value: any;
          if (e !== ordenator[ordenator.length - 1]) {
            value = { title, key: uuidv4(), children: [] };
            r[title] = { result: value.children };
          } else {
            value = [
              {
                title: `Doc. ${doc.numeroDoc}`,
                key: uuidv4(),
                children: [
                  {
                    title: doc.numeroDoc,
                    author: doc,
                    key: uuidv4(),
                    isLeaf: true,
                    children: [],
                  },
                ],
              },
            ];

            if (doc.documentoAnexo.length != 0) {
              value[0].children.push({
                title: 'Anexos',
                key: uuidv4(),
                children: [],
              });
              for (const [index, anexo] of doc.documentoAnexo.entries()) {
                value[0].children[value[0].children.length - 1].children.push({
                  title: anexo.nome,
                  key: uuidv4(),
                  isLeaf: true,
                  author: anexo,
                  children: [],
                });
              }
            }

            r[title] = { result: value.children };
          }
          Array.isArray(value)
            ? r.result.push(...value)
            : r.result.push(...[value]);
        }
        return r[title];
      }, levels);
    });

    this.docNodes = result;
    return;
  }

  async openFile(data) {
    if (await data.origin.author?.documentoAnexo) {
      const uuid = data.origin.author.infoUpload.uuidArquivoConfirmado;
      const urlDocToView = await this.documentService.getUrlDocToUploadApi(
        uuid,
        'VisualizarAssinado'
      );
      if (this.innerWidth <= 768) {
        return uuid ? window.location.assign(urlDocToView.pdfURL) : null;
      }
      return uuid ? window.open(urlDocToView.pdfURL, '_blank') : null;
    }
    return await this.documentService.getURLAnexo(data.origin.author, false);
  }

  async openFolder(data) {
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.node;
      if (node) {
        node.isExpanded = !node.isExpanded;
      }
    }
  }

  async formatResultGetDocuments(documents: IDocumento[], identifyer = false) {
    // this.formLoading = true;
    // const docs = documents.filter(async (doc) => {
    //   // const dataLimiteAssinatura = doc.dataLimiteAssinatura?.slice(
    //   //   0,
    //   //   doc.dataLimiteAssinatura.lastIndexOf('.'),
    //   // );

    //   return (
    //     doc.signatarios.some(
    //       (ass) => ass.status === StatusDocumento.Assinado,
    //     ) ||
    //     doc.statusDocumento === StatusDocumento.Pendente ||
    //     doc.statusDocumento === StatusDocumento.Confirmado ||
    //     doc.statusDocumento === StatusDocumento.Desacordo ||
    //     doc.statusDocumento === StatusDocumento.Cancelado
    //     // new Date(dataLimiteAssinatura) < new Date()
    //   );
    // });

    // docs.map(async (doc: IDocumento) => {
    //   await this.documentService.formatColumnsDocForDysplay(
    //     doc,
    //     this.listSignatarios,
    //   );
    // });

    this.displayData = await documents.map((itm) => {
      return {
        ...itm,
        dataDocumentoFormated: moment(itm.dataDocumento)
          .utcOffset(0, true)
          .format('DD/MM/YYYY'),
        tipoDoc: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.tipo}`,
        // aplicacaoOrigemDetalhe:
        //   ModulosPublicSoft['Assinatura Digital'] === itm.aplicacaoOrigem
        //     ? 'Avulso'
        //     : CodigoModulos[itm.aplicacaoOrigem],
        nomeUnidadeGestora: this.ugName,
        expand: false,
      };
    });

    // if (this.flagQueryParams === true) {
    //   this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
    // }

    this.documentList = this.displayData;
  }

  resetFabButtons(percent = 0) {
    if (this.arquivosProntos) {
      return this.buildFabButtons([
        {
          icon: 'download',
          tooltip: 'Download',
          condition: true,
          onClick: this.downloadFiles,
        },
      ]);
    }

    percent = percent === 100.0 || percent === 100 ? 99.99 : percent;
    this.buildFabButtons([
      {
        icon: 'null',
        tooltip: 'Os arquivos estão sendo preparados.',
        color: 'secondary',
        percent: `${percent}%`,
        condition: true,
        onClick: this.downloadUnready,
      },
    ]);
  }

  downloadUnready = () => {
    return this.notification.warning(
      'Exportação de arquivos',
      'Os arquivos estão sendo preparados.'
    );
  };

  downloadFiles = () => {
    this.service.downloadFileAvaliable(this.filesExporteds.uuidUpload);
  };

  goBack() {
    window.history.back();
  }

  nzExpandSwitch(data) {
    if (data instanceof NzTreeNode) {
      return (data.isExpanded = !data.isExpanded);
    }
    const node = data.node;
    if (node) {
      return (node.isExpanded = !node.isExpanded);
    }
  }

  nzEvent(event) {
    if (event.node.origin.checked) {
      this.filesTreeViewExport = event.node.origin;
    }
  }

  ngOnInit(): void {
    setInterval(() => {
      this.docNodes = this.docNodes;
    }, 1000);

    this.innerWidth = window.innerWidth;

    this.footerConfig = {
      hasIcons: true,
      company: {
        name: 'Publicsoft',
        url: 'https://www.publicsoft.com.br/',
      },
      icons: [
        {
          type: 'instagram',
          url: 'https://www.instagram.com/publicsoft/?hl=pt-br',
        },
        {
          type: 'facebook',
          url: 'https://www.facebook.com/PublicSoftOficial/',
        },
        {
          type: 'twitter',
          url: 'https://twitter.com/publicsoft',
        },
      ],
    };

    this.resetFabButtons();
  }
}
