import { AfterContentInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import {
  NzContextMenuService,
  NzDropdownMenuComponent,
} from 'ng-zorro-antd/dropdown';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  NzFormatEmitEvent,
  NzTreeComponent,
  NzTreeNode,
  NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';

import { v4 as uuidv4 } from 'uuid';

import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  AuthService,
  CodigoModulos,
  DocumentService,
  DocumentsPendingService,
  DocumentsUploadService,
  ExportacaoArquivosService,
  IAssinante,
  IColumnsFieldsProps,
  IDocumento,
  ISearchFieldsProps,
  ITreeview,
  IntervalsTime,
  IntervalsTimeTranslate,
  ModulosPublicSoft,
  PessoaFisicaService,
  StatusDocumento,
  TipoDocumentoService,
  TypeDocDownload,
} from '@ps-erp-angular/shared';
import { PeriodVisualizeDoc } from 'libs/shared/src/lib/types/document.types';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ModalDialogComponent } from './../modal-dialog/modal-dialog.component';

@Component({
  selector: 'ps-digitalizacao-exportacao-arquivos',
  templateUrl: './exportacao-arquivos.component.html',
  styleUrls: ['./exportacao-arquivos.component.scss'],
})
export class ExportacaoArquivosComponent implements OnInit, AfterContentInit {
  @ViewChild('nzTreeComponent', { static: false })
  nzTreeComponent!: NzTreeComponent;
  intervalsTime = Object.entries(IntervalsTime);
  intervalTimeSelected = this.intervalsTime[0][0];
  listSignatarios: IAssinante[];
  filterType: number[];
  entidadeTipoTreeview: ITreeview = null;
  public getDateNow;
  public innerWidth: any;
  panels = [
    {
      active: true,
      name: 'Filtros',
    },
  ];
  none;
  displayData = [];
  displayDataFileExport = [];
  filesTreeViewExport;
  tableColumns: IColumnsFieldsProps[] = [];
  docsSelecteds: number;
  anexsSelecteds: number;
  countArquivosTotal: number;
  countArquivos: number;

  fieldSelected;
  fieldFilesSelected;

  loading: boolean = false;
  treeLoading: boolean = false;

  arrColumnsSelect = [];
  arrColumnsfilesSelect = [];

  fieldsSelected = [];
  fieldsFilesSelected = [];
  tooltipVisible: object = {
    filter1: false,
    filter2: false,
    filter3: false,
    filter4: false,
    filter5: false,
    filter6: false,
  };

  identifyer: boolean = false;

  selectedValues = [];
  selectedFilesValues = [];

  earchColumns: ISearchFieldsProps[] = [];

  assinanteSelected: any;
  showDocsAvulsos = false;
  public adm: boolean = true;
  fabButtons: IMenuButton[];
  public formLoading: boolean = false;
  assinantesSelected = [];
  allAssinantes = [];

  customInterval: string[];

  listSignatariosToSign = [];
  certificadoAssinante: any[];
  newDocument: IDocumento[] = [];
  showComboTags = false;
  showComboTagsFiles;
  colorExpiredDocSelected: string;
  paramsWhere: any;

  pageTotal: number;
  countCheked: number;

  flagQueryParams = false;

  documentList = [];

  formB: FormGroup = new FormGroup({});
  tabIndex = 0;
  searchInput: any;
  resultLength: number;

  docsChecked = [];

  docNodes: NzTreeNodeOptions[] | any = [];

  searchForm: FormGroup = new FormGroup({});

  checkboxInvalidar: boolean = true;

  displayExportData: [];
  tableExportColumns: any;

  activatedNode?: NzTreeNode;
  page = 1;
  pageSize = 10;
  dataExpiracao: Date;

  checkedAll = false;
  checkAllIndeterminate = true;
  checkList = {
    idPublico: true,
    idPrivado: false,
    usCriacao: true,
    descricao: true,
    dtCriacao: true,
    dtAlteracao: true,
    dtExpiracao: true,
    pastas: true,
    uuidUpload: true,
  };

  nomeTpl;

  public usuarioCriacaoArr: Array<any> = new Array<any>();

  constructor(
    private tipoDocService: TipoDocumentoService,
    private documentService: DocumentService,
    private documentsPendingService: DocumentsPendingService,
    private activateRouter: ActivatedRoute,
    public uploadService: DocumentsUploadService,
    private nzContextMenuService: NzContextMenuService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    public service: ExportacaoArquivosService,
    public pessoaFisicaService: PessoaFisicaService,
    private modal: NzModalService,
    public notification: NzNotificationService
  ) {
    this.getDate();
    // this.admUser();
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.fieldsSelected = [];
      this.fieldsFilesSelected = [];
      this.showComboTags = false;
      this.intervalTimeSelected = queryParams.get('interval')
        ? queryParams.get('interval')
        : null;

      this.filterType = Number(queryParams.get('tipo'))
        ? [Number(queryParams.get('tipo'))]
        : [];

      const adm = queryParams.get('adm') === 'false' ? false : true;

      this.adm = adm;

      this.flagQueryParams = true;

      await this.setConfigTreeFilter();
      await this.setConfigExportTable();
      await this.getAllAssinantes();

      this.columnsSelect(this.tableColumns);
      this.filterPreSet();

      this.setConfigExportTable();
      this.colorExpiredDocSelected = queryParams.get('colorExpired');

      if (queryParams.keys.length > 0) {
        this.changeViewDocsAvulsos(JSON.parse(queryParams.get('docAvulso')));

        if (queryParams.getAll('assinantes').length > 0) {
          this.assinanteSelected = null;
          this.assinantesSelected = [];
          const arrAssinantes = [];
          await this.getAllAssinantes();

          for (const ids of queryParams.getAll('assinantes')) {
            this.assinanteSelected = this.listSignatarios
              ?.filter((key) => key.idPessoaFisica === Number(ids))
              .map((newArr) => {
                return {
                  ...newArr,
                  queryParams: true,
                };
              });
            arrAssinantes.push(...this.assinanteSelected);
          }
          await this.addFiltroPorAssinante(arrAssinantes);
        }

        if (queryParams.get('initialDate')) {
          const initialDate = queryParams.get('initialDate');
          const finalDate = queryParams.get('finalDate');
          this.customInterval = [initialDate, finalDate];
        }

        await this.getDocumentsPerPeriod(this.showDocsAvulsos);
      }
    });

    this.setFilterType(4);
    this.reset();
    this.authService.dateSelectedSubject.subscribe((date) => {
      this.formB.controls.dataExpiracao.setValue(this.formatDate23hrs(date));
    });
  }

  formatDate23hrs(date: string) {
    const systemDate = new Date(date);

    return new Date(
      systemDate.getFullYear(),
      systemDate.getMonth(),
      systemDate.getDate(),
      23,
      59,
      0
    ).toISOString();
  }

  async openFile(data) {
    if (await data.origin.author?.documentoAnexo) {
      const uuid = data.origin.author.infoUpload.uuidArquivoConfirmado;
      const urlDocToView = await this.documentService.getUrlDocToUploadApi(
        uuid,
        'VisualizarAssinado'
      );
      if (this.innerWidth <= 768) {
        return uuid ? window.location.assign(urlDocToView.pdfURL) : null;
      }
      return uuid ? window.open(urlDocToView.pdfURL, '_blank') : null;
    }
    return await this.documentService.getURLAnexo(data.origin.author, false);
  }

  async openFolder(data) {
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.node;
      if (node) {
        node.isExpanded = !node.isExpanded;
      }
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    // if (this.tabIndex === 1 && search === true) {
    //   this.consultar();
    // }
  }

  consultar = () => {
    this.changeTabIndex(1);
  };
  async ngAfterContentInit() {
    // await this.setConfigTable();
    // this.reset();
  }

  reset() {
    this.formB = this.formBuilder.group({
      id: [{ value: null, disabled: true }],
      dataExpiracao: [this.dataExpiracao],
      checkboxInvalidar: [{ value: false, disabled: true }],
      usuarioCriacao: [{ value: null, disabled: true }],
      descricao: [null, [Validators.required]],
      hash: [{ value: null, disabled: true }],
      pastas: [{ value: true, disabled: false }],
    });
  }

  async getOptionsSelect(column) {
    let arrTypes = [];

    this.selectedValues = [];

    column.objectChildren === 'tag'
      ? (arrTypes = await this.tipoDocService.getTipoDocByTag())
      : (arrTypes = await this.tipoDocService.getTipoDocs(null, true));
    for (const teste of arrTypes) {
      this.selectedValues.push({
        field: this.fieldSelected.text,
        value: column.objectChildren === 'tag' ? teste.tag : teste.tipo,
        text:
          column.objectChildren === 'tag'
            ? teste.tag
            : `${teste.idPublico} - ${teste.tipo}`,
      });
    }
  }
  async getFilterFilesOptionsSelect(column) {
    let arrTypes = [];
    this.selectedFilesValues = [];

    for (const teste of arrTypes) {
      this.selectedFilesValues.push({
        field: this.fieldFilesSelected.text,
        value: column.objectChildren === 'tag' ? teste.tag : teste.tipo,
        text:
          column.objectChildren === 'tag'
            ? teste.tag
            : `${teste.idPublico} - ${teste.tipo}`,
      });
    }
  }

  activeNode(data: NzFormatEmitEvent): void {
    this.activatedNode = data.node!;
  }

  contextMenu($event: MouseEvent, menu: NzDropdownMenuComponent): void {
    this.nzContextMenuService.create($event, menu);
  }

  selectDropdown(): void {
    // do something
  }

  async getAllSignatarios(avulso) {
    return await this.documentService.getAllSignatarios(avulso);
  }

  addFiltroPorAssinante(arrAssinantes = []) {
    if (arrAssinantes.length === 0) {
      this.assinantesSelected.push(this.assinanteSelected);

      this.fieldsSelected.push({
        props: 'assinante',
        field: 'Assinante',
        value: this.assinanteSelected.cpf,
        text: `${this.assinanteSelected.cpf} - ${this.assinanteSelected.nome}`,
      });

      this.showComboTags = true;
    }

    if (arrAssinantes.length > 0) {
      for (const assinante of arrAssinantes) {
        this.assinantesSelected.push(assinante);

        this.fieldsSelected.push({
          props: 'assinante',
          field: 'Assinante',
          value: assinante.cpf,
          text: `${assinante.cpf} - ${assinante.nome}`,
        });
      }
      this.showComboTags = true;
    }

    setTimeout(() => (this.assinanteSelected = null), 0);
  }

  async getAllAssinantes(avulso = false, idDocumento?) {
    this.formLoading = true;
    const allSignatarios = await this.getAllSignatarios(avulso);

    const newAss = allSignatarios
      .filter((el) => {
        return (
          el.hasOwnProperty('cpf') && el.hasOwnProperty('cpfRepresentante')
        );
      })
      .sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      });

    if (idDocumento) {
      this.listSignatariosToSign = await this.documentService.getSignatarioByIdDocumento(
        idDocumento
      );
    }

    idDocumento ? this.listSignatariosToSign : (this.listSignatarios = newAss);
    this.formLoading = false;
  }

  async changeViewDocsAvulsos(avulso) {
    if (avulso === undefined) {
      this.showDocsAvulsos = !this.showDocsAvulsos;
      return;
    }
    this.showDocsAvulsos = avulso;
  }

  clearFilterByDashboard() {
    this.intervalTimeSelected = this.intervalsTime[0][0];
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }
  ngOnInit(): void {
    this.formB.controls.dataExpiracao.setValue(
      this.formatDate23hrs(this.authService.getDateSelected())
    );
    this.getDate();
    this.populateUsuarioCriacaoFiltro();

    this.innerWidth = window.innerWidth;
    setInterval(() => {
      this.docNodes = this.docNodes;
    }, 1000);
    // this.reset();
    this.checkboxInvalidar = true;
    this.resetFabButtons();
  }

  async populateUsuarioCriacaoFiltro(): Promise<void> {
    this.usuarioCriacaoArr = await this.service.getAllUsCreations();
  }

  filterPreSet() {
    const date = new Date();
    let primeiroDia: any = moment(
      new Date(date.getFullYear(), date.getMonth(), 1)
    ).format('DD/MM/YYYY');
    let diaAtual: any = moment().format('DD/MM/YYYY');

    //https://pt.stackoverflow.com/questions/226086/como-retornar-o-primeiro-e-%C3%BAltimo-dia-do-m%C3%AAs-corrente-em-javascript
    // if (queryParams.keys?.length > 0) {
    //   const { initialDate, finalDate, interval } = queryParams?.params;

    //   // const primeiroDiaIso = initialDate;
    //   primeiroDia = moment(initialDate).format('DD/MM/YYYY');
    //   diaAtual = moment(finalDate).format('DD/MM/YYYY');

    //   this.fieldsSelected = [];
    //   this.showComboTags = true;

    //   if (interval && !(initialDate && finalDate)) {
    //     return this.fieldsSelected.push({
    //       field: 'Data do documento',
    //       props: 'dataDocumentoFormated',
    //       text: `Período - ${IntervalsTime[interval]}`,
    //     });
    //   }
    // }
    this.fieldsSelected = [];
    this.showComboTags = true;
    const id = this.arrColumnsSelect.findIndex(
      (a) => a.value === 'dataDocumentoFormated'
    );
    return this.fieldsSelected.push({
      index: id,
      field: 'Data do documento',
      props: 'dataDocumentoFormated',
      text: `Data do documento - ${primeiroDia} - ${diaAtual}`,
      value: `${primeiroDia} - ${diaAtual}`,
    });

    // this.showComboTags = this.fieldsSelected.length != 0;
  }

  resetFabButtons() {
    const buttons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: true,
        onClick: this.novoCadastro,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0,
        onClick: this.gravar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'orange',
        condition: this.tabIndex === 1,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        color: 'red',
        tooltip: 'Excluir',
        condition: this.tabIndex === 1,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: true,
        onClick: this.attExportacoes,
      },
    ];

    const buttonsFilteres = buttons.filter((button) => button.condition);
    this.buildFabButtons(buttonsFilteres);
  }

  attExportacoes = async () => {
    this.changeTabIndex(1);
    this.filterFilesExporteds();
  };

  novoCadastro = async () => {
    this.formB.reset();
    this.reset();
    this.resetAllCheckbox();
    this.columnsSelect(this.tableColumns);
    this.docNodes = [];
    this.pageTotal = 0;
    this.anexsSelecteds = 0;
    this.docsSelecteds = 0;
    // this.fieldsSelected = [];
    this.showComboTags = false;
    this.fieldSelected = false;
    this.filesTreeViewExport = [];
    // this.displayDataFileExport = []; //resetar a grid de exportação
    this.resetFilterSelecteds();
    this.resetFilterExportationsSelecteds();
    this.filterPreSet();
    this.formB
      .get('dataExpiracao')
      .setValue(this.formatDate23hrs(this.authService.getDateSelected()));

    this.treeLoading = false;
    this.loading = false;
    this.formLoading = false;
    this.changeTabIndex(0);
  };

  deletar = () => {
    let editFileExport: ITreeview;
    let editFileExportsChecked: ITreeview[] = [];
    this.displayDataFileExport.forEach((fileExported) => {
      editFileExport = fileExported.checked ? fileExported : editFileExport;
      if (fileExported.checked) {
        editFileExport = fileExported;
        return editFileExportsChecked.push(fileExported);
      }
      return (editFileExport = editFileExport);
    });

    if (editFileExportsChecked.length != 1) {
      this.resetAllCheckbox();
      return this.documentService.notification.info(
        'Deletar arquivos',
        `Não foi possível deletar o arquivos pois exitem muitos arquivos selecionados ou nenhum. `
      );
    }

    this.modal.confirm({
      nzTitle: '<i>Deseja excluir esses arquivos?</i>',
      // nzContent: '<b>Some descriptions</b>',
      nzOnOk: () =>
        this.deletarExportacao(editFileExport, editFileExportsChecked),
    });
  };

  deletarExportacao(editFileExport, editFileExportsChecked) {
    // for (const [index, fileExported] of this.displayDataFileExport.entries()) {
    //   editFileExport = fileExported.checked ? fileExported : editFileExport;
    //   if (fileExported.checked) {
    //     editFileExport = fileExported;
    //     editFileExportsChecked.push(fileExported);
    //   } else {
    //     editFileExport = editFileExport;
    //   }
    // }

    this.service.deleteArquivosExportados(editFileExport);
    this.displayDataFileExport = this.displayDataFileExport.filter(
      (element, index, array) => {
        for (const fileExported of editFileExportsChecked) {
          if (element != fileExported) {
            return element;
          }
        }
      }
    );
  }

  resetAllCheckbox() {
    this.displayDataFileExport.forEach((e) => {
      e.checked = false;
    });
    this.checkAllIndeterminate = true;
  }

  cancelar = async () => {
    this.formB.reset();
    this.formB.get('checkboxInvalidar').disable();
    this.docNodes = [];
    this.fieldsSelected = [];
    this.showComboTags = false;
    this.fieldSelected = false;
    // this.columnsSelect(this.tableColumns);
  };

  editar = async () => {
    // this.columnsSelect(this.tableColumns);
    let editFileExport: ITreeview;
    let editFileExportsChecked: ITreeview[] = [];
    this.displayDataFileExport.forEach((fileExported) => {
      // editFileExport = fileExported.checked ? fileExported : editFileExport;
      if (fileExported.checked) {
        editFileExport = fileExported;
        return editFileExportsChecked.push(fileExported);
      }
      return (editFileExport = editFileExport);
    });
    // for (const [index, fileExported] of this.displayDataFileExport.entries()) {
    //   editFileExport = fileExported.checked ? fileExported : editFileExport;
    //   if (fileExported.checked) {
    //     editFileExport = fileExported;
    //     return editFileExportsChecked.push(fileExported);
    //   }
    //   else {
    //     editFileExport = editFileExport;
    //   }
    //   return (editFileExport = editFileExport);
    // }
    if (editFileExportsChecked.length != 1) {
      this.resetAllCheckbox();
      return this.documentService.notification.info(
        'Exportação de arquivos',
        'Não foi possível editar o arquivo pois exitem muitos arquivos selecionados ou nenhum.'
      );
    }
    let countDocs = 0;
    let countAnex = 0;

    const listauuids = [];
    const listaTitles = [];

    this.eachRecursive(editFileExport.tree, listauuids, listaTitles);
    let novalistaDocs = listaTitles.filter((elemento, index, array) => {
      return array[index].isDoc.numeroDoc != undefined;
    });
    const uniqueDocsIds = [];

    let novalistaAnex = listaTitles.filter((elemento, index, array) => {
      return array[index].isDoc.numeroDoc == undefined;
    });
    const uniqueAnexIds = [];

    novalistaDocs = novalistaDocs.filter((element) => {
      const isDuplicate = uniqueDocsIds.includes(element.isDoc.idPrivado);
      if (!isDuplicate) {
        uniqueDocsIds.push(element.isDoc.idPrivado);

        return true;
      }

      return false;
    });

    novalistaAnex = novalistaAnex.filter((element) => {
      const isDuplicate = uniqueAnexIds.includes(element.isDoc.idPrivado);
      if (!isDuplicate) {
        uniqueAnexIds.push(element.isDoc.idPrivado);

        return true;
      }

      return false;
    });
    countDocs = countDocs + novalistaDocs.length;
    countAnex = countAnex + novalistaAnex.length;

    this.anexsSelecteds = countAnex;
    this.docsSelecteds = countDocs;
    this.pageTotal = countDocs;
    this.countArquivos = countAnex + countDocs;

    this.changeTabIndex(0);
    this.setDataFormTipoFileExported(editFileExport);
    this.resetAllCheckbox();
    this.treeLoading = true;

    const filesExporteds = (
      await this.service.getFileExportByUuidUpload(
        editFileExport.uuidUpload,
        true
      )
    ).data;

    const documentos: IDocumento[] = filesExporteds.documentos.map((doc) => {
      return {
        ...doc,
        documentoAnexo: filesExporteds.anexos.filter(
          (item) => item.idDocumento === doc.idPrivado
        ),
      };
    });
    // return;
    let arrOrders: string[] = [
      `nomeUnidadeGestora`,
      'tipoDoc',
      'dataDocumentoFormated',
      'numeroDoc',
    ];
    await this.formatResultGetDocuments(documentos);
    await this.docNodesTreeView(this.documentList, arrOrders);
    this.treeLoading = false;
    // return;
    // this.docNodes = [editFileExport.tree];
  };

  removeFiltroPorField(index) {
    // this.assinantesSelected.findIndex(
    //   (a) => a.cpf === this.fieldsSelected[index].value,
    // );

    // this.assinantesSelected.splice(index, 1);

    this.fieldsSelected.splice(index, 1);

    this.columnsSelect(this.tableColumns);

    if (this.fieldsSelected.length === 0) {
      this.showComboTags = false;
    }
  }

  removeFiltroExportedPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableExportColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsfilesSelect.splice(
      this.tableExportColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsFilesSelected.splice(
      this.fieldsFilesSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsFilesSelected.length === 0) {
      this.showComboTagsFiles = false; //fieldsFilesSelected
    }
  }

  setDataFormTipoFileExported(value: ITreeview) {
    this.formB.get('checkboxInvalidar').enable();
    const newValue = {
      id: value.idPublico,
      hash: value.hash,
      usuarioCriacao: value.usCriacao,
      descricao: value.descricao,
      dataExpiracao: value.dtExpiracao,
      pastas: value.pastas,
      // checkboxInvalidar: !value.checked,
    };

    this.formB.patchValue(newValue);
  }

  resetFilterSelecteds() {
    while (this.fieldsSelected.length !== 0) {
      for (const [index, fieldSelected] of this.fieldsSelected.entries()) {
        this.removeFiltroPorField(index);
      }
    }
  }

  resetFilterExportationsSelecteds() {
    while (this.fieldsFilesSelected.length !== 0) {
      for (const [index, fieldSelected] of this.fieldsFilesSelected.entries()) {
        this.removeFiltroExportedPorField(fieldSelected);
      }
    }
  }

  gravar = async () => {
    if (this.formB.invalid) {
      return this.service.notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente.'
      );
    }

    const treeCheckeds = [];
    this.nzTreeComponent.nzFlattenNodes.forEach((element) => {
      const hasChecked = element.origin.checked;
      if (hasChecked) {
        treeCheckeds.push(element.origin);
      }
    });

    try {
      //tree-view modificado e vazio
      if (!this.filesTreeViewExport[0]?.checked && treeCheckeds.length === 0) {
        return this.service.notification.warning(
          'Exportação de arquivos',
          'Por favor, selecione os arquivos que serão exportados.'
        );
      }
    } catch (error) {
      // tree-view não modificado
      return this.service.notification.warning(
        'Exportação de arquivos',
        'Por favor, selecione os arquivos que serão exportados.'
      );
    }
    const tree = this.filesTreeViewExport[0]?.checked
      ? this.filesTreeViewExport
      : treeCheckeds;

    // const tree = hash
    //   ? (await this.service.getArquivosExportadosByHash(hash)).data
    //   : null;

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma os dados da exportação?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (componentInstance) => {
      this.saveExportation(tree);
      modal.close();
    };
    const modal = this.modal.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        { label: cancelText, onClick: () => modal.close() },
        {
          label: okText,
          type: 'primary',
          disabled: false,
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        docsSelecteds: this.docsSelecteds,
        anexsSelecteds: this.anexsSelecteds,
        dtExpiracao: this.formB.get('dataExpiracao').value,
        treeDocs: this.filesTreeViewExport[0]?.checked
          ? this.filesTreeViewExport
          : treeCheckeds,
        // countArquivosTotal: this.pageTotal,
        // countArquivos: number;
        // valorSelecionado: this.documentService.getValorSelecionado(newDocs),
        // qtdSelecionados: newDocs.length,
        // docsSelecionados: newDocs,
        // assinanteLogado: this.certificadoAssinante,
        // signatarios: this.listSignatarios,
        // attachToSign: this.ratificarDoc === true ? false : this.listedAttachs,
        // allChecked: this.checkedAll,
        // docsChecked: this.pageTotal,
        // labelAnexo: this.ratificarDoc === true ? null : 'Assinar',
        // ratificar: this.ratificarDoc,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        // this.ratificarDoc = false;
        this.loading = false;
      }
      // this.resetAllCheckbox();
      // this.attDashboard();
    });
  };

  async saveExportation(trees) {
    let lista = [];
    let listaTitles = [];
    let novaListaIdsTitles;

    for (const tree of trees) {
      await this.eachRecursiveToSave(tree, lista, listaTitles);
      const isDuplicateListIdsTitles = [];
      novaListaIdsTitles = listaTitles.filter((element) => {
        // let isDuplicate;
        // for (const itm of isDuplicateListIdsTitles) {
        if (
          !isDuplicateListIdsTitles.find((data) => {
            return (
              data.uuid === element.uuid && data.idPrivado === element.idPrivado
            );
          })
        ) {
          return isDuplicateListIdsTitles.push(element);
        }
        return;
      });
    }

    const hash = this.formB.get('hash').value;
    if (hash) {
      const editFileExport: ITreeview = {
        hash: this.formB.get('hash').value,
        usCriacao: this.formB.get('usuarioCriacao').value,
        dtExpiracao: this.formB.get('dataExpiracao').value,
        descricao: this.formB.get('descricao').value,
        idUnidadeGestora: (await this.authService.getUgSelected()).id,
        files: novaListaIdsTitles,
        uuidUpload: null,
        pastas: this.formB.get('pastas').value,
      };
      // return;

      this.service.putArquivosExportados(editFileExport);

      return this.novoCadastro();
    }

    const usCriacao = this.authService.getUserInfo().idUsuario;

    //pegar o id do usuário logado no token
    const saveFileExported: ITreeview = {
      idPrivado: this.formB.value.id,
      hash: uuidv4(),
      usCriacao: usCriacao,
      dtExpiracao: this.formB.get('dataExpiracao').value,
      // isValid: !this.formB.value.checkboxInvalidar,
      descricao: this.formB.value.descricao,
      idUnidadeGestora: (await this.authService.getUgSelected()).id,
      // tree: this.filesTreeViewExport,
      files: novaListaIdsTitles,
      uuidUpload: uuidv4(),
      pastas: this.formB.get('pastas').value,
    };
    //teste para colocar a checkbox withFolder
    // return;

    this.service.postArquivosExportados(saveFileExported);

    return this.novoCadastro();
  }

  nzEvent(checkedTree) {
    // checkedTree.node.parentNode._children
    //   .find((e) => e._title === 'Anexos')
    //   ._children.map((e) => {
    //     return { ...e, _isChecked: true };
    //   }),

    const trees = [];
    this.nzTreeComponent.getCheckedNodeList().forEach((e) => {
      trees.push(e);
    });

    if (checkedTree.node.origin.checked) {
      this.filesTreeViewExport = trees.map((tree) => tree.origin);
    }

    let countDocs = 0;
    let countAnex = 0;

    for (const tree of trees) {
      const listauuids = [];
      const listaTitles = [];

      this.eachRecursive(tree.origin, listauuids, listaTitles);
      let novalistaDocs = listaTitles.filter((elemento, index, array) => {
        return array[index].isDoc.numeroDoc != undefined;
      });
      const uniqueDocsIds = [];

      let novalistaAnex = listaTitles.filter((elemento, index, array) => {
        return array[index].isDoc.numeroDoc == undefined;
      });
      const uniqueAnexIds = [];

      novalistaDocs = novalistaDocs.filter((element) => {
        const isDuplicate = uniqueDocsIds.includes(element.isDoc.idPrivado);
        if (!isDuplicate) {
          uniqueDocsIds.push(element.isDoc.idPrivado);

          return true;
        }

        return false;
      });

      novalistaAnex = novalistaAnex.filter((element) => {
        const isDuplicate = uniqueAnexIds.includes(element.isDoc.idPrivado);
        if (!isDuplicate) {
          uniqueAnexIds.push(element.isDoc.idPrivado);

          return true;
        }

        return false;
      });
      countDocs = countDocs + novalistaDocs.length;
      countAnex = countAnex + novalistaAnex.length;
    }

    this.anexsSelecteds = countAnex;
    this.docsSelecteds = countDocs;
    this.countArquivos = countAnex + countDocs;
  }

  async eachRecursiveToSave(obj, lista, listaTitles) {
    if (Array.isArray(obj)) {
      for (let item of obj) {
        this.eachRecursiveToSave(item, lista, listaTitles);
      }
      return;
    }

    for (let key in obj) {
      if (obj['isLeaf']) {
        const document = obj['author'];
        const typeAction = TypeDocDownload[document.statusDocumento];
        let uuid = await this.getUuidFile(typeAction, document);

        // Anexo
        if (!document.infoUpload) {
          uuid = document.uuidManifestoAnexo;
        }

        lista.push(uuid);
        listaTitles.push({
          // uuid,
          // title: obj['title'],
          idPrivado: document.idPrivado,
          isAnexo: document.numeroDoc ? false : true,
          // isDoc: obj['author'],
        });
      }

      if (key === 'children' && obj['children'].length > 0) {
        this.eachRecursiveToSave(obj['children'], lista, listaTitles);
      }
    }
  }

  async getUuidFile(action, doc: any) {
    if (!doc.infoUpload) {
      return;
    }
    if (
      action === 'Visualizar' ||
      action === 'view' ||
      action === 'DownloadOriginal' ||
      action === 'VisualizarDocPai'
    ) {
      return doc.infoUpload.uuidArquivoOriginal;
    }
    if (action === 'VisualizarAssinado' || action === 'DownloadAssinado') {
      return doc.infoUpload.uuidArquivoConfirmado;
    }
    if (action === 'VisualizarDesacordado' || action === 'DownloadDesacordo') {
      return doc.infoUpload.uuidArquivoDesacordo;
    }
    if (action === 'VisualizarCancelado' || action === 'DownloadCancelado') {
      return doc.infoUpload.uuidArquivoCancelado;
    }
  }

  /*tentativa de montar o tree-view comentado para referências futuras*/
  // eachRecursiveNode(node) {
  //   if (Array.isArray(node)) {
  //     for (let item of node) {
  //       this.eachRecursiveNode(item);
  //     }
  //     return;
  //   }

  //   for (let key in node) {
  //     if (key === 'level' && node[key] === 0) {
  //       // const document = node['level'];
  //       // // const typeAction = TypeDocDownload[document.statusDocumento];
  //       // // let uuid = await this.getUuidFile(typeAction, document);

  //       // // Anexo
  //       // if (!document.infoUpload) {
  //       //   // uuid = document.uuidManifestoAnexo;
  //       // }

  //       // // lista.push(uuid);
  //       // listaTitles.push({
  //       //   // uuid,
  //       //   // title: obj['title'],
  //       //   isDoc: obj['author'],
  //       // });
  //     }

  //     if (key === 'parentNode') {
  //       this.eachRecursiveNode(node[key]);
  //     }
  //   }
  // }

  eachRecursive(obj, lista, listaTitles) {
    if (Array.isArray(obj)) {
      for (let item of obj) {
        this.eachRecursive(item, lista, listaTitles);
      }
      return;
    }

    for (let key in obj) {
      if (obj['isLeaf']) {
        const document = obj['author'];
        // const typeAction = TypeDocDownload[document.statusDocumento];
        // let uuid = await this.getUuidFile(typeAction, document);

        // Anexo
        if (!document.infoUpload) {
          // uuid = document.uuidManifestoAnexo;
        }

        // lista.push(uuid);
        listaTitles.push({
          // uuid,
          // title: obj['title'],
          isDoc: obj['author'],
        });
      }

      if (key === 'children' && obj['children'].length > 0) {
        this.eachRecursive(obj['children'], lista, listaTitles);
      }
    }
  }

  navigateTofileExport(fileExported: ITreeview) {
    this.showModalDetailDocument(fileExported);
    // this.activateRouter(fileExported.uuidUpload);
  }

  showModalDetailDocument(filesExporteds: ITreeview) {
    // const userInfo = this.authService.getUserInfo();

    const uuidUpload: string = filesExporteds.uuidUpload;
    const configs: any = { footer: false, header: false };
    this.modal.create({
      nzTitle: `<h4>Detalhes da exportação</h4>`,
      nzWidth: window.innerWidth * 0.6,
      nzContent: ModalDialogComponent,
      nzComponentParams: { filesExporteds, uuidUpload, configs },
      nzFooter: [],
    });
    // this.modal.afterAllClose.subscribe(() => {
    //   // this.updateDisplayDocs(this.idDocSelected ? [this.idDocSelected] : []);
    //   this.idDocSelected = null;
    // });
  }

  editFileExport(file) {}

  async filterFilesExporteds() {
    // const usuario = await this.pessoaFisicaService.getPessoaFisicaById2(
    //   assinante.idPessoaFisica,
    // );
    // if (this.filterType.length === 0) {
    //   return this.documentService.notification.info(
    //     'Selecionar Status',
    //     'Selecione algum status para poder aplicar os filtros!',
    //     { nzDuration: 7000 },
    //   );
    // }

    // const files = await this.service.getAllFilesExporteds();
    // const mappedFiles = files.data.map((file: ITreeview) => {
    //   return {
    //     ...file,
    //     // usCriacao: 2,
    //     checked: false,
    //   };
    // });

    // this.setConfigExportTable();
    // this.displayDataFileExport = mappedFiles;

    this.getFilesExportedsPerPeriod(this.fieldsFilesSelected);
    return;
  }

  updateAllChecked() {
    if (this.displayDataFileExport.length != 0) {
      this.checkAllIndeterminate = !this.checkAllIndeterminate;
      this.checkedAll = !this.checkedAll;
      this.displayDataFileExport.forEach((e) => (e.checked = this.checkedAll));
    }
    let map = []; //até aqui
    this.displayDataFileExport.forEach((e) => map.push(e.checked));
  }

  check(file) {
    this.countCheked = this.displayDataFileExport.filter(
      (e) => e.checked
    ).length;
    //   if (
    //     !this.documentList.find((registro) => registro.checked === false) &&
    //     this.checkedAll
    //   ) {
    //     this.checkAllIndeterminate = !this.checkAllIndeterminate;
    //     this.checkedAll = !this.checkedAll;
    //   }
  }

  getDate() {
    this.getDateNow = new Date().toLocaleString();
  }
  setFilterType(type: number) {
    this.filterType = this.documentService.updateFilterType(
      this.filterType,
      type
    );
    if (this.innerWidth <= 748) {
      this.tooltipVisible[`filter${type}`] = true;
      setTimeout(() => {
        this.tooltipVisible[`filter${type}`] = false;
      }, 2000);
    }
  }
  async getDocumentsPerPeriod(
    avulsos = false,
    checkAll = false,
    typePeriod?: PeriodVisualizeDoc,
    skip?: number,
    take?: number
  ) {
    const types = IntervalsTime[Number(this.intervalTimeSelected)];

    typePeriod = IntervalsTimeTranslate[types];

    this.treeLoading = true;

    await this.service
      .getAllDocumentsInInterval(
        typePeriod,
        this.assinantesSelected,
        Number(this.intervalTimeSelected),
        avulsos,
        this.customInterval,
        this.filterType,
        this.fieldsSelected,
        checkAll,
        1,
        0
      )
      .then(async (documents: any) => {
        this.paramsWhere = documents.where;
        if (!this.identifyer) {
          this.pageTotal = documents.data.total;
        }
        await this.formatResultGetDocuments(documents.data.resultado);

        let arrOrders: string[] = [
          `nomeUnidadeGestora`,
          'tipoDoc',
          'dataDocumentoFormated',
          'numeroDoc',
        ];

        await this.docNodesTreeView(this.displayData, arrOrders);
        this.treeLoading = false;
      })
      .catch((err) => {
        this.treeLoading = false;
      });
  }

  async docNodesTreeView(docs: IDocumento[], ordenator: string[]) {
    const result = [];
    const levels = { result };
    docs.forEach(async (doc: IDocumento, index, docs) => {
      ordenator.reduce((r, e) => {
        const title = doc[e];
        if (!r[title]) {
          let value: any;
          if (e !== ordenator[ordenator.length - 1]) {
            value = { title, key: uuidv4(), children: [] };
            r[title] = { result: value.children };
          } else {
            value = [
              {
                title: `Doc ${doc.numeroDoc}`,
                key: uuidv4(),
                children: [
                  {
                    title: doc.numeroDoc,
                    author: doc,
                    key: uuidv4(),
                    isLeaf: true,
                    children: [],
                  },
                ],
              },
            ];

            if (doc.documentoAnexo.length != 0) {
              value[0].children.push({
                title: 'Anexos',
                disableCheckbox: true,
                key: uuidv4(),
                children: [],
              });
              for (const [index, anexo] of doc.documentoAnexo.entries()) {
                value[0].children[value[0].children.length - 1].children.push({
                  title: anexo.nome,
                  key: uuidv4(),
                  disableCheckbox: true,
                  isLeaf: true,
                  author: anexo,
                  children: [],
                });
              }
            }

            r[title] = { result: value.children };
          }

          Array.isArray(value)
            ? r.result.push(...value)
            : r.result.push(...[value]);
        }
        return r[title];
      }, levels);
    });

    this.docNodes = result;
    return;
  }

  async docNodesExport() {
    if (this.docNodes.length === 0) {
      return this.documentService.notification.info(
        'Selecionar Filtro',
        'Selecione algum filtros para exportar!',
        { nzDuration: 7000 }
      );
    }
  }

  async formatResultGetDocuments(documents: IDocumento[], identifyer = false) {
    // this.formLoading = true;
    const docs = documents.filter(async (doc) => {
      const dataLimiteAssinatura = doc.dataLimiteAssinatura?.slice(
        0,
        doc.dataLimiteAssinatura.lastIndexOf('.')
      );

      return (
        doc.signatarios.some(
          (ass) => ass.status === StatusDocumento.Assinado
        ) ||
        doc.statusDocumento === StatusDocumento.Pendente ||
        doc.statusDocumento === StatusDocumento.Confirmado ||
        doc.statusDocumento === StatusDocumento.Desacordo ||
        doc.statusDocumento === StatusDocumento.Cancelado ||
        new Date(dataLimiteAssinatura) < new Date()
      );
    });

    docs.map(async (doc: IDocumento) => {
      await this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios
      );
    });

    const unidadeGestora = await this.authService.getUgSelected();

    this.displayData = await documents.map((itm) => {
      return {
        ...itm,
        dataDocumentoFormated: moment(itm.dataDocumento)
          .utcOffset(0, true)
          .format('DD/MM/YYYY'),
        tipoDoc: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.tipo}`,
        aplicacaoOrigemDetalhe:
          ModulosPublicSoft['Assinatura Digital'] === itm.aplicacaoOrigem
            ? 'Avulso'
            : CodigoModulos[itm.aplicacaoOrigem],
        nomeUnidadeGestora: unidadeGestora.nome,
        expand: false,
      };
    });

    if (this.flagQueryParams === true) {
      this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
    }

    if (identifyer) {
      this.pageTotal = this.displayData.length;
    }

    this.documentList = this.displayData;

    if (this.docsChecked.length > 0) {
      for (const newDoc of this.docsChecked) {
        const index = this.displayData.findIndex(
          (a) => a.idPrivado === newDoc.idPrivado
        );

        if (index !== -1) {
          this.displayData.splice(index, 1);

          this.displayData.push(newDoc);
          this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
        }
      }
    }
    await this.setConfigTreeFilter();
    // this.formLoading = false;
  }
  async setConfigExportTable() {
    this.loading = true;
    await this.service
      .getFileExportedsTableProps('exportacao')
      .then((result) => {
        const tableColumnsFileExports = result.tableColumnsAnexo.map(
          (column) => {
            return {
              ...column,
              compare:
                column.value === 'idPublico' || column.value === 'pastas'
                  ? (a, b) => a[column.value] - b[column.value]
                  : (a, b) => a[column.value].localeCompare(b[column.value]),

              width:
                column.value === 'idPublico' || column.value === 'pastas'
                  ? '5rem'
                  : column.value === 'descricao'
                  ? '12rem'
                  : column.value === 'dtCriacao' ||
                    column.value === 'dtAlteracao' ||
                    column.value === 'dtExpiracao' ||
                    column.value === 'uuidUpload' ||
                    column.value === 'usCriacao'
                  ? '8rem'
                  : null,
            };
          }
        );

        this.tableExportColumns = tableColumnsFileExports;

        this.arrColumnsfilesSelect = result.searchColumns;
        return;
        // Object.assign(this, result);
        // this.documentService.setCompareToTableColumnsExp(
        //   this.displayExportData,
        //   this.displayDataFileExport,
        // );
      });
    this.loading = false;
  }

  async setConfigTreeFilter() {
    this.loading = true;
    await this.documentsPendingService
      .getDocumentTableProps()
      .then((result) => {
        Object.assign(this, result);
        this.documentService.setCompareToTableColumns(
          this.displayData,
          this.tableColumns
        );
      });
    this.loading = false;
  }

  filterDocuments() {
    if (this.filterType.length === 0) {
      return this.documentService.notification.info(
        'Selecionar Status',
        'Selecione algum status para poder aplicar os filtros!',
        { nzDuration: 7000 }
      );
    }
    this.intervalTimeSelected = null;
    this.getDocumentsPerPeriod(this.showDocsAvulsos);
  }
  columnsSelect(tableColumns) {
    this.arrColumnsSelect = this.documentService.getColumnsSelect(tableColumns);
  }

  checkDataExpiracao(data) {
    if (data) {
      const today = new Date(this.authService.getDateSelected()).getTime();
      const selectedDate = new Date(data).getTime();

      if (selectedDate < today) {
        return this.service.notification.warning(
          'Formulário',
          'Data de expiração não pode ser anterior a data de hoje'
        );
      }

      return;
    }

    // if (data) {
    //   const today = this.formatDate23hrs(this.authService.getDateSelected());
    //   const selectedDate = this.formatDate23hrs(
    //     this.authService.getDateSelected(),
    //   );

    //   if (selectedDate < today && !this.formB.get('hash').value) {
    //     this.formB.get('dataExpiracao').setValue(today);

    //     return this.service.notification.warning(
    //       'Formulário',
    //       'Data de expiração não pode ser anterior a data de hoje',
    //     );
    //   }

    //   return;
    // }
  }

  addFiltroPorCampo() {
    if (this.fieldSelected && this.searchInput) {
      if (this.fieldSelected.type === 'range-picker') {
        this.fieldsSelected = this.fieldsSelected.filter((el) => {
          return el.field != 'Data do documento';
        });
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      const id = this.arrColumnsSelect.findIndex(
        (a) => a.value === this.fieldSelected.value
      );

      if (this.fieldsSelected.find((key) => key.index === id)) {
        const arrFiltros = this.fieldsSelected
          .filter((key) => key.index === id)
          .map((a) => ({
            ...a,
            value: a.value.concat(
              `,${
                this.searchInput.text ? this.searchInput.text : this.searchInput
              }`
            ),
            text: a.text.concat(
              `, ${
                this.searchInput.text ? this.searchInput.text : this.searchInput
              }`
            ),
          }));

        // this.fieldsSelected.splice(id, 1);
        const teste = this.fieldsSelected.filter(function (item) {
          return item.index != id;
        });

        teste.push(...arrFiltros);
        this.fieldsSelected = teste;
      } else {
        this.fieldsSelected.push({
          index: id,
          field: this.fieldSelected.text,
          props: this.fieldSelected.value,
          value: this.searchInput.text
            ? this.searchInput.text
            : this.searchInput,
          text: `${this.fieldSelected.text} - ${
            this.searchInput.text ? this.searchInput.text : this.searchInput
          }`,
        });
      }

      this.showComboTags = true;
      this.fieldSelected = '';
      this.searchInput = '';
      this.selectedValues = [];
    }
  }

  addFiltroValuesPorCampo() {
    if (this.fieldFilesSelected && this.searchInput) {
      if (this.fieldFilesSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldFilesSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }

      let usCriacaoText: string;
      if (this.fieldFilesSelected.value === 'usCriacao') {
        usCriacaoText = `${this.searchInput.cpf} - ${this.searchInput.nomPessoa}`;
      }

      this.fieldsFilesSelected.push({
        props: this.fieldFilesSelected.value,
        field: this.fieldFilesSelected.text,
        value:
          this.fieldFilesSelected.value === 'usCriacao'
            ? this.searchInput.usCriacao
            : this.searchInput.text
            ? this.searchInput.text
            : this.searchInput,
        text:
          this.fieldFilesSelected.value === 'usCriacao'
            ? usCriacaoText
            : `${this.fieldFilesSelected.text} - ${
                this.searchInput.text ? this.searchInput.text : this.searchInput
              }`,
      });

      const index = this.arrColumnsfilesSelect.findIndex(
        (a) => a.value === this.fieldFilesSelected.value
      );

      this.arrColumnsfilesSelect.splice(index, 1);

      this.showComboTagsFiles = true;
      this.fieldFilesSelected = '';
      this.searchInput = '';
      this.selectedFilesValues = [];
    }
  }

  async getFilesExportedsPerPeriod(fieldsFilesSelected) {
    // const types = IntervalsTime[Number(this.intervalTimeSelected)];

    // typePeriod = IntervalsTimeTranslate[types];

    this.formLoading = true;

    await this.service
      .getAllFilesExportedsInInterval(fieldsFilesSelected)
      .then(async (filesExporteds: any) => {
        this.paramsWhere = filesExporteds.where;
        this.displayDataFileExport = filesExporteds.data;
        if (filesExporteds.data.length === 0) {
          this.service.notification.warning(
            'Arquivos Exportados',
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        await this.formatResultGetDocuments(filesExporteds.data.resultado);
        this.formLoading = false;
      })
      .catch((err) => {
        this.formLoading = false;
      });
  }
}
//displayDataFileExport
