<ps-complex-form [titleTemplate]="titulo" [tabIndex]="tabIndex" [isAntd]="true"
  (changeTabIndex)="changeTabIndex($event)">
  <ng-template #titulo>
    <h3>{{title}}</h3>
    <div *ngIf="breadCrumps">
      <nz-breadcrumb nzSeparator=">">
        <nz-breadcrumb-item *ngFor="let breadCrump of breadCrumps">{{breadCrump}}</nz-breadcrumb-item>

      </nz-breadcrumb>
    </div>
  </ng-template>
  <div table>
    <nz-spin [nzSpinning]="loading">
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">

          <nz-collapse nzGhost>
            <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <nz-select *ngIf="adm" style="width: 350px" nzShowSearch nzAllowClear
                          [(ngModel)]="assinanteSelected" nzPlaceHolder="Selecione um Atestador"
                          (ngModelChange)="addFiltroPorAssinante()">
                          <nz-option *ngFor="let assinante of listSignatarios" [nzValue]="assinante"
                            [nzLabel]="assinante.text">
                          </nz-option>
                        </nz-select>

                        <nz-avatar (click)="setFilterType(2)" [ngStyle]="{
                            opacity: filterType.includes(2) ? '100%' : '40%'
                          }" [nzSize]="40" nzIcon="field-time" class="m-l-15 cursor-pointer" [ngClass]="
                            filterType.includes(2)
                              ? 'ant-avatar-gold'
                              : 'ant-avatar-dark'
                          " nz-tooltip nzTooltipTitle="Filtrar por pendentes" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(1)" [ngStyle]="{
                            opacity: filterType.includes(1) ? '100%' : '40%'
                          }" [nzSize]="40" nzIcon="form" class="m-l-15 cursor-pointer" [ngClass]="
                          filterType.includes(1)
                            ? 'ant-avatar-blue' 
                            : 'ant-avatar-dark'
                          " nz-tooltip nzTooltipTitle="Filtrar por assinados" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(4)" [ngStyle]="{
                            opacity: filterType.includes(4) ? '100%' : '40%'
                          }" [nzSize]="40" nzIcon="check-circle" class="m-l-15 cursor-pointer" [ngClass]="
                            filterType.includes(4)
                              ? 'ant-avatar-green'
                              : 'ant-avatar-dark'
                          " nz-tooltip nzTooltipTitle="Filtrar por concluídos" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(3)" [ngStyle]="{
                            opacity: filterType.includes(3) ? '100%' : '40%'
                          }" [nzSize]="40" nzIcon="close-circle" class="m-l-10 cursor-pointer" [ngClass]="
                            filterType.includes(3)
                              ? 'ant-avatar-red'
                              : 'ant-avatar-dark'
                          " nz-tooltip nzTooltipTitle="Filtrar por desacordos" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(5)" [ngStyle]="{
                            opacity: filterType.includes(5) ? '100%' : '40%'
                          }" [nzSize]="40" nzIcon="history" class="m-l-10 cursor-pointer" [ngClass]="
                            filterType.includes(5)
                              ? 'ant-avatar-orange'
                              : 'ant-avatar-dark'
                          " nz-tooltip nzTooltipTitle="Filtrar por expirados" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(6)" [ngStyle]="{
                            opacity: filterType.includes(6) ? '100%' : '40%'
                          }" [nzSize]="40" nzIcon="stop" class="ant-avatar-dark m-l-10 cursor-pointer" nz-tooltip
                          nzTooltipTitle="Filtrar por cancelados" nzTooltipPlacement="topRight">
                        </nz-avatar>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div nz-row class="m-l-20">
                <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo" [(ngModel)]="fieldSelected"
                  (ngModelChange)="getOptionsSelect(fieldSelected)">
                  <nz-option *ngFor="let interval of arrColumnsSelect" [nzValue]="interval" [nzLabel]="interval?.text">
                  </nz-option>
                </nz-select>

                <!-- <div class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"> -->
                <nz-input-group *ngIf="fieldSelected && fieldSelected.type === 'input'" class="m-b-10 m-l-20" nz-col
                  nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="prefixTemplate">
                  <input type="text" class="text-disabled" nz-input placeholder="Informe um valor"
                    [(ngModel)]="searchInput" [mask]="getMaskReturned" />
                </nz-input-group>

                <nz-select *ngIf="fieldSelected && fieldSelected.type === 'select'" class="m-b-10 m-l-20" nz-col
                  nzSpan="8" nzXl="8" nzLg="12" nzXs="24" nzPlaceHolder="Selecione o campo" [(ngModel)]="searchInput">
                  <nz-option *ngFor="let result of selectedValues" [nzValue]="result" [nzLabel]="result?.text">
                  </nz-option>
                </nz-select>

                <div *ngIf="fieldSelected && fieldSelected.type === 'range-picker'" nzSpan="8" nzXl="8" nzLg="12"
                  nzXs="24" class="m-b-10 m-l-20">
                  <nz-range-picker nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-range-picker>
                </div>

                <div *ngIf="fieldSelected && fieldSelected.type === 'date-picker'" nzSpan="8" nzXl="12" nzLg="12"
                  nzXs="24" class="m-b-10 m-l-20">
                  <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy" [(ngModel)]="searchInput"></nz-date-picker>
                </div>

                <div nz-row *ngIf="fieldSelected && fieldSelected.type === 'boolean'" class="m-b-10 m-l-20">
                  <div nz-col nzSpan="24">
                    <nz-radio-group class="style-radio" [(ngModel)]="searchInput" nzName="radiogroup">
                      <label nz-radio [nzValue]="'Sim'">Sim</label>
                      <label nz-radio [nzValue]="'Não'">Não</label>
                    </nz-radio-group>
                  </div>
                </div>


                <ng-template #prefixTemplate>
                  <i nz-icon nzType="search" class="opacity-05"></i>
                </ng-template>
                <!-- </div> -->

                <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                  <button nz-button [disabled]="
                      !searchInput
                    " (click)="addFiltroPorCampo()" nzType="primary">
                    <i nz-icon nzType="plus"></i>
                  </button>
                </div>

              </div>
              <div nz-row class="m-t-10 m-l-20">
                <div *ngIf="fieldsSelected.length != 0" nz-col nzSpan="12" nzXl="12" nzLg="12" nzXs="24">
                  <nz-input-group [nzPrefix]="prefixTemplate">
                    <div class="align-self-center">
                      <nz-tag style="margin-bottom: 0" [nzColor]="'blue'"
                        [nzMode]="fieldsValuesTag.notCloseable?'default':'closeable'" (nzOnClose)="
                    removeFiltroPorField(i)
                      " class="filters" nz-tooltip [nzTooltipTitle]="fieldsValuesTag.textDescription"
                        *ngFor="let fieldsValuesTag of fieldsSelected; let i = index;" nzTooltipPlacement="bottom">

                        {{ fieldsValuesTag.text }}
                      </nz-tag>
                    </div>
                  </nz-input-group>
                </div>
                <div nz-col class="m-t-15 p-l-20" [nzOffset]="fieldsSelected.length !=0?openModal?7:8:openModal?19:20"
                  nzSpan="3" nzXl="3" nzLg="3" nzXs="24">
                  <button nzSize="small" nz-button nzType="primary" (click)="filterDocuments()" nz-tooltip
                    nzTooltipTitle='Aplicar Filtro'>
                    <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
                  </button>
                </div>

              </div>

            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <div class="row is-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">

                        <nz-select *ngIf="
                          adm
                        " style="width: 350px" nzShowSearch nzAllowClear [(ngModel)]="assinanteSelected"
                          nzPlaceHolder="Selecione um Atestador" (ngModelChange)="addFiltroPorAssinante()">
                          <nz-option *ngFor="let assinante of listSignatarios" [nzValue]="assinante"
                            [nzLabel]="assinante.text">
                          </nz-option>
                        </nz-select>


                        <nz-avatar (click)="setFilterType(2)" [ngStyle]="{
                          opacity: filterType.includes(2) ? '100%' : '40%'
                        }" [nzSize]="40" nzIcon="field-time" class="m-l-15 cursor-pointer" [ngClass]="
                          filterType.includes(2)
                            ? 'ant-avatar-gold'
                            : 'ant-avatar-dark'
                        " nz-tooltip nzTooltipTitle="Filtrar por pendentes"
                          [nzTooltipVisible]="tooltipVisible.filterType2" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(1)" [ngStyle]="{
                          opacity: filterType.includes(1) ? '100%' : '40%'
                        }" [nzSize]="40" nzIcon="form" class="m-l-15 cursor-pointer" [ngClass]="
                        filterType.includes(1)
                          ? 'ant-avatar-blue' 
                          : 'ant-avatar-dark' 
                        " nz-tooltip nzTooltipTitle="Filtrar por assinados"
                          [nzTooltipVisible]="tooltipVisible.filterType1" nzTooltipPlacement="topRight"></nz-avatar>

                        <nz-avatar (click)="setFilterType(4)" [ngStyle]="{
                          opacity: filterType.includes(4) ? '100%' : '40%'
                        }" [nzSize]="40" nzIcon="check-circle" class="m-l-15 cursor-pointer" [ngClass]="
                          filterType.includes(4)
                            ? 'ant-avatar-green'
                            : 'ant-avatar-dark'
                        " nz-tooltip nzTooltipTitle="Filtrar por concluídos"
                          [nzTooltipVisible]="tooltipVisible.filterType4" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(3)" [ngStyle]="{
                          opacity: filterType.includes(3) ? '100%' : '40%'
                        }" [nzSize]="40" nzIcon="close-circle" class="m-l-10 cursor-pointer" [ngClass]="
                          filterType.includes(3)
                            ? 'ant-avatar-red'
                            : 'ant-avatar-dark'
                        " nz-tooltip nzTooltipTitle="Filtrar por desacordos"
                          [nzTooltipVisible]="tooltipVisible.filterType3" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(5)" [ngStyle]="{
                          opacity: filterType.includes(5) ? '100%' : '40%'
                        }" [nzSize]="40" nzIcon="history" class="m-l-10 cursor-pointer" [ngClass]="
                          filterType.includes(5)
                            ? 'ant-avatar-orange'
                            : 'ant-avatar-dark'
                        " nz-tooltip nzTooltipTitle="Filtrar por expirados"
                          [nzTooltipVisible]="tooltipVisible.filterType5" nzTooltipPlacement="topRight">
                        </nz-avatar>

                        <nz-avatar (click)="setFilterType(6)" [ngStyle]="{
                          opacity: filterType.includes(6) ? '100%' : '40%'
                        }" [nzSize]="40" nzIcon="stop" class="ant-avatar-dark m-l-10 cursor-pointer" nz-tooltip
                          nzTooltipTitle="Filtrar por cancelados" [nzTooltipVisible]="tooltipVisible.filterType6"
                          nzTooltipPlacement="topRight">
                        </nz-avatar>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div nz-row class="m-l-20">
                <nz-select style="width: 350px" nzPlaceHolder="Selecione o campo" [(ngModel)]="fieldSelected"
                  (ngModelChange)="getOptionsSelect(fieldSelected)">
                  <nz-option *ngFor="let interval of documentService.getTableColumns(tableColumns)" [nzValue]="interval"
                    [nzLabel]="interval?.text"></nz-option>
                </nz-select>

                <!-- <div class="m-b-10 m-l-20" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24"> -->
                <nz-input-group *ngIf="fieldSelected && fieldSelected.type === 'input'" class="m-b-10 m-l-20" nz-col
                  nzSpan="8" nzXl="8" nzLg="12" nzXs="24" [nzPrefix]="prefixTemplate">
                  <input type="text" class="text-disabled" nz-input placeholder="Informe um valor"
                    [(ngModel)]="searchInput" [mask]="getMaskReturned" />
                </nz-input-group>

                <nz-select *ngIf="fieldSelected && fieldSelected.type === 'select'" class="m-b-10 m-l-20" nz-col
                  nzSpan="8" nzXl="8" nzLg="12" nzXs="24" nzPlaceHolder="Selecione o campo" [(ngModel)]="searchInput">
                  <nz-option *ngFor="let result of selectedValues" [nzValue]="result" [nzLabel]="result?.text">
                  </nz-option>
                </nz-select>

                <div *ngIf="fieldSelected && fieldSelected.type === 'date-picker'" nzSpan="8" nzXl="8" nzLg="12"
                  nzXs="24" class="m-b-10 m-l-20">
                  <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy HH:mm:ss" [(ngModel)]="searchInput"></nz-date-picker>
                </div>

                <div nz-row *ngIf="fieldSelected && fieldSelected.type === 'boolean'" class="m-b-10 m-l-20">
                  <div nz-col nzSpan="24">
                    <nz-radio-group class="style-radio" [(ngModel)]="searchInput" nzName="radiogroup">
                      <label nz-radio [nzValue]="'Sim'">Sim</label>
                      <label nz-radio [nzValue]="'Não'">Não</label>
                    </nz-radio-group>
                  </div>
                </div>

                <ng-template #prefixTemplate>
                  <i nz-icon nzType="search" class="opacity-05"></i>
                </ng-template>
                <!-- </div> -->

                <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
                  <button nz-button [disabled]="
                      !searchInput
                    " (click)="addFiltroPorCampo()" nzType="primary">
                    <i nz-icon nzType="plus"></i>
                  </button>
                </div>

              </div>

              <div *ngIf="showComboTags" class="m-t-10 m-l-20" nz-col nzSpan="12" nzXl="14" nzLg="12" nzXs="24">
                <nz-input-group [nzPrefix]="prefixTemplate">
                  <div class="align-self-center">
                    <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'" (nzOnClose)="
                    removeFiltroPorField(fieldsValuesTag.field)
                      " class="filters" *ngFor="let fieldsValuesTag of fieldsSelected">
                      {{ fieldsValuesTag.text }}
                    </nz-tag>
                  </div>
                </nz-input-group>
              </div>

              <button nzSize="small" class="m-t-10 m-l-20" nz-button nzType="primary" (click)="filterDocuments()">
                <!--tinha nztooltip-->

                <i nz-icon nzType="filter" nzTheme="outline"></i>Aplicar Filtro
              </button>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <nz-table class="m-b-20" nzSize="small" [nzLoading]="loading" #documentListTable [nzShowPagination]="false"
        [nzScroll]="{ y: '380px' }" style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <tr>
            <th style="background: #edf1fd" [nzLeft]="documentService.getWidthContent() > 1024" nzWidth="40px"></th>
            <th style="background: #edf1fd" nzWidth="60px" [nzLeft]="documentService.getWidthContent() > 1024">
              <label nz-checkbox [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"></label>
            </th>
            <ng-container *ngIf="documentService.getColumnsStatus(tableColumns)">
              <th nzWidth="150px" *ngIf="checkListConsulta.status" [nzSortFn]="
                documentService.getColumnsStatus(tableColumns).compare
              ">
                {{ documentService.getColumnsStatus(tableColumns).text }}
              </th>
            </ng-container>
            <ng-container *ngIf="documentService.getColumnsRatificado(tableColumns)">
              <th nzWidth="150px" *ngIf="checkListConsulta.ratificado"
                [nzSortFn]="documentService.getColumnsRatificado(tableColumns).compare">
                {{ documentService.getColumnsRatificado(tableColumns).text }}
              </th>
            </ng-container>
            <ng-container *ngFor="
              let column of documentService.getTableColumns(tableColumns);
              let i = index
            ">
              <th nzWidth="150px" *ngIf="checkListConsulta[column.value]" [nzSortFn]="column.compare">
                {{ column.text }}
              </th>
            </ng-container>
            <ng-container *ngIf="documentService.getColumnsChancela(tableColumns)">
              <th nzWidth="150px" *ngIf="checkListConsulta.chancela" [nzSortFn]="
                documentService.getColumnsChancela(tableColumns).compare
              ">
                {{ documentService.getColumnsChancela(tableColumns).text }}
              </th>
            </ng-container>
            <th *ngIf="checkListConsulta.acoes" style="background: #edf1fd" nzWidth="110px"
              [nzRight]="documentService.getWidthContent() > 1024">
              Ações
            </th>
            <th style="background: #edf1fd" nzWidth="40px" [nzRight]="documentService.getWidthContent() > 1024">
              <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click"
                [nzDropdownMenu]="colunasConsulta">
                <i nz-icon nzType="setting"></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="documentListTable.data" let-indexArr="index">
            <tr style="cursor: pointer" (dblclick)="doubleClick(item, indexArr)" nz-tooltip
              [nzTooltipTitle]="innerWidth<768?null:'Clique duas vezes para visualizar mais detalhes'"
              (click)="toggleTooltipVisible(item)" nzTooltipPlacement="topRight"
              [nzTooltipVisible]="item.tooltipVisibleTableRow">
              <td [nzLeft]="documentService.getWidthContent() > 1024" [(nzExpand)]="item.expand"
                (click)="modifyDocument(item, item.expand, indexArr)">
              </td>
              <td [nzLeft]="documentService.getWidthContent() > 1024" style="padding: 6px;">
                <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="check(item, !item.checked)"></label>
                <a nz-dropdown nzTrigger="click" nz-tooltip (click)="toggleTooltipVisibleEye(item)"
                  [nzTooltipVisible]="item.tooltipVisibleEye"
                  [nzTooltipTitle]="innerWidth<768?null:'Clique para visualizar a descrição do documento'"
                  nzTooltipPlacement="top" [nzDropdownMenu]="menu">
                  <i nz-icon nzType="eye" theme="outline"></i>
                </a>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu>
                    <li nz-menu-item>Descrição do documento: {{documentService.visualizeDocDescription(item)}}</li>
                  </ul>
                </nz-dropdown-menu>
              </td>
              <ng-container *ngIf="documentService.getColumnsStatus(tableColumns)">
                <td *ngIf="checkListConsulta.status">
                  <nz-tag class="m-b-0" [nzColor]="getCorDocumento(item)">
                    {{ getNomeStatusDocumento(item) }}
                    {{ getQuantidadeDeAssinantesProgressBar(item) }}</nz-tag>
                  <div>
                    <nz-progress *ngIf="mostarProgressBarAndClock(item)" [nzPercent]="documentService.progressBar(item)"
                      [nzStrokeColor]="{ '0%': '#108ee9', '100%': '#87d068' }"
                      [nzFormat]="documentService.formatProgress" nzSize="small"></nz-progress>
                  </div>
                </td>
              </ng-container>
              <ng-container *ngIf="documentService.getColumnsRatificado(tableColumns)">
                <td *ngIf="checkListConsulta.ratificado">
                  <nz-avatar style="background-color:#ffffff; opacity: 100%;" [nzSize]="40"
                    [nzIcon]="item.ratificado === true ? 'trademark' : null" class="m-l-10 cursor-pointer" [ngClass]="
                  showColorRatificado(item.signatarios) === true
                    ? 'ant-avatar-gold'
                    : 'ant-avatar-green'
                "></nz-avatar>
                </td>
              </ng-container>
              <ng-container *ngFor="
                let column of documentService.getTableColumns(tableColumns)
              ">
                <td (click)="previewDocVinculado(item)" *ngIf="
                checkListConsulta[column.value] &&
              column.value === 'numeroDocOriginal'
            " nz-tooltip
                  [nzTooltipTitle]="item.numeroDocOriginal||item.hashDocOriginal?'Clique para visualizar o documento vinculado':null"
                  nzTooltipPlacement="bottom">
                  <a>{{
                    documentService.prepareColumnValue(item, column) }}</a>
                </td>
                <td *ngIf="checkListConsulta[column.value] &&
                column.value !== 'dataLimiteAssinatura' && column.value !== 'numeroDocOriginal'">
                  {{ documentService.prepareColumnValue(item, column) }}
                </td>
                <td *ngIf="
                checkListConsulta[column.value] &&
                column.value === 'dataLimiteAssinatura'
              ">
                  <i *ngIf="mostarProgressBarAndClock(item)" nz-icon nzType="clock-circle"
                    class="ant-scroll-number-custom-component p-l-10" [style]="
                  documentService.getColorByDataLimiteAssinatura(
                    item.dataLimiteAssinatura
                  )
                "></i>
                  {{ documentService.getDateFormatted(item.dataLimiteAssinatura) }}
                </td>
              </ng-container>
              <ng-container *ngIf="documentService.getColumnsChancela(tableColumns)">
                <td *ngIf="checkListConsulta.chancela">
                  <nz-avatar style="background-color:#ffffff; opacity: 100%;" [nzSize]="40"
                    [nzIcon]="item.chancela === true ? 'trademark' : 'close-circle'" class="m-l-10 cursor-pointer"
                    [ngClass]="
                  item.chancela === true
                    ? 'ant-avatar-green'
                    : 'ant-avatar-red'
                "></nz-avatar>
                </td>
              </ng-container>

              <td *ngIf="checkListConsulta.acoes" [nzRight]="documentService.getWidthContent() > 1024">
                <div class="d-flex">
                  <div (click)="viewDoc(item, type)" class="font-size-25 p-l-20 p-r-20" nz-tooltip
                    nzTooltipTitle="Clique para visualizar o documento" nzTooltipPlacement="left">
                    <i nz-icon [nzType]="'file-pdf'" theme="twotone" [twoToneColor]="red"></i>
                  </div>
                  <div>
                    <a class="text-dark font-size-20" nz-dropdown [nzTrigger]="'click'" [nzPlacement]="'bottomRight'"
                      [nzDropdownMenu]="latestUploadDropDown">
                      <i nz-icon nzType="ellipsis" theme="outline"></i>
                    </a>
                    <nz-dropdown-menu #latestUploadDropDown="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item (click)="downloadDoc(item, 'DownloadOriginal')">
                          <i nz-icon nzType="download" theme="outline"></i>
                          <span class="m-l-5">Baixar documento original</span>
                        </li>
                        <li *ngIf="item.numProcesso" nz-menu-item (click)="documentService.visualizeProcess(item)">
                          <i nz-icon nzType="eye" nzTheme="outline"></i>
                          <span class="m-l-5">Visualizar processo</span>
                        </li>
                        <li *ngIf="
                      item.statusDocumento === statusDoc.Confirmado
                      " nz-menu-item (click)="downloadDoc(item, 'DownloadAssinado')">
                          <i nz-icon nzType="download" theme="outline"></i>
                          <span class="m-l-5">Baixar documento assinado</span>
                        </li>
                        <li *ngIf="
                        (adm || item.usCriacao === authService.getIdUser()) && isSigned(item) === false &&
                      item.statusDocumento === statusDoc.Pendente
                    " nz-menu-item (click)="editDoc(item)">
                          <i nz-icon nzType="edit" theme="outline"></i>
                          <span class="m-l-5">Editar</span>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </div>
                </div>
              </td>
              <td></td>
            </tr>

            <tr [nzExpand]="item.expand">
              <nz-spin *ngIf="item.expand" [nzSpinning]="loadingSignatarios" [nzSize]="'middle'">
                <nz-table #innerTable [nzData]="item.signatarios" nzSize="middle" [nzShowPagination]="false">
                  <thead>
                    <tr>
                      <th class="p-l-15">Data de Assinatura</th>
                      <th *ngIf="item.ratificado">Data da Ratificação</th>
                      <th>Nome</th>
                      <th *ngIf="documentService.getColumnsStatus(tableColumns)">
                        Status
                      </th>
                      <th>Ações</th>
                    </tr>
                  </thead>
        <tbody>
          <tr *ngFor="let data of innerTable.data">
            <td>
              {{ data?.dataAssinatura | date: 'dd/MM/yyyy HH:mm:ss' }}
            </td>
            <td *ngIf="item.ratificado">
              {{ data?.dataRatificacao | date: 'dd/MM/yyyy HH:mm:ss' }}
            </td>
            <td>{{ data?.nome || data?.nomeRepresentante }}</td>
            <td *ngIf="documentService.getColumnsStatus(tableColumns)">
              <nz-tag class="m-b-0" [nzColor]="
                                          this.documentService.themeColors[
                                            colorStatusDoc[data?.status]
                                          ]
                                        ">
                {{ statusDocLabel[data?.status] }}</nz-tag>
            </td>
            <td>
              <button *ngIf="data?.status === statusDoc.Pendente" nzSize="small" nz-button nzType="primary"
                (click)="notifySigner(data?.idPessoaFisica, item.idPrivado)" nz-tooltip
                [nzTooltipTitle]="getEmail(data?.idPessoaFisica, indexArr)" nzTooltipPlacement="topRight">
                <i nz-icon nzType="mail" nzTheme="outline"></i>Notificar
              </button>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </nz-spin>
    </tr>
    </ng-template>
    </tbody>
    </nz-table>
    <nz-pagination style="text-align: end" [nzSize]="'small'" [nzPageIndex]="1" [nzTotal]="pageTotal"
      (nzPageIndexChange)="pageChange($event)"></nz-pagination>
    <div class="shadow-default p-b-1" nz-row style="background: rgba(63, 135, 245, 0.1); align-items: center">
      <div nz-col nzSpan="12" nzMd="12" nzXs="24" class="text-left text-xs-center p-l-10 p-b-5">
        Documentos selecionados:
        <b> {{ checkedAll ?pageTotal:dataDocumentsUploadCheckeds().length }} </b>
      </div>

      <div nz-col nzSpan="12" nzMd="12" nzXs="24" class="text-right text-xs-center p-r-10 p-b-5">
        Documentos listados: <b> {{ pageTotal||0 }} </b>
      </div>
    </div>
    </nz-spin>
    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>

  <div body>
    <nz-spin [nzSpinning]="loading">
      <form nz-form [formGroup]="formB" nzLayout="vertical">
        <H4>Informações básicas</H4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <nz-spin [nzSpinning]="loadingTipoDoc">
          <div nz-row>
            <div nz-col nzSpan="5" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label nzRequired>Código</nz-form-label>
                <nz-form-control [nzErrorTip]="codTipoDocTpl">
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonTpDoc">
                    <input type="number" nz-input placeholder="Digite o código do tipo de documento"
                      formControlName="idTipoDocumento" (ngModelChange)="searchTipoDoc($event)" />
                  </nz-input-group>
                  <ng-template #suffixIconButtonTpDoc>
                    <button *ngIf="formB.value.idTipoDocumento" (click)="limparEntidadeTipoDoc()" nz-button
                      type="button" nzType="text" nzSearch>
                      <i nz-icon nzType="close-circle"></i>
                    </button>

                    <button (click)="showModalTipoDoc()" disabled nz-button type="button" nzType="primary" nzSearch>
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>

                  <ng-template #codTipoDocTpl let-control>
                    <ng-container *ngIf="control.hasError('codTipoDocumento')">
                      Valor inválido para Tipo de documento!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('required')">
                      Por favor digite um tipo de documento!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label nzRequired>Tipo de documento</nz-form-label>
                <nz-form-control nzHasFeedback>
                  <input nz-input class="text-disabled" formControlName="tipo" placeholder="Tipo de documento"
                    maxlength="150" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="11" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label nzRequired>Número do documento</nz-form-label>
                <nz-form-control>
                  <input nz-input class="text-disabled" formControlName="numeroDoc" placeholder="Número do documento"
                    maxlength="150" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="11" nzXl="6" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label nzRequired>Data de expiração</nz-form-label>
                <nz-form-control>
                  <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy HH:mm:ss" formControlName="dataLimiteAssinatura"
                    nzPlaceHolder="Data de expiração" (ngModelChange)="checkDataExpiracao($event)"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="24">
              <nz-form-item>
                <nz-form-label nzRequired>Descrição do documento</nz-form-label>
                <nz-form-control nzHasFeedback>
                  <textarea nz-input rows="3" formControlName="historicoDoc"
                    placeholder="Descrição do documento"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="11" nzXl="6" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label>Número do processo</nz-form-label>
                <nz-form-control>
                  <input nz-input class="text-disabled" formControlName="numProcesso" placeholder="Número do processo"
                    maxlength="150" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="4" nzXl="4" nzLg="10" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label>Código vinculação</nz-form-label>
                <nz-form-control [nzErrorTip]="codTipoDocTplAnexo">
                  <nz-input-group nzSearch [nzAddOnAfter]="vinculacaoTemplate">
                    <input (click)="showModalDocAvulso()" (keydown)="showModalDocAvulso()" nz-input
                      placeholder="Código doc vinculado" formControlName="idDocVinculado" />
                  </nz-input-group>
                  <ng-template #vinculacaoTemplate>
                    <button *ngIf="formB.value.idDocVinculado" (click)="limparDocAvulso()" nz-button type="button"
                      nzType="text" nzSearch>
                      <i nz-icon nzType="close-circle"></i>
                    </button>

                    <button (click)="showModalDocAvulso()" nz-button type="button" nzType="primary" nzSearch>
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>

                  <ng-template #codTipoDocTplAnexo let-control>
                    <ng-container *ngIf="control.hasError('idTipoDocumentoAnexo')">
                      Valor inválido para Tipo de documento!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('required')">
                      Por favor digite um tipo de documento!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label>Número documento vinculado</nz-form-label>
                <nz-form-control nzHasFeedback>
                  <input nz-input class="text-disabled" formControlName="numeroDocOriginal"
                    placeholder="Número documento vinculado" maxlength="150" />
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="11" nzXl="6" nzLg="12" nzXs="24" class="m-t-40">
              <nz-form-item>
                <label nz-checkbox formControlName="chancela">Adicionar Chancela no Documento</label>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="5" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label>Código setor</nz-form-label>
                <nz-form-control [nzErrorTip]="codTipoDocTpl">
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonSetor">
                    <input type="number" nz-input placeholder="Digite o código do setor"
                      formControlName="idPublicoSetor" (ngModelChange)="seachSetor($event)" />
                  </nz-input-group>
                  <ng-template #suffixIconButtonSetor>
                    <button *ngIf="formB.value.idPublicoSetor" (click)="limparEntidadeSetor()" nz-button type="button"
                      nzType="text" nzSearch>
                      <i nz-icon nzType="close-circle"></i>
                    </button>

                    <button (click)="showModalSetor()" nz-button type="button" nzType="primary" nzSearch>
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>

                  <ng-template #codTipoDocTpl let-control>
                    <ng-container *ngIf="control.hasError('codTipoDocumento')">
                      Valor inválido para Tipo de documento!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('required')">
                      Por favor digite um tipo de documento!
                    </ng-container>
                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" class="p-r-15">
              <nz-form-item>
                <nz-form-label>Descrição do setor</nz-form-label>
                <nz-form-control nzHasFeedback>
                  <input nz-input class="text-disabled" formControlName="descricaoSetor"
                    placeholder="Descrição do setor" maxlength="150" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <h4>Informações Adicionais</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <nz-spin [nzSpinning]="loadingTipoDoc">
            <div nz-row [nzGutter]="16">

              <!-- número licitação -->
              <div nz-col nzSpan="6" nzXl="6" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Número da Licitação</nz-form-label>
                  <nz-form-control>
                    <input nz-input class="text-disabled" formControlName="numeroLicitacao"
                      placeholder="Número da Licitação" maxlength="255" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- Modalidade licitação -->
              <div nz-col nzSpan="6" nzXl="6" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Modalidade</nz-form-label>
                  <nz-form-control>
                    <nz-select nz-input formControlName="modalidadeLicitacao" style="width: 100%"
                      nzPlaceHolder="Selecione a Modalidade Licitação" [(ngModel)]="tipoModalidadeLicitacaoValue"
                      (ngModelChange)="getOptionsSelect(tipoModalidadeLicitacaoValue)">
                      <nz-option *ngFor="let modalidadeLicitacao of tiposModalidadesLicitacaoArrSelect"
                        [nzValue]="modalidadeLicitacao.value" [nzLabel]="modalidadeLicitacao.text">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="6" nzXl="6" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Número do Programa Institucional</nz-form-label>
                  <nz-form-control>
                    <input nz-input class="text-disabled" formControlName="numeroProgramaInstitucional"
                      placeholder="Número do Programa Institucional" maxlength="100" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- Número obra -->
              <div nz-col nzSpan="6" nzXl="6" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Número da Obra</nz-form-label>
                  <nz-form-control>
                    <input nz-input class="text-disabled" formControlName="numeroObra" placeholder="Número da Obra"
                      maxlength="100" />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <!-- Número Contrato -->
              <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Número do Contrato</nz-form-label>
                  <nz-form-control>
                    <input nz-input class="text-disabled" formControlName="numeroContrato"
                      placeholder="Número do Contrato" maxlength="100" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <!-- Número Convênio -->
              <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Número do Convênio</nz-form-label>
                  <nz-form-control>
                    <input nz-input class="text-disabled" formControlName="numeroConvenio"
                      placeholder="Número do Convênio" maxlength="100" />
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Número do Evento</nz-form-label>
                  <nz-form-control>
                    <input nz-input class="text-disabled" formControlName="numeroEvento" placeholder="Número do Evento"
                      maxlength="100" />
                  </nz-form-control>
                </nz-form-item>
              </div>


              <!-- Número Programa Institucional -->
              <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Valor do documento</nz-form-label>
                  <nz-form-control>
                    <nz-input-group nzPrefix="R$">
                      <input nz-input class="text-disabled" type="text" [ngModel]="valorDocFormValue" mask="separator.2"
                        thousandSeparator="." decimalMarker="," [dropSpecialCharacters]="false"
                        formControlName="valorDoc" placeholder="0,00" maxlength="50" />
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="24" nzXl="24" nzLg="24" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Informações Adicionais</nz-form-label>
                  <nz-form-control>

                    <textarea nz-input rows="2" formControlName="informacoesAdicionais"
                      placeholder="Informações Adicionais" maxlength="255"></textarea>
                    <!-- <input nz-input class="text-disabled" formControlName="informacoesAdicionais"
                      placeholder="Informações Adicionais" maxlength="255" /> -->
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>CPF do Favorecido</nz-form-label>
                  <nz-form-control [nzErrorTip]="cpfIdPessoaFavorecido">
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonIdPessoaFavorecido">
                      <input (click)="showModalFavorecido()" nz-input placeholder="Digite um cpf"
                        formControlName="cpfPessoaFavorecido" (keydown)="showModalFavorecido()"
                        [mask]="'000.000.000-00'" [dropSpecialCharacters]="true" />
                    </nz-input-group>
                    <ng-template #suffixIconButtonIdPessoaFavorecido>
                      <button (click)="limparFavorecido()" *ngIf="this.formB.value.cpfPessoaFavorecido" nz-button
                        type="button" nzType="text" nzSearch>
                        <i nz-icon nzType="close-circle"></i>
                      </button>

                      <button (click)="showModalFavorecido()" nz-button type="button" nzType="primary" nzSearch>
                        <i nz-icon nzType="search"></i>
                      </button>
                    </ng-template>

                    <ng-template #cpfIdPessoaFavorecido let-control>
                      <ng-container *ngIf="control.hasError('cpfPessoaFavorecido')">
                        Valor inválido para CPF!
                      </ng-container>

                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div (click)="showModalFavorecido()" nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24">
                <nz-form-item>
                  <nz-form-label>Nome do Favorecido</nz-form-label>
                  <nz-form-control>
                    <input nz-input formControlName="nomeFavorecido" placeholder="Nome do Favorecido" maxlength="100" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-spin>
        </nz-spin>

        <h4 class="m-t-15">Cadastro de Atestador</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>

        <div nz-row class="align-items-center">
          <nz-spin nz-col nzSpan="20" nzXl="20" nzXs="24" [nzSpinning]="loadingPessoaFisica">
            <div nz-row>
              <div nz-col nzSpan="24" class="m-b-15">
                <nz-radio-group formControlName="typePeopleSelected" nzName="radiogroup">
                  <label nz-radio [nzValue]="0">Pessoa Física</label>
                  <label nz-radio [nzValue]="1">Pessoa Jurídica</label>
                </nz-radio-group>
              </div>

              <div nz-col nzSpan="6" nzXl="6" nzLg="8" nzXs="24" class="p-r-15">
                <nz-form-item *ngIf="this.formB.value.typePeopleSelected === 0" class="m-b-10">
                  <nz-form-label nzRequired>CPF</nz-form-label>
                  <nz-form-control [nzErrorTip]="cpfTpl">
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                      <input nz-input placeholder="Digite um cpf" formControlName="cpf" [mask]="'000.000.000-00'"
                        [dropSpecialCharacters]="true" (ngModelChange)="searchPessoaFisica($event)" />
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                      <button *ngIf="formB.value.entidadePessoaFisica" (click)="limparAssinante()" nz-button
                        type="button" nzType="text" nzSearch>
                        <i nz-icon nzType="close-circle"></i>
                      </button>

                      <button *ngIf="!this.formB.controls.cpf.disabled" (click)="showModalPF()" nz-button type="button"
                        nzType="primary" nzSearch>
                        <i nz-icon nzType="search"></i>
                      </button>
                    </ng-template>

                    <ng-template #cpfTpl let-control>
                      <ng-container *ngIf="control.hasError('cpf')">
                        Valor inválido para CPF!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('required')">
                        Por favor digite seu CPF!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="this.formB.value.typePeopleSelected === 1" class="m-b-10">
                  <nz-form-label nzRequired>CNPJ</nz-form-label>
                  <nz-form-control [nzErrorTip]="cnpjTpl">
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                      <input nz-input placeholder="Digite um cnpj" formControlName="cnpj" [mask]="'00.000.000/0000-00'"
                        [dropSpecialCharacters]="true" (ngModelChange)="searchPessoaJuridica($event)" />
                    </nz-input-group>
                    <ng-template #suffixIconButton>
                      <button *ngIf="formB.value.entidadePessoaJuridica" (click)="limparAssinante()" nz-button
                        type="button" nzType="text" nzSearch>
                        <i nz-icon nzType="close-circle"></i>
                      </button>

                      <button *ngIf="!this.formB.controls.cnpj.disabled" (click)="showModalPJ()" nz-button type="button"
                        nzType="primary" nzSearch>
                        <i nz-icon nzType="search"></i>
                      </button>
                    </ng-template>

                    <ng-template #cnpjTpl let-control>
                      <ng-container *ngIf="control.hasError('cnpj')">
                        Valor inválido para CNPJ!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('required')">
                        Por favor digite seu CNPJ!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="18" nzXl="18" nzLg="16" nzXs="24" class="p-r-15">
                <nz-form-item *ngIf="this.formB.value.typePeopleSelected === 0" class="m-b-10">
                  <nz-form-label nzRequired>Nome</nz-form-label>
                  <nz-form-control nzHasFeedback [nzErrorTip]="nomeTpl">
                    <input nz-input class="text-disabled" formControlName="nome" placeholder="Digite um nome"
                      maxlength="150" />
                    <ng-template #nomeTpl let-control>
                      <ng-container *ngIf="control.hasError('nome')">
                        Valor inválido para nome!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="this.formB.value.typePeopleSelected === 1" class="m-b-10">
                  <nz-form-label nzRequired>Razão social</nz-form-label>
                  <nz-form-control nzHasFeedback [nzErrorTip]="razaoSocialTpl">
                    <input nz-input class="text-disabled" formControlName="razaoSocial"
                      placeholder="Digite uma razão social" maxlength="150" />
                    <ng-template #razaoSocialTpl let-control>
                      <ng-container *ngIf="control.hasError('razaoSocial')">
                        Valor inválido para Razão social!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="11" nzSm="11" nzXs="24" class="p-r-15">
                <nz-form-item *ngIf="this.formB.value.typePeopleSelected === 0" class="m-b-20">
                  <nz-form-label nzRequired>Email</nz-form-label>
                  <nz-form-control nzHasFeedback [nzErrorTip]="emailTpl">
                    <input nz-input class="text-disabled" formControlName="email" placeholder="Digite um email"
                      maxlength="100" />
                    <ng-template #emailTpl let-control>
                      <ng-container *ngIf="control.hasError('email')">
                        Valor inválido para email!
                      </ng-container>
                      <ng-container *ngIf="control.hasError('required')">
                        Por favor digite seu email!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>

                <nz-form-item *ngIf="this.formB.value.typePeopleSelected === 1" class="m-b-20">
                  <nz-form-label nzRequired>Cidade</nz-form-label>
                  <nz-form-control nzHasFeedback [nzErrorTip]="nomCidadeTpl">
                    <input nz-input class="text-disabled" formControlName="nomCidade" placeholder="Digite uma cidade"
                      maxlength="100" />
                    <ng-template #nomCidadeTpl let-control>
                      <ng-container *ngIf="control.hasError('nomCidade')">
                        Valor inválido para email!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="this.formB.value.typePeopleSelected === 0" nz-col nzSpan="7" nzSm="7" nzXs="24"
                class="p-r-15">
                <nz-form-item class="m-b-20">
                  <nz-form-label>Cargo</nz-form-label>
                  <nz-form-control nzHasFeedback [nzErrorTip]="cargoTpl">
                    <input nz-input class="text-disabled" formControlName="cargo" placeholder="Digite um cargo"
                      maxlength="100" />
                    <ng-template #cargoTpl let-control>
                      <ng-container *ngIf="control.hasError('cargo')">
                        Valor inválido para cargo!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="this.formB.value.typePeopleSelected === 1" nz-col nzSpan="7" nzSm="7" nzXs="24"
                class="p-r-15">
                <nz-form-item class="m-b-20">
                  <nz-form-label nzRequired>Atestador</nz-form-label>
                  <nz-form-control nzHasFeedback [nzErrorTip]="signatarioTpl">
                    <nz-select style="width: 400px;" nzShowSearch nzAllowClear formControlName="signatario"
                      nzPlaceHolder="Selecione um Atestador" (ngModelChange)="addFiltroPorAssinante()">
                      <nz-option *ngFor="let assinante of allSignatarios" [nzValue]="assinante" [nzLabel]="assinante">
                      </nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div *ngIf="this.formB.value.typePeopleSelected === 0" nz-col nzSpan="6" nzSm="6" nzXs="24"
                class="p-r-15">
                <nz-form-item class="m-b-20">
                  <nz-form-label>Matrícula</nz-form-label>
                  <nz-form-control nzHasFeedback [nzErrorTip]="matriculaTpl">
                    <input nz-input class="text-disabled" formControlName="matricula" placeholder="Digite uma matrícula"
                      maxlength="100" />
                    <ng-template #matriculaTpl let-control>
                      <ng-container *ngIf="control.hasError('matricula')">
                        Valor inválido para matrícula!
                      </ng-container>
                    </ng-template>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </nz-spin>

          <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="text-right d-block">
            <button nz-button [disabled]="!formB.value.entidadePessoaFisica &&
            !formB.value.signatario" (click)="limparAssinante()" nzType="primary" nzDanger>
              <i nz-icon nzType="clear"></i>
            </button>
          </div>

          <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
            <button nz-button [disabled]="
                !formB.value.entidadePessoaFisica &&
                !formB.value.signatario
              " (click)="addAssinante()" nzType="primary">
              <i nz-icon nzType="plus"></i>
            </button>
          </div>
        </div>

        <hr class="m-t-25 m-b-25" />

        <div>
          <nz-table class="m-b-20" nzSize="small" #assinantesListTable [nzScroll]="{ y: '210px' }"
            style="overflow-y: overlay" [nzData]="formB.value.signatarios" [nzShowPagination]="true">
            <thead>
              <tr>
                <th *ngIf="checkList.assinantes">Atestadores</th>
                <th *ngIf="checkList.acoes" nzWidth="30%" style="background: #edf1fd; text-align: center"
                  [nzRight]="documentService.getWidthContent() > 1024">
                  Ações
                </th>
                <th style="background: #edf1fd" nzWidth="5%">
                  <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click"
                    [nzDropdownMenu]="colunas">
                    <i nz-icon nzType="setting"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-template ngFor let-item [ngForOf]="assinantesListTable.data">
                <tr>
                  <td *ngIf="checkList.assinantes">
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <p class="m-b-0">{{ getCpfMasked(item.cpf) || getCpfMasked(item.cnpj) }}</p>
                      </div>
                      <div nz-col nzSpan="24">
                        <p class="m-b-0" style="color: black; font-size: 16">
                          {{ item.nome || item.razaoSocial }}
                        </p>
                      </div>
                      <div nz-col nzSpan="24">
                        <p class="m-b-0">{{ item.email || item.nomCidade }}</p>
                      </div>
                      <div nz-col nzSpan="24">
                        <p class="m-b-0">{{ item.signatario }}</p>
                      </div>
                    </div>
                  </td>

                  <td *ngIf="checkList.acoes" nzRight style="text-align-last: center">
                    <button *ngIf="!this.formB.controls.cpf.disabled" nz-button nz nzSize="small" nzShape="circle"
                      (click)="removeAssinante(item.cpf)">
                      <i nz-icon nzType="delete"></i>
                    </button>
                  </td>
                  <td></td>
                </tr>
              </ng-template>
            </tbody>
          </nz-table>
        </div>

        <div nz-row>
          <div nz-col nzSpan="12" class="text-left d-block">
            <h4 class="m-t-15">Upload de documento</h4>
          </div>

          <div nz-col nzSpan="12" class="text-right d-block">
            <button *ngIf="!this.formB.value.idPrivado" nz-button [disabled]="!formB.value.documentB64"
              (click)="resetDocument()" nzType="primary" nzDanger>
              <i nz-icon nzType="clear"></i>
            </button>
          </div>
        </div>

        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <nz-upload *ngIf="!file" nzType="drag" [nzSize]="10929170" [nzBeforeUpload]="beforeUpload">
          <p class="ant-upload-drag-icon">
            <i nz-icon nzType="inbox"></i>
          </p>
          <p class="ant-upload-text">
            Clique ou arraste seu documento na area de upload
          </p>
        </nz-upload>
        <!-- <object *ngIf="file" [src]="file" type="application/pdf">
        <embed [src]="file" type="application/pdf" />
        </object> -->
        <iframe *ngIf="file" [src]="file" type="application/pdf" height="467px" width="100%"></iframe>

        <div>
          <div nz-row>
            <div nz-col nzSpan="12" class="text-left d-block">
              <h4 class="m-t-15">Upload de Anexos</h4>
            </div>
          </div>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row class="align-items-center">
            <nz-spin nz-col nzSpan="20" nzXl="20" nzXs="24" [nzSpinning]="loadingPessoaFisica">
              <div nz-row>
                <div nz-col nzSpan="5" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
                  <nz-form-item>
                    <nz-form-label nzRequired>Código</nz-form-label>
                    <nz-form-control [nzErrorTip]="codTipoDocTplAnexo">
                      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonTpDocAnexo">
                        <input type="number" nz-input placeholder="Digite o código do tipo de documento"
                          formControlName="idTipoDocumentoAnexo" (ngModelChange)="searchTipoDocAnexo($event)" />
                      </nz-input-group>
                      <ng-template #suffixIconButtonTpDocAnexo>
                        <button *ngIf="formB.value.idTipoDocumentoAnexo" (click)="limparEntidadeTipoDocAnexo()"
                          nz-button type="button" nzType="text" nzSearch>
                          <i nz-icon nzType="close-circle"></i>
                        </button>

                        <button (click)="showModalTipoDocAnexo()" nz-button type="button" nzType="primary" nzSearch>
                          <i nz-icon nzType="search"></i>
                        </button>
                      </ng-template>

                      <ng-template #codTipoDocTplAnexo let-control>
                        <ng-container *ngIf="control.hasError('idTipoDocumentoAnexo')">
                          Valor inválido para Tipo de documento!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('required')">
                          Por favor digite um tipo de documento!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" class="p-r-15">
                  <nz-form-item>
                    <nz-form-label nzRequired>Tipo de documento</nz-form-label>
                    <nz-form-control nzHasFeedback>
                      <input nz-input class="text-disabled" formControlName="tipoDocumentoAnexo"
                        placeholder="Tipo de documento" maxlength="150" />
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="5" nzXs="20" nzSm="4" nzMd="6" nzLg="12" nzXl="11">
                  <nz-form-item class="m-b-20">
                    <nz-form-label nzRequired>Documento</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="documentoTpl">
                      <input nz-input class="text-disabled" formControlName="descricao"
                        placeholder="Digite uma descrição" maxlength="150" />
                      <ng-template #documentoTpl let-control>
                        <ng-container *ngIf="control.hasError('descricao')">
                          Valor inválido para descricao!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="5" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
                  <nz-form-item class="m-b-10">
                    <nz-form-label nzRequired>Vinculação</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="vinculacaoTpl">
                      <input nz-input class="text-disabled" formControlName="vinculacao"
                        placeholder="Digite uma vinculação" maxlength="150" />
                      <ng-template #vinculacaoTpl let-control>
                        <ng-container *ngIf="control.hasError('vinculacao')">
                          Valor inválido para vinculação!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>


              </div>
              <nz-upload [(nzFileList)]="anexos" [nzRemove]="removeFile" [nzBeforeUpload]="upload" nz-tooltip
                nzTooltipTitle="Informe os dados acima para poder anexar o arquivo" nzTooltipPlacement="left">
                <button nz-button [disabled]="!formB.value.documento && !formB.value.vinculacao"><i nz-icon
                    nzType="upload"></i>Selecionar Anexos</button>
              </nz-upload>
            </nz-spin>

            <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="text-right d-block">
              <button nz-button [disabled]="anexos.length === 0" (click)="limparAnexo()" nzType="primary" nzDanger>
                <i nz-icon nzType="clear"></i>
              </button>
            </div>

            <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
              <button nz-button [disabled]="anexos.length === 0" (click)="addAnexo()" nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>

          <hr class="m-t-25 m-b-25" />

          <div>
            <nz-table class="m-b-20" nzSize="small" #anexosListTable [nzScroll]="{ y: '210px' }"
              style="overflow-y: overlay" [nzData]="formB.value.anexos" [nzShowPagination]="true">
              <thead>
                <tr>
                  <th *ngIf="checkList.anexos">Anexos</th>
                  <th *ngIf="checkList.acoes" nzWidth="30%" style="background: #edf1fd; text-align: center"
                    [nzRight]="documentService.getWidthContent() > 1024">
                    Ações
                  </th>
                  <th style="background: #edf1fd" nzWidth="5%">
                    <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click"
                      [nzDropdownMenu]="colunas">
                      <i nz-icon nzType="setting"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-item [ngForOf]="anexosListTable.data">
                  <tr>
                    <td *ngIf="checkList.anexos">
                      <div nz-row>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ item.vinculacao }}</p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0" style="color: black; font-size: 16">
                            {{ item.grupoDoc }}
                          </p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0" style="color: black; font-size: 16">
                            {{ item.tipoDocumentoAnexo }}
                          </p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ item.descricao }}</p>
                        </div>

                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ item.nome }}</p>
                        </div>
                      </div>
                    </td>

                    <td nzRight style="text-align-last: center">
                      <button *ngIf="formB.value.anexos.length > 0" nz-button nz nzSize="small" nzShape="circle"
                        (click)="removeAnexo(item.documento)">
                        <i nz-icon nzType="delete"></i>
                      </button>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </nz-table>
          </div>

          <h4 class="m-t-15">Cadastro de Atestador do(s) anexo(s)</h4>
          <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
          <div nz-row class="align-items-center">
            <nz-spin nz-col nzSpan="20" nzXl="20" nzXs="24" [nzSpinning]="loadingPessoaFisica">
              <div nz-row>
                <div nz-col nzSpan="24" class="m-b-15">
                  <nz-form-item style="margin-bottom: 11px">
                    <label nz-checkbox [(ngModel)]="reaproveitarSignatariosDoc" (ngModelChange)="reuseSignerDoc($event)"
                      formControlName="reaproveitar">Reaproveitar
                      Atestadores do documento</label>
                  </nz-form-item>
                  <nz-radio-group formControlName="typePeopleAnexoSelected" nzName="radiogroup">
                    <label nz-radio [nzValue]="0">Pessoa Física</label>
                    <label nz-radio [nzValue]="1">Pessoa Jurídica</label>
                  </nz-radio-group>
                </div>

                <div nz-col nzSpan="6" nzXl="6" nzLg="8" nzXs="24" class="p-r-15">
                  <nz-form-item *ngIf="this.formB.value.typePeopleAnexoSelected === 0" class="m-b-10">
                    <nz-form-label nzRequired>CPF</nz-form-label>
                    <nz-form-control [nzErrorTip]="cpfAnexoTpl">
                      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                        <input nz-input placeholder="Digite um cpf" formControlName="cpfAnexo" [mask]="'000.000.000-00'"
                          [dropSpecialCharacters]="true" (ngModelChange)="searchPessoaFisicaAnexo($event)" />
                      </nz-input-group>
                      <ng-template #suffixIconButton>
                        <button *ngIf="formB.value.entidadePessoaFisicaAnexo" (click)="limparAssinanteAnexo()" nz-button
                          type="button" nzType="text" nzSearch>
                          <i nz-icon nzType="close-circle"></i>
                        </button>

                        <button *ngIf="!this.formB.controls.cpfAnexo.disabled" (click)="showModalPFAnexo()" nz-button
                          type="button" nzType="primary" nzSearch>
                          <i nz-icon nzType="search"></i>
                        </button>
                      </ng-template>

                      <ng-template #cpfAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('cpfAnexo')">
                          Valor inválido para CPF!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('required')">
                          Por favor digite seu CPF!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item *ngIf="this.formB.value.typePeopleAnexoSelected === 1" class="m-b-10">
                    <nz-form-label nzRequired>CNPJ</nz-form-label>
                    <nz-form-control [nzErrorTip]="cnpjAnexoTpl">
                      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                        <input nz-input placeholder="Digite um cnpj" formControlName="cnpjAnexo"
                          [mask]="'00.000.000/0000-00'" [dropSpecialCharacters]="true"
                          (ngModelChange)="searchPessoaJuridicaAnexo($event)" />
                      </nz-input-group>
                      <ng-template #suffixIconButton>
                        <button *ngIf="formB.value.entidadePessoaJuridicaAnexo" (click)="limparAssinanteAnexo()"
                          nz-button type="button" nzType="text" nzSearch>
                          <i nz-icon nzType="close-circle"></i>
                        </button>

                        <button *ngIf="!this.formB.controls.cnpjAnexo.disabled" (click)="showModalPJAnexo()" nz-button
                          type="button" nzType="primary" nzSearch>
                          <i nz-icon nzType="search"></i>
                        </button>
                      </ng-template>

                      <ng-template #cnpjAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('cnpj')">
                          Valor inválido para CNPJ!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('required')">
                          Por favor digite seu CNPJ!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="18" nzXl="18" nzLg="16" nzXs="24" class="p-r-15">
                  <nz-form-item *ngIf="this.formB.value.typePeopleAnexoSelected === 0" class="m-b-10">
                    <nz-form-label nzRequired>Nome</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="nomeAnexoTpl">
                      <input nz-input class="text-disabled" formControlName="nomeAnexo" placeholder="Digite um nome"
                        maxlength="150" />
                      <ng-template #nomeAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('nomeAnexo')">
                          Valor inválido para nome!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item *ngIf="this.formB.value.typePeopleAnexoSelected === 1" class="m-b-10">
                    <nz-form-label nzRequired>Razão social</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="razaoSocialAnexoTpl">
                      <input nz-input class="text-disabled" formControlName="razaoSocialAnexo"
                        placeholder="Digite uma razão social" maxlength="150" />
                      <ng-template #razaoSocialAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('razaoSocialAnexo')">
                          Valor inválido para Razão social!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="11" nzSm="11" nzXs="24" class="p-r-15">
                  <nz-form-item *ngIf="this.formB.value.typePeopleAnexoSelected === 0" class="m-b-20">
                    <nz-form-label nzRequired>Email</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="emailAnexoTpl">
                      <input nz-input class="text-disabled" formControlName="emailAnexo" placeholder="Digite um email"
                        maxlength="100" />
                      <ng-template #emailAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('emailAnexo')">
                          Valor inválido para email!
                        </ng-container>
                        <ng-container *ngIf="control.hasError('required')">
                          Por favor digite seu email!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>

                  <nz-form-item *ngIf="this.formB.value.typePeopleAnexoSelected === 1" class="m-b-20">
                    <nz-form-label nzRequired>Cidade</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="nomCidadeAnexoTpl">
                      <input nz-input class="text-disabled" formControlName="nomCidadeAnexo"
                        placeholder="Digite uma cidade" maxlength="100" />
                      <ng-template #nomCidadeAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('nomCidadeAnexo')">
                          Valor inválido para email!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div *ngIf="this.formB.value.typePeopleAnexoSelected === 0" nz-col nzSpan="7" nzSm="7" nzXs="24"
                  class="p-r-15">
                  <nz-form-item class="m-b-20">
                    <nz-form-label>Cargo</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="cargoAnexoTpl">
                      <input nz-input class="text-disabled" formControlName="cargoAnexo" placeholder="Digite um cargo"
                        maxlength="100" />
                      <ng-template #cargoAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('cargoAnexo')">
                          Valor inválido para cargo!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div *ngIf="this.formB.value.typePeopleAnexoSelected === 1" nz-col nzSpan="7" nzSm="7" nzXs="24"
                  class="p-r-15">
                  <nz-form-item class="m-b-20">
                    <nz-form-label nzRequired>Atestador</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="signatarioAnexoTpl">
                      <nz-select style="width: 400px;" nzShowSearch nzAllowClear formControlName="signatarioAnexo"
                        nzPlaceHolder="Selecione um signatário" (ngModelChange)="addFiltroPorAssinante()">
                        <nz-option *ngFor="let assinante of allSignatarios" [nzValue]="assinante" [nzLabel]="assinante">
                        </nz-option>
                      </nz-select>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div *ngIf="this.formB.value.typePeopleAnexoSelected === 0" nz-col nzSpan="6" nzSm="6" nzXs="24"
                  class="p-r-15">
                  <nz-form-item class="m-b-20">
                    <nz-form-label>Matrícula</nz-form-label>
                    <nz-form-control nzHasFeedback [nzErrorTip]="matriculaAnexoTpl">
                      <input nz-input class="text-disabled" formControlName="matriculaAnexo"
                        placeholder="Digite uma matrícula" maxlength="100" />
                      <ng-template #matriculaAnexoTpl let-control>
                        <ng-container *ngIf="control.hasError('matriculaAnexo')">
                          Valor inválido para matrícula!
                        </ng-container>
                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </div>
            </nz-spin>

            <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="text-right d-block">
              <button nz-button [disabled]="!formB.value.entidadePessoaFisicaAnexo &&
              !formB.value.signatarioAnexo" (click)="limparAssinanteAnexo()" nzType="primary" nzDanger>
                <i nz-icon nzType="clear"></i>
              </button>
            </div>

            <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
              <button nz-button [disabled]="
                  !formB.value.entidadePessoaFisicaAnexo &&
                  !formB.value.signatarioAnexo
                " (click)="addAssinanteAnexo()" nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>

          <hr class="m-t-25 m-b-25" />

          <div>
            <nz-table class="m-b-20" nzSize="small" #assinantesAnexoListTable [nzScroll]="{ y: '210px' }"
              style="overflow-y: overlay" [nzData]="formB.value.signatariosAnexo" [nzShowPagination]="true">
              <thead>
                <tr>
                  <th *ngIf="checkList.assinantes">Atestador</th>
                  <th *ngIf="checkList.acoes" nzWidth="30%" style="background: #edf1fd; text-align: center"
                    [nzRight]="documentService.getWidthContent() > 1024">
                    Ações
                  </th>
                  <th style="background: #edf1fd" nzWidth="5%">
                    <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click"
                      [nzDropdownMenu]="colunas">
                      <i nz-icon nzType="setting"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-item [ngForOf]="assinantesAnexoListTable.data">
                  <tr>
                    <td *ngIf="checkList.assinantes">
                      <div nz-row>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ getCpfMasked(item.cpfAnexo) || getCpfMasked(item.cnpjAnexo) }}</p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0" style="color: black; font-size: 16">
                            {{ item.nomeAnexo || item.razaoSocialAnexo }}
                          </p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ item.emailAnexo || item.nomCidadeAnexo }}</p>
                        </div>
                        <div nz-col nzSpan="24">
                          <p class="m-b-0">{{ item.signatarioAnexo }}</p>
                        </div>
                      </div>
                    </td>

                    <td *ngIf="checkList.acoes" nzRight style="text-align-last: center">
                      <button *ngIf="!this.formB.controls.cpfAnexo.disabled" nz-button nz nzSize="small"
                        nzShape="circle" (click)="removeAssinanteAnexo(item.cpfAnexo)">
                        <i nz-icon nzType="delete"></i>
                      </button>
                    </td>
                    <td></td>
                  </tr>
                </ng-template>
              </tbody>
            </nz-table>
          </div>

        </div>
      </form>
    </nz-spin>
    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
</ps-complex-form>

<nz-dropdown-menu #colunas="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkList.assinantes">Assinantes</label>
    </li>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkList.acoes">Ações</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetar()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>

<nz-dropdown-menu #colunasConsulta="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let column of documentService.getTableColumns(tableColumns)">
      <label nz-checkbox [(ngModel)]="checkList[column.value]">{{
        column.text
        }}</label>
    </li>
    <li nz-menu-item *ngIf="documentService.getColumnsStatus(tableColumns)">
      <label nz-checkbox [(ngModel)]="checkListConsulta.status">Status</label>
    </li>
    <li nz-menu-item>
      <label nz-checkbox [(ngModel)]="checkListConsulta.acoes">Ações</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetarConsulta()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>