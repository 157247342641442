<nz-spin [nzSpinning]="formLoading" [nzSize]="'large'">
  <div class="row">
    <div nz-col nzSpan="19" nzMd="19" nzXs="24" class="text-left text-xs-center p-l-20 p-b-5 row">
      <!-- <nz-switch
        style="margin-right: 10px"
        nzSize="small"
        [(ngModel)]="switchValue"
        [nzControl]="true"
        [nzLoading]="loading"
        (click)="changeToDetached()"
      ></nz-switch> -->
      <!-- <p>Trocar visualização para documentos avulsos.</p> -->
    </div>
    <p style="text-align: end">Atualizado em: {{ getDateNow }}</p>
  </div>
  <div nz-row [nzGutter]="8">
    <div *ngIf="adm" nz-col [nzSm]="adm ? '24' : '24'" nzXs="24" class="p-b-15">
      <nz-collapse nzGhost>
        <nz-collapse-panel *ngFor="let panel of panels" [nzHeader]="panel.name" [nzActive]="panel.active">
          <div nz-row class="d-flex">
            <div nz-col nzSpan="24" nzXs="24">
              <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                <div class="d-flex">
                  <div class="p-r-10">
                    <nz-select style="width: 350px" nzShowSearch nzAllowClear [(ngModel)]="assinanteSelected"
                      nzPlaceHolder="Selecione um signatário" (ngModelChange)="addFiltroPorAssinante()">
                      <nz-option *ngFor="let assinante of listSignatarios" [nzValue]="assinante"
                        [nzLabel]="assinante?.text">
                      </nz-option>
                    </nz-select>
                  </div>

                  <div class="align-self-center">
                    <nz-tag style="margin-bottom: 0" [nzColor]="'blue'" [nzMode]="'closeable'"
                      (nzOnClose)="removeFiltroPorAssinante(assinanteTag.cpf)" class="filters"
                      *ngFor="let assinanteTag of assinantesSelected">
                      {{ assinanteTag.text }}
                    </nz-tag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
  </div>

  <nz-card class="dashboard">
    <p>
      Cards dos Documentos
      <i nz-icon nzType="file-protect" nzTheme="outline"> </i><span class="m-l-10 float-end" nz-icon
        nzType="info-circle" nzTheme="outline" nz-tooltip nzTooltipPlacement="right"
        nzTooltipTitle="Quantitativos considerando o documento principal"></span>
    </p>
    <!-- <H5>Cards de Documentos</H5>
  <nz-divider class="m-t-0" nzOrientation="left"></nz-divider> -->
    <div style="place-content: center" nz-row [nzGutter]="8">
      <div nz-col class="cursor-pointer" nzSpan="4" nzXl="4" nzLg="8" nzXs="12"
        *ngFor="let data of documentsResumeChartData; index as i"
        (click)="navigateToSListDocument(documentsOrderType[i])">
        <nz-card class="info-top" nzTooltipPlacement="topRight" [nzTooltipVisible]="
            tooltipVisibleDocumentsCharts[documentsOrderType[i]]?.chart
          " [nzTooltipTitle]="innerWidth < 768 || true? null : chartTooltipsMessage" nz-tooltip>
          <div class="media align-items-center">
            <nz-avatar [nzSize]="40" [nzIcon]="documentsResumeChartColors[0].icons[i]" [class]="
                'ant-avatar-' +
                getNameColorDashboard(
                  documentsResumeChartColors[0].backgroundColorName[i]
                )
              ">
            </nz-avatar>
            <h5 class="m-l-10 m-b-0">{{ data }}</h5>
          </div>
          <div>
            <p class="m-b-0 text-muted">
              Doc. {{ documentsResumeChartLabels[i] }}
            </p>
          </div>
        </nz-card>
      </div>
    </div>
  </nz-card>

  <nz-card class="dashboard">
    <p>
      Cards dos Anexos <i nz-icon nzType="paper-clip" nzTheme="outline"> </i><span class="m-l-10" nz-icon
        nzType="info-circle" nzTheme="outline" nz-tooltip nzTooltipPlacement="right"
        nzTooltipTitle="Quantitativos considerando a quantidade de anexos de um documento principal"></span>
    </p>
    <!-- <H5>Cards de Anexos</H5>
    <nz-divider class="m-t-0" nzOrientation="left"></nz-divider> -->
    <div style="place-content: center" nz-row [nzGutter]="8">
      <div nz-col class="cursor-pointer" nzSpan="4" nzXl="4" nzLg="8" nzXs="12"
        *ngFor="let data of cardAnexosData; index as i" (click)="navigateToSListAnexos(i)">
        <nz-card class="info-top" nzTooltipPlacement="topRight" [nzTooltipVisible]="
            tooltipVisibleDocumentsCharts[documentsOrderType[i]]?.chart
          " [nzTooltipTitle]="innerWidth < 768 ||true ? null : chartTooltipsMessage" nz-tooltip>
          <div class="media align-items-center">
            <nz-avatar [nzSize]="50" [nzIcon]="cardAnexosColors[0]?.icons[i]" [class]="
                'ant-avatar-' +
                getNameColorDashboard(
                  cardAnexosColors[0]?.backgroundColorName[i]
                )
              ">
            </nz-avatar>
            <h5 class="m-l-10 m-b-0">{{ data }}</h5>
          </div>
          <div>
            <p class="m-b-0 text-muted">Anexo {{ cardAnexosLabels[i] }}</p>
          </div>
        </nz-card>
      </div>
    </div>
  </nz-card>

  <div class="row">
    <div class="col-md-24 col-xl-8">
      <nz-card class="dashboard">
        <p>Documentos por período</p>
        <div nz-row class="d-flex">
          <div nz-col nzSpan="24" nzXs="24">
            <button class="m-r-10" nz-button nzType="default" nzSize="small" (click)="setPeriodTypeSelected('Day')"
              style="font-size: 0.8rem" [nzType]="
                periodoTypeSelected === 'Day' && !customInterval
                  ? 'primary'
                  : 'default'
              " nzShape="round">
              Dia
            </button>
            <button class="m-r-10" nz-button nzType="default" nzSize="small" (click)="setPeriodTypeSelected('Week')"
              style="font-size: 0.8rem" [nzType]="
                periodoTypeSelected === 'Week' && !customInterval
                  ? 'primary'
                  : 'default'
              " nzShape="round">
              Semana
            </button>
            <button class="m-r-10" nz-button nzType="default" nzSize="small" (click)="setPeriodTypeSelected('Month')"
              style="font-size: 0.8rem" [nzType]="
                periodoTypeSelected === 'Month' && !customInterval
                  ? 'primary'
                  : 'default'
              " nzShape="round">
              Mês
            </button>
            <button class="m-r-10" nz-button nzType="default" nzSize="small" (click)="setPeriodTypeSelected('Year')"
              style="font-size: 0.8rem" [nzType]="
                periodoTypeSelected === 'Year' && !customInterval
                  ? 'primary'
                  : 'default'
              " nzShape="round">
              Ano
            </button>
            <button *ngIf="!customInterval" nz-button nzType="default" nzSize="small"
              (click)="setCustomDate([], 'Others')" style="font-size: 0.8rem" nzShape="round">
              Outros
            </button>
            <nz-range-picker class="m-t-10" *ngIf="customInterval" nzFormat="dd/MM/yyyy" [(ngModel)]="customInterval"
              (ngModelChange)="setCustomDate($event, 'Others')"></nz-range-picker>
          </div>
        </div>
        <div class="m-t-20 p-b-10" [ngStyle]="{ height: divChartBarHeigth }">
          <canvas #chart id="chart" ngChartjs style="height: 100%" [datasets]="documentsChartData"
            [labels]="documentsChartLabels" [options]="documentsChartOptions" [colors]="documentsChartColors"
            [legend]="false" [chartType]="documentsChartType">
          </canvas>
        </div>

        <div nz-row nz-col nzSpan="24" nzXs="24" class="d-flex border-top p-t-10 m-b-10">
          <div nz-tooltip [nzTooltipTitle]="data.label" nzTooltipPlacement="bottom"
            *ngFor="let data of documentsChartData; index as i" nz-col>
            <div class="m-t-10 cursor-pointer" (click)="navigateToSListDocument(periodsOrderType[i])">
              <nz-badge class="m-l-10" nzStatus="default" [nzColor]="documentsChartColors[i].backgroundColor">
              </nz-badge>
              <span class="text-gray font-weight-semibold font-size-13">{{
                data.label
                }}</span>
            </div>
          </div>
        </div>
      </nz-card>
    </div>

    <div class="col-md-24 col-xl-4 mobile-mt-5">
      <nz-card class="dashboard">
        <p>Documentos por situação</p>
        <div class="m-b-40 m-v-20 text-center" [ngStyle]="{ height: divChartCircleHeigth }">
          <canvas id="chartResume" ngChartjs [data]="documentsResumeChartData" [labels]="documentsResumeChartLabels"
            [colors]="
              documentsResumeChartColors ? documentsResumeChartColors : null
            " [options]="documentsResumeChartOptions" [chartType]="documentsResumeChartType" [legend]="false">
          </canvas>
        </div>
        <div style="place-content: center" nz-row [nzGutter]="4" class="border-top p-t-10 m-b-10">
          <div nz-tooltip [nzTooltipTitle]="labelLegend" [nzTooltipVisible]="this.innerWidth <= 748 ? false : null"
            nzTooltipPlacement="bottom" nz-col nzSpan="8" nzSm="8" nzXs="8"
            *ngFor="let labelLegend of documentsResumeChartLabels; index as i">
            <div class="d-flex justify-content-center cursor-pointer"
              (click)="navigateToSListDocument(documentsOrderType[i])">
              <div class="media align-items-center">
                <div class="m-l-5">
                  <div class="d-flex">
                    <nz-badge class="more-size" nzStatus="default" [nzColor]="
                        documentsResumeChartColors[0].pointBackgroundColor[i]
                      "></nz-badge>
                    <h5 class="m-b-0">{{ documentsResumeChartData[i] }}</h5>
                  </div>
                  <p class="m-b-0 muted" style="font-size: 13px">
                    {{
                    labelLegend.split(' ').length > 1
                    ? labelLegend.split(' ')[1] + 's'
                    : labelLegend
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-card>
    </div>

    <div class="m-t-15 col-md-12 col-lg-12">
      <nz-card *ngIf="
          documentsResumeExpiration && documentsResumeExpiration.length > 0
        " class="dashboard m-b-0">
        <p>Tempo de expiração</p>
        <div class="d-flex p-b-10" style="
            border-radius: '10px';
            flex-direction: row-reverse;
            opacity: 0.85;
            font-size: 1.1rem;
          ">
          <div *ngIf="documentsResumeExpiration[0].qtd" [ngStyle]="{
              'margin-left': '-15px',
              height: '88px',
              'border-radius': '10px',
              width: documentsResumeExpiration[0].width,
              'text-align': '-webkit-right'
            }">
            <div style="display: table-caption">
              <label class="m-r-20 m-t-5">{{
                documentsResumeExpiration[0].qtd
                }}</label>
              <i class="m-r-15" style="margin-top: -15px" nz-icon nzType="caret-down" nzTheme="outline"></i>
            </div>
            <div [ngStyle]="{
                backgroundColor: documentsResumeExpiration[0].color,
                height: '24px',
                'border-radius': '10px',
                width: '100%'
              }"></div>
          </div>
          <div *ngIf="documentsResumeExpiration[1].qtd" [ngStyle]="{
              height: '88px',
              'border-radius': '10px',
              'margin-left': '-15px',
              width: documentsResumeExpiration[1].width,
              'text-align': '-webkit-right'
            }">
            <div style="display: table-caption">
              <label class="m-r-20 m-t-5">{{
                documentsResumeExpiration[1].qtd
                }}</label>
              <i class="m-r-15" nz-icon nzType="caret-down" nzTheme="outline"></i>
            </div>
            <div [ngStyle]="{
                height: '24px',
                'border-radius': '10px',
                width: '100%',
                backgroundColor: documentsResumeExpiration[1].color
              }"></div>
          </div>

          <div *ngIf="documentsResumeExpiration[2].qtd" [ngStyle]="{
              height: '88px',
              'border-radius': '10px',
              'margin-left': '-15px',
              width: documentsResumeExpiration[2].width,
              'text-align': '-webkit-right'
            }">
            <div style="display: table-caption">
              <label class="m-r-20 m-t-5">{{
                documentsResumeExpiration[2].qtd
                }}</label>
              <i class="m-r-15" nz-icon nzType="caret-down" nzTheme="outline"></i>
            </div>
            <div [ngStyle]="{
                height: '24px',
                'border-radius': '10px',
                width: '100%',
                backgroundColor: documentsResumeExpiration[2].color
              }"></div>
          </div>

          <div *ngIf="documentsResumeExpiration[3].qtd" [ngStyle]="{
              height: '88px',
              'border-radius': '10px',
              'margin-left': '-15px',
              width: documentsResumeExpiration[3].width,
              'text-align': '-webkit-right'
            }">
            <div style="display: table-caption">
              <label class="m-r-20 m-t-5">{{
                documentsResumeExpiration[3].qtd
                }}</label>
              <i class="m-r-15" nz-icon nzType="caret-down" nzTheme="outline"></i>
            </div>
            <div [ngStyle]="{
                height: '24px',
                'border-radius': '10px',
                width: '100%',
                backgroundColor: documentsResumeExpiration[3].color
              }"></div>
          </div>
        </div>

        <div></div>

        <div nz-row style="place-content: center" class="border-top p-t-10 m-b-10">
          <div nz-col class="m-r-15">
            <div style="float: right">
              <div class="d-flex cursor-pointer">
                <nz-badge class="more-size" nzStatus="default" [nzColor]="documentsResumeExpiration[3].color">
                </nz-badge>
                <p class="m-b-0 muted" style="font-size: 13px">
                  {{ documentsResumeExpiration[3].label }}
                </p>
              </div>
            </div>
          </div>
          <div nz-col class="m-r-15">
            <div style="float: right">
              <div class="d-flex cursor-pointer">
                <nz-badge class="more-size" nzStatus="default" [nzColor]="documentsResumeExpiration[2].color">
                </nz-badge>
                <p class="m-b-0 muted" style="font-size: 13px">
                  {{ documentsResumeExpiration[2].label }}
                </p>
              </div>
            </div>
          </div>
          <div nz-col class="m-r-15">
            <div class="d-flex cursor-pointer">
              <nz-badge class="more-size" nzStatus="default" [nzColor]="documentsResumeExpiration[1].color"></nz-badge>
              <p class="m-b-0 muted" style="font-size: 13px">
                {{ documentsResumeExpiration[1].label }}
              </p>
            </div>
          </div>
          <div nz-col class="m-r-15">
            <div class="d-flex cursor-pointer">
              <nz-badge class="more-size" nzStatus="default" [nzColor]="documentsResumeExpiration[0].color"></nz-badge>
              <p class="m-b-0 muted" style="font-size: 13px">
                {{ documentsResumeExpiration[0].label }}
              </p>
            </div>
          </div>
        </div>
      </nz-card>
    </div>
  </div>
  <ps-fab [actions]="fabButtons"></ps-fab>
</nz-spin>