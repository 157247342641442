<div>
  <div *ngIf="isPrinter">
    <p *ngIf="false"><label nz-checkbox nzDisabled [(ngModel)]="docsPrintChecked">Imprimir documentos</label></p>
    <p *ngIf="false"><label nz-checkbox [(ngModel)]="anxsPrintChecked">Imprimir anexos</label></p>
  </div>
  <p *ngIf="tipoDocumento">
    <b>Tipo do documento: {{tipoDocumento}}</b>
  </p>
  <p *ngIf="qtdAssinantes">
    <b>Quantidade de assinantes: {{qtdAssinantes}}</b>
  </p>

  <p *ngIf="valorSelecionado">
    <b>Valor total selecionado: {{valorSelecionado}}</b>
  </p>
  <p *ngIf="qtdSelecionados">
    <b>Quantidade de documentos selecionados: {{qtdSelecionados}}</b>
  </p>



  <p *ngIf="existMotivo">
    <label><b>Motivo</b></label>
    <textarea nz-input rows="3" placeholder="Digite o motivo do desacordo" [(ngModel)]="motivo"></textarea>
  </p>

  <div *ngIf="selecionarCertificado">
    <div class="form">
      <form [formGroup]="formB">
        <p>Selecione o certificado digital</p>
        <div nz-col nzXl="10" nzLg="24" nzXs="24">
          <nz-upload [(nzFileList)]="certificados" [nzBeforeUpload]="upload">
            <button nz-button><i nz-icon nzType="upload"></i>Selecionar certificado digital</button>
          </nz-upload>

        </div>
        <div nz-col nzXl="10" nzLg="24" nzXs="24">
          <nz-form-item>
            <nz-form-label nzRequired>Senha Certificado:</nz-form-label>
            <nz-form-control>
              <nz-input-group [nzSuffix]="suffixTemplate">
                <input [type]="passwordVisible ? 'text' : 'password'" required nz-input
                  placeholder="Senha do certificado" formControlName="senhaCert" />
              </nz-input-group>
              <ng-template #suffixTemplate>
                <i nz-icon class="cursor-pointer" [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
                  (click)="passwordVisible = !passwordVisible"></i>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
      </form>
    </div>
  </div>

  <div *ngIf="reativarDocs">
    <div nz-col nzSpan="20" nzXl="12" nzLg="12" nzXs="24" class="p-r-15">
      <form [formGroup]="formB">

        <nz-form-item>
          <nz-form-label nzRequired>Nova data de expiração</nz-form-label>
          <nz-form-control>
            <nz-date-picker nzShowTime nzFormat="dd/MM/yyyy HH:mm:ss" formControlName="dataExpiracao"
              nzPlaceHolder="Data de expiração" (ngModelChange)="checkDataExpiracao($event)"></nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
  </div>

  <nz-list *ngIf="certByDocumentForSign?.length > 0 || allChecked||(dtExpiracao && docsSelecteds && anexsSelecteds)"
    nzBordered nzSize="small">
    <nz-list-header>
      <div *ngIf="dtExpiracao" nz-row nz-col class="p-t-10">

        <div nz-col class="p-t-10" nzSpan="12">
          <p>
            <b>Quantidade de documentos selecionados: {{docsSelecteds||0}}</b>
          </p>
          <p>
            <b>Quantidade de anexos selecionados: {{anexsSelecteds||0}}</b>
          </p>
          <p>
            <b>Total de arquivos selecionados: {{docsSelecteds + anexsSelecteds||0}}</b>
          </p>
          <p><b>Data da expiração: {{dtExpiracao | date:'dd/MM/yyyy - HH:mm:ss'}}</b></p>
        </div>
      </div>
      <ng-container *ngIf="certByDocumentForSign?.length > 0 || allChecked">
        <div nz-row *ngFor="let row of getCertsSelecteds()" nz-col class="p-t-10">
          <div nz-col class="p-t-10" nzSpan="12">
            <p><b>Certificado: {{row.descricaoCert}}</b></p>
          </div>

          <div nz-col nzSpan="12">
            <nz-input-group [nzSuffix]="suffixTemplate">
              <input [type]="row.passwordVisible ? 'text' : 'password'" required nz-input
                placeholder="Senha do certificado" [(ngModel)]="row.passwordCert"
                (ngModelChange)="updatePasswordCerts($event)" />
            </nz-input-group>
            <ng-template #suffixTemplate>
              <i nz-icon class="cursor-pointer" [nzType]="row.passwordVisible ? 'eye-invisible' : 'eye'"
                (click)="row.passwordVisible = !row.passwordVisible"></i>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </nz-list-header>
    <ng-container *ngIf="certByDocumentForSign?.length > 0 || allChecked">
      <nz-list-header *ngIf="ratificarDoc || ratificarAnexo">
        <div nz-col nzSpan="24" nz-row *ngFor="let row of getCertsSelecteds()" class="p-t-10">
          <p>
            <label><b>Motivo</b></label>
            <textarea nz-input rows="3" placeholder="Digite o motivo da ratificação"
              [(ngModel)]="row.motivo"></textarea>
          </p>
        </div>
      </nz-list-header>

      <nz-collapse nzGhost>
        <nz-collapse-panel [nzHeader]="'documentos'" [nzActive]="true">
          <nz-list-header nz-row>
            <div class="p-t-10" nz-col nzSpan="14">
              <p><b>Documento(s)</b></p>
            </div>

            <div nz-col class="p-t-10" nzSpan="10">
              <p><b>Certificado</b></p>
            </div>
          </nz-list-header>
          <nz-list-item *ngFor="let row of certByDocumentForSign">
            <div class="p-t-10" nz-col nzSpan="14">
              <p>Número Doc: <b>{{ row.numeroDoc }}</b></p>
              <p>Descrição: <b>{{row.historicoDoc}}</b></p>
            </div>

            <div nz-col class="p-t-10" nzSpan="10">
              <!--  -->
              <div nz-col>
                <p><b>Certificado: {{row.descricaoCert}}</b></p>
              </div>
              <!--  -->
              <!-- <nz-select style="width: 250px" nzShowSearch nzAllowClear nzPlaceHolder="Selecione um certificado"
            [(ngModel)]="row.uuidCert">
            <nz-option *ngFor="let cert of row.certificados" [nzLabel]="cert.descricaoCert" [nzValue]="cert.uuid">
            </nz-option>
          </nz-select> -->
            </div>
          </nz-list-item>
        </nz-collapse-panel>
      </nz-collapse>

      <p class="p-l-15" *ngIf="allChecked"><b>*Você selecionou a opção de {{label()}} todos os documentos</b></p>
    </ng-container>
  </nz-list>
  <form [formGroup]="formB">
    <div *ngIf="attachToSign && labelAnexo === 'Assinar'" class="sign-all-documents">
      <label nz-checkbox (ngModelChange)="updateAssinarAnexo($event)" formControlName="assinarAnexos"><b>Assinar
          todos os anexos</b></label>
    </div>
    <div *ngIf="attachToSign && labelAnexo === 'Ratificar'" class="sign-all-documents">
      <label nz-checkbox (ngModelChange)="updateRatificarAnexo($event)" formControlName="ratificarAnexos">Ratificar
        todos os anexos</label>
    </div>
    <div *ngIf="labelAnexo === 'Ratificar' && !verifyIfRatificado() && !verifySigners() && !verifyIfAnexoRatificado()"
      class="sign-all-documents">
      <label nz-checkbox (ngModelChange)="updateRatificar($event)" formControlName="ratificarTodos">Abrir processo
        de ratificação para todos os signatários</label>
    </div>
  </form>

  <div *ngIf="dtExpiracao && docsSelecteds && anexsSelecteds" class="align-items-center p-10 m-t-10 shadow-default"
    nz-row style="background: #fdfdff">
    <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
      <h5>Arquivos Exportados</h5>
    </div>
    <div class="col-md-24 col-xl-12  p-b-0 p-l-0 p-r-0">
      <div class="row tree-view m-t-10">
        <div class="col-md-24 col-xl-12 align-items-center  p-b-15 p-l-10 p-r-10">
          <nz-tree nzBlockNode class="bg-wgrey p-t-10 p-b-10 tree-view" [nzData]="treeDocs"
            (nzDblClick)="openFolder($event)" [nzTreeTemplate]="nzTreeTemplate" (nzCheckBoxChange)="nzEvent($event)">
          </nz-tree>
          <ng-template #nzTreeTemplate let-node let-origin="origin" class="bg-grey">

            <span class="custom-node">
              <span *ngIf="!node.isLeaf" (contextmenu)="contextMenu($event, menu)">
                <i nz-icon [nzType]="node.isExpanded ? 'folder-open' : 'folder'"></i>
                <span class="folder-name">{{ node.title }}</span>
                <!-- <span class="folder-desc">created by {{ origin.author | lowercase }}</span> -->
              </span>
              <span *ngIf="node.isLeaf" (contextmenu)="contextMenu($event, menu)" (click)='openFile(node)'>
                <i nz-icon nzType="file-pdf" twoToneColor='#ff0000' theme="twotone"></i>
                <span class="file-name">{{ node.title }}</span>
                <!-- <span class="file-desc">modified by {{ origin.author | lowercase }}</span> -->
              </span>
            </span>
          </ng-template>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <!-- <ul nz-menu>
        <li nz-menu-item (click)="selectDropdown()">Action 1</li>
        <li nz-menu-item (click)="selectDropdown()">Action 2</li>
      </ul> -->
          </nz-dropdown-menu>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- exportação de arquivos -->

<!-- <nz-spin *ngIf="filesExporteds" [nzSpinning]="isSpinning">

  <ng-template #cardTitle>
    <h4>
      <i class="m-r-10" nz-icon nzType="folder-open"></i> Arquivos Exportados
    </h4>
  </ng-template> -->

<!-- <nz-card [nzTitle]="cardTitle" *ngIf="filesExporteds" [nzLoading]="loading"> -->
<div *ngIf="filesExporteds">
  <div>
    <div class="align-items-center shadow-default" nz-row style="background: #edf1fd">
      <div nz-col nzSpan="10">
        <div class="media align-items-center">
          <div class="p-l-15">
            <h5 class="m-b-0">Status da exportação:</h5>
            <h4 class="m-b-0">
              <b>{{validateDate(filesExporteds.dtExpiracao)?'Ativo': 'Inativo'}}</b>

            </h4>
          </div>
        </div>
      </div>
      <div>
        <nz-avatar class="cursor-pointer" (click)="copiarLinkExportacao(filesExporteds.uuidUpload)" [nzSize]="40"
          [nzIcon]="'copy'" [class]="'ant-avatar-blue'" nz-tooltip
          nzTooltipTitle="Clique para copiar o link da exportação" nzTooltipPlacement="top">
        </nz-avatar>
      </div>
      <!-- <a target="_blank" [routerLink]="['arquivos-exportados/'+filesExporteds.uuidUpload]"> -->
      <div class="p-l-10">
        <nz-avatar class="cursor-pointer" (click)="visualizarExportacao(filesExporteds.uuidUpload)" [nzSize]="40"
          [nzIcon]="'file'" [class]="'ant-avatar-blue'" nz-tooltip nzTooltipTitle="Clique para visualizar a exportação"
          nzTooltipPlacement="top"></nz-avatar>
      </div>
      <!-- </a> -->
      <div class="p-r-5" nz-col nzSpan="9" nzOffset="2">
        <div nz-row>
          <div nz-col nzSpan="24" class="p-l-15 align-items-center form-group" style="text-align: end">
            <h5 class="m-b-0">Expiração da exportação:</h5>
            <h5 class="m-b-0 p-l-5 p-r-10">
              <b>{{filesExporteds.dtExpiracao| date: 'dd/MM/yyyy - HH:mm:ss'}}</b>
              <!-- <b>{{validateDate(filesExporteds.dtExpiracao)}}</b> -->
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
      <div class="p-l-15 p-t-10 m-b-10 align-items-center" nz-col nzSpan="24">
        <h5 class="m-b-0">Dados da exportação</h5>
        <!-- <h6 class="text-color-value">
            asdf
          </h6> -->
      </div>

      <div nz-col nzSpan="24" class="d-flex">
        <div nz-col nzSpan="12" class="p-l-15 p-t-10 align-items-center d-flex">
          <div class="form-group">
            <!-- <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Tipo do documento:</h6>
                <h6 class="m-b-0 text-color-value">asdf</h6>
              </div> -->

            <!-- <div *ngIf="true" class="d-flex">
                <h6 class="m-b-0 p-r-5">Número do documento:</h6>
                <h6 class="m-b-0 text-color-value">adsf</h6>
              </div> -->



            <div class="d-flex">
              <h6 class="m-b-0 p-r-5">Data da criação da exportação:</h6>
              <h6 class="m-b-0 text-color-value">
                {{exportacaoService.getDateFormatted3h(filesExporteds.dtCriacao)}}
              </h6>
            </div>
            <div class="d-flex">
              <h6 class="m-b-0 p-r-5">Data da alteração da exportação:</h6>
              <h6 *ngIf="!(filesExporteds?.dtAlteracao === filesExporteds?.dtCriacao)" class="m-b-0 text-color-value">
                {{exportacaoService.getDateFormatted3h(filesExporteds.dtAlteracao)}}
              </h6>
            </div>
            <div class="d-flex">
              <h6 class="m-b-0 p-r-5">Usuário criador:</h6>
              <h6 class="m-b-0 text-color-value">
                {{filesExporteds.usCriacao}}
              </h6>
            </div>
            <!-- <div class="d-flex">
              <h6 class="m-b-0 p-r-5">Visualização externa:</h6>
              <h6 class="m-b-0 text-color-value">
                
              </h6>
            </div> -->
          </div>
        </div>

        <div nz-col nzSpan="12" nzSm="12" nzXs="24" class="p-l-15 p-t-10 align-items-center d-flex">
          <div class="form-group">
            <!-- <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Unidade Gestora:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{filesExporteds.idUnidadeGestora}}
                </h6>
              </div> -->




            <div class="d-flex">
              <h6 class="m-b-0 p-r-5">Unidade Gestora:</h6>
              <h6 class="m-b-0 text-color-value">
                {{uGName}}
              </h6>
            </div>
            <!-- <div  class="d-flex">
                <h6 class="m-b-0 p-r-5">Favorecido:</h6>
                <h6 class="m-b-0 text-color-value">
                  asdf
                </h6>
              </div> -->

            <div class="d-flex">
              <h6 class="m-b-0 p-r-5">Descrição:</h6>
              <h6 class="m-b-0 text-color-value">
                {{filesExporteds.descricao}}
              </h6>
            </div>

          </div>
        </div>
      </div>
    </div>


    <nz-spin [nzSpinning]="formLoading">
      <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
        <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
          <h5>Arquivos Exportados</h5>
        </div>
        <div class="col-md-24 col-xl-12  p-b-0 p-l-0 p-r-0">
          <div class="row tree-view m-t-10">
            <div class="col-md-24 col-xl-12 align-items-center  p-b-15 p-l-10 p-r-10">
              <nz-tree nzBlockNode class="bg-wgrey p-t-10 p-b-10 tree-view" [nzData]="docNodes"
                (nzDblClick)="openFolder($event)" [nzTreeTemplate]="nzTreeTemplate"
                (nzCheckBoxChange)="nzEvent($event)"></nz-tree>
              <ng-template #nzTreeTemplate let-node let-origin="origin" class="bg-grey">

                <span class="custom-node">
                  <span *ngIf="!node.isLeaf" (contextmenu)="contextMenu($event, menu)">
                    <i nz-icon [nzType]="node.isExpanded ? 'folder-open' : 'folder'"></i>
                    <span class="folder-name">{{ node.title }}</span>
                    <!-- <span class="folder-desc">created by {{ origin.author | lowercase }}</span> -->
                  </span>
                  <span *ngIf="node.isLeaf" (contextmenu)="contextMenu($event, menu)" (click)='openFile(node)'>
                    <i nz-icon nzType="file-pdf" twoToneColor='#ff0000' theme="twotone"></i>
                    <span class="file-name">{{ node.title }}</span>
                    <!-- <span class="file-desc">modified by {{ origin.author | lowercase }}</span> -->
                  </span>
                </span>
              </ng-template>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <!-- <ul nz-menu>
            <li nz-menu-item (click)="selectDropdown()">Action 1</li>
            <li nz-menu-item (click)="selectDropdown()">Action 2</li>
          </ul> -->
              </nz-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </nz-spin>




    <!-- <ps-fab *ngIf="isActiveFabButton()" [actions]="fabButtons"></ps-fab> -->
  </div>
</div>

<div *ngIf="mensagem">
  <div>
    <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
      <div class="p-l-15 p-t-10 m-b-10 align-items-center" nz-col nzSpan="24">
        <h5 class="m-b-0">Dados da mensagem</h5>
        <div nz-col nzSpan="24" class="d-flex">
          <div nz-col nzSpan="24" class="p-l-15 p-t-10 align-items-center d-flex">
            <div class="form-group">
              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Destinatario(s):</h6>
                <div *ngIf="mensagem.destinatarios.length == 1"> {{mensagem.destinatarios[0].nome}}</div>
                <div *ngIf="mensagem.destinatarios.length > 1">
                  <ng-template ngFor let-destinatarios [ngForOf]="mensagem.destinatarios">

                    {{destinatarios.nome}},&nbsp;

                  </ng-template>
                </div>
              </div>

              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Título:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{mensagem.mensagem.titulo}}
                </h6>
              </div>

              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Texto:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{mensagem.mensagem.texto}}
                </h6>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="mensagemEnvio">
  <div>
    <div class="align-items-center p-10 m-t-10 shadow-default" nz-row style="background: #fdfdff">
      <div class="p-l-15 p-t-10 m-b-10 align-items-center" nz-col nzSpan="24">
        <h5 class="m-b-0">Dados da mensagem</h5>
        <div nz-col nzSpan="24" class="d-flex">
          <div nz-col nzSpan="24" class="p-l-15 p-t-10 align-items-center d-flex">
            <div class="form-group">
              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Destinatario(s):</h6>
                <div *ngIf="mensagemEnvio.destinatarios.length == 1"> {{mensagemEnvio.destinatarios[0].nome}}</div>
                <div *ngIf="mensagemEnvio.destinatarios.length > 1">
                  <ng-template ngFor let-destinatarios [ngForOf]="mensagemEnvio.destinatarios">

                    {{destinatarios.nome}},&nbsp;

                  </ng-template>
                </div>
              </div>

              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Título:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{mensagemEnvio.titulo}}
                </h6>
              </div>

              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Texto:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{mensagemEnvio.texto}}
                </h6>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>

<!-- </nz-card> -->
<!-- </nz-spin> -->