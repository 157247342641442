import { Component, Input, OnInit, Optional } from '@angular/core';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import { Helper, TipoDocumentoService } from '@ps-erp-angular/shared';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ModuloComponent } from '../modulo/modulo.component';

@Component({
  selector: 'ps-grid-modal-atestadores',
  templateUrl: './grid-modal-atestadores.component.html',
  styleUrls: ['./grid-modal-atestadores.component.scss'],
})
export class GridModalAtestadoresComponent implements OnInit {
  tabIndex = 1;
  fabButtons: IMenuButton[];
  displayData: Array<any> = [];
  loading: boolean = false;
  checkAllIndeterminate = false;
  checkedAll = false;

  @Input()
  idPrivadoTipoDocumento: number = 1044;

  @Input()
  atestadoresAdicionados: Array<any>;

  public getTableAtestadores = [
    {
      label: 'CPF',
      columnName: 'cpfAtestador',
      mascara: 'cpf',
      width: '10em',
      compare: (a: any, b: any) => {
        return a['cpfAtestador'].localeCompare(b['cpfAtestador']);
      },
    },
    {
      label: 'Nome Atestador',
      columnName: 'nomeAtestador',
      width: '20rem',
      compare: (a: any, b: any) => {
        return a['nomeAtestador'].localeCompare(b['nomeAtestador']);
      },
    },
    {
      label: 'Data Inicio Atesto',
      columnName: 'dataInicioAtesto',
      mascara: 'date',
      width: '20rem',
      compare: (a: any, b: any) => {
        return a['dataInicioAtesto'] - b['dataInicioAtesto'];
      },
    },
  ];

  checkListTableAtestadores = {
    cpfAtestador: true,
    dataInicioAtesto: true,
    nomeAtestador: true,
  };
  constructor(
    private readonly _notification: NzNotificationService,
    @Optional()
    private modalRef: NzModalRef<ModuloComponent>,
    private readonly _tipoDocumento: TipoDocumentoService
  ) {
    this.resetFabButtons();
  }

  private buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  private resetFabButtons(): void {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: true,
        onClick: this.selecionar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  ngOnInit(): void {
    this.resetFabButtons();
    this.loading = true;
    this._tipoDocumento
      .consultarAtestadoresPorIdPrivadoTipoDoc(this.idPrivadoTipoDocumento)
      .then((resp) => {
        this.displayData = resp.data.filter(
          (data: any) =>
            !this.atestadoresAdicionados
              .filter((at) => !at.deleted)
              .some(
                (atesAdded: any) => atesAdded.cpfAtestador === data.cpfAtestador
              )
        );
        if (this.displayData.length === 0) {
          this.modalRef.destroy();
          this._notification.warning(
            'Atestadores Tipo Documento',
            'Não há mais atestadores desse Tipo Documento para serem adicionados!'
          );
        }
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        this._notification.error('Atestadores Tipo Documento', e);
      });
  }

  selecionar = () => {
    if (this.displayData.filter((data) => data.checked).length !== 1) {
      return this._notification.warning('Alterar', 'Nenhum item selecionado!');
    }

    this.select(this.displayData.find((data) => data.checked));
  };

  select(item) {
    this.modalRef.destroy({
      idPrivado: item.idPrivado,
      cpfAtestador: item.cpfAtestador,
      dataInicioAtesto: item.dataInicioAtesto,
      idPessoa: item.pessoa.id,
      nomeAtestador: item.pessoa.nome,
      vinculacaoModuloTipoDoc: undefined,
    });
  }

  checkItemPesquisa(item) {
    this.displayData.map((atestador) => {
      return {
        ...atestador,
        checked: atestador.idPrivado === item.idPrivado,
      };
    });
  }

  changeTabIndex($event) {}

  prepareColumnValue(item, column) {
    if (!item) return;
    if (item.deleted) return;
    if (this.displayData.length > 0) {
      return column?.mascara
        ? Helper.addMask(
            column?.mascara === 'date'
              ? item[column.columnName].toString()
              : item[column.columnName],
            column.mascara
          )
        : item[column.columnName];
    }
  }
}
