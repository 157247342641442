import * as moment from 'moment';

import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  AssinantesService,
  AuthService,
  ColorStatusDoc,
  ColorStatusProcessDoc,
  DateLabel,
  DetailDocumentService,
  DocumentService,
  DocumentsUploadService,
  EventsActionsDoc,
  Helper,
  IAnexoSignatarios,
  IAssinante,
  IColumnsFieldsProps,
  IDocumento,
  IDocumentoAnexo,
  IEventTableDetailDocument,
  ITipoDoc,
  IUnidadeGestora,
  IconsStatusDoc,
  IconsStatusProcessDoc,
  StatusCertificado,
  StatusDoc,
  StatusDocLabel,
  StatusDocumento,
  TipoDocumentoService,
  TypeActions,
  TypeDocDownload,
  TypeDocView,
} from '@ps-erp-angular/shared';
import { TypeActionsDoc } from 'libs/shared/src/lib/types/events.types';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ModalDialogComponent } from '../modal-dialog/modal-dialog.component';

@Component({
  selector: 'ps-digitalizacao-documents-detail',
  templateUrl: './documents-detail.component.html',
  styleUrls: ['./documents-detail.component.scss'],
})
export class DocumentsDetailComponent implements OnInit {
  @Input() document: any;
  resizeModal;
  @Input() filterType: any[];
  @Input() infoAnexo: boolean = true;
  selectURLDocAttch: string;
  idDocument: number;
  pessoas: any;
  newDoc = [];
  eventos = [];
  anexos: IDocumentoAnexo[] = [];
  newDocument: IDocumentoAnexo[] = [];

  certificadoAssinante: any[];
  hasCertificado: any;
  adm: boolean;

  allChecked = false;
  indeterminate = true;
  checkOptionsOne = [];

  searchInput;
  loading = false;

  eventTableDataList: IEventTableDetailDocument[] = [];

  pageLoading = false;
  loadingAnexosSig = false;
  loadingAnexos = false;
  loadingEvents = false;
  dadosUnidadeGestora: IUnidadeGestora;
  dadosTipoDocumento: ITipoDoc;
  listSignatariosToSign = [];

  listedAttachs: boolean;

  statusDoc = StatusDoc;
  statusDocLabel = StatusDocLabel;
  listSignatarios: IAssinante[];

  checkListConsulta = {
    nome: true,
    nomeOriginal: true,
    descricao: true,
    tipoDocumentoAnexo: true,
    status: true,
    uuid: true,
    ratificado: true,
    // anexoSignatarios: true,
  };
  tableColumns: IColumnsFieldsProps[] = [];

  panels: any;

  fabButtons: IMenuButton[];

  tooltibVisibleNzAvatar: any = { previewDoc: false, downloadDoc: false };
  public innerWidth: any;

  constructor(
    public detailDocumentService: DetailDocumentService,
    private modal: NzModalService,
    private modalRef: NzModalRef,
    public service: DocumentsUploadService,
    public documentService: DocumentService,
    public tipoDocService: TipoDocumentoService,
    private assinantesService: AssinantesService,
    protected authService: AuthService,
    private router: Router
  ) {
    this.menuFabButtons();
    this.listedAttachs =
      this.document?.documentoAnexo && this.document?.documentoAnexo?.length > 0
        ? true
        : false;
  }

  async updateAllChecked() {
    await this.documentService
      .updateAllAnexosChecked(this.anexos, this.allChecked, this.indeterminate)
      .then((result) => Object.assign(this, result));
  }

  menuFabButtons() {
    this.buildFabButtons([
      {
        icon: 'form',
        tooltip: 'Assinar',
        condition: true,
        onClick: this.assinar,
      },
      {
        icon: 'close-circle',
        tooltip: 'Discordar',
        condition: true,
        onClick: this.discordar,
      },
      {
        icon: 'rollback',
        tooltip: 'Desfazer Desacordo',
        condition: true,
        onClick: this.desfazer,
      },
      // {
      //   icon: 'download',
      //   tooltip: 'Download',
      //   condition: true,
      //   onClick: this.downloadDocuments,
      // },
      // {
      //   icon: 'stop',
      //   tooltip: 'Cancelar',
      //   condition: true,
      //   onClick: this.cancelar,
      // },
    ]);
  }
  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }

  async admUser() {
    this.adm = await this.documentService.isAdmUser();
  }

  getCorDocumento(anexo: any) {
    return this.documentService.themeColors[ColorStatusDoc[anexo.status]];
  }

  async mostarProgressBarAndClock(anexo: IDocumentoAnexo) {
    if (
      [
        StatusDocumento.Cancelado,
        StatusDocumento.Expirado,
        StatusDocumento.Desacordo,
        StatusDocumento.Confirmado,
        StatusDocumento.Assinado,
      ].includes(anexo.status)
    ) {
      return false;
    }
    return true;
  }

  // cancelar = async () => {
  //   const userInfo = this.authService.getUserInfo();
  //   const nomUser = this.authService.getNomUser();

  //   const qtdSelecionados = this.documentService.getQtdAnexosSelecionados(
  //     this.anexos,
  //   );

  //   if (qtdSelecionados === 0 || qtdSelecionados > 1) {
  //     return this.service.notification.info(
  //       'Cancelar documento anexado',
  //       `Não foi possível cancelar o documento anexado pois exitem muitos anexos selecionados ou nenhum. `,
  //     );
  //   }

  //   const confirmedAnx = await this.anexos.filter((doc) => doc.checked);
  //   const userDocument = confirmedAnx.find((ass) => ass.idPrivado);

  //   if (
  //     userDocument.usCriacao !== userInfo.idUsuario &&
  //     !(await this.documentService.isAdmUser())
  //   ) {
  //     return this.documentService.notification.info(
  //       'Cancelar documento anexado',
  //       'Não foi possivel cancelar o documento anexado, pois você não é criador deste anexo!',
  //       { nzDuration: 7000 },
  //     );
  //   }

  //   for (const docs of confirmedAnx) {
  //     if (docs.status === StatusDocumento.Cancelado) {
  //       return this.documentService.notification.info(
  //         'Cancelar documento anexado',
  //         'Não foi possivel cancelar o documento anexado, pois o anexo selecionado está com status Cancelado!',
  //         { nzDuration: 7000 },
  //       );
  //     }
  //   }

  //   const anexos = this.anexos.filter(
  //     (registro) => registro.checked === true,
  //   );

  //   const id = anexos.map((item) => {
  //     return item.idPrivado;
  //   });

  //   const usuarioCriador = {
  //     nome: nomUser,
  //     cpf: userInfo.cpf,
  //     id: userInfo.idUsuario,
  //     email: userInfo.login,
  //     admin: this.adm,
  //     sistema: true,
  //   };

  //   const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o cancelamento do anexo selecionado?</i>`;
  //   const okText = 'Sim';
  //   const cancelText = 'Não';
  //   const onOk = async (motivo) => {
  //     const idsDocsParaAssinar: number[] = this.documentService
  //       .getAnexosSelecionadosParaAssinar(this.anexos)
  //       .map((doc) => doc.idPrivado);

  //     await this.documentService
  //       .cancelarAnexo(Number(id), motivo, usuarioCriador)
  //       .then(async () => {
  //         await this.reloadAttach();
  //         // this.getDocumentsPerPeriod();
  //         // this.updateDisplayAnexos(idsDocsParaAssinar);
  //         modal.close();
  //       })
  //       .catch((e) => {
  //         this.service.notification.error(
  //           'Cancelar documento',
  //           `Error ao cancelar documento \n ${e}`,
  //         );
  //       });
  //   };

  //   const modal = this.modal.create({
  //     nzTitle: title,
  //     nzContent: ModalDialogComponent,
  //     nzFooter: [
  //       { label: cancelText, onClick: () => modal.close() },
  //       {
  //         label: okText,
  //         disabled: (componentInstance) => !componentInstance.motivo,
  //         type: 'primary',
  //         onClick: (componentInstance) => onOk(componentInstance.motivo),
  //       },
  //     ],
  //     nzComponentParams: {
  //       qtdSelecionados: this.documentService.getQtdAnexosSelecionados(
  //         this.anexos,
  //       ),
  //       // tipoDocumento: `${anexos.map((i) => i.tipoDocumento.tipo)}`,
  //       existMotivo: true,
  //       attachToSign: this.listedAttachs,
  //     },
  //   });

  //   modal.afterClose.subscribe((resultClose: any) => {
  //     if (!resultClose) {
  //       modal.destroy();
  //     }
  //   });
  // };

  discordar = async () => {
    this.listSignatariosToSign = [];
    const anxCheckeds = this.anexos.filter((anx) => anx.checked).length;
    const confirmedAnx = await this.anexos.filter((doc) => doc.checked);
    if (anxCheckeds === 0) {
      return this.documentService.notification.info(
        'Discordar documento anexado',
        'Não foi possivel discordar do documento anexado, pois não foi selecionado nenhum anexo!',
        { nzDuration: 7000 }
      );
    }

    this.pageLoading = true;

    for (const ext of confirmedAnx) {
      if (ext.status === StatusDocumento.Confirmado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Discordar documento anexado',
          'Não foi possivel discordar o documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 }
        );
      }

      if (ext.status === StatusDocumento.Assinado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Discordar documento anexado',
          'Não foi possivel discordar o documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Assinado!',
          { nzDuration: 7000 }
        );
      }

      if (ext.status === StatusDocumento.Cancelado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Discordar documento anexado',
          'Não foi possivel discordar o documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 }
        );
      }
      const arraySigners = await this.documentService.getSignatarioByIdDocumentoAnexo(
        ext.idPrivado
      );

      this.listSignatariosToSign.push(...arraySigners);
    }

    await confirmedAnx.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumentoAnexo === itm.idPrivado
      );
      return {
        ...itm,
        anexoSignatarios: itm.anexoSignatarios = pessoa,
      };
    });

    for (const docs of confirmedAnx) {
      if (
        !docs.anexoSignatarios.some(
          (item) => item.cpf === this.documentService.authService.getCpfUser()
          // item.cpfRepresentante ===
          //   this.documentService.authService.getCpfUser(),
        )
      ) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Discordar documento anexado',
          'Não foi possivel discordar o documento anexado, pois o usuário logado não é signatário desse documento anexado!',
          { nzDuration: 7000 }
        );
      }

      if (
        docs.anexoSignatarios
          .filter(
            (item) => item.cpf === this.documentService.authService.getCpfUser()
            // ||
            // item.cpfRepresentante ===
            //   this.documentService.authService.getCpfUser(),
          )
          .some(
            (stats) =>
              stats.status === StatusDocumento.Desacordo ||
              stats.status === StatusDocumento.Assinado
          ) ||
        docs.status === StatusDocumento.Desacordo ||
        docs.status === StatusDocumento.Confirmado
        //  ||
        // this.documentService.docIsExpirado(docs.dataLimiteAssinatura)
      ) {
        const disagreed = docs.anexoSignatarios.some(
          (stats) => stats.status === StatusDocumento.Desacordo
        )
          ? 'signatário está com status Desacordo'
          : '';
        const signed = docs.anexoSignatarios.some(
          (stats) =>
            stats.status === StatusDocumento.Assinado ||
            docs.status === StatusDocumento.Confirmado
        )
          ? 'signatário está com status Assinado'
          : '';
        // const expired = this.documentService.docIsExpirado(
        //   docs.dataLimiteAssinatura,
        // )
        //   ? 'documento está Expirado'
        //   : '';
        this.pageLoading = false;

        return this.documentService.notification.info(
          'Discordar documento',
          `Não foi possivel discordar do anexo, pois o ${disagreed}${signed}!`,
          { nzDuration: 7000 }
        );
      }
    }

    // await confirmedDocs.map((itm) => {
    //   const pessoa = this.listSignatariosToSign.filter(
    //     (key) => key.idDocumento === itm.idPrivado,
    //   );
    //   return {
    //     ...itm,
    //     signatarios: (itm.signatarios = pessoa),
    //   };
    // });

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desacordo do documento anexado selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = this.documentService
        .getAnexosSelecionadosParaAssinar(this.anexos)
        .map((doc) => doc.idPrivado);

      await this.documentService
        .discordarAnexo(idsDocsParaAssinar, motivo)
        .then(async () => {
          await this.reloadAttach();
          // this.updateDisplayAnexos(idsDocsParaAssinar);
          modal.close();
          this.pageLoading = false;
        })
        .catch(() =>
          this.service.notification.error(
            'Discordar documento',
            'Error ao discordar documento'
          )
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.pageLoading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        // valorSelecionado: this.documentService.getValorSelecionado(
        //   this.documentList,
        // ),
        qtdSelecionados: this.documentService.getQtdAnexosSelecionados(
          this.anexos
        ),
        existMotivo: true,
        attachToSign: this.listedAttachs,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.pageLoading = false;
      }
    });
  };

  desfazer = async () => {
    this.listSignatariosToSign = [];

    const anexosCheckeds = this.anexos.filter((anx) => anx.checked).length;

    const confirmedAnx = await this.anexos.filter((doc) => doc.checked);

    if (anexosCheckeds === 0) {
      return this.documentService.notification.info(
        'Desfazer desacordo',
        'Não foi possivel desfazer a discordância do documento anexado, pois não foi selecionado nenhum anexo!',
        { nzDuration: 7000 }
      );
    }
    this.pageLoading = true;

    // for (const ext of confirmedAnx) {
    //   const arraySigners =
    //     await this.documentService.getSignatarioByIdDocumento(ext.idPrivado);

    //   this.listSignatariosToSign.push(...arraySigners);
    // }

    for (const ext of confirmedAnx) {
      if (ext.status === StatusDocumento.Confirmado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 }
        );
      }

      if (ext.status === StatusDocumento.Assinado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Assinado!',
          { nzDuration: 7000 }
        );
      }

      if (ext.status === StatusDocumento.Cancelado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 }
        );
      }

      const arrSigners = await this.documentService.getSignatarioByIdDocumentoAnexo(
        ext.idPrivado
      );

      this.listSignatariosToSign.push(...arrSigners);
    }

    await confirmedAnx.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumentoAnexo === itm.idPrivado
      );
      return {
        ...itm,
        anexoSignatarios: itm.anexoSignatarios = pessoa,
      };
    });

    for (const docs of confirmedAnx) {
      if (
        !docs.anexoSignatarios.some(
          (item) => item.cpf === this.documentService.authService.getCpfUser()
          // item.cpfRepresentante ===
          //   this.documentService.authService.getCpfUser(),
        )
      ) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento anexado, pois o usuário logado não é signatário desse documento anexado!',
          { nzDuration: 7000 }
        );
      }

      if (docs.status === StatusDocumento.Confirmado) {
        this.pageLoading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          'Não foi possivel desfazer a discordância do documento anexado, pois o documento selecionado está(ão) com status Concluído!',
          { nzDuration: 7000 }
        );
      }

      const signed = docs.anexoSignatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser()
        )
        .some((stats) => stats.status === StatusDocumento.Assinado)
        ? 'Assinado'
        : '';

      const pending = docs.anexoSignatarios
        .filter(
          (item) => item.cpf === this.documentService.authService.getCpfUser()
        )
        .some((stats) => stats.status === StatusDocumento.Pendente)
        ? 'Pendente'
        : '';

      if (signed || pending) {
        this.pageLoading = false;

        return this.documentService.notification.info(
          'Desfazer desacordo',
          `Não foi possivel desfazer a discordância do documento anexado, pois o signatário está com status ${signed}${pending}!`,
          { nzDuration: 7000 }
        );
      }
    }

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma o desfazer da discordância do documento selecionado?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (motivo) => {
      const idsDocsParaAssinar: number[] = this.documentService
        .getAnexosSelecionadosParaAssinar(this.anexos)
        .map((doc) => doc.idPrivado);

      await this.documentService
        .desfazerAnexo(idsDocsParaAssinar, motivo)
        .then(async () => {
          await this.reloadAttach();
          // this.updateDisplayAnexos(idsDocsParaAssinar);
          modal.close();
          this.pageLoading = false;
        })
        .catch(() =>
          this.service.notification.error(
            'Desfazer documento',
            'Error ao desfazer desacordo'
          )
        );
    };

    const modal = this.modal.create({
      nzTitle: title,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.pageLoading = false;
          },
        },
        {
          label: okText,
          disabled: (componentInstance) => !componentInstance.motivo,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance.motivo),
        },
      ],
      nzComponentParams: {
        // valorSelecionado: this.documentService.getValorSelecionado(
        //   this.documentList,
        // ),
        qtdSelecionados: this.documentService.getQtdAnexosSelecionados(
          this.anexos
        ),
        existMotivo: true,
        attachToSign: this.listedAttachs,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.pageLoading = false;
      }
    });
  }; //

  updateDisplayAnexos(idsAnexos: number[]) {
    if (this.anexos.length === 0 || idsAnexos?.length === 0) {
      return;
    }
    // this.loading = true;
    const listDocs = this.anexos;
    const documentList = [];
    const displayData = [];
    const indexBeforeDocs: number[] = [];
    const newDocs = [];
    for (const id of idsAnexos) {
      const indexDoc = listDocs.findIndex((anx) => anx.idPrivado === id);
      indexBeforeDocs.push(indexDoc);
    }
    newDocs.forEach((anx, index) => {
      this.documentService.formatColumnsDocForDysplay(
        anx,
        this.listSignatarios
      );
      listDocs.splice(indexBeforeDocs[index], 1, anx);
    });
    setTimeout(() => {
      this.documentService
        .setDocumentsData(
          listDocs,
          this.filterType,
          documentList,
          displayData,
          this.listSignatarios
        )
        .then((result) => Object.assign(this, result));
      // this.loading = false;
    }, 0);
  }

  check(check) {
    //this.menuFabButtons();
    this.anexos.map((keys) => ({
      ...keys,
      checked: check,
    }));

    if (
      !this.anexos.find(
        (registro) => registro.checked === false
        // && registro.status !== StatusDocumento.Assinado,
      ) ||
      (this.anexos.filter(
        (registro) => registro.checked === false
        // &&
        // registro.statusDocumento !== StatusDocumento.Assinado,
      ).length === 1 &&
        this.allChecked)
    ) {
      this.indeterminate = !this.indeterminate;
      this.allChecked = !this.allChecked;
    }
  }
  ngOnInit(): void {
    this.admUser();
    this.getInfoDoc();
    this.innerWidth = window.innerWidth;
  }

  getPanelsNames() {
    for (const names of this.anexos) {
      this.panels = [{ active: true, name: `${names.nome}` }];
    }
  }

  resetarConsulta() {
    this.checkListConsulta = {
      nome: true,
      nomeOriginal: true,
      descricao: true,
      tipoDocumentoAnexo: true,
      status: true,
      uuid: true,
      ratificado: true,
      // anexoSignatarios: true,
    };
  }

  getWidthContent(): number {
    return window.innerWidth;
  }

  async setConfigTable() {
    this.pageLoading = true;
    await this.service
      .getDocumentTableProps('documentoAnexo')
      .then((result) => {
        Object.assign(this, result);
        this.setCompareToTableColumns();
      });
    this.pageLoading = false;
  }

  setCompareToTableColumns() {
    this.tableColumns.map((column) => {
      column[`compare`] =
        this.document?.documentoAnexo?.length > 0 &&
        typeof this.document?.documentoAnexo[0][column.value] === 'string'
          ? (a: IDocumentoAnexo, b: IDocumentoAnexo) =>
              a[column.value].localeCompare(b[column.value])
          : (a: IDocumentoAnexo, b: IDocumentoAnexo) =>
              a[column.value] - b[column.value];
    });
  }

  async getInfoDoc() {
    this.pageLoading = true;
    this.getPanelsNames();
    await this.setConfigTable();

    const mergedAnexos = await this.documentService.getAllAnexosByDocument(
      this.document.idPrivado
    );

    this.anexos = await mergedAnexos?.map((itm) => {
      const descAnexo = `${itm?.tipoDocumento?.idPublico} - ${itm?.tipoDocumento?.tipo}`;
      return {
        ...itm,
        tipoDocumentoAnexo: itm.tipoDocumento.idPublico
          ? descAnexo
          : 'Não Informado',
        expand: false,
      };
    });

    this.getPanelHeader();
    this.dadosUnidadeGestora = await this.documentService.getUnidadeGestoraById(
      this.document.idUnidadeGestora
    );

    this.pageLoading = false;
  }

  getDataAnexos(title?, anexos?: IDocumentoAnexo[]) {
    if (anexos) {
      this.anexos = anexos;
      return this.anexos.filter((key) => key.vinculacao === title);
    }
    return this.anexos.filter((key) => key.vinculacao === title);
  }

  async modifyDocument(document: IDocumentoAnexo, expand: boolean, index = 0) {
    this.loadingAnexosSig = true;
    try {
      if (document.anexoSignatarios.some((a) => a.nome)) {
        this.loadingAnexosSig = false;

        this.newDocument.push(this.anexos[index]);
        return;
      }

      if (expand === true) {
        const pessoaReturned = await this.documentService.getSignatarioByIdDocumentoAnexo(
          document.idPrivado
        );

        const documentFormatted = Object.assign(document, {
          anexoSignatarios: pessoaReturned.sort((a, b) => {
            if (a.nome < b.nome) {
              return -1;
            }
            if (a.nome > b.nome) {
              return 1;
            }
            return 0;
          }),
        });
        this.loadingAnexosSig = false;
        this.newDocument.push(documentFormatted);
      }
    } catch (error) {
      this.loadingAnexosSig = false;
    }
  }

  getEmail(id: number, index) {
    let email = 'Email não encontrado';

    for (const sig of this.anexos[index].anexoSignatarios) {
      if (sig.idPessoaFisica === id) {
        email = sig.email;
      }
    }
    return email;
  }

  getPanelHeader() {
    this.loadingAnexos = true;
    const panelNames = this.anexos.map((ass) => {
      return { vinculacao: ass.vinculacao };
    });
    const anexos = panelNames.filter(
      (v, i, a) => a.findIndex((t) => t.vinculacao === v.vinculacao) === i
    );
    this.newDoc = anexos;
    this.loadingAnexos = false;
  }

  getResult(value) {
    let data;
    this.pageLoading = true;
    const hasDash = /\-/;
    const hasBar = /\//;

    if (hasBar.test(value) && value?.length === 10) {
      data = moment(value, 'DD/MM/yyyy').format('yyyy-MM-DD');
    }

    if (hasBar.test(value) && value?.length === 5) {
      const getYear = new Date().getFullYear();
      value = moment(value, 'DD/MM').format('MM-DD');
      data = `${getYear}-${value}`;
    }

    Object.assign(
      this,
      this.documentService.getResult(
        this.anexos,
        this.anexos,
        hasDash.test(data) ? data : value
      )
    );
    this.pageLoading = false;
  }

  async getSignatarios(idDoc: number) {
    this.listSignatarios = await this.documentService.getSignatarioByIdDocumento(
      idDoc
    );
  }

  async getSignatariosDetail(idDoc: number) {
    const signatario = await this.documentService.getSignatarioByIdDocumento(
      idDoc
    );
  }

  getValueDoc() {
    const valor = Number(this.document?.valorDoc);
    return valor?.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  showColorRatificado(anexoSignatario: IAnexoSignatarios[]): boolean {
    if (
      anexoSignatario.some(
        (key) => key.status === StatusDocumento.PendenteRatificacao
      )
    ) {
      return true;
    }
    return false;
  }

  getStatusDocument() {
    return (
      StatusDocLabel[this.documentService.getStatusDocumento(this.document)] ??
      ''
    );
  }

  getNomeStatusDocumento(documento: IDocumento) {
    return StatusDocLabel[this.documentService.getStatusDocumento(documento)];
  }

  getNomeStatusAnexo(anexo: IDocumentoAnexo): string {
    return StatusDocLabel[anexo.status];
  }

  getIcoStatusSigner(status: StatusDocumento) {
    return IconsStatusDoc[status];
  }

  getColorStatusSigner(status: StatusDocumento) {
    return ColorStatusDoc[status];
  }

  getIcoProcessDoc(status) {
    return IconsStatusProcessDoc[status];
  }

  getColorProcessDoc(status) {
    return ColorStatusProcessDoc[status];
  }

  getDataDocFormated() {
    return moment(this.document?.dataDocumento)
      .utcOffset(0, true)
      .format('DD/MM/YYYY - HH:mm:ss');
  }

  getLimitDateFormated() {
    return moment(this.document?.dataLimiteAssinatura)
      .utcOffset(0, false)
      .format('DD/MM/YYYY HH:mm:ss');
  }

  getDateSign(date) {
    return moment(date).utcOffset(0, true).format('DD/MM/YYYY HH:mm:ss');
  }

  getMotivoDesacordo() {
    let motivo = '';
    this.document.signatarios.map(
      (subscribe) => (motivo = subscribe?.motivoDesacordo ?? null)
    );
    return motivo;
  }

  prepareColumnValueEventTable(item, column) {
    const value = column.objectChildren
      ? item[column.objectChildren][column.value]
      : item[column.value];
    if (value && column.mask) {
      return Helper.addMask(value, column.mask);
    }
    return value;
  }

  async getURLAnexo(anexo: IDocumentoAnexo, download = false) {
    this.pageLoading = true;
    return await this.documentService
      .getURLAnexo(anexo, download)
      .then((result) => {
        this.eventTableDataList.push(result);
        this.pageLoading = false;
      })
      .catch(() => (this.pageLoading = false));
  }

  getEventColor(acao: string) {
    const colors = {
      [EventsActionsDoc.Assinar]: 'blue',
      [EventsActionsDoc.Discordar]: 'red',
      [EventsActionsDoc.DownloadCancelado]: 'black',
      [EventsActionsDoc.DownloadAssinado]: 'green',
      [EventsActionsDoc.DownloadDesacordo]: 'red',
      [EventsActionsDoc.DownloadOriginal]: 'grayLight',
      [EventsActionsDoc.Visualizar]: 'grayLight',
      [EventsActionsDoc.UsuarioCriador]: 'grayLight',
    };

    return colors[acao];
  }

  getEventIco(acao) {
    const icons = {
      [EventsActionsDoc.Assinar]: 'form',
      [EventsActionsDoc.UsuarioCriador]: 'user',
      [EventsActionsDoc.Discordar]: 'close-circle',
      [EventsActionsDoc.DownloadAssinado]: 'download',
      [EventsActionsDoc.DownloadCancelado]: 'download',
      [EventsActionsDoc.DownloadDesacordo]: 'download',
      [EventsActionsDoc.DownloadOriginal]: 'download',
      [EventsActionsDoc.DownloadAnexo]: 'download',
      [EventsActionsDoc.Visualizar]: 'file-search',
      [EventsActionsDoc.Desfazer]: `rollback`,
      [EventsActionsDoc.Cancelar]: `stop`,
      [EventsActionsDoc.VisualizarCancelado]: `file-search`,
      [EventsActionsDoc.VisualizarAssinado]: `file-search`,
      [EventsActionsDoc.VisualizarAnexo]: `folder-open`,
      [EventsActionsDoc.VisualizarAnexoAssinado]: `folder-open`,
      [EventsActionsDoc.VisualizarDocPai]: `folder-open`,
      [EventsActionsDoc.Edicao]: `file`,
      [EventsActionsDoc.AssinarAnexo]: `form`,
      [EventsActionsDoc.DiscordarAnexo]: `close-circle`,
      [EventsActionsDoc.DesfeitoAnexo]: `rollback`,
      [EventsActionsDoc.CancelarAnexo]: `stop`,
      [EventsActionsDoc.RatificarDocumento]: `diff`,
      [EventsActionsDoc.RatificarAnexo]: `diff`,
    };

    return icons[acao];
  }

  getDescEventDoc(user: string, acao: TypeActionsDoc, empresa?: string) {
    const text = empresa ? `representante da: ` : '';
    const textUserCreator =
      this.document.avulso === false ? 'via integração' : '';
    const textEmpresa = empresa ? empresa : '';

    // const prefix = this.document.signatarios.find(
    //   (key) => key.cpf === this.userInfo.cpf,
    // )
    //   ? 'O signatário'
    //   : 'A pessoa';

    const descs = {
      [EventsActionsDoc.UsuarioCriador]: `O Usuário ${user} criou o documento ${textUserCreator}`,
      [EventsActionsDoc.Assinar]: `O signatário ${user} ${text} ${textEmpresa} realizou uma assinatura`,
      [EventsActionsDoc.Visualizar]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento`,
      [EventsActionsDoc.Discordar]: `O signatário ${user} ${text} ${textEmpresa} não concordou com o documento`,
      [EventsActionsDoc.Desfazer]: `O signatário ${user} ${text} ${textEmpresa} desfez a discordância do documento`,
      [EventsActionsDoc.DownloadAssinado]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento assinado`,
      [EventsActionsDoc.DownloadCancelado]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento cancelado`,
      [EventsActionsDoc.DownloadDesacordo]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento em desacordo`,
      [EventsActionsDoc.DownloadOriginal]: `O signatário ${user} ${text} ${textEmpresa} fez o download do documento original`,
      [EventsActionsDoc.Cancelar]: `O signatário ${user} ${text} ${textEmpresa} cancelou o documento`,
      [EventsActionsDoc.VisualizarDesacordado]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento em desacordo`,
      [EventsActionsDoc.VisualizarCancelado]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento cancelado`,
      [EventsActionsDoc.VisualizarAssinado]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento assinado`,
      [EventsActionsDoc.VisualizarAnexo]: `O signatário ${user} ${text} ${textEmpresa} visualizou um documento anexado`,
      [EventsActionsDoc.VisualizarAnexoAssinado]: `O signatário ${user} ${text} ${textEmpresa} visualizou um anexo assinado`,
      [EventsActionsDoc.DownloadAnexo]: `O signatário ${user} ${text} ${textEmpresa} fez o download de um documento anexado`,
      [EventsActionsDoc.VisualizarDocPai]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento pai`,
      [EventsActionsDoc.Edicao]: `O signatário ${user} ${text} ${textEmpresa} editou este documento`,
      [EventsActionsDoc.AssinarAnexo]: `O signatário ${user} ${text} ${textEmpresa} assinou um documento anexado`,
      [EventsActionsDoc.DiscordarAnexo]: `O signatário ${user} ${text} ${textEmpresa} discordou um documento anexado`,
      [EventsActionsDoc.DesfeitoAnexo]: `O signatário ${user} ${text} ${textEmpresa} desfez a discordância do documento anexado`,
      [EventsActionsDoc.CancelarAnexo]: `O signatário ${user} ${text} ${textEmpresa} cancelou um documento anexado`,
      [EventsActionsDoc.RatificarDocumento]: `O signatário ${user} ${text} ${textEmpresa} ratificou um documento`,
      [EventsActionsDoc.RatificarAnexo]: `O signatário ${user} ${text} ${textEmpresa} ratificou um documento anexado`,
      [EventsActionsDoc.VisualizarRatificado]: `O signatário ${user} ${text} ${textEmpresa} visualizou o documento ratificado`,
    };

    return descs[acao];
  }

  sortEvents() {
    return this.document.eventos.sort((a, b) => a.idPrivado - b.idPrivado);
  }

  getLabelByStatus(assinante: IAssinante) {
    return DateLabel[assinante.status];
  }

  // verifyAllDisagreements(doc: IDocumento) {
  //   return doc.signatarios.every(key => key.status === StatusDocumento.Desacordo);
  // }

  getDateSigned(assinante: IAssinante) {
    if (
      !assinante.status ||
      DateLabel[assinante.status] === 'Pendente de assinatura'
    ) {
      return '__/__/____';
    }
    return moment(
      assinante?.dataAssinatura ||
        assinante?.dataCancelamento ||
        assinante?.dataDesacordo
    ).format('DD/MM/YYYY HH:mm:ss');
  }

  getNameSubscriber() {
    let pessoaJuridicaResponsavel;
    let pessoaFisica;
    this.listSignatarios.find(async (item) => {
      pessoaFisica = await this.service.getPessoasFisicasById(
        Number(item.idPessoaFisica)
      );
      const pessoaJuridica =
        pessoaFisica.pessoa.pessoaJuridica?.responsaveis?.idPessoaFisica;
      pessoaJuridicaResponsavel = await this.service.getPessoasFisicasById(
        pessoaJuridica
      );
    });
    return pessoaJuridicaResponsavel.pessoa.nome ?? pessoaFisica;
  }

  assinar = async () => {
    this.listSignatariosToSign = [];
    const anxCheckeds = this.anexos.filter((anx) => anx.checked).length;

    const confirmedAnx = await this.anexos.filter((anx) => anx.checked);

    if (anxCheckeds === 0) {
      return this.documentService.notification.info(
        'Assinar documento anexado',
        'Não foi possivel assinar o documento, pois não foi selecionado nenhum documento anexado!',
        { nzDuration: 7000 }
      );
    }

    this.pageLoading = true;

    for (const ext of confirmedAnx) {
      if (ext.status === StatusDocumento.Confirmado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Assinar documento anexado',
          'Não foi possivel assinar o documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Concluído!',
          { nzDuration: 7000 }
        );
      }

      if (ext.status === StatusDocumento.Assinado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Assinar documento anexado',
          'Não foi possivel assinar o documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Assinado!',
          { nzDuration: 7000 }
        );
      }

      if (ext.status === StatusDocumento.Cancelado) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Assinar documento anexado',
          'Não foi possivel assinar o documento anexado, pois o(s) anexo(s) selecionado(s) está(ão) com status Cancelado!',
          { nzDuration: 7000 }
        );
      }
      const arraySigners = await this.documentService.getSignatarioByIdDocumentoAnexo(
        ext.idPrivado
      );

      this.listSignatariosToSign.push(...arraySigners);
    }

    await confirmedAnx.map((itm) => {
      const pessoa = this.listSignatariosToSign.filter(
        (key) => key.idDocumentoAnexo === itm.idPrivado
      );
      return {
        ...itm,
        anexoSignatarios: itm.anexoSignatarios = pessoa,
      };
    });

    for (const docs of confirmedAnx) {
      // POR ENQUANTO QUE A AÇÃO DE ASSINAR COM PESSOA JURIDICA NÃO FOR HOMOLOGADA
      if (
        !docs.anexoSignatarios.some(
          (item) => item.cpf === this.documentService.authService.getCpfUser()
          // item.cpfRepresentante ===
          //   this.documentService.authService.getCpfUser(),
        )
      ) {
        this.pageLoading = false;
        return this.documentService.notification.info(
          'Assinar documento anexado',
          'Não foi possivel assinar o documento anexado, pois o usuário logado não é signatário desse documento anexado!',
          { nzDuration: 7000 }
        );
      }

      if (
        docs.anexoSignatarios
          .filter(
            (item) => item.cpf === this.documentService.authService.getCpfUser()
            // ||
            // item.cpfRepresentante ===
            //   this.documentService.authService.getCpfUser(),
          )
          .some(
            (stats) =>
              stats.status === StatusDocumento.Desacordo ||
              stats.status === StatusDocumento.Assinado
          ) ||
        docs.status === StatusDocumento.Desacordo ||
        docs.status === StatusDocumento.Confirmado
        //  ||
        // this.documentService.docIsExpirado(docs.dataLimiteAssinatura)
      ) {
        const confirmed = docs.anexoSignatarios
          .filter(
            (item) => item.cpf === this.documentService.authService.getCpfUser()
          )
          .find((stats) => stats.status === StatusDocumento.Confirmado)
          ? 'signatário está com status Confirmado'
          : '';

        const signed = docs.anexoSignatarios
          .filter(
            (item) => item.cpf === this.documentService.authService.getCpfUser()
          )
          .some((stats) => stats.status === StatusDocumento.Assinado)
          ? 'signatário está com status Assinado'
          : '';

        const disagreed = docs.anexoSignatarios
          .filter(
            (item) => item.cpf === this.documentService.authService.getCpfUser()
          )
          .find((stats) => stats.status === StatusDocumento.Desacordo)
          ? 'signatário está com status Desacordo'
          : '';

        // const expired = this.documentService.docIsExpirado(
        //   this.document.dataLimiteAssinatura,
        // )
        //   ? 'documento pai está Expirado'
        //   : '';

        this.pageLoading = false;

        return this.documentService.notification.info(
          'Assinar documento anexado',
          `Não foi possivel assinar o documento anexado, pois o ${disagreed}${confirmed}${signed}!`,
          { nzDuration: 7000 }
        );
      }
    }

    const searchCert = await this.getCertificados();

    if (searchCert) {
      return;
    }

    const newArrayDocuments = [];
    newArrayDocuments.push(this.document);

    const title = `<i nz-icon nzType="warning" nzTheme="outline"> Confirma a assinatura dos anexos selecionados?</i>`;
    const okText = 'Sim';
    const cancelText = 'Não';
    const onOk = async (componentInstance) => {
      const dateUser = new Date().toLocaleString();
      // const idsDocsParaAssinar: number[] = this.documentService
      //   .getAnexosSelecionadosParaAssinar(this.anexos)
      //   .map((doc) => doc.idPrivado);

      const newComponent = componentInstance.certByDocumentForSign.map((a) => {
        return {
          ...a,
          passwordCert: this.documentService.encryptUsingAES256(a.passwordCert),
        };
      });

      await this.documentService
        .assinarAnexo(newComponent, dateUser)
        .then(async () => {
          await this.reloadAttach();
          // this.updateDisplayAnexos(idsDocsParaAssinar);
          modal.close();
          this.pageLoading = false;
        })
        .catch((err) => {
          this.service.notification.error('Assinar documento anexado', err);
          modal.close();
          this.pageLoading = false;
        });
    };
    const modal = this.modal.create({
      nzTitle: title,
      nzWidth: 850,
      nzContent: ModalDialogComponent,
      nzFooter: [
        {
          label: cancelText,
          onClick: () => {
            modal.close();
            this.pageLoading = false;
          },
        },
        {
          label: okText,
          type: 'primary',
          onClick: (componentInstance) => onOk(componentInstance),
        },
      ],
      nzComponentParams: {
        // valorSelecionado:
        //   this.documentService.getValorSelecionado(confirmedDocs),
        qtdSelecionados: this.documentService.getQtdAnexosSelecionados(
          confirmedAnx
        ),
        docsSelecionados: newArrayDocuments,
        assinanteLogado: this.certificadoAssinante,
        signatarios: this.listSignatarios,
        attachToSign: this.listedAttachs,
        anexoSelecionado: confirmedAnx,
      },
    });

    modal.afterClose.subscribe((resultClose: any) => {
      if (!resultClose) {
        modal.destroy();
        this.pageLoading = false;
      }
    });
  };

  async reloadAttach() {
    const anexo = await this.documentService.getAllAnexosByDocument(
      this.document.idPrivado
    );

    const mergedAnexos = await anexo?.map((itm) => {
      const descAnexo = `${itm?.tipoDocumento?.idPublico} - ${itm?.tipoDocumento?.tipo}`;
      return {
        ...itm,
        tipoDocumentoAnexo: itm.tipoDocumento.idPublico
          ? descAnexo
          : 'Não Informado',
        expand: false,
      };
    });

    for (const anxTitle of mergedAnexos) {
      this.getDataAnexos(anxTitle.vinculacao, mergedAnexos);
    }
  }

  async getCertificados() {
    // await this.assinantesService
    //   .getCertificadosAssinanteLogado()
    //   .then((result) => {
    //     this.certificadoAssinante = result;
    //
    //   })
    //   .catch(() => (this.hasCertificado = false));

    return await this.assinantesService
      .getCertificadosAssinanteLogado()
      .then((res: any) => {
        if (res.every((cert) => new Date(cert.dtFinalCert) < new Date())) {
          this.documentService.notification.error(
            'Certificado',
            `Renove a validade do seu certificado digital!`
          );
          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });

          return this.router.navigate(['/session/meus-certificados']);
          // return true
          // return (this.hasCertificado = !!res?.length);
        }

        if (res.every((cert) => cert.status === StatusCertificado.Deletado)) {
          this.documentService.notification.info(
            'Certificado',
            `Carregue um certificado digital válido!`
          );
          this.documentService.tabSessionService.addTab({
            name: 'Meus Certificados',
            url: '/session/meus-certificados',
          });
          return this.router.navigate(['/session/meus-certificados']);
          // return true
          // return (this.hasCertificado = !!res?.length);
        }

        // if (res.length === 0) {
        //   this.authService.createModalTermoAceite();
        //   return true;
        //   //   // this.certificadoInvalido = true;
        //   //   // this.certificadoLabel = 'Carregue um Certificado';
        //   //   this.notification.info(
        //   //     'Certificado',
        //   //     `Carregue um certificado digital válido!`,
        //   //   );
        // }
        this.certificadoAssinante = res;
        return false;
      })
      .catch((err) => {
        return this.documentService.notification.error('Certificado', `${err}`);
      });
  }

  async previewDoc() {
    this.pageLoading = true;
    const uuid = await this.detailDocumentService.documentService.getUuidFile(
      'view',
      this.document
    );

    const urlDoc = await this.detailDocumentService.documentService.getUrlDocToUploadApi(
      uuid,
      'Visualizar'
    );

    this.pageLoading = false;

    if (this.innerWidth <= 768) {
      uuid ? window.location.assign(urlDoc.pdfURL) : null;
    } else {
      uuid ? window.open(urlDoc.pdfURL, '_blank') : null;
    }
    this.eventTableDataList.push(urlDoc.events);
    this.tooltibVisibleNzAvatar.previewDoc = true;

    setTimeout(() => {
      this.tooltibVisibleNzAvatar.previewDoc = false;
    }, 2000);
  }

  async previewDocVinculado() {
    this.pageLoading = true;

    const document = await this.documentService.getDocumentByDocOriginal({
      numeroDocOriginal: this.document.numeroDocOriginal,
      hashDocOriginal: this.document.hashDocOriginal,
    });

    if (!document) {
      this.pageLoading = false;
      return this.service.notification.warning(
        'Documento',
        'Documento cancelado, em desacordo ou inexistente'
      );
    }

    const pessoaReturned = await this.documentService.getSignatarioByIdDocumento(
      document.idPrivado
    );

    const documentFormatted = Object.assign(document, {
      signatarios: pessoaReturned.sort((a, b) => {
        if (a.nome < b.nome) {
          return -1;
        }
        if (a.nome > b.nome) {
          return 1;
        }
        return 0;
      }),
    });

    if (!document) {
      this.pageLoading = false;
      return this.documentService.notification.warning(
        'Todos os documentos',
        'Documento em desacordo ou cancelado'
      );
    }
    this.modal.create({
      nzTitle: `<h4>Detalhes do documento</h4>`,
      nzWidth: window.innerWidth * (this.resizeModal ?? 0.68),
      nzContent: DocumentsDetailComponent,
      nzComponentParams: {
        document: {
          ...documentFormatted,
          tipoDoc: document.tipoDocumento.descricao,
        },
        resizeModal: this.resizeModal ? this.resizeModal * 0.95 : 0.66,
      },
      nzFooter: [],
    });
    //usar função a baixo caso queira executar alguma ação após fechar modal
    // this.modal.afterAllClose.subscribe(() => {});

    this.pageLoading = false;
  }

  async viewDoc(doc: IDocumento) {
    this.pageLoading = true;
    let typeView = TypeDocView[doc.statusDocumento];
    let typeAction = TypeActions[doc.statusDocumento];

    if (doc.statusDocumento === StatusDocumento.Confirmado && doc.ratificado) {
      typeView = 'VisualizarRatificado';
      typeAction = 'VisualizarRatificado';
    }

    const uuid = await this.documentService.getUuidFile(typeView, doc);

    const urlDocToView = await this.documentService.getUrlDocToUploadApi(
      uuid,
      typeAction
    );
    this.pageLoading = false;

    if (this.innerWidth <= 768) {
      uuid ? window.location.assign(urlDocToView.pdfURL) : null;
    } else {
      uuid ? window.open(urlDocToView.pdfURL, '_blank') : null;
    }
    if (urlDocToView.events) {
      doc.eventos.push(urlDocToView.events);
    }
  }

  async downloadDoc() {
    const typeDownload = TypeDocDownload[this.document.statusDocumento];
    this.pageLoading = true;
    const event = await this.detailDocumentService.documentService.downloadDocWithUrlToUploadApi(
      await this.detailDocumentService.documentService.getUuidFile(
        typeDownload,
        this.document
      ),
      typeDownload,
      this.document.tipoDocumento.tipo,
      this.document.numeroDoc
    );
    this.eventTableDataList.push(event);
    setTimeout(() => (this.pageLoading = false), 3000);

    this.tooltibVisibleNzAvatar.downloadDoc = true;
    setTimeout(() => {
      this.tooltibVisibleNzAvatar.downloadDoc = false;
    }, 2000);
  }

  isActiveFabButton() {
    let pessoaFisica;
    return [
      StatusDocumento.Assinado,
      StatusDocumento.Desacordo,
      StatusDocumento.Pendente,
    ].includes(Number(this.document?.statusDocumento)) ||
      this.listSignatarios
        ?.filter(async (ass) => {
          pessoaFisica =
            (await this.service.getPessoasFisicasById(
              Number(ass.idPessoaFisica)
            )) &&
            pessoaFisica.cpf ===
              this.detailDocumentService.authService.getCpfUser();
        })
        .some((ass) => ass.status === StatusDocumento.Assinado)
      ? false
      : true;
  }
}
