<ps-complex-form
  [title]="'Setor'"
  [tabIndex]="tabIndex"
  [isAntd]="true"
  (changeTabIndex)="changeTabIndex($event)"
>
  <div body>
    <form nz-form [formGroup]="formB" nzLayout="vertical">
      <h4 class="m-t-15">Informações básicas</h4>
      <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
      <div nz-row [nzGutter]="16">
        <div nz-col nzSpan="8" nzSm="6" nzXs="24">
          <nz-form-item>
            <nz-form-label>Código</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                formControlName="idPublico"
                placeholder="código"
              />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="16" nzSm="18" nzXs="24">
          <nz-form-item>
            <nz-form-label nzRequired>Descrição do Setor</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                formControlName="descricao"
                placeholder="Descrição do setor"
                maxlength="150"
              />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </form>
  </div>

  <div table>
    <div class="row">
      <div nz-row nz-col nzSpan="12" nzOffset="12" nzJustify="end">
        <div class="m-b-10 m-r-20">
          <nz-input-group [nzPrefix]="iconLupa">
            <input
              type="text"
              nz-input
              placeholder="Buscar setor"
              nz-tooltip
              (click)="this.innerWidth <= 748 ? searchSetor() : null"
              nzTooltipTitle="Digite um valor ou clique em consultar para buscar todos os registros"
              [nzTooltipVisible]="tooltipSetor"
              nzTooltipPlacement="bottom"
              [(ngModel)]="buscarSetorInput"
              (ngModelChange)="getResult($event)"
            />
          </nz-input-group>
          <ng-template #iconLupa>
            <i nz-icon nzType="search" class="opacity-05"></i>
          </ng-template>
        </div>
      </div>
    </div>
    <nz-table
      nzSize="small"
      [nzLoading]="loading"
      [nzShowPagination]="true"
      #table
      [nzScroll]="{ y: '420px' }"
      style="overflow-y: overlay"
      [nzData]="displayData"
    >
      <thead>
        <th
          class="p-t-15 p-b-15"
          style="background: #edf1fd; width: 2rem"
          [nzLeft]="true"
        ></th>
        <th
          class="p-t-15 p-b-15"
          style="background: #edf1fd; width: 2rem"
          [nzLeft]="getWidthContent() > 1024"
        >
          <label
            nz-checkbox
            [nzIndeterminate]="checkAllIndeterminate"
            (ngModelChange)="updateAllChecked()"
            [(ngModel)]="checkedAll"
          ></label>
        </th>
        <th
          class="p-t-15 p-b-15"
          style="background: #edf1fd; width: 2rem"
          [nzLeft]="true"
        ></th>
        <ng-container *ngFor="let column of getTableColumns">
          <th
            [ngStyle]="{ background: '#edf1fd', width: column.width }"
            *ngIf="checkList[column.columnName]"
            [nzSortFn]="column.compare"
          >
            {{ column.label }}
          </th>
        </ng-container>
        <th style="background: #edf1fd; width: 4rem">
          <button
            nz-button
            nzType="text"
            nzSize="small"
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="colunas"
          >
            <i nz-icon nzType="setting"></i>
          </button>
        </th>
      </thead>
      <tbody>
        <ng-template ngFor let-item [ngForOf]="table.data">
          <tr
            (dblclick)="dbClickEdit(item)"
            style="cursor: pointer"
            nzTooltipPlacement="left"
          >
            <td style="width: 1.6rem"></td>

            <td style="width: 2rem" [nzLeft]="getWidthContent() > 1024">
              <label nz-checkbox [(ngModel)]="item.checked"></label>
            </td>
            <td style="width: 2rem"></td>

            <ng-container *ngFor="let column of getTableColumns">
              <td
                [ngStyle]="{ width: column.width }"
                *ngIf="checkList[column.columnName]"
              >
                {{ prepareColumnValue(item, column) }}
              </td>
            </ng-container>
            <td style="width: 4rem"></td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
</ps-complex-form>

<nz-dropdown-menu #colunas="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let column of getTableColumns">
      <label nz-checkbox [(ngModel)]="checkList[column.columnName]">{{
        column.label
      }}</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetar()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>
