<nz-spin [nzSize]="'medium'" [nzSpinning]="loading">
  <ps-complex-form [title]="'Submódulos'" [tabIndex]="tabIndex" [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)">

    <div body>
      <form nz-form [formGroup]="formB" nzLayout="vertical">
        <h4 class="m-t-15">Informações básicas</h4>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row>
          <div class="pl-1 pr-1" nz-col nzSpan="8" nzSm="6" nzXs="24">
            <nz-form-item>
              <nz-form-label>Código</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="idPublico" placeholder="Código" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="pl-1 pr-1" nz-col nzSpan="16" nzSm="18" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Descrição do submódulo</nz-form-label>
              <nz-form-control>
                <input nz-input formControlName="descricao" placeholder="Descrição do submódulo" maxlength="150" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="5" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
            <nz-form-item>
              <nz-form-label nzRequired>Código Módulo</nz-form-label>
              <nz-form-control [nzErrorTip]="codTipoDocTpl">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonModulo">
                  <input type="number" nz-input placeholder="Digite o código do módulo"
                    formControlName="idModuloPublico" (ngModelChange)="searchModulo($event)" />
                </nz-input-group>
                <ng-template #suffixIconButtonModulo>
                  <button *ngIf="formB.value.idModuloPublico" (click)="limparEntidadeModulo()" nz-button type="button"
                    nzType="text" nzSearch>
                    <i nz-icon nzType="close-circle"></i>
                  </button>

                  <button (click)="showModalModulo()" nz-button type="button" nzType="primary" nzSearch>
                    <i nz-icon nzType="search"></i>
                  </button>
                </ng-template>

                <ng-template #codTipoDocTpl let-control>
                  <ng-container *ngIf="control.hasError('codTipoDocumento')">
                    Valor inválido para Tipo de documento!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    Por favor digite um tipo de documento!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="19" nzXl="19" nzLg="12" nzXs="24" class="p-r-8">
            <nz-form-item>
              <nz-form-label nzRequired>Descrição do módulo</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input nz-input class="text-disabled" formControlName="descricaoModulo"
                  placeholder="Descrição do módulo" maxlength="150" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="12" class="text-left d-block">
            <h4 class="m-t-15">Tipos de Documento</h4>
          </div>
        </div>
        <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
        <div nz-row class="align-items-center">
          <nz-spin nz-col nzSpan="20" nzXl="20" nzXs="24" [nzSpinning]="loadingTipoDoc">
            <div nz-row>
              <div nz-col nzSpan="5" nzXl="5" nzLg="12" nzXs="24" class="p-r-15">
                <nz-form-item>
                  <nz-form-label>Código</nz-form-label>
                  <nz-form-control [nzErrorTip]="codTipoDocTpl">
                    <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonTpDoc">
                      <input type="number" nz-input placeholder="Digite o código do tipo de documento"
                        formControlName="idTipoDocPublico" (ngModelChange)="searchTipoDoc($event)" />
                    </nz-input-group>
                    <ng-template #suffixIconButtonTpDoc>
                      <button *ngIf="formB.value.idTipoDocPublico" (click)="limparEntidadeTipoDoc()" nz-button
                        type="button" nzType="text" nzSearch>
                        <i nz-icon nzType="close-circle"></i>
                      </button>

                      <button (click)="showModalTipoDoc()" nz-button type="button" nzType="primary" nzSearch>
                        <i nz-icon nzType="search"></i>
                      </button>
                    </ng-template>

                    <!-- <ng-template #codTipoDocTpl let-control>
                    <ng-container *ngIf="control.hasError('idTipoDocPublico')">
                      Valor inválido para Tipo de documento!
                    </ng-container>
                    <ng-container *ngIf="control.hasError('required')">
                      Por favor digite um tipo de documento!
                    </ng-container>
                  </ng-template> -->
                  </nz-form-control>
                </nz-form-item>
              </div>

              <div nz-col nzSpan="8" nzXl="8" nzLg="12" nzXs="24" class="p-r-15">
                <nz-form-item>
                  <nz-form-label>Descrição Tipo de documento</nz-form-label>
                  <nz-form-control nzHasFeedback>
                    <input nz-input class="text-disabled" formControlName="tipoDocumentoSubmodulo"
                      placeholder="Tipo de documento" maxlength="150" />
                  </nz-form-control>
                </nz-form-item>
              </div>

            </div>
          </nz-spin>

          <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="text-right d-block">
            <button nz-button [disabled]="!formB.value.entidadeTipoDoc" (click)="limparTipoDoc()" nzType="primary"
              nzDanger>
              <i nz-icon nzType="clear"></i>
            </button>
          </div>

          <div nz-col nzSpan="2" nzXl="2" nzXs="12" class="p-l-10">
            <button nz-button [disabled]="!formB.value.entidadeTipoDoc" (click)="addTipoDoc()" nzType="primary">
              <i nz-icon nzType="plus"></i>
            </button>
          </div>
        </div>

        <hr class="m-t-25 m-b-25" />

        <div>
          <nz-table class="m-b-20" nzSize="small" #tipoDocListTable [nzScroll]="{ y: '210px' }"
            style="overflow-y: overlay" [nzData]="formB.value.tiposDocumento" [nzShowPagination]="true">
            <thead>
              <tr>
                <th>Tipos de documento</th>
                <th nzWidth="30%" style="background: #edf1fd; text-align: center" [nzRight]="1024">
                  Ações
                </th>
                <th style="background: #edf1fd" nzWidth="5%">
                  <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click"
                    [nzDropdownMenu]="colunas">
                    <i nz-icon nzType="setting"></i>
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-template ngFor let-item [ngForOf]="tipoDocListTable.data">
                <tr>
                  <td>
                    <div nz-row>
                      <div nz-col nzSpan="24">
                        <p class="m-b-0">{{ item.idPublico }}</p>
                      </div>
                      <div nz-col nzSpan="24">
                        <p class="m-b-0" style="color: black; font-size: 16">
                          {{ item.descricao }}
                        </p>
                      </div>
                    </div>
                  </td>

                  <td nzRight style="text-align-last: center">
                    <button *ngIf="formB.value.tiposDocumento.length > 0" nz-button nz nzSize="small" nzShape="circle"
                      (click)="removeTipoDoc(item.idTipoDocPublico)">
                      <i nz-icon nzType="delete"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </nz-table>
        </div>

      </form>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
    <div table>
      <div class="row">
        <div nz-row nz-col nzSpan="12" nzOffset="12" nzJustify="end">
          <div class="m-b-10 m-r-20">
            <nz-input-group [nzPrefix]="iconLupa">
              <input type="text" nz-input placeholder="Buscar submódulos" nz-tooltip
                (click)="this.innerWidth <= 748 ? searchSubmodulo() : null"
                nzTooltipTitle="Digite um valor ou clique em consultar para buscar todos os registros"
                [nzTooltipVisible]="tooltipSubmodulo" nzTooltipPlacement="bottom" [(ngModel)]="buscarSubmoduloInput"
                (ngModelChange)="getResult($event)" />
            </nz-input-group>
            <ng-template #iconLupa>
              <i nz-icon nzType="search" class="opacity-05"></i>
            </ng-template>
          </div>
        </div>
      </div>
      <nz-table nzSize="small" [nzLoading]="loading" [nzShowPagination]="true" #table [nzScroll]="{ y: '420px' }"
        style="overflow-y: overlay" [nzData]="displayData">
        <thead>
          <th class="p-t-15 p-b-15" style="background: #edf1fd; width: 2rem" [nzLeft]="true"></th>
          <th class="p-t-15 p-b-15" style="background: #edf1fd; width: 2rem" [nzLeft]="getWidthContent() > 1024">
            <label nz-checkbox [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
              [(ngModel)]="checkedAll"></label>
          </th>
          <th class="p-t-15 p-b-15" style="background: #edf1fd; width: 2rem" [nzLeft]="true"></th>
          <ng-container *ngFor="let column of getTableColumns">
            <th [ngStyle]="{ background: '#edf1fd', width: column.width }" *ngIf="checkList[column.columnName]"
              [nzSortFn]="column.compare">
              {{ column.label }}
            </th>
          </ng-container>
          <th style="background: #edf1fd; width: 4rem">
            <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click" [nzDropdownMenu]="colunas">
              <i nz-icon nzType="setting"></i>
            </button>
          </th>
        </thead>
        <tbody>
          <ng-template ngFor let-item [ngForOf]="table.data">
            <tr (dblclick)="dbClickEdit(item)" style="cursor: pointer" nzTooltipPlacement="left">
              <td style="width: 1.6rem"></td>

              <td style="width: 2rem" [nzLeft]="getWidthContent() > 1024">
                <label nz-checkbox [(ngModel)]="item.checked"></label>
              </td>
              <td style="width: 2rem"></td>

              <ng-container *ngFor="let column of getTableColumns">
                <td [ngStyle]="{ width: column.width }" *ngIf="checkList[column.columnName]">
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
              <td style="width: 4rem"></td>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>
<nz-dropdown-menu #colunas="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let column of getTableColumns">
      <label nz-checkbox [(ngModel)]="checkList[column.columnName]">{{
        column.label
        }}</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetar()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>