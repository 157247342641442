<ps-complex-form [title]="'Tipo de Documento'" [tabIndex]="tabIndex" [isAntd]="true"
  (changeTabIndex)="changeTabIndex($event)">
  <div table>
    <div class="row">
      <div nz-row nz-col nzSpan="12" nzOffset="12" nzJustify="end">
        <div class="m-b-10 m-r-20">
          <nz-input-group [nzPrefix]="prefixTemplate">
            <input type="text" nz-input placeholder="Buscar tipo de documento" nz-tooltip
              nzTooltipTitle="Digite um valor ou clique em consultar para buscar todos os registros"
              (click)="this.innerWidth <= 748 ? searchTiposDocumentos() : null"
              [nzTooltipVisible]="tooltipTiposDocumentos" nzTooltipPlacement="bottom" [(ngModel)]="searchInput"
              (ngModelChange)="getResult()" />
          </nz-input-group>
          <ng-template #prefixTemplate>
            <i nz-icon nzType="search" class="opacity-05"></i>
          </ng-template>
        </div>
      </div>
    </div>

    <nz-table nzSize="small" [nzLoading]="loading" [nzShowPagination]="true" #documentListTable
      [nzScroll]="{ y: '420px' }" style="overflow-y: overlay" [nzData]="displayData">
      <thead>
        <tr>
          <th class="p-t-15 p-b-15" style="background: #edf1fd" [nzLeft]="false" nzWidth="15px"></th>
          <th class="p-t-15 p-b-15" style="background: #edf1fd" nzWidth="60px" [nzLeft]="true">
            <label nz-checkbox [nzIndeterminate]="checkAllIndeterminate" [(ngModel)]="checkedAll">
            </label>
          </th>
          <ng-container *ngFor="let column of getTableColumns()">
            <th nzWidth="150px" *ngIf="checkList[column.value]" [nzSortFn]="column.compare">
              {{ column.text }}
            </th>
          </ng-container>
          <th style="background: #edf1fd" nzWidth="40px">
            <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click" [nzDropdownMenu]="colunas">
              <i nz-icon nzType="setting"></i>
            </button>
          </th>
        </tr>
      </thead>

      <tbody>
        <ng-template ngFor let-item [ngForOf]="documentListTable.data">
          <tr (dblclick)="dbClickItem(item)" style="cursor: pointer" nzTooltipPlacement="left">
            <td></td>

            <td [nzLeft]="getWidthContent() > 1024">
              <label nz-checkbox [(ngModel)]="item.checked" (ngModelChange)="checkItemPesquisa(item)"></label>
            </td>
            <ng-container *ngFor="let column of getTableColumns()">
              <td *ngIf="checkList[column.value]">
                {{ prepareColumnValue(item, column) }}
              </td>
            </ng-container>
            <td></td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>

    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>

  <div body>
    <nz-spin [nzSpinning]="loading">
      <form nz-form [formGroup]="formB" nzLayout="vertical">
        <ng-template #infoBasic>
          <h4>Informações básicas</h4>
        </ng-template>

        <nz-divider [nzText]="infoBasic" nzOrientation="left"></nz-divider>

        <div nz-row>
          <div class="p-r-15" nz-col nzSpan="3" nzSm="4" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Código</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input class="text-disabled" nz-input formControlName="idPublico" placeholder="Digite um código"
                  maxlength="150" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="p-r-15" nz-col nzSpan="7" nzSm="7" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Tipo do documento</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="cpfTpl">
                <input nz-input formControlName="tipo" placeholder="Digite um tipo de documento válido"
                  maxlength="150" />
                <ng-template #cpfTpl let-control>
                  <ng-container *ngIf="control.hasError('tipo')">
                    Valor inválido para tipo de documento!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    Por favor digite um tipo de documento!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="14" nzSm="13" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Descrição do tipo</nz-form-label>
              <nz-form-control nzHasFeedback [nzErrorTip]="descTpl">
                <input nz-input formControlName="descricao"
                  placeholder="Digite uma breve descrição do tipo de documento" maxlength="150" />
                <ng-template #descTpl let-control>
                  <ng-container *ngIf="control.hasError('descricao')">
                    Valor inválido para descrição do tipo de documento!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    Por favor digite uma descrição para o tipo de documento!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div class="p-r-15" nz-col nzSpan="11" nzSm="11" nzXs="24">
            <nz-form-item>
              <nz-form-label>Tag</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input nz-input formControlName="tag" placeholder="Digite uma tag" maxlength="100" />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="13" nzSm="13" nzXs="24">
            <nz-form-item>
              <nz-form-label>Título do manifesto</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input nz-input formControlName="tituloManifesto" placeholder="Digite um título para o manifesto"
                  maxlength="100" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div nz-row>
          <div nz-col nzSpan="24" nzSm="24" nzXs="24">
            <nz-form-item>
              <nz-form-label>Texto complementar</nz-form-label>
              <nz-form-control nzHasFeedback>
                <textarea nz-input formControlName="textoComplementar"
                  placeholder="Digite um texto complementar para o manifesto" maxlength="500"></textarea>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <ng-container>
          <div nz-row nzGutter="8">
            <div nz-col nzSpan="23" nzSm="23" nzXl="23" nzXs="23">
              <h4 class="p-l-5 p-l-10">Atestadores</h4>
            </div>
            <div nz-col nzSpan="1" nzSm="1" nzXl="1" nzXs="1">
              <button nz-button [disabled]="!addAtestadorValidate()" (click)="addAtestador()" nzType="primary">
                <i nz-icon nzType="plus"></i>
              </button>
            </div>
          </div>

          <nz-divider class="m-2" nzOrientation="left"></nz-divider>
          <div formArrayName="atestadores">
            <div *ngFor="let atestador of formB.get('atestadores')['controls']; index as i" [formGroupName]="i">
              <div nz-row nzGutter="8" *ngIf="!formB.get('atestadores')['value'][i]['deletado']">

                <div nz-col nzSpan="6" nzXl="6" nzLg="12" nzXs="24">
                  <nz-form-item>
                    <nz-form-label>CPF do Atestador</nz-form-label>
                    <nz-form-control>
                      <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonIdPessoaAtestador">
                        <input (click)="showModalAtestador(i)" nz-input placeholder="Digite um cpf"
                          formControlName="cpfAtestador" (keydown)="showModalAtestador(i)" [mask]="'000.000.000-00'"
                          [dropSpecialCharacters]="true" />
                      </nz-input-group>
                      <ng-template #suffixIconButtonIdPessoaAtestador>
                        <button *ngIf="atestador.value.cpfAtestador && !atestador.value.idPrivado"
                          (click)="limparEntidadeAtestardor(i)" nz-button type="button" nzType="text" nzSearch>
                          <i nz-icon nzType="close-circle"></i>
                        </button>

                        <button *ngIf="!atestador.value.idPrivado"
                          (click)="showModalAtestador(i) && !getAtestadorFormBuild(i)" nz-button type="button"
                          nzType="primary" nzSearch>
                          <i nz-icon nzType="search"></i>
                        </button>
                      </ng-template>

                      <ng-template #cpfIdPessoaAtestador let-control>
                        <ng-container *ngIf="control.hasError('cpfPessoaAtestador')">
                          Valor inválido para CPF!
                        </ng-container>

                      </ng-template>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="11" nzXl="11" nzLg="11" nzXs="24">
                  <nz-form-item>
                    <nz-form-label>Nome do Atestador</nz-form-label>
                    <nz-form-control>
                      <input nz-input class="text-disabled" [disabled]="true" formControlName="nomeAtestador"
                        placeholder="Nome do Atestador" maxlength="100" />
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="6" nzXl="6" nzLg="6" nzXs="6">
                  <nz-form-item>
                    <nz-form-label nzRequired>Data inicio Atesto</nz-form-label>
                    <nz-form-control>
                      <nz-date-picker nzShowTime style="width: 100%;" nzFormat="dd/MM/yyyy HH:mm:ss"
                        formControlName="dataInicioAtesto" nzPlaceHolder="Data de expiração"
                        (ngModelChange)="checkDataExpiracao($event,i)"></nz-date-picker>
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col nzSpan="1" nzSm="1" nzXl="1" nzXs="12">
                  <nz-form-item>
                    <nz-form-label>&nbsp;</nz-form-label>
                    <nz-form-control>
                      <button nz-button *ngIf="!formB.get('atestadores')['value'][i]['idPrivado']"
                        (click)="limparAtestadorForm(i)" [disabled]="!getAtestadorFormBuild(i)" nzType="primary"
                        nzDanger>
                        <i nz-icon nzType="clear"></i>
                      </button>
                      <button nz-button *ngIf="formB.get('atestadores')['value'][i]['idPrivado']"
                        (click)="deletarAtestador(i)" nzType="primary" nzDanger>
                        <i nz-icon nzType="delete"></i>
                      </button>
                    </nz-form-control>
                  </nz-form-item>

                </div>
              </div>
              <!-- <div nz-col nzSpan="1" nzSm="1" nzXl="1" nzXs="12">
                  <nz-form-item>
                    <nz-form-label>&nbsp;</nz-form-label>
                    <nz-form-control>
                      <button nz-button [disabled]="entidadeAtestadorArr[i]" (click)="addAtestador()" nzType="primary">
                        <i nz-icon nzType="plus"></i>
                      </button>
                    </nz-form-control>
                  </nz-form-item>
                </div> -->
            </div>
          </div>
        </ng-container>
      </form>
    </nz-spin>
    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
</ps-complex-form>

<nz-dropdown-menu #colunas="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let column of getTableColumns()">
      <label nz-checkbox [(ngModel)]="checkList[column.value]">{{
        column.text
        }}</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetar()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>