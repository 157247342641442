import { Component, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IMenuButton } from '@ps-erp-angular/ps-ui';
import {
  DocumentService,
  IColumnsFieldsProps,
  IMensagem,
  MensagensService,
} from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { MentionOnSearchTypes } from 'ng-zorro-antd/mention';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'ps-digitalizacao-mensagens',
  templateUrl: './mensagens.component.html',
  styleUrls: ['./mensagens.component.scss'],
})
export class MensagensComponent implements OnInit {
  tabIndex: 1 | 0;
  fabButtons;
  inputValue?: string;
  suggestionsLoading = false;
  suggestions: string[] = [];
  loading: boolean;

  openModal: boolean = false;

  tableColumns: IColumnsFieldsProps[] = [];
  displayData: Array<IMensagem> = [];
  checkAllIndeterminate;
  checkedAll;

  arrColumnsMensagensSelect = [];
  tableMensagemColumns;
  fieldMensagensSelected;

  selectedMensagensValues;
  fieldsMensagensSelected = [];
  showComboTagsMensagens;
  searchInput: any;

  checkList = {
    idPublico: true,
    titulo: true,
    texto: true,
  };

  countCheked;
  manutencaoLoading: boolean;

  chave;
  prefixTemplate;

  formB: FormGroup = new FormGroup({});

  constructor(
    @Optional()
    private modalRef: NzModalRef<MensagensComponent>,
    private formBuilder: FormBuilder,
    private service: MensagensService,
    private documentService: DocumentService,
    private activateRouter: ActivatedRoute,
    private modal: NzModalService
  ) {
    this.tabIndex = 0;
    this.activateRouter.queryParamMap.subscribe(async (queryParams) => {
      this.setConfigMensagemTable();
      await this.setConfigMensagemFilter();
    });
    this.resetForm();
    this.suggestions = [
      'NOME_DO_DESTINATARIO',
      'CIDADE_DO_DESTINATARIO',
      'NÚMERO_DO_DESTINATARIO',
      'CARGO_DO_DESTINATARIO',
      'EMAIL_DO_DESTINATARIO',
    ];
  }

  prepareColumnValue(item, column) {
    const value = column.objectChildren
      ? item[column.value][column.objectChildren]
      : item[column.value];
    return value;
  }

  getWidthContent() {
    return window.innerWidth;
  }

  removeFiltroMensagemPorField(fieldsValuesTag) {
    const tableExportColumn = this.tableMensagemColumns.find(
      (e) => e.value === fieldsValuesTag.props
    );

    const item = {
      value: tableExportColumn.value,
      text: tableExportColumn.text,
      type: tableExportColumn.type,
    };

    this.arrColumnsMensagensSelect.splice(
      this.tableMensagemColumns.indexOf(tableExportColumn),
      0,
      item
    );

    this.fieldsMensagensSelected.splice(
      this.fieldsMensagensSelected.indexOf(fieldsValuesTag),
      1
    );

    if (this.fieldsMensagensSelected.length === 0) {
      this.showComboTagsMensagens = false;
    }
  }

  async getOptionsSelect(column) {
    this.formB.controls.mensagem.setValue(
      `${this.formB.get('mensagem').value ?? ''} @${column}`
    );
    const suggestions = this.suggestions;
    this.suggestions = [];

    this.chave = null;
    this.suggestions = suggestions;
  }

  ngModelChange(event) {
    //
  }

  async getFilterMensagemOptionsSelect(column) {
    let arrTypes = [];
    this.selectedMensagensValues = [];

    for (const teste of arrTypes) {
      this.selectedMensagensValues.push({
        field: this.fieldMensagensSelected.text,
        value: column.objectChildren === 'tag' ? teste.tag : teste.tipo,
        text:
          column.objectChildren === 'tag'
            ? teste.tag
            : `${teste.idPublico} - ${teste.tipo}`,
      });
    }
  }

  onSearchChange({ value }: MentionOnSearchTypes) {
    //
  }

  onChange(value: string): void {
    //
  }

  onSelect(suggestion: string): void {
    //
  }

  buildFabButtons(fabButtons: IMenuButton[]) {
    this.fabButtons = fabButtons;
  }
  dbClickItem(mensagem) {
    if (this.openModal && this.tabIndex === 1)
      return this.selectMensagem(mensagem);

    this.formB.patchValue({ ...mensagem, id: mensagem.idPublico });
    this.tabIndex = 0;
  }
  selectItem(item) {
    this.displayData = this.displayData.map((data) => {
      if (data.id === item.id) {
        return data;
      }
      return { ...data, checked: !data.checked };
    });
  }

  mensagensChecadas(): IMensagem[] {
    return this.displayData.filter(
      (data: IMensagem) => data.checked
    ) as IMensagem[];
  }

  desmarcarTodasMensagens() {
    this.displayData = this.displayData.map((mensagem) => {
      return { ...mensagem, checked: false };
    });
  }

  resetFabButtons() {
    const fabButtons: IMenuButton[] = [
      {
        icon: 'plus',
        tooltip: 'novo cadastro',
        condition: !this.openModal,
        onClick: this.novoCadastro,
      },
      {
        icon: 'save',
        tooltip: 'Salvar',
        color: 'green',
        condition: this.tabIndex === 0 && !this.openModal,
        onClick: this.salvar,
      },
      {
        icon: 'edit',
        tooltip: 'Editar',
        color: 'yellow',
        condition: this.tabIndex === 1 && !this.openModal,
        onClick: this.editar,
      },
      {
        icon: 'delete',
        tooltip: 'Deletar',
        color: 'red',
        condition: this.tabIndex === 1 && !this.openModal,
        onClick: this.deletar,
      },
      {
        icon: 'search',
        tooltip: 'Consultar',
        condition: this.tabIndex === 0 && !this.openModal,
        onClick: this.consultar,
      },
      {
        icon: 'reload',
        tooltip: 'Atualizar',
        // color: 'blue',
        condition: this.tabIndex === 1 || this.tabIndex === 0,
        onClick: this.attMensagemTable,
      },
      {
        icon: 'select',
        tooltip: 'Selecionar',
        condition: this.openModal && this.tabIndex === 1,
        onClick: this.selecionar,
      },
    ];

    const fabButtonsFiltered = fabButtons.filter((button) => button.condition);

    this.buildFabButtons(fabButtonsFiltered);
  }

  novoCadastro = () => {
    this.resetarFormulario(), this.changeTabIndex(0);
  };

  selecionar = () => {
    const mensagens = this.mensagensChecadas();
    if (mensagens.length !== 1) {
      return this.service.notification.warning(
        'Alterar',
        'Nenhum item selecionado!'
      );
    }
    // o que isso faz: novo recurso do javascript usado para remover uma key de um objeto
    const { idPublico, ...applyMolde } = {
      ...mensagens[0],
      idModelo: mensagens[0].idPublico,
    };
    this.modalRef.destroy(applyMolde);
  };

  selectMensagem(mensagem) {
    // o que isso faz: novo recurso do javascript usado para remover uma key de um objeto
    const { idPublico, ...applyMolde } = {
      ...mensagem,
      idPublico: null,
      idModelo: mensagem.idPublico,
    };
    this.modalRef.destroy(applyMolde);
  }

  salvar = async () => {
    if (this.formB.invalid) {
      return this.service.notification.warning(
        'Formulário',
        'Por favor, preencha todos os campos corretamente.'
      );
    }
    const dataMessage = {};
    for (const key in this.formB.controls) {
      dataMessage[key] = this.formB.controls[key].value;
    }
    this.manutencaoLoading = true;
    if ((dataMessage as IMensagem).id) {
      await this.service.editarMensagemModel(dataMessage as IMensagem);
      this.resetarFormulario();
      this.manutencaoLoading = false;
      this.attMensagemTable();
      return;
    }
    await this.service.saveMensageModel(dataMessage as IMensagem);
    this.resetarFormulario();
    this.manutencaoLoading = false;
    this.attMensagemTable();
    return;
  };

  editar = () => {
    const mensagens = this.mensagensChecadas();
    if (mensagens.length !== 1) {
      this.service.notification.info(
        'Mensagens',
        'Não foi possível editar a mensagem pois exitem muitos modelos selecionados ou nenhum.'
      );
      this.desmarcarTodasMensagens();
      return;
    }
    const mensagem = mensagens[0];

    this.formB.patchValue({ ...mensagem, id: mensagem.idPublico });
    this.changeTabIndex(0);
  };

  deletar = () => {
    const mensagens = this.mensagensChecadas();
    if (mensagens.length !== 1) {
      this.service.notification.info(
        'Mensagens',
        'Não foi possível deletar a mensagem pois exitem muitos modelos selecionados ou nenhum.'
      );
      this.desmarcarTodasMensagens();
      return;
    }
    const mensagem = mensagens[0];
    this.modal.confirm({
      nzTitle: '<i>Deseja excluir esse modelo de mensagem?</i>',
      nzOnOk: () => {
        this.service.deletarMensagem(mensagem.idPublico);
        this.displayData = this.displayData.filter(
          (msg) => !(msg.idPublico === mensagem.idPublico)
        );
      },
    });
  };

  consultar = () => {
    this.changeTabIndex(1);
  };

  attMensagemTable = () => {
    this.changeTabIndex(1);
    this.filterMensagens();
  };

  ngOnInit(): void {
    if (this.tabIndex && this.openModal) {
      this.attMensagemTable();
    }

    this.resetForm();

    this.resetFabButtons();
  }

  resetForm() {
    this.formB = this.formBuilder.group({
      id: [{ value: null, disabled: true }],
      titulo: [{ value: null, disabled: false }, [Validators.required]],
      texto: [{ value: null, disabled: false }, [Validators.required]],
    });
  }

  resetarFormulario(): void {
    this.resetForm();
    this.formB.reset();
  }

  filterMensagens() {
    return this.getMensagensPerPeriod(this.fieldsMensagensSelected);
  }

  async getMensagensPerPeriod(fieldsMensagensSelected) {
    this.loading = true;
    await this.service
      .getAllMensagensInInterval(fieldsMensagensSelected)
      .then(async (mensagens: any) => {
        if (mensagens.data.length === 0) {
          this.service.notification.warning(
            'Mensagens',
            'Nenhum registro correspondente aos filtros.',
            { nzDuration: 7000 }
          );
        }
        this.displayData = mensagens.data.map((m) => {
          return {
            ...m,
            checked: false,
            texto: m.texto,
          };
        });

        this.loading = false;
      })
      .catch((err) => {
        this.service.notification.error('Mensagens', err);
        this.loading = false;
      });
  }

  async setConfigMensagemFilter() {
    this.loading = true;

    await this.service.getMensagemTableProps('mensagem').then((result) => {
      Object.assign(this, result);

      this.documentService.setCompareToTableColumns(
        this.displayData,
        this.tableColumns
      );
    });
    this.loading = false;
  }

  async setConfigMensagemTable() {
    this.loading = true;
    await this.service.getMensagemTableProps('mensagem').then((result) => {
      const tableColumnsMensagens = result.tableColumnsAnexo.map((column) => {
        return {
          ...column,
          compare:
            column.value === 'idPublico' || column.value === 'pastas'
              ? (a, b) => a[column.value] - b[column.value]
              : (a, b) => a[column.value].localeCompare(b[column.value]),

          width:
            column.value === 'idPublico'
              ? '8rem'
              : column.value === 'titulo'
              ? '15rem'
              : column.value === 'dtCriacao'
              ? '20rem'
              : null,
        };
      });

      this.tableMensagemColumns = tableColumnsMensagens;

      this.arrColumnsMensagensSelect = result.searchColumns;
      return;
    });
    this.loading = false;
  }

  updateAllChecked() {
    if (this.displayData.length != 0) {
      this.checkAllIndeterminate = !this.checkAllIndeterminate;
      this.checkedAll = !this.checkedAll;
      this.displayData.forEach((e) => (e.checked = this.checkedAll));
    }
    let map = [];
    this.displayData.forEach((e) => map.push(e.checked));
  }

  check(file) {
    this.countCheked = this.displayData.filter((e) => e.checked).length;
  }

  addFiltroValuesPorCampo() {
    if (this.fieldMensagensSelected && this.searchInput) {
      if (this.fieldMensagensSelected.type === 'range-picker') {
        this.searchInput = `${moment(this.searchInput[0]).format(
          'DD/MM/yyyy'
        )} - ${moment(this.searchInput[1]).format('DD/MM/yyyy')}`;
      }

      if (this.fieldMensagensSelected.type === 'date-picker') {
        this.searchInput = moment(this.searchInput).format('DD/MM/yyyy');
      }
      this.fieldsMensagensSelected.push({
        props: this.fieldMensagensSelected.value,
        field: this.fieldMensagensSelected.text,
        value: this.searchInput.text ? this.searchInput.text : this.searchInput,
        text: `${this.fieldMensagensSelected.text} - ${
          this.searchInput.text ? this.searchInput.text : this.searchInput
        }`,
      });

      const index = this.arrColumnsMensagensSelect.findIndex(
        (a) => a.value === this.fieldMensagensSelected.value
      );

      this.arrColumnsMensagensSelect.splice(index, 1);

      this.showComboTagsMensagens = true;
      this.fieldMensagensSelected = '';
      this.searchInput = '';
      this.selectedMensagensValues = [];
    }
  }

  async changeTabIndex(value, search = true) {
    this.tabIndex = value;
    this.resetFabButtons();
    if (this.tabIndex === 1 && search === true) {
      return;
    }
  }
}
