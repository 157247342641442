<nz-spin [nzSpinning]="isSpinning">
  <div class="header">
    <div class="header-child">
      <div class="nav-wrap d-flex flex-sm-row flex-column">
        <div class="nav-left">
          <nz-avatar [nzSize]="32" [nzSrc]="'../../favicon.ico'"></nz-avatar>
          <h5 class="m-l-15 mt-2 m-r-15">Publicsoft</h5>
        </div>
      </div>
    </div>
  </div>

  <ng-template #cardTitle>
    <h4>
      <i class="m-r-10" nz-icon nzType="file-protect"></i> Assinar documento
    </h4>
  </ng-template>

  <nz-card
    [nzTitle]="cardTitle"
    [nzLoading]="loading"
    style="
      margin-top: 100px !important;
      margin-bottom: 30px !important;
      max-width: 1290px;
      margin: auto;
    "
  >
    <div *ngIf="document">
      <div
        class="align-items-center p-10 shadow-default"
        nz-row
        style="background: #edf1fd"
      >
        <div nz-col nzSpan="8">
          <div *ngIf="document.valorDoc" class="media align-items-center">
            <div class="p-l-15">
              <h5 class="m-b-0">Valor documento</h5>
              <h4 class="m-b-0">
                <b>{{ getValueDoc() }}</b>
              </h4>
            </div>
          </div>
        </div>

        <div
          class="p-r-15"
          nz-col
          [nzSpan]="document.valorDoc ? '14' : '22'"
          nzOffset="2"
        >
          <div nz-row>
            <div
              nz-col
              nzSpan="24"
              class="p-l-15 align-items-center form-group"
              style="text-align: end"
            >
              <h5 class="m-b-0">Status documento</h5>
              <h5 class="m-b-0 p-l-5 p-r-10">
                <b>{{ getStatusDocument() }}</b>
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div
        class="align-items-center p-10 m-t-10 shadow-default"
        nz-row
        style="background: #fdfdff"
      >
        <div class="p-l-15 p-t-10 m-b-10 align-items-center" nz-col nzSpan="24">
          <h5 class="m-b-0">Dados do documento</h5>
          <h6 class="text-color-value">
            {{ getDataDocFormated() }}
          </h6>
        </div>

        <div nz-col nzSpan="24" class="d-flex">
          <div
            nz-col
            nzSpan="12"
            class="p-l-15 p-t-10 align-items-center d-flex"
          >
            <div class="form-group">
              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Tipo do documento:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.tipoDocumento.tipo }}
                </h6>
              </div>

              <div *ngIf="document.numeroDoc" class="d-flex">
                <h6 class="m-b-0 p-r-5">Número do documento:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.numeroDoc }}
                </h6>
              </div>

              <div *ngIf="document.dataLimiteAssinatura" class="d-flex">
                <h6 class="m-b-0 p-r-5">Data limite de assinatura:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{
                    documentService.getDateFormatted(
                      document?.dataLimiteAssinatura
                    )
                  }}
                </h6>
              </div>
            </div>
          </div>

          <div
            nz-col
            nzSpan="12"
            nzSm="12"
            nzXs="24"
            class="p-l-15 p-t-10 align-items-center d-flex"
          >
            <div class="form-group">
              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Unidade Gestora:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.unidadeGestora }}
                </h6>
              </div>

              <div class="d-flex">
                <h6 class="m-b-0 p-r-5">Chancela:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.chancela === true ? 'Sim' : 'Não' }}
                </h6>
              </div>

              <div *ngIf="document?.nomeUnidadeOrc" class="d-flex">
                <h6 class="m-b-0 p-r-5">Unidade Orçamentária:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.nomeUnidadeOrc }}
                </h6>
              </div>

              <div *ngIf="document?.numProcesso" class="d-flex">
                <h6 class="m-b-0 p-r-5">Número do processo:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.numProcesso }}
                </h6>
              </div>

              <div *ngIf="document?.historicoDoc" class="d-flex">
                <h6 class="m-b-0 p-r-5">Descrição do documento:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.historicoDoc }}
                </h6>
              </div>

              <div *ngIf="document?.nomeFav" class="d-flex">
                <h6 class="m-b-0 p-r-5">Favorecido:</h6>
                <h6 class="m-b-0 text-color-value">
                  {{ document?.nomeFav }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <nz-spin
        *ngIf="document.documentoAnexo.length > 0"
        [nzSpinning]="loadingAnexos"
        [nzSize]="'large'"
      >
        <div
          class="align-items-center p-10 m-t-10 shadow-default"
          nz-row
          style="background: #fdfdff"
        >
          <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
            <h5>Documentos anexados</h5>
          </div>

          <div class="col-md-24 col-xl-12 p-b-15">
            <nz-collapse nzGhost>
              <nz-collapse-panel
                *ngFor="let panel of newDoc"
                [nzHeader]="panel.vinculacao"
                [nzActive]="false"
              >
                <div nz-row class="d-flex">
                  <div nz-col nzSpan="24" nzXs="24">
                    <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                      <div class="p-r-10">
                        <div>
                          <div class="p-r-10">
                            <div class="m-b-10">
                              <nz-input-group [nzPrefix]="prefixTemplate">
                                <input
                                  type="text"
                                  nz-input
                                  placeholder="Buscar documento"
                                  [(ngModel)]="searchInput"
                                  (ngModelChange)="getResult($event)"
                                />
                              </nz-input-group>
                              <ng-template #prefixTemplate>
                                <i
                                  nz-icon
                                  nzType="search"
                                  class="opacity-05"
                                ></i>
                              </ng-template>
                            </div>
                            <nz-table
                              class="m-b-20"
                              nzSize="small"
                              [nzLoading]="loading"
                              #documentListTableAttach
                              [nzShowPagination]="true"
                              [nzScroll]="{ y: '380px' }"
                              style="overflow-y: overlay"
                              [nzData]="anexos"
                            >
                              <thead>
                                <tr>
                                  <th
                                    style="background: #edf1fd"
                                    [nzLeft]="
                                      documentService.getWidthContent() > 1024
                                    "
                                    nzWidth="35px"
                                  ></th>
                                  <th
                                    style="background: #edf1fd"
                                    nzWidth="70px"
                                    [nzLeft]="
                                      documentService.getWidthContent() > 1024
                                    "
                                  >
                                    <label
                                      nz-checkbox
                                      [nzIndeterminate]="indeterminate"
                                      (ngModelChange)="updateAllChecked()"
                                      [(ngModel)]="allChecked"
                                    >
                                    </label>
                                  </th>
                                  <ng-container
                                    *ngIf="
                                      documentService.getColumnsAnexoStatus(
                                        tableColumns
                                      )
                                    "
                                  >
                                    <th
                                      nzWidth="150px"
                                      *ngIf="checkListConsulta.status"
                                      [nzSortFn]="
                                        documentService.getColumnsAnexoStatus(
                                          tableColumns
                                        ).compare
                                      "
                                    >
                                      {{
                                        documentService.getColumnsAnexoStatus(
                                          tableColumns
                                        ).text
                                      }}
                                    </th>
                                  </ng-container>
                                  <ng-container
                                    *ngFor="
                                      let column of documentService.getTableColumnsAnexos(
                                        tableColumns
                                      );
                                      let i = index
                                    "
                                  >
                                    <th
                                      nzWidth="150px"
                                      *ngIf="checkListConsulta[column.value]"
                                      [nzSortFn]="column.compare"
                                    >
                                      {{ column.text }}
                                    </th>
                                  </ng-container>
                                  <th
                                    style="background: #edf1fd"
                                    nzWidth="110px"
                                    [nzRight]="
                                      documentService.getWidthContent() > 1024
                                    "
                                  >
                                    Visualizar
                                  </th>
                                  <th
                                    style="background: #edf1fd"
                                    nzWidth="110px"
                                    [nzRight]="
                                      documentService.getWidthContent() > 1024
                                    "
                                  >
                                    Baixar
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-template
                                  ngFor
                                  let-item
                                  [ngForOf]="documentListTableAttach.data"
                                  let-indexArr="index"
                                >
                                  <tr>
                                    <td
                                      [nzLeft]="
                                        documentService.getWidthContent() > 1024
                                      "
                                      [(nzExpand)]="item.expand"
                                      (click)="
                                        modifyDocument(
                                          item,
                                          item.expand,
                                          indexArr
                                        )
                                      "
                                    ></td>
                                    <td
                                      [nzLeft]="
                                        documentService.getWidthContent() > 1024
                                      "
                                      style="padding: 6px"
                                    >
                                      <label
                                        nz-checkbox
                                        [(ngModel)]="item.checked"
                                      ></label>
                                    </td>

                                    <ng-container
                                      *ngIf="
                                        documentService.getColumnsAnexoStatus(
                                          tableColumns
                                        )
                                      "
                                    >
                                      <td *ngIf="checkListConsulta.status">
                                        <nz-tag
                                          class="m-b-0"
                                          [nzColor]="getCorDocumento(item)"
                                        >
                                          {{ getNomeStatusAnexo(item) }}</nz-tag
                                        >
                                      </td>
                                    </ng-container>

                                    <ng-container
                                      *ngFor="
                                        let column of documentService.getTableColumnsAnexos(
                                          tableColumns
                                        )
                                      "
                                    >
                                      <td
                                        *ngIf="checkListConsulta[column.value]"
                                      >
                                        {{
                                          documentService.prepareColumnValue(
                                            item,
                                            column
                                          )
                                        }}
                                      </td>
                                    </ng-container>
                                    <td
                                      class="cursor-pointer"
                                      [nzRight]="
                                        documentService.getWidthContent() > 1024
                                      "
                                    >
                                      <div class="d-flex">
                                        <div
                                          (click)="getURLAnexo(item)"
                                          class="font-size-25 p-l-20 p-r-20"
                                          nz-tooltip
                                          nzTooltipTitle="Clique para visualizar o anexo"
                                          nzTooltipPlacement="left"
                                        >
                                          <i
                                            nz-icon
                                            [nzType]="'file-pdf'"
                                            theme="twotone"
                                          ></i>
                                        </div>
                                      </div>
                                    </td>
                                    <td
                                      class="cursor-pointer"
                                      [nzRight]="
                                        documentService.getWidthContent() > 1024
                                      "
                                    >
                                      <div class="d-flex">
                                        <div
                                          class="cursor-pointer"
                                          (click)="getURLAnexo(item, true)"
                                          class="font-size-25 p-l-20 p-r-20"
                                          nz-tooltip
                                          nzTooltipTitle="Clique para baixar o anexo"
                                          nzTooltipPlacement="left"
                                        >
                                          <i nz-icon [nzType]="'download'"></i>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr [nzExpand]="item.expand">
                                    <nz-spin
                                      *ngIf="item.expand"
                                      [nzSpinning]="loadingAnexosSig"
                                      [nzSize]="'default'"
                                    >
                                      <nz-table
                                        #innerTable
                                        [nzData]="item.anexoSignatarios"
                                        nzSize="middle"
                                        [nzShowPagination]="false"
                                      >
                                        <thead>
                                          <tr>
                                            <th class="p-l-15">
                                              Data de Assinatura
                                            </th>
                                            <th>Nome</th>
                                            <th
                                              *ngIf="
                                                documentService.getColumnsAnexoStatus(
                                                  tableColumns
                                                )
                                              "
                                            >
                                              Status
                                            </th>
                                            <th>Ações</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let data of innerTable.data"
                                          >
                                            <td>
                                              {{
                                                data?.dataAssinatura
                                                  | date: 'dd/MM/yyyy HH:mm:ss'
                                              }}
                                            </td>
                                            <td>
                                              {{
                                                data?.nome ||
                                                  data?.nomeRepresentante
                                              }}
                                            </td>
                                            <td
                                              *ngIf="
                                                documentService.getColumnsAnexoStatus(
                                                  tableColumns
                                                )
                                              "
                                            >
                                              <nz-tag
                                                class="m-b-0"
                                                [nzColor]="
                                                  getCorDocumento(data)
                                                "
                                              >
                                                {{
                                                  statusDocLabel[data?.status]
                                                }}</nz-tag
                                              >
                                            </td>
                                            <td>
                                              <button
                                                *ngIf="
                                                  data?.status ===
                                                  statusDoc.Pendente
                                                "
                                                nzSize="small"
                                                nz-button
                                                nzType="primary"
                                                (click)="
                                                  notifySigner(
                                                    data?.idPessoaFisica,
                                                    item.idPrivado
                                                  )
                                                "
                                                nz-tooltip
                                                [nzTooltipTitle]="
                                                  getEmail(
                                                    data?.idPessoaFisica,
                                                    indexArr
                                                  )
                                                "
                                                nzTooltipPlacement="topRight"
                                              >
                                                <i
                                                  nz-icon
                                                  nzType="mail"
                                                  nzTheme="outline"
                                                ></i
                                                >Notificar
                                              </button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </nz-table>
                                    </nz-spin>
                                  </tr>
                                </ng-template>
                              </tbody>
                            </nz-table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </nz-collapse-panel>
            </nz-collapse>
          </div>
        </div>
      </nz-spin>

      <div
        class="align-items-center p-10 m-t-10 shadow-default"
        nz-row
        style="background: #fdfdff"
      >
        <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
          <h5>Documento</h5>
        </div>

        <iframe
          *ngIf="file"
          [src]="file"
          type="application/pdf"
          height="467px"
          width="100%"
        ></iframe>
      </div>

      <div
        class="align-items-center p-10 m-t-10 shadow-default"
        nz-row
        style="background: #fdfdff"
      >
        <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
          <h5>Assinaturas</h5>
        </div>

        <div
          *ngFor="let assinante of document.signatarios"
          nz-col
          nzSpan="8"
          class="p-l-20 p-t-10 p-b-10 align-items-center d-flex"
        >
          <div class="d-flex align-items-center">
            <nz-avatar
              class="m-r-10"
              [nzSize]="50"
              [nzIcon]="getIcoStatusSigner(assinante.status)"
              [class]="'ant-avatar-' + getColorStatusSigner(assinante.status)"
              nz-tooltip
              [nzTooltipTitle]="statusDocLabel[assinante.status]"
              nzTooltipPlacement="left"
            ></nz-avatar>
            <div>
              <h5 class="m-b-0 p-r-5">
                {{ assinante.nome || assinante.razaoSocial }}
              </h5>
              <div class="" *ngIf="assinante.signatario">
                <p class="m-b-0 p-r-5 text-color-value">
                  Representante:
                  <!-- {{
                    getNameSubscriber(assinante.dadosPessoaJuridica.signatario)
                  }} -->
                </p>
              </div>
              <div class="">
                <p class="m-b-0 p-r-5 text-color-value">
                  {{ getLabelByStatus(assinante) }}:
                </p>
                <p class="m-b-0 text-color-value">
                  {{ getDateSigned(assinante) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="align-items-center p-10 m-t-10 shadow-default"
        nz-row
        style="background: #fdfdff"
      >
        <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
          <h5>Linha do tempo</h5>
        </div>

        <div class="p-r-15 p-l-15 p-t-15" nz-col nzSpan="24">
          <nz-timeline nzMode="alternate">
            <nz-timeline-item
              [nzDot]="template"
              [nzColor]="getEventColor(event.acao)"
              *ngFor="let event of document.eventos"
            >
              <p>{{ event.data | date: 'dd/MM/yyyy HH:mm:ss' }}</p>
              {{ getDescEventDoc(event.usuario, event.acao, event.empresa) }}
              <ng-template #template>
                <i
                  nz-icon
                  [nzType]="getEventIco(event.acao)"
                  style="font-size: 22px"
                ></i>
              </ng-template>
            </nz-timeline-item>
          </nz-timeline>
        </div>
      </div>

      <ps-fab *ngIf="isActiveFabButton()" [actions]="fabButtons"></ps-fab>
    </div>

    <div
      *ngIf="!document"
      class="valid-doc m-t-20"
      style="max-width: 1200px; margin: auto"
    >
      <nz-card>
        <div class="container justify-content-center d-flex">
          <div class="m-r-30">
            <img
              src="../../assets/icons/invalid.webp"
              style="height: 105px; opacity: 0.8"
            />
          </div>

          <div class="m-t-20">
            <h4>Chave de autenticação inválida!</h4>
            <p>Documento não encontrado na base de dados</p>
          </div>
        </div>
      </nz-card>
    </div>
  </nz-card>
</nz-spin>

<ps-footer
  class="position-{{ document ? 'relative' : 'fixed' }}"
  style="bottom: 0; width: 100%"
  [config]="footerConfig"
>
</ps-footer>
