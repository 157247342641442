<ps-complex-form [title]="'Módulos'" [tabIndex]="tabIndex" [isAntd]="true" (changeTabIndex)="changeTabIndex($event)">
  <div body>
    <form nz-form [formGroup]="formB" nzLayout="vertical">
      <h4 class="m-t-15">Informações básicas</h4>
      <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
      <div nz-row>
        <div class="pl-1 pr-1" nz-col nzSpan="8" nzSm="6" nzXs="24">
          <nz-form-item>
            <nz-form-label>Código</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="idPublico" placeholder="Código" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="pl-1 pr-1" nz-col nzSpan="16" nzSm="18" nzXs="24">
          <nz-form-item>
            <nz-form-label nzRequired>Descrição do módulo</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="descricao" placeholder="Descrição do módulo" maxlength="150" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row>
        <div class="pl-1 pr-1" nz-col nzSpan="8" nzSm="6" nzXs="24">
          <nz-form-item>
            <nz-form-label>Código Módulo Pai</nz-form-label>
            <nz-form-control [nzErrorTip]="'Erro desconhecido'">
              <nz-input-group nzSearch [nzAddOnAfter]="vinculacaoTemplate">
                <input nz-input placeholder="Código modulo vinculado" formControlName="idPublicoModuloPai" />
              </nz-input-group>
              <ng-template #vinculacaoTemplate>
                <button *ngIf="formB.value.idPublicoModuloPai" (click)="limparModuloPai()" nz-button type="button"
                  nzType="text" nzSearch>
                  <i nz-icon nzType="close-circle"></i>
                </button>

                <button (click)="showModalModuloPai()" nz-button type="button" nzType="primary" nzSearch>
                  <i nz-icon nzType="search"></i>
                </button>
              </ng-template>

              <ng-template #codTipoDocTplAnexo let-control>
                <ng-container *ngIf="control.hasError('idPublicoModuloPai')">
                  Valor inválido para módulo!
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">
                  Por favor digite um módulo!
                </ng-container>
              </ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div class="pl-1 pr-1" nz-col nzSpan="16" nzSm="18" nzXs="24">
          <nz-form-item>
            <nz-form-label>Descrição do Módulo Pai</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="descricaoModuloPai" placeholder="Descrição do módulo pai"
                maxlength="150" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
      <div nz-row>
        <div nz-col nzSpan="12" class="text-left d-block">
          <h4 class="p-b-15">Tipos de Documento</h4>
        </div>
        <div nz-col class=" p-b-10" [nzOffset]="10" nzSpan="1" nzSm="1" nzXl="1" nzXs="12">
          <button nz-button [disabled]="!formB.value.entidadeTipoDoc" (click)="limparTipoDocAtestador()"
            nzType="primary" nzDanger>
            <i nz-icon nzType="clear"></i>
          </button>

        </div>

        <div nz-col class="p-b-15" nzSpan="1" nzSm="1" nzXl="1" nzXs="12">

          <button nz-button [disabled]="!(formB.value.entidadeTipoDoc)" (click)="addTipoDoc()" nzType="primary">
            <!--TODO: salvar documento vinculado ao modulo-->
            <i nz-icon nzType="plus"></i>
          </button>

        </div>
      </div>

      <nz-divider class="m-t-0" nzOrientation="left"></nz-divider>
      <nz-spin nzSpan="20" nzXl="20" nzXs="24" [nzSpinning]="loadingTipoDoc">
        <div nz-row [nzGutter]="8">
          <div nz-col nzSpan="6" nzSm="6" nzXl="6" nzXs="24">
            <nz-form-item>
              <nz-form-label nzRequired>Código</nz-form-label>
              <nz-form-control [nzErrorTip]="codTipoDocTpl">
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonTpDoc">
                  <input type="number" nz-input placeholder="Digite o código do tipo de documento"
                    formControlName="idTipoDocPublico" (ngModelChange)="searchTipoDoc($event)" />
                </nz-input-group>
                <ng-template #suffixIconButtonTpDoc>
                  <button *ngIf="formB.value.idTipoDocPublico" (click)="limparEntidadeTipoDoc()" nz-button type="button"
                    nzType="text" nzSearch>
                    <i nz-icon nzType="close-circle"></i>
                  </button>

                  <button (click)="showModalTipoDoc()" nz-button type="button" nzType="primary" nzSearch>
                    <i nz-icon nzType="search"></i>
                  </button>
                </ng-template>

                <ng-template #codTipoDocTpl let-control>
                  <ng-container *ngIf="control.hasError('codTipoDocumento')">
                    Valor inválido para Tipo de documento!
                  </ng-container>
                  <ng-container *ngIf="control.hasError('required')">
                    Por favor digite um tipo de documento!
                  </ng-container>
                </ng-template>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col nzSpan="18" nzSm="18" nzXl="18" nzXs="24">
            <nz-form-item>
              <nz-form-label>Descrição Tipo de documento</nz-form-label>
              <nz-form-control nzHasFeedback>
                <input nz-input class="text-disabled" formControlName="descricaoTipoDocumento"
                  placeholder="Tipo de documento" maxlength="150" />
              </nz-form-control>
            </nz-form-item>
          </div>


        </div>
      </nz-spin>
      <!-- <nz-divider class="m-2" nzOrientation="left"></nz-divider> -->
      <!-- <ng-container *ngIf="formB.value.idTipoDocPublico">
        <div nz-row nzGutter="8">
          <div nz-col nzSpan="23" nzSm="23" nzXl="23" nzXs="23">
            <h4 class="p-l-5 p-l-10">Atestadores</h4>
          </div>
          <div nz-col nzSpan="1" nzSm="1" nzXl="1" nzXs="1">
            <button nz-button [disabled]="!addAtestadorValidate()" (click)="addAtestador()" nzType="primary">
              <i nz-icon nzType="plus"></i>
            </button>
          </div>
        </div>

        <nz-divider class="m-2" nzOrientation="left"></nz-divider>
        <div formArrayName="atestadores">
          <div nz-row nzGutter="8" *ngFor="let atestador of formB.get('atestadores')['controls']; index as i"
            [formGroupName]="i">

            <div nz-col nzSpan="6" nzXl="6" nzLg="12" nzXs="24">
              <nz-form-item>
                <nz-form-label>CPF do Atestador</nz-form-label>
                <nz-form-control>
                  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButtonIdPessoaAtestador">
                    <input (click)="showModalAtestador(i)" [disabled]="!getAtestadorFormBuild(i)" nz-input
                      placeholder="Digite um cpf" formControlName="cpfAtestador" (keydown)="showModalAtestador(i)"
                      [mask]="'000.000.000-00'" [dropSpecialCharacters]="true" />
                  </nz-input-group>
                  <ng-template #suffixIconButtonIdPessoaAtestador>
                    <button (click)="limparEntidadeAtestardor(i)"
                      *ngIf="formB.controls.atestadores.value[i].idPessoaAtestador && !getAtestadorFormBuild(i)"
                      nz-button type="button" nzType="text" nzSearch>
                      <i nz-icon nzType="close-circle"></i>
                    </button>

                    <button (click)="showModalAtestador(i) && !getAtestadorFormBuild(i)" nz-button type="button"
                      nzType="primary" nzSearch>
                      <i nz-icon nzType="search"></i>
                    </button>
                  </ng-template>

                  <ng-template #cpfIdPessoaAtestador let-control>
                    <ng-container *ngIf="control.hasError('cpfPessoaAtestador')">
                      Valor inválido para CPF!
                    </ng-container>

                  </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="11" nzXl="11" nzLg="11" nzXs="24">
              <nz-form-item>
                <nz-form-label>Nome do Atestador</nz-form-label>
                <nz-form-control>
                  <input nz-input class="text-disabled" [disabled]="!getAtestadorFormBuild(i)"
                    formControlName="nomeAtestador" placeholder="Nome do Atestador" maxlength="100" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col nzSpan="6" nzXl="6" nzLg="6" nzXs="6">
              <nz-form-item>
                <nz-form-label nzRequired>Data inicio Atesto</nz-form-label>
                <nz-form-control>
                  <nz-date-picker nzShowTime style="width: 100%;" nzFormat="dd/MM/yyyy HH:mm:ss"
                    formControlName="dataInicioAtesto" nzPlaceHolder="Data de expiração"
                    (ngModelChange)="checkDataExpiracao($event,i)"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>

            <div nz-col nzSpan="1" nzSm="1" nzXl="1" nzXs="12">
              <nz-form-item>
                <nz-form-label>&nbsp;</nz-form-label>
                <nz-form-control>
                  <button nz-button [disabled]="!getAtestadorFormBuild(i)" (click)="limparAtestadorForm(i)"
                    nzType="primary" nzDanger>
                    <i nz-icon nzType="clear"></i>
                  </button>
                </nz-form-control>
              </nz-form-item>

            </div>

            <div nz-col nzSpan="1" nzSm="1" nzXl="1" nzXs="12">
                <nz-form-item>
                  <nz-form-label>&nbsp;</nz-form-label>
                  <nz-form-control>
                    <button nz-button [disabled]="entidadeAtestadorArr[i]" (click)="addAtestador()" nzType="primary">
                      <i nz-icon nzType="plus"></i>
                    </button>
                  </nz-form-control>
                </nz-form-item>
              </div>
          </div>
        </div>
      </ng-container> -->


      <hr class="m-t-25 m-b-25" />

      <div>
        <nz-table class="m-b-20" [nzLoading]="loadingTableTipoDoc" nzSize="small" #tipoDocListTable
          [nzScroll]="{ y: '500px' }" style="overflow-y: overlay" [nzData]="tiposDocumentosAtestadoresVinculados"
          [nzShowPagination]="true">
          <thead>
            <tr>
              <th nzWidth="1rem"></th>
              <ng-container *ngFor="let column of getTableTipoDoc">
                <th [ngStyle]="{ background: '#edf1fd' }" [nzWidth]="column.width"
                  *ngIf="checkListTableTipoDoc[column.columnName]" [nzSortFn]="column.compare">
                  {{ column.label }}
                </th>
              </ng-container>
              <th style="background: #edf1fd; text-align: center" nzWidth="5rem" [nzRight]="true">
                Ações
              </th>
              <th (click)="(tipoDocListTable)" style="background: #edf1fd" [nzRight]="true" nzWidth="3rem">
                <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click"
                  [nzDropdownMenu]="colunasTipoDoc">
                  <i nz-icon nzType="setting"></i>
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-template ngFor let-item [ngForOf]="tipoDocListTable.data">
              <tr>
                <td *ngIf="item.atestadores.length > 0" [(nzExpand)]="item.expand"></td>
                <td *ngIf="item.atestadores.length === 0"></td>
                <ng-container *ngFor="let column of getTableTipoDoc">
                  <td (click)="({column:column,data:tipoDocListTable.nzData,item:item})" class="p-l-15"
                    [ngStyle]="{ width: column.width }" nzWidth="column.width"
                    *ngIf="checkListTableTipoDoc[column.columnName]">
                    {{ prepareColumnValue(item, column) }}
                  </td>
                </ng-container>

                <td nzRight style="text-align-last: center">
                  <button style="visibility: hidden;" nz-button nzSize="small" nzShape="circle" class="border-green">
                    <!-- <i nz-icon nzType="edit"></i> -->
                  </button>
                  <button [disabled]="(formB.value.entidadeTipoDoc)"
                    *ngIf="tiposDocumentosAtestadoresVinculados.length > 0" nz-button nzSize="small" nzShape="circle"
                    (click)="showModalAdicinarNovoAtestadorTipoDoc(item)" class="border-green">
                    <i nz-icon nzType="user-add"></i>
                  </button>
                  <button style="visibility: hidden;" nz-button nz nzSize="small" nzShape="circle" class="border-green">
                    <!-- <i nz-icon nzType="edit"></i> -->
                  </button>
                  <button [disabled]="(formB.value.entidadeTipoDoc)"
                    *ngIf="tiposDocumentosAtestadoresVinculados.length > 0" nz-button nzSize="small" nzShape="circle"
                    (click)="removerTipoDocModulo(item)" nzDanger nz-tooltip
                    [nzTooltipTitle]="formB.value.entidadeTipoDoc ? 'Você não pode deletar um tipo de documento enquanto estiver editando outro.' : ''">
                    <i nz-icon nzType="delete"></i>
                  </button>
                </td>
                <td>

                </td>
              </tr>
              <div *ngIf="item?.atestadores && item?.atestadores?.length >0">
                <tr [nzExpand]="item.expand">



                  <!---------------------------------------------------------------------------->
                  <!---->




                  <nz-table class="m-b-20" nzSize="small" #atestadoresListTable [nzScroll]="{ y: '210px' }"
                    style="overflow-y: overlay" [nzData]="item.atestadores" [nzShowPagination]="true">
                    <thead>
                      <tr>
                        <ng-container *ngFor="let column of getTableAtestadores">
                          <th [ngStyle]="{ background: '#edf1fd' }" [nzWidth]="column.width"
                            *ngIf="checkListTableAtestadores[column.columnName]" [nzSortFn]="column.compare">
                            {{ column.label }}
                          </th>
                        </ng-container>
                        <th style="background: #edf1fd; text-align: center" nzWidth="10rem" [nzRight]="true">
                          Ações
                        </th>
                        <!-- <th (click)="log(AtestadoresListTable)" style="background: #edf1fd" [nzRight]="true"
                          nzWidth="3rem">
                          <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click"
                            [nzDropdownMenu]="colunasAtestadores">
                            <i nz-icon nzType="setting"></i>
                          </button>
                        </th> -->
                      </tr>
                    </thead>
          <tbody>
            <ng-template ngFor let-subItem [ngForOf]="atestadoresListTable?.data">
              <tr>
                <ng-container *ngFor="let column of getTableAtestadores">
                  <td (click)="({column:column,data:atestadoresListTable.nzData,subItem:subItem})" class="p-l-15"
                    [ngStyle]="{ width: column.width }" nzWidth="column.width"
                    *ngIf="checkListTableAtestadores[column.columnName] && !subItem.deleted">
                    {{ prepareColumnValue(subItem, column) }}
                  </td>
                </ng-container>

                <td *ngIf="!subItem.deleted" nzRight style="text-align-last: center">

                  <button *ngIf="tiposDocumentosAtestadoresVinculados.length > 0" nz-button nz nzSize="small"
                    nzShape="circle" (click)="removerAtestador(item, subItem)" nzDanger>
                    <i nz-icon nzType="delete"></i>
                  </button>
                </td>
                <!-- <td>

                  </td> -->
              </tr>
            </ng-template>
          </tbody>
        </nz-table>






        <!---->
        <!---------------------------------------------------------------------------->



        </tr>
      </div>
      </ng-template>
      </tbody>
      </nz-table>
  </div>
  </form>
  <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
  <div table>
    <div class="row">
      <div nz-row nz-col nzSpan="12" nzOffset="12" nzJustify="end">
        <div class="m-b-10 m-r-20">
          <nz-input-group [nzPrefix]="iconLupa">
            <input type="text" nz-input placeholder="Buscar setor" nz-tooltip
              (click)="this.innerWidth <= 748 ? searchModulo() : null"
              nzTooltipTitle="Digite um valor ou clique em consultar para buscar todos os registros"
              [nzTooltipVisible]="tooltipModulo" nzTooltipPlacement="bottom" [(ngModel)]="buscarModuloInput"
              (ngModelChange)="getResult($event)" />
          </nz-input-group>
          <ng-template #iconLupa>
            <i nz-icon nzType="search" class="opacity-05"></i>
          </ng-template>
        </div>
      </div>
    </div>
    <nz-table nzSize="small" [nzLoading]="loading" [nzShowPagination]="true" #table [nzScroll]="{ y: '420px' }"
      style="overflow-y: overlay" [nzData]="displayData">
      <thead>
        <th class="p-t-15 p-b-15" style="background: #edf1fd; width: 1.8rem" [nzLeft]="true"></th>
        <th class="p-t-15 p-b-15" style="background: #edf1fd; width: 1.8rem" [nzLeft]="getWidthContent() > 1024">
          <label nz-checkbox [nzIndeterminate]="checkAllIndeterminate" (ngModelChange)="updateAllChecked()"
            [(ngModel)]="checkedAll"></label>
        </th>
        <th class="p-t-15 p-b-15" style="background: #edf1fd; width: 2rem" [nzLeft]="true"></th>
        <ng-container *ngFor="let column of getTableColumns">
          <th [ngStyle]="{ background: '#edf1fd', width: column.width }" *ngIf="checkList[column.columnName]"
            [nzSortFn]="column.compare">
            {{ column.label }}
          </th>
        </ng-container>
        <th style="background: #edf1fd; width: 4rem">
          <button nz-button nzType="text" nzSize="small" nz-dropdown nzTrigger="click" [nzDropdownMenu]="colunas">
            <i nz-icon nzType="setting"></i>
          </button>
        </th>
      </thead>
      <tbody>
        <ng-template ngFor let-item [ngForOf]="table.data">
          <tr (dblclick)="dblClick(item)" style="cursor: pointer" nzTooltipPlacement="left">
            <td style="width: 1.6rem"></td>

            <td style="width: 2rem" [nzLeft]="getWidthContent() > 1024">
              <label nz-checkbox [(ngModel)]="item.checked"></label>
            </td>
            <td style="width: 2rem"></td>

            <ng-container *ngFor="let column of getTableColumns">
              <td class="p-l-15" [ngStyle]="{ width: column.width }" *ngIf="checkList[column.columnName]">
                {{ prepareColumnValue(item, column) }}
              </td>
            </ng-container>
            <td style="width: 4rem"></td>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
    <ps-fab [actions]="fabButtons"></ps-fab>
  </div>
</ps-complex-form>

<nz-dropdown-menu #colunas="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let column of getTableColumns">
      <label nz-checkbox [(ngModel)]="checkList[column.columnName]">{{
        column.label
        }}</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetar()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>
<nz-dropdown-menu #colunasTipoDoc="nzDropdownMenu">
  <ul nz-menu>
    <li nz-menu-item *ngFor="let column of getTableTipoDoc">
      <label nz-checkbox [(ngModel)]="checkListTableTipoDoc[column.columnName]">{{
        column.label
        }}</label>
    </li>
    <li nz-menu-item class="text-center">
      <button nz-button nzType="text" nzSize="small" (click)="resetar()">
        Resetar
      </button>
    </li>
  </ul>
</nz-dropdown-menu>