<div class="header">
  <div class="header-child">
    <div class="nav-wrap d-flex flex-sm-row flex-column">
      <div class="nav-left">
        <nz-avatar [nzSize]="32" nzSrc="global-assets/images/logo3.png"></nz-avatar>
        <h4 class="m-l-9 mt-2 m-r-15">
          <span>
            <b style="padding: 7px">
              <a style="color: rgb(55, 92, 130)" [href]="'https://www.publicsoft.com.br/'">PublicSoft
              </a>
            </b>
          </span>
        </h4>
      </div>
    </div>
  </div>
</div>

<ng-template #cardTitle>
  <h4>
    <i class="m-r-10" nz-icon nzType="file-protect"></i> Verificador de
    documentos
  </h4>
</ng-template>
<nz-card [nzTitle]="cardTitle" style="margin-top: 80px !important; max-width: 1200px; margin: auto">
  <div class="container m-t-30">
    <div class="m-t-20">
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input type="text" [(ngModel)]="psHash" nz-input placeholder="Digite a chave de autenticação do documento" />
      </nz-input-group>
      <ng-template #suffixButton>
        <button nz-button nzType="primary" [nzLoading]="loading" nzSize="large" (click)="executeValidation()" nzSearch
          type="submit">
          <i nz-icon nzType="check"></i>Validar
        </button>
      </ng-template>
    </div>

    <div class="m-t-20">
      <p>
        Aqui você pode verificar o documento submetido para assinatura através
        do hash gerado no manifesto de assinatura.
      </p>
      <p>
        Para validar a autenticidade das assinaturas acesse:
        <a href="https://validar.iti.gov.br/" target="_blank">https://validar.iti.gov.br/</a>
      </p>
    </div>
  </div>
</nz-card>

<div #cardValidDoc class="valid-doc m-t-20" style="max-width: 1200px; margin: auto">
  <nz-card>
    <div *ngIf="urlDoc" class="container justify-content-center d-flex">
      <div class="m-t-15 m-r-30">
        <img src="../../assets/icons/valid.png" style="height: 80px; opacity: 0.8" />
      </div>

      <div class="m-t-20">
        <h4>Chave de autenticação válida!</h4>
        <a (click)="showDoc()">Clique aqui para visualizar o documento</a>
      </div>
    </div>

    <div *ngIf="!urlDoc" class="container justify-content-center d-flex">
      <div class="m-r-30">
        <img src="../../assets/icons/invalid.webp" style="height: 105px; opacity: 0.8" />
      </div>

      <div class="m-t-20">
        <h4>Chave de autenticação inválida!</h4>
        <p>Documento não encontrado na base de dados</p>
      </div>
    </div>
  </nz-card>
</div>

<ps-footer style="bottom: 0; width: 100%; position: fixed; background-color: white" [config]="footerConfig"></ps-footer>