<nz-spin [nzSpinning]="loading" [nzSize]="'large'">
  <div>
    <div class="header">
      <div class="header-child">
        <div class="nav-wrap d-flex flex-sm-row flex-column">
          <div class="nav-left">
            <nz-avatar [nzSize]="32" [nzSrc]="'../favicon.ico'"> </nz-avatar>
            <h5 class="m-l-15 mt-2 m-r-15">Publicsoft</h5>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="exportacaoIsValid && !loading">
      <div class="header">
        <div class="header-child">
          <div class="nav-wrap d-flex flex-sm-row flex-column">
            <div class="nav-left">
              <nz-avatar
                [nzSize]="32"
                [nzSrc]="'../../favicon.ico'"
              ></nz-avatar>
              <h5 class="m-l-15 mt-2 m-r-15">Publicsoft</h5>
            </div>
          </div>
        </div>
      </div>

      <ng-template #cardTitle>
        <h4>
          <i class="m-r-10" nz-icon nzType="folder-open"></i> Arquivos
          Exportados
        </h4>
      </ng-template>

      <nz-card
        [nzTitle]="cardTitle"
        [nzLoading]="loading"
        style="
          margin-top: 100px !important;
          margin-bottom: 100px !important;
          max-width: 1290px;
          margin: auto;
        "
      >
        <div>
          <div
            class="align-items-center p-10 shadow-default"
            nz-row
            style="background: #edf1fd"
          >
            <div nz-col nzSpan="8">
              <div class="media align-items-center">
                <div class="p-l-15">
                  <h5 class="m-b-0">Status da exportação</h5>
                  <h4 class="m-b-0">
                    <b>{{
                      validateDate(filesExporteds?.dtExpiracao)
                        ? 'Ativo'
                        : 'Inativo'
                    }}</b>
                  </h4>
                </div>
              </div>
            </div>

            <div class="p-r-15" nz-col nzSpan="14" nzOffset="2">
              <div nz-row>
                <div
                  nz-col
                  nzSpan="24"
                  class="p-l-15 align-items-center form-group"
                  style="text-align: end"
                >
                  <h5 class="m-b-0">Expiração da exportação</h5>
                  <h5 class="m-b-0 p-l-5 p-r-10">
                    <b>{{
                      filesExporteds?.dtExpiracao
                        | date: 'dd/MM/yyyy - HH:mm:ss'
                    }}</b>
                    <!-- <b>{{validateDate(filesExporteds.dtExpiracao)}}</b> -->
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div
            class="align-items-center p-10 m-t-10 shadow-default"
            nz-row
            style="background: #fdfdff"
          >
            <div
              class="p-l-15 p-t-10 m-b-10 align-items-center"
              nz-col
              nzSpan="24"
            >
              <h5 class="m-b-0">Dados da exportação</h5>
              <!-- <h6 class="text-color-value">
              asdf
            </h6> -->
            </div>

            <div nz-col nzSpan="24" class="d-flex">
              <div
                nz-col
                nzSpan="12"
                class="p-l-15 p-t-10 align-items-center d-flex"
              >
                <div class="form-group">
                  <!-- <div class="d-flex">
                  <h6 class="m-b-0 p-r-5">Tipo do documento:</h6>
                  <h6 class="m-b-0 text-color-value">asdf</h6>
                </div> -->

                  <!-- <div *ngIf="true" class="d-flex">
                  <h6 class="m-b-0 p-r-5">Número do documento:</h6>
                  <h6 class="m-b-0 text-color-value">adsf</h6>
                </div> -->

                  <div class="d-flex">
                    <h6 class="m-b-0 p-r-5">Data da criação da exportação:</h6>
                    <h6 class="m-b-0 text-color-value">
                      {{
                        service.getDateFormatted3h(filesExporteds?.dtCriacao)
                      }}
                    </h6>
                  </div>
                  <div class="d-flex">
                    <h6 class="m-b-0 p-r-5">
                      Data da alteração da exportação:
                    </h6>
                    <h6
                      *ngIf="
                        !(
                          filesExporteds?.dtAlteracao ===
                          filesExporteds?.dtCriacao
                        )
                      "
                      class="m-b-0 text-color-value"
                    >
                      {{
                        service.getDateFormatted3h(filesExporteds?.dtAlteracao)
                      }}
                    </h6>
                  </div>
                  <div class="d-flex">
                    <h6 class="m-b-0 p-r-5">Usuário criador:</h6>
                    <h6 class="m-b-0 text-color-value">
                      {{ usCriacaoName }}
                    </h6>
                  </div>
                </div>
              </div>

              <div
                nz-col
                nzSpan="12"
                nzSm="12"
                nzXs="24"
                class="p-l-15 p-t-10 align-items-center d-flex"
              >
                <div class="form-group">
                  <!-- <div class="d-flex">
                  <h6 class="m-b-0 p-r-5">Unidade Gestora:</h6>
                  <h6 class="m-b-0 text-color-value">
                    {{filesExporteds.idUnidadeGestora}}
                  </h6>
                </div> -->

                  <div class="d-flex">
                    <h6 class="m-b-0 p-r-5">Unidade Gestora:</h6>
                    <h6 class="m-b-0 text-color-value">
                      {{ ugName }}
                    </h6>
                  </div>
                  <!-- <div  class="d-flex">
                  <h6 class="m-b-0 p-r-5">Favorecido:</h6>
                  <h6 class="m-b-0 text-color-value">
                    asdf
                  </h6>
                </div> -->

                  <div class="d-flex">
                    <h6 class="m-b-0 p-r-5">Descrição:</h6>
                    <h6 class="m-b-0 text-color-value">
                      {{ filesExporteds?.descricao }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <nz-spin class="m-t-5" [nzSpinning]="treeLoading" [nzSize]="'large'">
            <div
              class="align-items-center p-10 m-t-10 shadow-default"
              nz-row
              style="background: #fdfdff"
            >
              <div class="p-l-15 p-t-10 align-items-center" nz-col nzSpan="24">
                <h5>Arquivos Exportados</h5>
              </div>
              <div class="col-md-24 col-xl-12 p-b-0 p-l-0 p-r-0">
                <div
                  class="row tree-view m-t-10"
                  [style]="docNodes ? null : 'background-color:#FDFDFF;'"
                >
                  <div class="col-md-24 col-xl-12 p-b-15 p-l-10 p-r-10">
                    <nz-tree
                      nzBlockNode
                      class="bg-wgrey p-t-10 p-b-10 tree-view"
                      [nzData]="docNodes"
                      (nzDblClick)="openFolder($event)"
                      [nzTreeTemplate]="nzTreeTemplate"
                      (nzCheckBoxChange)="nzEvent($event)"
                    ></nz-tree>
                    <ng-template
                      #nzTreeTemplate
                      let-node
                      let-origin="origin"
                      class="bg-grey"
                    >
                      <span class="custom-node">
                        <span *ngIf="!node.isLeaf">
                          <i
                            nz-icon
                            [nzType]="
                              node.isExpanded ? 'folder-open' : 'folder'
                            "
                          ></i>
                          <span class="folder-name">{{ node.title }}</span>
                          <!-- <span class="folder-desc">created by {{ origin.author | lowercase }}</span> -->
                        </span>
                        <span *ngIf="node.isLeaf" (click)="openFile(node)">
                          <i
                            nz-icon
                            nzType="file-pdf"
                            twoToneColor="#ff0000"
                            theme="twotone"
                          ></i>
                          <span class="file-name">{{ node.title }}</span>
                          <!-- <span class="file-desc">modified by {{ origin.author | lowercase }}</span> -->
                        </span>
                      </span>
                    </ng-template>
                    <nz-dropdown-menu #menu="nzDropdownMenu">
                      <!-- <ul nz-menu>
              <li nz-menu-item (click)="selectDropdown()">Action 1</li>
              <li nz-menu-item (click)="selectDropdown()">Action 2</li>
            </ul> -->
                    </nz-dropdown-menu>
                  </div>
                </div>
              </div>
            </div>
          </nz-spin>

          <!-- <ps-fab *ngIf="isActiveFabButton()" [actions]="fabButtons"></ps-fab> -->
        </div>
      </nz-card>
    </div>

    <div
      *ngIf="!exportacaoIsValid && !loading"
      class="container-fluid p-v-20 h-100"
    >
      <div class="d-flex flex-column justify-content-between h-100">
        <div class="container">
          <div class="row align-items-center items">
            <div class="col-md-8 m-h-auto">
              <div class="text-center m-t-100">
                <img
                  class="img-fluid w-90"
                  src="assets/images/others/error-2.png"
                  alt=""
                />
                <h2 class="font-weight-light font-size-30 m-t-60 m-b-20">
                  Desculpe, você não tem acesso aos arquivos exportados...
                </h2>
                <p class="w-70 lh-1-8 m-h-auto font-size-17">
                  Certifique-se da validade dos arquivos exportados ou entre em
                  contato com o usuário criador da exportação
                </p>
                <!-- <button [nzType]="'primary'" class="m-l-10 btn-actions" nz-button type="button" (click)="goBack()">
                Voltar
              </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="true" style="height: 5vh"></div>

    <ps-footer
      class="position-fixed"
      style="
        bottom: 0;
        width: 100%;
        z-index: 999;
        background-color: #edf1f7;
        height: 10vh;
      "
      [config]="footerConfig"
    >
    </ps-footer>
    <ps-fab *ngIf="exportacaoIsValid" [actions]="fabButtons"></ps-fab>
  </div>
</nz-spin>
