import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthService,
  CodigoModulos,
  DocumentService,
  DocumentsPendingService,
  DocumentsUploadService,
  ExportacaoArquivosService,
  IAssinante,
  IDocumento,
  IDocumentoAnexo,
  ModulosPublicSoft,
  StatusCertificado,
} from '@ps-erp-angular/shared';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'ps-digitalizacao-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements OnInit {
  @Input() docsSelecionados: IDocumento[];
  @Input() qtdSelecionados: number;
  @Input() reativarDocs: boolean = false;
  @Input() qtdAssinantes: number;
  @Input() valorSelecionado: string;
  @Input() existMotivo: boolean;
  @Input() assinanteLogado: any;
  @Input() signatarios: any;
  @Input() tipoDocumento: any;
  @Input() selecionarCertificado: any;
  @Input() isPrinter: boolean;
  @Input() ratificarAnexo: boolean;
  //file-exporteds
  @Input() filesExporteds: any;
  @Input() uuidUpload: string;
  @Input() configs: any;
  isValid;
  docNodes;
  uGName;

  //print-document
  docsPrintChecked = true;
  anxsPrintChecked = false;

  //formatResultGetDocuments
  formLoading;
  displayData: any = '';
  flagQueryParams;
  documentList;
  // docsChecked;
  loading;
  tableColumns;
  //exportação de arquivos - confirmar exportação
  docsSelecteds: number;
  anexsSelecteds: number;
  dtExpiracao: string;
  treeDocs;
  signatariosLength: number;

  //mensagens-envio
  mensagem;
  mensagemEnvio;

  @Output() assinarAnexo = new EventEmitter();
  motivo: string;
  certByDocumentForSign = [];
  detailAnexo = [];
  certsSelecteds = [];
  certificados = [];
  formB: FormGroup = new FormGroup({});
  listSignatarios: IAssinante[];
  passwordVisible = false;
  certSelected: any;
  password: string;
  attachToSign: boolean;
  allChecked: boolean;
  docsChecked: any;
  anexoSelecionado: IDocumentoAnexo[];
  labelAnexo: string;
  reativar: boolean = false;
  ratificarDoc = false;
  innerWidth;

  constructor(
    public notification: NzNotificationService,
    private formBuilder: FormBuilder,
    public service: DocumentsUploadService,
    private exportacaoService: ExportacaoArquivosService,
    public documentService: DocumentService,
    private authService: AuthService,
    private documentsPendingService: DocumentsPendingService
  ) {
    this.formB = this.formBuilder.group({
      certificadoDigitalB64: [null, Validators.required],
      senhaCert: [null, Validators.required],
      dataExpiracao: [null],
      passwordCert: [null],
      assinarAnexos: [true],
      ratificarTodos: [true],
      ratificarAnexos: [true],
    });
  }

  validateDate(date: string | Date) {
    return !this.documentService.docIsExpirado(date);
  }

  async visualizarExportacao(uuidUpload) {
    // window.location.assign(uuidUpload);
    //link para testar local
    //const link = `http://localhost:5504/arquivos-exportados/${uuidUpload}`;
    const link = `${this.service.getUrlAppDigitalizacao()}/arquivos-exportados/${uuidUpload}`;
    const usName = this.filesExporteds.usCriacao;
    const cnpjUg = await this.authService.getUserInfo().cnpjUg;
    const ugName = (await this.authService.getUgSelected()).nome;
    const uri = encodeURI(`cnpjUg=${cnpjUg}&ugName=${ugName}&usName=${usName}`);
    if (this.innerWidth <= 768) {
      return uuidUpload ? window.location.assign(`${link}?${uri}`) : null;
    }
    return uuidUpload ? window.open(`${link}?${uri}`, '_blank') : null;
  }

  async copiarLinkExportacao(uuidUpload) {
    const link = `${this.service.getUrlAppDigitalizacao()}/arquivos-exportados/${uuidUpload}`;
    const usName = this.filesExporteds.usCriacao;
    const cnpjUg = await this.authService.getUserInfo().cnpjUg;
    const ugName = (await this.authService.getUgSelected()).nome;
    const uri = encodeURI(`cnpjUg=${cnpjUg}&ugName=${ugName}&usName=${usName}`);
    navigator.clipboard.writeText(`${link}?${uri}`);
    this.notification.info(
      'Exportação de arquivos',
      'O link da exportação foi copiado para a área de transferência'
    );
  }

  checkDataExpiracao(data) {
    if (data) {
      const today = new Date(this.authService.getDateSelected()).getTime();
      const selectedDate = new Date(data).getTime();

      if (selectedDate < today) {
        return this.service.notification.warning(
          'Formulário',
          'Data de expiração não pode ser anterior a data de hoje'
        );
      }
      this.reativar = true;
      return;
    }
  }

  async openFile(data) {
    if (await data.origin.author?.documentoAnexo) {
      const uuid = data.origin.author.infoUpload.uuidArquivoConfirmado;
      const urlDocToView = await this.documentService.getUrlDocToUploadApi(
        uuid,
        'VisualizarAssinado'
      );
      if (this.innerWidth <= 768) {
        return uuid ? window.location.assign(urlDocToView.pdfURL) : null;
      }
      return uuid ? window.open(urlDocToView.pdfURL, '_blank') : null;
    }
    return await this.documentService.getURLAnexo(data.origin.author, false);
  }

  async openFolder(data) {
    if (data instanceof NzTreeNode) {
      data.isExpanded = !data.isExpanded;
    } else {
      const node = data.node;
      if (node) {
        node.isExpanded = !node.isExpanded;
      }
    }
  }

  nzExpandSwitch(data) {
    if (data instanceof NzTreeNode) {
      return (data.isExpanded = !data.isExpanded);
    }
    const node = data.node;
    if (node) {
      return (node.isExpanded = !node.isExpanded);
    }
  }
  ngOnInit(): void {
    (async (e) => {
      this.uGName = (await this.authService.getUgSelected()).nome;
    })();

    if (this.mensagem) {
      this.mensagem = this.mensagem;
    }

    this.innerWidth = window.innerWidth;
    if (this.filesExporteds) {
      this.buildTreeView();
    }

    // if (this.filesExporteds) {
    //   this.buildTreeView();
    //   // this.isValid = this.filesExporteds?.isValid;
    //   this.docNodes = [this.filesExporteds.tree];
    // }

    if (!this.allChecked) {
      this.certByDocumentForSign = this.docsSelecionados?.map((doc) => {
        this.signatariosLength = doc.signatarios.length;
        const certificados: any = this.getCertDocument(this.assinanteLogado);
        const getAnexosByChecked = doc.documentoAnexo
          .filter((key) => key.checked === true)
          .map((anx) => ({
            idPrivadoAnexo: anx.idPrivado,
          }));

        const getAnexos = doc.documentoAnexo.map((anx) => ({
          idPrivadoAnexo: anx.idPrivado,
        }));

        return {
          signatarios: doc.signatarios,
          idDoc: doc.idPrivado,
          certificados,
          anexos:
            getAnexosByChecked.length > 0 ? getAnexosByChecked : getAnexos,
          ratificarDoc: this.ratificarDoc,
          numeroDoc: doc.numeroDoc,
          historicoDoc: doc.historicoDoc,
          ratificarAnexo:
            this.attachToSign && this.verifyIfAnexoRatificado()
              ? false
              : this.ratificarAnexo,
          descricaoCert: certificados.descricaoCert,
          uuidCert: certificados.uuidCertificado,
          passwordVisible: false,
          passwordCert: '',
          motivo: '',
          allChecked: this.allChecked,
        };
      });

      this.updateAssinarAnexo(this.formB.value.assinarAnexos);
      this.updateRatificar(this.formB.value.ratificarTodos);
      this.updateRatificarAnexo(this.formB.value.ratificarAnexos);
      this.verifySigners();
      return;
    }

    this.certByDocumentForSign = this.docsSelecionados?.map((doc) => {
      const getAnexosByChecked = doc.documentoAnexo
        .filter((key) => key.checked === true)
        .map((anx) => ({
          idPrivadoAnexo: anx.idPrivado,
        }));

      this.signatariosLength = doc.signatarios.length;

      const getAnexos = doc.documentoAnexo.map((anx) => ({
        idPrivadoAnexo: anx.idPrivado,
      }));

      const certificados: any = this.getCertDocument(this.assinanteLogado);
      return {
        signatarios: doc.signatarios,
        idDoc: doc.idPrivado,
        numeroDoc: doc.numeroDoc,
        historicoDoc: doc.historicoDoc,
        certificados,
        ratificarDoc: this.ratificarDoc,
        ratificarAnexo: this.ratificarAnexo,
        anexos: getAnexosByChecked.length > 0 ? getAnexosByChecked : getAnexos,
        descricaoCert: certificados.descricaoCert,
        uuidCert: certificados.uuidCertificado,
        passwordVisible: false,
        passwordCert: '',
        motivo: '',
        allChecked: this.allChecked,
      };
    });

    this.updateAssinarAnexo(this.formB.value.assinarAnexos);
    this.updateRatificar(this.formB.value.ratificarTodos);
    this.updateRatificarAnexo(this.formB.value.ratificarAnexos);
    this.verifySigners();
  }

  async buildTreeView() {
    this.formLoading = true;
    this.filesExporteds = {
      ...(
        await this.exportacaoService.getFileExportByUuidUpload(
          this.filesExporteds.uuidUpload,
          true
        )
      ).data,
      usCriacao: this.filesExporteds.usCriacao,
    };

    const documentos: IDocumento[] = this.filesExporteds.documentos.map(
      (doc) => {
        return {
          ...doc,
          documentoAnexo: this.filesExporteds.anexos.filter(
            (item) => item.idDocumento === doc.idPrivado
          ),
        };
      }
    );

    await this.formatResultGetDocuments(documentos);
    let arrOrders: string[] = [
      `nomeUnidadeGestora`,
      'tipoDoc',
      'dataDocumentoFormated',
      'numeroDoc',
    ];
    await this.docNodesTreeView(this.documentList, arrOrders);
    this.formLoading = false;
  }

  async setConfigTreeFilter() {
    this.loading = true;
    await this.documentsPendingService
      .getDocumentTableProps()
      .then((result) => {
        Object.assign(this, result);
        this.documentService.setCompareToTableColumns(
          this.displayData,
          this.tableColumns
        );
      });
    this.loading = false;
  }

  async docNodesTreeView(docs: IDocumento[], ordenator: string[]) {
    const result = [];
    const levels = { result };
    docs.forEach(async (doc: IDocumento, index, docs) => {
      ordenator.reduce((r, e) => {
        const title = doc[e];
        if (!r[title]) {
          let value: any;
          if (e !== ordenator[ordenator.length - 1]) {
            value = { title, key: uuidv4(), children: [] };
            r[title] = { result: value.children };
          } else {
            value = [
              {
                title: `Doc ${doc.numeroDoc}`,
                key: uuidv4(),
                children: [
                  {
                    title: doc.numeroDoc,
                    author: doc,
                    key: uuidv4(),
                    isLeaf: true,
                    children: [],
                  },
                ],
              },
            ];

            if (doc.documentoAnexo.length != 0) {
              value[0].children.push({
                title: 'Anexos',
                key: uuidv4(),
                children: [],
              });
              for (const [index, anexo] of doc.documentoAnexo.entries()) {
                value[0].children[value[0].children.length - 1].children.push({
                  title: anexo.nome,
                  key: uuidv4(),
                  isLeaf: true,
                  author: anexo,
                  children: [],
                });
              }
            }

            r[title] = { result: value.children };
          }
          Array.isArray(value)
            ? r.result.push(...value)
            : r.result.push(...[value]);
        }
        return r[title];
      }, levels);
    });

    this.docNodes = result;
    return;
  }

  async formatResultGetDocuments(documents: IDocumento[], identifyer = false) {
    this.formLoading = true;
    const docs = documents.filter(async (doc) => {
      // const dataLimiteAssinatura = doc.dataLimiteAssinatura?.slice(
      //   0,
      //   doc.dataLimiteAssinatura.lastIndexOf('.'),
      // );

      return;
      // (
      // doc.signatarios.some(
      //   (ass) => ass.status === StatusDocumento.Assinado,
      // ) ||
      // doc.statusDocumento === StatusDocumento.Pendente ||
      // doc.statusDocumento === StatusDocumento.Confirmado ||
      // doc.statusDocumento === StatusDocumento.Desacordo ||
      // doc.statusDocumento === StatusDocumento.Cancelado ||
      // new Date(dataLimiteAssinatura) < new Date()
      // );
    });

    docs.map(async (doc: IDocumento) => {
      await this.documentService.formatColumnsDocForDysplay(
        doc,
        this.listSignatarios
      );
    });

    const unidadeGestora = await this.authService.getUgSelected();

    this.displayData = await documents.map((itm) => {
      return {
        ...itm,
        dataDocumentoFormated: moment(itm.dataDocumento)
          .utcOffset(0, true)
          .format('DD/MM/YYYY'),
        tipoDoc: `${itm.tipoDocumento.idPublico} - ${itm.tipoDocumento.tipo}`,
        aplicacaoOrigemDetalhe:
          ModulosPublicSoft['Assinatura Digital'] === itm.aplicacaoOrigem
            ? 'Avulso'
            : CodigoModulos[itm.aplicacaoOrigem],
        nomeUnidadeGestora: unidadeGestora.nome,
        expand: false,
      };
    });

    if (this.flagQueryParams === true) {
      this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
    }

    // if (identifyer) {
    //   this.pageTotal = this.displayData.length;
    // }

    this.documentList = this.displayData;

    // if (this.docsChecked.length > 0) {
    //   for (const newDoc of this.docsChecked) {
    //     const index = this.displayData.findIndex(
    //       (a) => a.idPrivado === newDoc.idPrivado,
    //     );

    //     if (index !== -1) {
    //       this.displayData.splice(index, 1);

    //       this.displayData.push(newDoc);
    //       this.displayData.sort((a, b) => b.idPrivado - a.idPrivado);
    //     }
    //   }
    // }
    await this.setConfigTreeFilter();
    this.formLoading = false;
    // this.resetFabButtons();
  }

  label() {
    return this.ratificarDoc ? 'ratificar' : 'assinar';
  }

  verifyIfRatificado(): boolean {
    return this.docsSelecionados.some((key) => key.ratificado === true)
      ? true
      : false;
  }

  verifyIfAnexoRatificado(): boolean {
    if (this.docsSelecionados[0].documentB64) {
      return false;
    }
    for (const anexo of this.docsSelecionados) {
      return anexo?.documentoAnexo?.some((key) => key.ratificado === true)
        ? true
        : false;
    }
  }

  verifySigners(): boolean {
    const verify = this.signatariosLength === 1 ? true : false;

    if (verify) {
      this.service.ratificarEvent(false);
    }
    return verify;
  }

  updateAssinarAnexo(value: boolean) {
    this.service.anexoEvent(value);
    // this.formB.controls.assinarAnexos.setValue(value);
  }

  updateRatificar(value) {
    this.service.ratificarEvent(value);
  }

  updateRatificarAnexo(value: boolean) {
    this.service.ratificarAnexoEvent(value);
  }

  upload = (file): boolean => {
    if (file.name.lastIndexOf('.pfx') === -1) {
      setTimeout(() => {
        this.certificados = [];
        this.notification.error('Arquivo', 'Tipo de arquivo inválido!');
      }, 1000);
      return false;
    }
    this.certificados = [file];
    this.formB.controls.certificadoDigitalB64.setValue(file);
    return false;
  };

  getCertsSelecteds() {
    this.certByDocumentForSign.map((certificado) => {
      if (
        !this.certsSelecteds.find(
          (certSelected) => certSelected.uuidCert === certificado.uuidCert
        )
      ) {
        this.certsSelecteds.push(certificado);
      }
    });
    return this.certsSelecteds;
  }

  updatePasswordCerts(value) {
    value ? (this.password = value) : (this.password = undefined);
    this.certsSelecteds.map((certSelected) => {
      this.certByDocumentForSign.map((certForSign) => {
        if (certForSign.uuidCert === certSelected.uuidCert) {
          certForSign.passwordCert = certSelected.passwordCert;
        }
      });
    });
  }

  getCertDocument(assinanteLogado) {
    if (assinanteLogado) {
      const dataHoje = new Date(this.authService.getDateSelected());

      return assinanteLogado?.find(
        (propsCert) =>
          propsCert.status === StatusCertificado.Valido &&
          new Date(propsCert.dtFinalCert).getTime() > dataHoje.getTime()
      );
    }
  }
}
