<nz-spin [nzSpinning]="loadingPage" nzSize="large">
  <ps-complex-form
    [title]="'Envio de Mensagens'"
    [tabIndex]="tabIndex"
    [isAntd]="true"
    (changeTabIndex)="changeTabIndex($event)"
  >
    <div table>
      <div class="row is-not-mobile">
        <div class="col-md-24 col-xl-12 p-b-15">
          <nz-collapse nzGhost>
            <nz-collapse-panel [nzHeader]="'Filtros'" [nzActive]="true">
              <div nz-row class="d-flex">
                <div nz-col nzSpan="24" nzXs="24">
                  <div class="p-l-10 p-b-15" nz-col nzSpan="24">
                    <div class="p-r-10">
                      <div class="p-l-10" nz-col nzSpan="24">
                        <div class="p-r-10" nz-col nzXs="24">
                          <nz-select
                            [ngModelOptions]="{ standalone: true }"
                            style="width: 22rem"
                            nzShowSearch
                            nzAllowClear
                            [(ngModel)]="assinanteSelectedMensagemEnvio"
                            nzPlaceHolder="Selecione um destinatário"
                            (ngModelChange)="
                              addFiltroPorAssinanteMensagensEnvio(
                                assinanteSelectedMensagemEnvio
                              )
                            "
                          >
                            <nz-option
                              *ngFor="
                                let assinante of listSignatariosMensagemEnvio
                              "
                              [nzValue]="assinante"
                              [nzLabel]="assinante.text"
                            >
                            </nz-option>
                          </nz-select>
                          <!--Status-->

                          <nz-avatar
                            *ngIf="true"
                            (click)="setFilterType(1)"
                            class="m-l-10"
                            [nzSize]="40"
                            nzIcon="save"
                            [nzTooltipVisible]="tooltipVisible[1]"
                            nz-tooltip
                            nzTooltipTitle="Filtrar por mensagens gravadas"
                            nzTooltipPlacement="topRight"
                            [ngClass]="
                              filterType.includes(1)
                                ? 'ant-avatar-gold'
                                : 'ant-avatar-dark'
                            "
                          ></nz-avatar>
                          <nz-avatar
                            *ngIf="true"
                            (click)="setFilterType(2)"
                            class="m-l-10"
                            [nzSize]="40"
                            nzIcon="send"
                            [nzTooltipVisible]="tooltipVisible[2]"
                            nz-tooltip
                            nzTooltipTitle="Filtrar por mensagens enviadas"
                            nzTooltipPlacement="topRight"
                            [ngClass]="
                              filterType.includes(2)
                                ? 'ant-avatar-green'
                                : 'ant-avatar-dark'
                            "
                          ></nz-avatar>

                          <nz-avatar
                            *ngIf="false"
                            (click)="setFilterType(3)"
                            class="m-l-10"
                            [nzSize]="40"
                            nzIcon="clock-circle"
                            [nzTooltipVisible]="tooltipVisible[3]"
                            nz-tooltip
                            nzTooltipTitle="Filtrar por mensagens programadas"
                            nzTooltipPlacement="topRight"
                            [ngClass]="
                              filterType.includes(3)
                                ? 'ant-avatar-blue'
                                : 'ant-avatar-dark'
                            "
                          >
                          </nz-avatar>
                          <nz-avatar
                            *ngIf="true"
                            (click)="setFilterType(4)"
                            class="m-l-10"
                            [nzSize]="40"
                            nzIcon="close-circle"
                            [nzTooltipVisible]="tooltipVisible[4]"
                            nz-tooltip
                            nzTooltipTitle="Filtrar por mensagens não enviadas"
                            nzTooltipPlacement="topRight"
                            [ngClass]="
                              filterType.includes(4)
                                ? 'ant-avatar-orange'
                                : 'ant-avatar-dark'
                            "
                          >
                          </nz-avatar>
                        </div>

                        <div nz-row>
                          <div
                            class="m-t-10"
                            nz-col
                            nzSpan="8"
                            nzXl="8"
                            nzLg="12"
                            nzXs="24"
                          >
                            <nz-select
                              style="width: 350px"
                              nzPlaceHolder="Selecione o campo"
                              [(ngModel)]="fieldMensagensEnvioSelected"
                              (ngModelChange)="
                                getFilterMensagemEnvioOptionsSelect(
                                  fieldMensagensEnvioSelected
                                )
                              "
                            >
                              <nz-option
                                *ngFor="
                                  let interval of arrColumnsMensagensEnvioSelect
                                "
                                [nzValue]="interval"
                                [nzLabel]="interval?.text"
                              >
                              </nz-option>
                            </nz-select>
                          </div>
                          <nz-input-group
                            *ngIf="
                              fieldMensagensEnvioSelected &&
                              fieldMensagensEnvioSelected.type === 'input'
                            "
                            class="m-l-10 m-t-10"
                            nz-col
                            nzSpan="8"
                            nzXl="8"
                            nzLg="12"
                            nzXs="24"
                            [nzPrefix]="prefixTemplate"
                          >
                            <input
                              type="text"
                              class="text-disabled"
                              nz-input
                              placeholder="Informe um valor"
                              [(ngModel)]="searchInput"
                            />
                          </nz-input-group>

                          <div
                            *ngIf="
                              fieldMensagensEnvioSelected &&
                              tabIndex == 1 &&
                              fieldMensagensEnvioSelected.type ===
                                'range-picker'
                            "
                            nzSpan="8"
                            nzXl="8"
                            nzLg="8"
                            nzXs="24"
                            class="m-l-10 m-t-10"
                          >
                            <nz-range-picker
                              nzFormat="dd/MM/yyyy"
                              [(ngModel)]="searchInput"
                            ></nz-range-picker>
                          </div>

                          <div
                            nz-col
                            *ngIf="fieldMensagensEnvioSelected"
                            nzSpan="2"
                            nzXl="2"
                            nzXs="12"
                            class="m-l-10 m-t-10"
                          >
                            <button
                              nz-button
                              [disabled]="!searchInput"
                              (click)="addFiltroValuesPorCampo()"
                              nzType="primary"
                            >
                              <i nz-icon nzType="plus"></i>
                            </button>
                          </div>
                        </div>
                        <div nz-row class="m-t-20">
                          <div
                            *ngIf="showComboTagsMensagensEnvio"
                            nz-col
                            nzSpan="12"
                            nzXl="12"
                            nzLg="12"
                            nzXs="24"
                          >
                            <nz-input-group [nzPrefix]="' '">
                              <div class="align-self-center">
                                <nz-tag
                                  style="margin-bottom: 0"
                                  [nzColor]="'blue'"
                                  [nzMode]="'closeable'"
                                  (nzOnClose)="
                                    removeFiltroMensagemPorField(
                                      fieldsValuesTag
                                    )
                                  "
                                  class="filters"
                                  *ngFor="
                                    let fieldsValuesTag of fieldsMensagensEnvioSelected;
                                    let i = index
                                  "
                                >
                                  {{ fieldsValuesTag.text }}
                                </nz-tag>
                              </div>
                            </nz-input-group>
                          </div>
                          <div
                            nz-col
                            [class]="'m-t-10 p-l-20'"
                            [nzOffset]="showComboTagsMensagensEnvio ? 8 : 20"
                            nzSpan="3"
                            nzXl="3"
                            nzLg="3"
                            nzXs="24"
                          >
                            <button
                              class="m-l-10"
                              nzSize="small"
                              nz-button
                              nzType="primary"
                              (click)="filterMensagensEnvio()"
                            >
                              <i nz-icon nzType="filter" nzTheme="outline"></i
                              >Aplicar Filtro
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
      </div>

      <nz-table
        nzSize="small"
        [nzLoading]="loadingTable"
        [nzShowPagination]="false"
        #MensagemEnvioListTable
        [nzScroll]="{ y: '420px' }"
        style="overflow-y: overlay"
        [nzData]="displayData"
      >
        <thead>
          <tr>
            <!-- <th class="p-t-15 p-b-15" style="background: #edf1fd " [nzLeft]="getWidthContent() > 1024" nzWidth="15px">
            </th> -->
            <th
              class="p-t-15 p-b-15"
              style="background: #edf1fd"
              [nzLeft]="getWidthContent() > 1024"
              nzWidth="5px"
            ></th>
            <th
              class="p-t-15 p-b-15"
              style="background: #edf1fd"
              nzWidth="3rem"
              [nzLeft]="getWidthContent() > 1024"
            ></th>
            <th
              class="p-t-15 p-b-15"
              style="background: #edf1fd"
              nzWidth="3rem"
              [nzLeft]="getWidthContent() > 1024"
            >
              <label
                nz-checkbox
                [nzIndeterminate]="checkAllIndeterminate"
                (ngModelChange)="updateAllChecked()"
                [(ngModel)]="checkedAll"
              ></label>
            </th>

            <ng-container *ngFor="let column of tableMensagemEnvioColumns">
              <th
                class="p-t-15 p-b-15"
                nzWidth="150px"
                *ngIf="checkList[column.value]"
                nzWidth="{{ column.width }}"
                [nzSortFn]="column.compare"
              >
                {{ column.text }}
              </th>
            </ng-container>
          </tr>
        </thead>
        <tbody>
          <ng-template
            ngFor
            let-item
            [ngForOf]="MensagemEnvioListTable.data"
            let-index="index"
          >
            <tr
              (click)="selectItem(item)"
              (dblclick)="editarMensagemEnvio(item)"
              style="cursor: pointer"
              nzTooltipPlacement="left"
            >
              <td [nzLeft]="getWidthContent() > 1024"></td>
              <td
                [nzLeft]="getWidthContent() > 1024"
                [(nzExpand)]="item.expand"
                (click)="expandTableRow(item, index)"
                [nzLeft]="getWidthContent() > 1024"
              ></td>
              <!-- <td [nzLeft]=" getWidthContent()> 1024" [(nzExpand)]="item.expand"
                (click)="modifyDocument(item, item.expand, indexArr)">
              </td> -->
              <td [nzLeft]="getWidthContent() > 1024">
                <label
                  nz-checkbox
                  [(ngModel)]="item.checked"
                  (ngModelChange)="selectItem(item)"
                ></label>
              </td>
              <ng-container *ngFor="let column of tableMensagemEnvioColumns">
                <td
                  *ngIf="checkList[column.value] && column.value === 'status'"
                >
                  <nz-tag
                    class="m-b-0"
                    [nzColor]="getCorStatusMensagem(item[column.value])"
                  >
                    {{ item[column.value] }}&nbsp;{{
                      item.qntdDestinatariosEnviado
                    }}/{{ item.qntdDestinatarios }}
                  </nz-tag>
                </td>
                <td
                  *ngIf="
                    checkList[column.value] &&
                    (column.value === 'dtCriacao' ||
                      column.value === 'dtAlteracao' ||
                      column.value === 'dtProgramado')
                  "
                >
                  {{ service.getDateFormatted3h(item[column.value]) }}
                </td>
                <td
                  *ngIf="checkList[column.value] && column.value === 'dtEnvio'"
                >
                  {{ service.getDateFormatted(item[column.value]) }}
                </td>
                <td
                  *ngIf="
                    checkList[column.value] &&
                    !(
                      column.value === 'dataLimiteAssinatura' ||
                      column.value === 'dtCriacao' ||
                      column.value === 'dtAlteracao' ||
                      column.value === 'dtEnvio' ||
                      column.value === 'dtProgramado' ||
                      column.value === 'status'
                    )
                  "
                >
                  {{ prepareColumnValue(item, column) }}
                </td>
              </ng-container>
            </tr>

            <tr [nzExpand]="item.expand">
              <nz-spin
                *ngIf="item.expand"
                [nzSpinning]="loadingDestinatarios"
                [nzSize]="'default'"
              >
                <nz-table
                  #innerTable
                  [nzData]="item.destinatarios"
                  nzSize="middle"
                  [nzShowPagination]="false"
                >
                  <thead>
                    <tr>
                      <th nzWidth="15px"></th>
                      <th class="p-l-15" [nzSortFn]="sort.nomPessoa">Nome</th>
                      <th [nzSortFn]="sort.status">Status</th>
                      <th [nzSortFn]="sort.emailPricipal">Email</th>
                      <th [nzSortFn]="sort.telefone">Número</th>
                      <th [nzSortFn]="sort.dtEnvio">Data Envio</th>
                      <!-- <th>Ações</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let destinatarios of innerTable.data">
                      <td></td>
                      <td>{{ destinatarios.nomPessoa }}</td>
                      <td>
                        <nz-tag
                          class="m-b-0"
                          [nzColor]="
                            getCorStatusMensagem(destinatarios['status'])
                          "
                        >
                          {{ destinatarios['status'] }}
                        </nz-tag>
                      </td>
                      <td>{{ destinatarios.emailPricipal }}</td>
                      <td>{{ phoneMask(destinatarios.telefone) }}</td>
                      <td>
                        {{ service.getDateFormatted3h(destinatarios.dtEnvio) }}
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-spin>
            </tr>
          </ng-template>
        </tbody>
      </nz-table>
      <nz-pagination
        style="text-align: end"
        [nzSize]="'small'"
        [nzPageIndex]="1"
        [nzTotal]="pageTotal"
        (nzPageIndexChange)="pageChange($event)"
      ></nz-pagination>
      <ps-fab [actions]="fabButtons"> </ps-fab>
    </div>

    <div body>
      <nz-spin [nzSpinning]="false">
        <form nz-form [formGroup]="formB" nzLayout="vertical">
          <div nz-row>
            <div class="p-r-20" nz-col nzSpan="8" nzSm="8" nzXs="24">
              <nz-select
                [ngModelOptions]="{ standalone: true }"
                style="width: 22rem"
                nzShowSearch
                nzAllowClear
                [(ngModel)]="assinanteSelected"
                nzPlaceHolder="Selecione um destinatário"
                (ngModelChange)="addFiltroPorAssinante(assinanteSelected)"
              >
                <nz-option
                  *ngFor="let assinante of listSignatarios"
                  [nzValue]="assinante"
                  [nzLabel]="assinante.text"
                >
                </nz-option>
              </nz-select>
            </div>
          </div>

          <div nz-row class="m-t-20">
            <div class="p-r-20" nz-col nzSpan="24" nzSm="24" nzXs="24">
              <div *ngIf="true">
                <nz-form-label nzRequired>Destinatários</nz-form-label>
                <nz-input-group [nzPrefix]="' '">
                  <div class="align-self-center">
                    <nz-tag
                      style="margin-bottom: 0"
                      [nzColor]="'blue'"
                      [nzMode]="'closeable'"
                      (nzOnClose)="removeFiltroPorField(signatarioValuesTag, i)"
                      class="filters"
                      *ngFor="
                        let signatarioValuesTag of signatariosSelected;
                        let i = index
                      "
                    >
                      {{ signatarioValuesTag.text }}
                    </nz-tag>
                  </div>
                </nz-input-group>
              </div>
            </div>
          </div>

          <div nz-row class="m-t-20">
            <div
              *ngIf="false"
              class="p-r-20"
              nz-col
              nzSpan="3"
              nzSm="3"
              nzXs="24"
            >
              <nz-form-item>
                <nz-form-label>código privado da mensagem</nz-form-label>
                <input
                  type="number"
                  formControlName="idPrivado"
                  nz-input
                  placeholder="Código da mensagem"
                />
              </nz-form-item>
            </div>
            <div class="p-r-20" nz-col nzSpan="3" nzSm="3" nzXs="24">
              <nz-form-item>
                <nz-form-label>Código</nz-form-label>
                <input
                  type="number"
                  formControlName="idPublico"
                  nz-input
                  placeholder="Código da mensagem"
                />
              </nz-form-item>
            </div>
            <div class="p-r-20" nz-col nzSpan="5" nzSm="5" nzXs="24">
              <nz-form-item>
                <nz-form-label>Código modelo</nz-form-label>
                <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton">
                  <input
                    type="number"
                    formControlName="idModelo"
                    nz-input
                    placeholder="Código da mensagem modelo"
                    (click)="showModalTipoMensagem($event)"
                  />
                </nz-input-group>
                <ng-template #suffixIconButton>
                  <button
                    nz-button
                    nzType="primary"
                    (click)="showModalTipoMensagem($event)"
                    nzSearch
                  >
                    <span nz-icon nzType="search"></span>
                  </button>
                </ng-template>
              </nz-form-item>
            </div>

            <div class="p-r-20" nz-col nzSpan="16" nzSm="16" nzXs="24">
              <nz-form-item>
                <nz-form-label nzRequired>Título da mensagem</nz-form-label>
                <nz-form-control>
                  <input
                    nz-input
                    formControlName="titulo"
                    placeholder="Título da mensagem"
                  />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <!-- <div nz-row> -->
          <div class="p-r-15">
            <nz-form-item>
              <nz-form-label nzRequired
                >Texto da mensagem&nbsp;<span
                  nz-icon
                  nzType="info-circle"
                  nzTheme="outline"
                  nzTooltipTitle="Para usar as chaves disponíveis adicione um '@' e as opções aparecerão"
                  nzTooltipPlacement="top"
                  nz-button
                  nz-tooltip
                ></span
              ></nz-form-label>
              <nz-form-control [nzErrorTip]="'preencha o campo corretamente'">
                <nz-mention [nzSuggestions]="suggestions" [nzPrefix]="['@']">
                  <textarea
                    nz-input
                    formControlName="texto"
                    [nzAutosize]="{ minRows: 3, maxRows: 999 }"
                    placeholder="Texto da mensagem"
                    nzAutosize
                    nzMentionTrigger
                  ></textarea>
                </nz-mention>
              </nz-form-control>
            </nz-form-item>
          </div>
          <!-- </div> -->
        </form>
      </nz-spin>
      <ps-fab [actions]="fabButtons"></ps-fab>
    </div>
  </ps-complex-form>
</nz-spin>
