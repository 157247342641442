import { PeriodVisualizeDoc } from './../types/document.types';
import {
  IAssinante,
  IEventTableDetailDocument,
  IResponseDocsPerPeriod,
  IDocumento,
} from './../interfaces/document-interfaces';
import * as faker from 'faker';
import { StatusDocumento } from '../enum/const.enum';

export class DocumentsMocks {
  // static getDocumentsPerPeriod(interval: number, typePeriod: PeriodVisualizeDoc, customInterval: string[]): IResponseDocsPerPeriod[] {
  //   let docsPerPeriod = [];
  //   if (typePeriod === 'Day') {
  //     docsPerPeriod = [
  //       { name: '22', data: this.generateListDocs('12', 5) },
  //       { name: '21', data: this.generateListDocs('11', 4) },
  //       { name: '20', data: this.generateListDocs('10', 3) },
  //       { name: '19', data: this.generateListDocs('09', 6) },
  //       { name: '18', data: this.generateListDocs('08', 10) },
  //       { name: '17', data: this.generateListDocs('07', 15) },
  //       { name: '16', data: this.generateListDocs('07', 15) },
  //     ] as IResponseDocsPerPeriod[];
  //   }
  //   if (typePeriod === 'Week') {
  //     docsPerPeriod = [
  //       { name: '21/12', data: this.generateListDocs('12', 12) },
  //       { name: '14/12', data: this.generateListDocs('11', 20) },
  //       { name: '07/12', data: this.generateListDocs('10', 15) },
  //       { name: '30/11', data: this.generateListDocs('09', 16) },
  //     ] as IResponseDocsPerPeriod[];
  //   }
  //   if (typePeriod === 'Month') {
  //     docsPerPeriod = [
  //       { name: 'Dez', data: this.generateListDocs('12') },
  //       { name: 'Nov', data: this.generateListDocs('11') },
  //       { name: 'Out', data: this.generateListDocs('10') },
  //       { name: 'Set', data: this.generateListDocs('09') },
  //       { name: 'Ago', data: this.generateListDocs('08') },
  //       { name: 'Jul', data: this.generateListDocs('07') },
  //     ] as IResponseDocsPerPeriod[];
  //   }
  //   if (typePeriod === 'Year') {
  //     docsPerPeriod = [
  //       { name: '2020', data: this.generateListDocs('12', 800) },
  //       { name: '2019', data: this.generateListDocs('11', 700) },
  //       { name: '2018', data: this.generateListDocs('10', 750) },
  //       { name: '2017', data: this.generateListDocs('09', 900) },
  //       { name: '2016', data: this.generateListDocs('08', 550) },
  //     ] as IResponseDocsPerPeriod[];
  //   }
  //   if (customInterval) {
  //     docsPerPeriod = [
  //       { name: `${customInterval[0]} - ${customInterval[1]}`, data: this.generateListDocs('09', 10000) },
  //     ] as IResponseDocsPerPeriod[];
  //   }
  //   return docsPerPeriod;
  //   // return docsPerPeriod.slice(0, interval);
  // }
  // static getDocumentById(idDocumento: string): IDocumento {
  //   return this.generateDocument('10');
  // }
  // static getAllDocuments() {
  //   const docs = [
  //     ...this.generateListDocs('12'),
  //     ...this.generateListDocs('11'),
  //     ...this.generateListDocs('10'),
  //     ...this.generateListDocs('09'),
  //     ...this.generateListDocs('08'),
  //   ];
  //   return docs;
  // }
  // static generateListDocs(month: string, numDocs = 100): IDocumento[] {
  //   const docs = [];
  //   for (let i = 0; i < faker.random.number({ min: numDocs / 2, max: numDocs }); i++) {
  //     docs.push(this.generateDocument(month));
  //   }
  //   return docs;
  // }
  // static generateDocument(month: string): IDocumento {
  //   const assinantes: IAssinante[] = [];
  //   for (let i = 0; i < faker.random.number({ min: 1, max: 3 }); i++) {
  //     const status = this.getStatusBalanceAssinante();
  //     assinantes.push({
  //       nome: faker.name.findName(),
  //       email: faker.internet.exampleEmail(),
  //       status,
  //       dataAssinatura: status === StatusDocumento.Assinado ? `${this.getValueDayDataFormat(5)}/${month}/2020` : '',
  //       dadosPessoaFisica: {
  //         cpf: faker.random.number({ min: 10000000000, max: 99999999999 }) ,
  //       }
  //     });
  //   }
  //   const events: IEventTableDetailDocument[] = [];
  //   for (let i = 0; i < faker.random.number({ min: 1, max: 3 }); i++) {
  //     events.push(
  //       {
  //         acao: 'Assinatura',
  //         data: new Date(),
  //         usuario: faker.name.findName(),
  //       },
  //       {
  //         acao: 'Download',
  //         data: new Date(),
  //         usuario: faker.name.findName(),
  //       },
  //       {
  //         acao: 'Visualização',
  //         data: new Date(),
  //         usuario: faker.name.findName(),
  //       },
  //     );
  //   }
  //   return {
  //     _id: faker.random.number({ min: 1000000000, max: 9999999999 }),
  //     aplicacaoOrigem: faker.random.number({ min: 1, max: 7 }),
  //     dtCriacao: new Date(),
  //     numeroDoc: faker.random.number(),
  //     usuarioCriacao: faker.random.number(),
  //     unidadeGestora: faker.company.companyName(),
  //     unidadeOrc: faker.company.companyName(),
  //     statusDocumento: this.getStatusBalanceDoc(assinantes) ,
  //     tipo: faker.name.jobTitle(),
  //     valorDoc: Number(faker.commerce.price()),
  //     docBase64: '',
  //     dataLimiteAssinatura: `${this.getValueDayDataFormat()}/0${faker.random.number({ min: 1, max: 2 })}/2021`,
  //     expand: false,
  //     dataDoc: `${this.getValueDayDataFormat()}/${month}/2020`,
  //     body: {},
  //     infoUpload: {
  //       uuidArquivoOriginal: '',
  //     },
  //     informacoesAdicionais: {},
  //     favorecido: {
  //       nome: faker.name.findName(),
  //       email: faker.internet.exampleEmail(),
  //     },
  //     assinantes,
  //     events
  //   };
  // }
  // static getValueDayDataFormat(max = 30) {
  //   const day = faker.random.number({ min: 1, max });
  //   return day < 10 ? `0${day}` : day;
  // }
  // static getStatusBalanceAssinante(): number {
  //   const status = faker.random.number({ min: 1, max: 30 });
  //   if (status <= 20 ) {
  //     return StatusDocumento.Assinado;
  //   }
  //   if (status >= 22 && status <= 30 ) {
  //     return StatusDocumento.Pendente;
  //   }
  //   return StatusDocumento.Desacordo;
  // }
  // static getStatusBalanceDoc(assinantes: IAssinante[]): number {
  //   if (assinantes.find(ass => ass.status === StatusDocumento.Desacordo ) ) {
  //     return StatusDocumento.Desacordo;
  //   }
  //   if ( assinantes.length === assinantes.filter(ass => ass.status === StatusDocumento.Assinado ).length ) {
  //     return StatusDocumento.Confirmado;
  //   }
  //   if ( assinantes.find(ass => ass.status === StatusDocumento.Assinado ) ) {
  //     return StatusDocumento.Assinado;
  //   }
  //   return StatusDocumento.Pendente;
  // }
  // static getAllEventsOneDocument(): IEventTableDetailDocument[] {
  //   return [
  //     {
  //       data: new Date(),
  //       acao: 'Visualização',
  //       usuario: 'Vitor Gouveia',
  //     },
  //     {
  //       data: new Date(),
  //       acao: 'Download',
  //       usuario: 'Vitor Gouveia',
  //     },
  //     {
  //       data: new Date(),
  //       acao: 'Assinatura',
  //       usuario: 'Vitor Gouveia',
  //     },
  //     {
  //       data: new Date(),
  //       acao: 'Download',
  //       usuario: 'Isaque Mendes',
  //     },
  //     {
  //       data: new Date(),
  //       acao: 'Desacordo',
  //       usuario: 'Isaque Mendes',
  //     },
  //   ];
  // }
}
